import React, { Component }  from 'react';
import "./styles/Workbookmenustyle.css"
import {  BookOpenIcon,PencilIcon } from '@heroicons/react/solid'
import imagen from '../../../assets/img-startcups-inc.png';
import Propositodevida from './Propositodevida';
import DesignThinking from './DesignThinking';
import LeanStartup from './LeanStartup';
import BusinessModelCanvas from './BusinessModelCanvas';
import DireccionEstrategica from './DireccionEstrategica';
import Mercadotecnia from './Mercadotecnia';
import Finanzas from './Finanzas';
import Legal from './Legal';
import Contabilidad from './Contabilidad';
import PitchDeck from './PitchDeck';
import UnitsEconomics from './UnitsEconomics';
import ValuacionAvanzada from './ValuacionAvanzada';

class Workbook_menu extends Component{
    constructor(props){
        super(props);
        this.state={
            readOnly:true,
            titulo:this.props.title,
            seleccion:'PV'
        }
        //this.props.regreso('Proposito de vida');
    }
    editar(){
        this.setState({readOnly:!this.state.readOnly});
        document.getElementById('titleproject').focus();
    }
    cambio(info){
        //this.props.regreso(info);
    }
    render(){
        return(
            <div className='flex'>
                <div className='mt-8 radios w-80'>
                <div className='grid grid-cols-3 flex justify-between'>
                    <div className='flex justify-center'>
                        <img src={imagen} alt="img" className="object-scale-down h-8"/>
                    </div>
                    <div className='w-1'>
                        <input type='text' value={this.state.titulo} onChange={(e)=>{this.setState({titulo:e.target.value})}} className='w-32' id='titleproject' readOnly={this.state.readOnly}/>
                    </div>
                    <div className='pr-3 flex justify-end'>
                        <button onClick={this.editar.bind(this)}><PencilIcon className='w-4' color='#fece01'/></button>
                    </div>
                </div>
                <div className='workbook flex mt-8'>
                    <BookOpenIcon className='w-4 mr-2'/>Workbook
                </div>
                <div className='flex mt-4'>
                    <input type="radio" name="rGroup" id="r1" defaultChecked />
                    <label className="radio flex" htmlFor='r1' onClick={()=>{this.setState({seleccion:'PV'});this.cambio('Proposito de vida')}}>Propósito Emprendedor </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r2"/>
                    <label className="radio flex" htmlFor="r2"  onClick={()=>{this.setState({seleccion:'DT'});this.cambio('Design Thinking')}}>Design Thinking </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r3"/>
                    <label className="radio flex" htmlFor="r3"  onClick={()=>{this.setState({seleccion:'LS'});this.cambio('Lean Startup')}}>Lean Startup </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r4"/>
                    <label className="radio flex" htmlFor="r4"  onClick={()=>{this.setState({seleccion:'BMC'});this.cambio('Business Model Canvas')}}>Business Model Canvas </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r5"/>
                    <label className="radio flex" htmlFor="r5"  onClick={()=>{this.setState({seleccion:'M'});this.cambio('Mercadotecnia')}}>Marketing </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r6"/>
                    <label className="radio flex" htmlFor="r6"  onClick={()=>{this.setState({seleccion:'DE'});this.cambio('Dirección Estratégica')}}>Dirección Estratégica </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r11"/>
                    <label className="radio flex" htmlFor="r11"  onClick={()=>{this.setState({seleccion:'UE'});this.cambio('Units Economics')}}>Units Economics </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r7"/>
                    <label className="radio flex" htmlFor="r7"  onClick={()=>{this.setState({seleccion:'F'});this.cambio('Finanzas')}}>Finanzas </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r8"/>
                    <label className="radio flex" htmlFor="r8"  onClick={()=>{this.setState({seleccion:'L'});this.cambio('Legal')}}>Legal </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r9"/>
                    <label className="radio flex" htmlFor="r9"  onClick={()=>{this.setState({seleccion:'C'});this.cambio('Contabilidad')}}>Contabilidad </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r10"/>
                    <label className="radio flex" htmlFor="r10"  onClick={()=>{this.setState({seleccion:'PD'});this.cambio('Pitch Deck')}}>Pitch Deck </label>
                </div>
                <div className='flex'>
                    <input type="radio" name="rGroup" id="r12"/>
                    <label className="radio flex" htmlFor="r12"  onClick={()=>{this.setState({seleccion:'VA'});this.cambio('Valuacion Avanzada')}}>Valuación Avanzada </label>
                </div>
            </div>
                <div className='w-full mr-8 md:mr-4'>
                    {this.state.seleccion==='PV'&&<Propositodevida />}
                    {this.state.seleccion==='DT'&&<DesignThinking />}
                    {this.state.seleccion==='LS'&&<LeanStartup />}
                    {this.state.seleccion==='BMC'&&<BusinessModelCanvas />}
                    {this.state.seleccion==='M'&&<Mercadotecnia />}
                    {this.state.seleccion==='DE'&&<DireccionEstrategica />}
                    {this.state.seleccion==='UE'&&<UnitsEconomics />}
                    {this.state.seleccion==='F'&&<Finanzas />}
                    {this.state.seleccion==='L'&&<Legal />}
                    {this.state.seleccion==='C'&&<Contabilidad />}
                    {this.state.seleccion==='PD'&&<PitchDeck />}
                    {this.state.seleccion==='VA'&&<ValuacionAvanzada />}
                </div>
               {/*} <div className=''>
                    <div className='fixed bottom-0 guardado'>
                        <div className='flex p-2'>
                            <div className='continuar'>Deseas continuar mas tarde...</div>
                            
                            <div><button className='guardar flex'>Guardar <SaveIcon className='w-4 ml-2'/></button></div>
                            <div><button className='revision flex'>Enviar a revisión<CloudUploadIcon className='w-4 ml-2' /></button></div>
                        </div>
                    </div>
        </div>*/}
            </div>
            
        )
    }
   

}

export default Workbook_menu;