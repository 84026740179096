import React from 'react';
import './styles/postimg.css';
import profile from '../../assets/profile.png';
import imagen from '../../assets/escritorio.jpg';
import { DotsHorizontalIcon, ChatAltIcon, ShareIcon,HeartIcon } from '@heroicons/react/solid';

const Postimg=props =>{
    return (
        <div className="cuadro w-full h-auto">
            <div className="flex">
                <div className="flex-none"><img src={profile} className=" w-14 h-14 mr-6 " alt="profile"/></div>
                <div className="name flex-none"><div>Usuario</div>
                    <span className="info">CEO STARTCUPS</span>
                </div>
                <div className="flex-grow">
                    <button className="float-right"><DotsHorizontalIcon className="w-6" /></button>
                </div>               
            </div>
            <div className="texto mt-4 mb-4">
                Este texto es muestra del contenido que puede tener un post asi como el tamaño de los textos y la extension que pudiera tener estos mismo
            </div>
            <div className="grid justify-items-center mt-1">
                <img src={imagen} className=" w-auto h-48 rounded-2xl" alt="img"/>
            </div>
            <div className="reacciones">
                <button ><ChatAltIcon className="w-5 mr-1"/></button>4
                <button className="ml-4"><ShareIcon className="w-5 mr-1"/></button>8
                <button className="ml-4"><HeartIcon className="w-5 mr-1" /></button>32
                <div className="float-right">
                    16m
                </div>
            </div>

        </div>
    );
};

export default Postimg;