import { Component } from "react";
import './styles/ValuacionAvanzada.css'
import { ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'

class ValuacionAvanzada extends Component{
    constructor(props){
        super(props);
        this.state={//Guardar en base de datos
            GR:0.0,
            EBITDAM:0.0,
            CoD:0.0,
            TR:0.0,
            yTreasury:0.0,
            Beta:0.0,
            MR:0.0,
            EQV:0.0,
            DEV:0.0,
            FCF:[{EBIT:0,DA:0,CapEx:0,NCWC:0}],
            WACC:{
                CE:0.0,
                DDE:0.0,
                EDE:0.0,
                WACC:0.0
            },
            TV:{
                EBITDA:0.0,
                ExEBITDA:0.0,
                PG:0.0,
                Avg:0.0
            },
            D:{
                DF:[],
                PVFCF:[],
                PVTV:0.0,
                EV:0.0
            },
            EV:0.0,
            Cash:0.0,
            MS:0.0,
            STD:0.0,
            LTD:0.0,
            SO:0.0,
            mvc:{
                ccr:0.0,
                incrementoV:0.0,
                td:0.0,
                paev:0.0,
                mvc:0.0
            }
        }
    }
    assumptions(e){
        let state=this.state;
        state[e.target.id]=parseFloat(e.target.value);
        this.setState({[this.state]:state});
    }
    calculoWACC(){
        let wacc=this.state.WACC;
        wacc.CE=parseFloat((this.state.yTreasury+this.state.Beta*(this.state.MR-this.state.yTreasury)).toFixed(2));
        wacc.DDE=parseFloat((this.state.DEV/(this.state.DEV+this.state.EQV)).toFixed(2));
        wacc.EDE=parseFloat(((this.state.EQV/(this.state.DEV+this.state.EQV))).toFixed(2));
        wacc.WACC=parseFloat((wacc.EDE*wacc.CE+wacc.DDE*this.state.CoD*(1-this.state.TR/100)).toFixed(2));
        this.setState({WACC:wacc},()=>this.calculoTV());
    }
    calculoTV(){
        let tv=this.state.TV;
        tv.EBITDA=parseFloat((this.state.FCF[this.state.FCF.length-1].EBIT+this.state.FCF[this.state.FCF.length-1].DA).toFixed(2));
        tv.ExEBITDA=parseFloat(((this.state.FCF[this.state.FCF.length-1].EBIT+this.state.FCF[this.state.FCF.length-1].DA)*this.state.EBITDAM).toFixed(2));
        tv.PG=parseFloat(((this.state.FCF[this.state.FCF.length-1].EBIT-(this.state.FCF[this.state.FCF.length-1].EBIT*(this.state.TR/100))+this.state.FCF[this.state.FCF.length-1].DA-this.state.FCF[this.state.FCF.length-1].CapEx-this.state.FCF[this.state.FCF.length-1].NCWC)*(1+this.state.GR/100)/(((this.state.EQV/(this.state.DEV+this.state.EQV))*(this.state.yTreasury+this.state.Beta*(this.state.MR-this.state.yTreasury))/100+(this.state.DEV/(this.state.DEV+this.state.EQV))*(this.state.CoD/100)*(1-this.state.TR/100))-(this.state.GR/100))).toFixed(2));
        tv.Avg=parseFloat(((tv.ExEBITDA+tv.PG)/2).toFixed(2));
        this.setState({TV:tv},()=>this.calculoD());
    }
    calculoD(){
        let d=this.state.D;
        d.DF=[];
        d.PVFCF=[];
        for(let i=0;i<this.state.FCF.length;i++){
            let cant=parseFloat((1/Math.pow((1+(this.state.WACC.WACC/100)),(i+1))).toFixed(2))
            d.DF.push(cant)
            let cant2=cant*(this.state.FCF[i].EBIT-(this.state.FCF[i].EBIT*(this.state.TR/100))+this.state.FCF[i].DA-this.state.FCF[i].CapEx-this.state.FCF[i].NCWC);
            d.PVFCF.push(parseFloat(cant2.toFixed(2)));
            d.PVTV=parseFloat((cant*this.state.TV.Avg).toFixed(2));
        }
        let ev=0
        for(let i=0;i<d.PVFCF.length;i++){
            ev+=parseFloat(d.PVFCF[i]);
        }
        ev+=parseFloat(d.PVTV);
        d.EV=parseFloat(ev.toFixed(2));
        this.setState({D:d});
    }
    calculomvc(){
        let mvc=this.state.mvc;
        let ccr=mvc.ccr;
        let td=mvc.td/100;
        let paev=mvc.paev/100;
        let multiplo=0;
        let incrementoV=mvc.incrementoV;
        let numanos=this.state.FCF.length;
        /*for(let i=0;i<this.state.crecimientoAnual.length;i++){
            incrementoV+=parseFloat(this.state.crecimientoAnual[i]);
        }
        incrementoV=incrementoV/this.state.crecimientoAnual.length;*/
        if(incrementoV>=350){
            multiplo=8;
        }
        if(incrementoV<350&&incrementoV>=325){
            multiplo=7.5;
        }
        if(incrementoV<325&&incrementoV>=300){
            multiplo=7;
        }
        if(incrementoV<300&&incrementoV>=275){
            multiplo=6.5;
        }
        if(incrementoV<275&&incrementoV>=250){
            multiplo=6;
        }
        if(incrementoV<250&&incrementoV>=225){
            multiplo=5.5;
        }
        if(incrementoV<225&&incrementoV>=200){
            multiplo=5;
        }
        if(incrementoV<200&&incrementoV>=175){
            multiplo=4.5;
        }
        if(incrementoV<175&&incrementoV>=150){
            multiplo=4;
        }
        if(incrementoV<150&&incrementoV>=125){
            multiplo=3.5;
        }
        if(ccr<0.06){
            multiplo+=.5
        }
        if(ccr<.125){
            multiplo+=.5
        }
        if(ccr<.25){
            multiplo+=.5
        }
        if(ccr<.50){
            multiplo+=.5
        }
        if(ccr<.75){
            multiplo+=.5
        }
        if(ccr<1){
            multiplo+=.5
        }
        if(ccr>=1.5){
            multiplo-=.5
        }
        if(ccr>=2){
            multiplo-=.5
        }
        if(ccr>=2.5){
            multiplo-=.5
        }
        if(ccr>=3){
            multiplo-=.5
        }
        if(ccr>=3.5){
            multiplo-=.5
        }
        if(ccr>=4){
            multiplo-=.5
        }
        if(multiplo>=8){
            multiplo=8;
        }
        if(multiplo<=1.5){
            multiplo=1.5
        }
        let fcf=(this.state.FCF[this.state.FCF.length-1].EBIT-(this.state.FCF[this.state.FCF.length-1].EBIT*(this.state.TR/100))+this.state.FCF[this.state.FCF.length-1].DA-this.state.FCF[this.state.FCF.length-1].CapEx-this.state.FCF[this.state.FCF.length-1].NCWC);
        mvc.mvc=((fcf*multiplo)*(1-paev))/Math.pow(1+td,numanos);
        mvc.mvc=parseFloat(mvc.mvc.toFixed(2));
        this.setState({[this.state.mvc]:mvc});
    }
    
    render(){
        return(
            <div className="ml-8">
                <div className="">

                    <div className="col-span-2 title">

                        Valuación Avanzada
                    </div>

                    <div className="flex">

                    <div className="subtitle mr-10 active mt-4 w-26" id="VA" >
                        Valuación Avanzada
                        <div className="rectangle" id="rectVA"></div>
                    </div>
                    
                    </div>
                   
                    <div className='lineamenuPV w-full'></div> 

                </div>
                <div className="mt-4"  id="VAQuestions">
                <div className="bg-gray-100 rounded-xl pb-2">
                    
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[0].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=0;i<2;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>VALUACIÓN AVANZADA <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="flex  accordion">
                     <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0"  allowFullScreen></iframe></div>
                     <div className="text-sm text-gray-400 mt-6 pr-40">Un propósito de vida es la razón o razones por las que te levantas en la mañana, aquellas motivaciones intrínsecas que dan un sentido de dirección y significado a la propia existencia, y que con dicho propósito será con el que te enfocarás a crear tu nueva empresa, la cual se convertirá en tu parque de diversiones y disfrutarás cada momento trabajando en y por ella.</div>
                     </div>
                </div>
                <div>
                    <div>Discounted Cash Flow Full</div>
                    <div className="flex">
                        <div className="mr-48">
                            <div>Assumptions pt1</div>
                            <div className="grid grid-cols-2">
                                <div>Growth rate (GDP) </div>
                                <div><input type="number" className="w-24" id="GR" onBlur={this.assumptions.bind(this)} defaultValue={this.state.GR}/></div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>EBITDA multiple</div>
                                <div><input type="number" className="w-24" id="EBITDAM" onBlur={this.assumptions.bind(this)} defaultValue={this.state.EBITDAM}/></div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Cost of debt</div>
                                <div><input type="number" className="w-24" id="CoD" onBlur={this.assumptions.bind(this)} defaultValue={this.state.CoD}/></div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Tax rate</div>
                                <div><input type="number" className="w-24" id="TR" onBlur={this.assumptions.bind(this)} defaultValue={this.state.TR}/></div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>10y Treasury</div>
                                <div><input type="number" className="w-24" id="yTreasury" onBlur={this.assumptions.bind(this)} defaultValue={this.state.yTreasury}/></div>
                            </div>
                        </div>
                        <div>
                            <div>Assumptions pt2</div>
                            <div className="grid grid-cols-2">
                                <div>Beta</div>
                                <div><input type="number" className="w-24" id="Beta" onBlur={this.assumptions.bind(this)} defaultValue={this.state.Beta}/></div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Market Return</div>
                                <div><input type="number" className="w-24" id="MR" onBlur={this.assumptions.bind(this)} defaultValue={this.state.MR}/></div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Equity Value</div>
                                <div><input type="number" className="w-24" id="EQV" onBlur={this.assumptions.bind(this)} defaultValue={this.state.EQV}/></div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Debt Value</div>
                                <div><input type="number" className="w-24" id="DEV" onBlur={this.assumptions.bind(this)} defaultValue={this.state.DEV}/></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>Free Cash Flows</div>
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Period</th>
                                    {this.state.FCF.map((val,index)=><th key={index}>{index+1}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>EBIT</td>
                                    {this.state.FCF.map((val,index)=><td key={index}><input type="number" id={index} defaultValue={val.EBIT}
                                    onBlur={(e)=>{
                                        let fcf=this.state.FCF;
                                        fcf[parseInt(e.target.id)].EBIT=parseFloat(e.target.value);
                                        this.setState({FCF:fcf})
                                    }}/></td>)}
                                </tr>
                                <tr>
                                    <td>TAX</td>
                                    {this.state.FCF.map((val,index)=><td key={index}>{(val.EBIT*(this.state.TR/100)).toFixed(2)}</td>)}
                                </tr>
                                <tr>
                                    <td>D Y A</td>
                                    {this.state.FCF.map((val,index)=><td key={index}><input type="number" id={index} defaultValue={val.DA}
                                    onBlur={(e)=>{
                                        let fcf=this.state.FCF;
                                        fcf[parseInt(e.target.id)].DA=parseFloat(e.target.value);
                                        this.setState({FCF:fcf})
                                    }}/></td>)}
                                </tr>
                                <tr>
                                    <td>CapEx</td>
                                    {this.state.FCF.map((val,index)=><td key={index}><input type="number" id={index} defaultValue={val.CapEx}
                                    onBlur={(e)=>{
                                        let fcf=this.state.FCF;
                                        fcf[parseInt(e.target.id)].CapEx=parseFloat(e.target.value);
                                        this.setState({FCF:fcf})
                                    }}/></td>)}
                                </tr>
                                <tr>
                                    <td>Non-cash Work Capital</td>
                                    {this.state.FCF.map((val,index)=><td key={index}><input type="number" id={index} defaultValue={val.NCWC}
                                    onBlur={(e)=>{
                                        let fcf=this.state.FCF;
                                        fcf[parseInt(e.target.id)].NCWC=parseFloat(e.target.value);
                                        this.setState({FCF:fcf},()=>{this.calculoWACC()})
                                    }}/></td>)}
                                </tr>
                                <tr>
                                    <td>FCF</td>
                                    {this.state.FCF.map((val,index)=><td key={index}>{(val.EBIT-(val.EBIT*(this.state.TR/100))+val.DA-val.CapEx-val.NCWC).toFixed(2)}</td>)}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div>WACC</div>
                    <div className="grid grid-cols-2">
                        <div>Cost of Equity</div>
                        <div>{(this.state.WACC.CE).toFixed(2)}%</div>
                        <div>D/D+E</div>
                        <div>{(this.state.WACC.DDE*100).toFixed(2)}%</div>
                        <div>E/D+E</div>
                        <div>{(this.state.WACC.EDE*100).toFixed(2)}%</div>
                        <div>WACC</div>
                        <div>{(this.state.WACC.WACC).toFixed(2)}</div>
                    </div>
                </div>
                <div>
                    <div>Terminal Value</div>
                    <div className="grid grid-cols-2">
                        <div>EBITDA</div>
                        <div>{(this.state.TV.EBITDA).toFixed(2)}</div>
                        <div>Exit Multiple (EV/EBITDA)</div>
                        <div>{(this.state.TV.ExEBITDA).toFixed(2)}</div>
                        <div>Perpetuity Growth</div>
                        <div>{(this.state.TV.PG).toFixed(2)}</div>
                        <div>Average</div>
                        <div>{(this.state.TV.Avg).toFixed(2)}</div>
                    </div>
                </div>
                <div>
                    <div>Discounting</div>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Discount Factor</td>
                                    {this.state.D.DF.map((val,index)=><td key={index}>{(val).toFixed(2)}</td>)}
                                    
                                </tr>
                                <tr>
                                    <td>PV of FCF</td>
                                    {this.state.D.PVFCF.map((val,index)=><td key={index}>{(val).toFixed(2)}</td>)}
                                </tr>
                                <tr>
                                    <td>PV of TV</td>
                                    <td>{(this.state.D.PVTV).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>Enterprise Value</td>
                                    <td>{(this.state.D.EV).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div>Enterprise Value to Equity Value</div>
                    <div className="grid grid-cols-2">
                        <div>Cash</div>
                        <div><input type="number" className="w-24" id="Cash" onBlur={this.assumptions.bind(this)} defaultValue={this.state.Cash}/></div>
                        <div>Market Securities</div>
                        <div><input type="number" className="w-24" id="MS" onBlur={this.assumptions.bind(this)} defaultValue={this.state.MS}/></div>
                        <div>Short term Debt</div>
                        <div><input type="number" className="w-24" id="STD" onBlur={this.assumptions.bind(this)} defaultValue={this.state.STD}/></div>
                        <div>Long term Debt</div>
                        <div><input type="number" className="w-24" id="LTD" onBlur={this.assumptions.bind(this)} defaultValue={this.state.LTD}/></div>
                        <div>Equity Value</div>
                        <div>{(this.state.D.EV+this.state.Cash+this.state.MS-this.state.STD-this.state.LTD).toFixed(2)}</div>
                    </div>
                </div>
                <div>
                    <div className="grid grid-cols-2">
                        <div>Shares Outstanding</div>
                        <div><input type="number" className="w-24" id="SO" onBlur={this.assumptions.bind(this)} defaultValue={this.state.SO}/></div>
                        <div>Implied Share Price</div>
                        <div>{((this.state.D.EV+this.state.Cash+this.state.MS-this.state.STD-this.state.LTD)/this.state.SO).toFixed(2)}</div>
                    </div>
                </div>
                <div className="border-t-4 border-black">
                    
                    <div>Método Venture Capital</div>
                    <div className="grid grid-cols-2">
                        <div>CCR</div>
                        <div><input type="number" defaultValue={this.state.mvc.ccr} onBlur={(e)=>{
                                        let mvc=this.state.mvc;
                                       mvc.ccr=parseFloat(e.target.value);
                                        this.setState({mvc:mvc})
                        }}/></div>
                        <div>incrementoV</div>
                        <div><input type="number" defaultValue={this.state.mvc.incrementoV} onBlur={(e)=>{
                                        let mvc=this.state.mvc;
                                       mvc.incrementoV=parseFloat(e.target.value);
                                        this.setState({mvc:mvc})
                        }}/></div>
                        <div>Tasa de descuento</div>
                        <div><input type="number" defaultValue={this.state.mvc.incrementoV} onBlur={(e)=>{
                                        let mvc=this.state.mvc;
                                       mvc.td=parseFloat(e.target.value);
                                        this.setState({mvc:mvc})
                        }}/></div>
                        <div>PAEV</div>
                        <div><input type="number" defaultValue={this.state.mvc.incrementoV} onBlur={(e)=>{
                                        let mvc=this.state.mvc;
                                       mvc.paev=parseFloat(e.target.value);
                                        this.setState({mvc:mvc},()=>this.calculomvc());
                        }}/></div>
                        <div>Método Venture Capital / Free Cash Flow</div>
                        <div>${this.state.mvc.mvc}</div>
                    </div>
                </div>
                <div className="border-t-4 border-black">
                    <div className="grid grid-cols-2">
                        <div>Valuación de la empresa Ponderada entre ambos métodos</div>
                        <div>${((this.state.mvc.mvc+(this.state.D.EV+this.state.Cash+this.state.MS-this.state.STD-this.state.LTD))/2).toFixed(2)}</div>
                    </div>
                </div>

                </div>
                <div className="py-10"></div>
            </div>
        );
    }
}
export default ValuacionAvanzada;
