import React, { Component } from 'react';
import Cardproyecto from '../components/ProyectosC/cardproyecto';
import "./styles/Proyectos.css";
import { PlusCircleIcon, CameraIcon, ArrowLeftIcon } from '@heroicons/react/solid'
//import {  ChevronDownIcon, ChevronRightIcon} from '@heroicons/react/solid'
//import profile from '../assets/profile.png';
import Workbookmenu from './Workbook/Workbook_menu/Workbookmenu';
class Formulario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre_proyecto: '',
            descripcion_proyecto: '',
            privacidad_proyecto: '',
            inputFile: ''
        }

    }
    abrirFile(e) {
        e.preventDefault();
        document.getElementById('fileinput').click();
    }
    checar(e) {
        this.setState({ inputFile: e.target.files[0] });
    }
    render() {
        return (
            <div>
                <form>
                    <div className='grid grid-cols-3 mb-6'>
                        <div className='flex justify-center'>
                            <button className='botonfile' onClick={this.abrirFile.bind(this)}><CameraIcon className='w-12' /></button>
                            <input type='file' accept="image/png, image/gif, image/jpeg" style={{ display: 'none' }} id='fileinput' onChange={this.checar.bind(this)} />
                        </div>
                        <div className='col-span-2'>
                            <label className="logo1">Logo de su proyecto</label><br />
                            <label className="logo2">Subir imagen de su logo</label><br />
                            <label className="logo3">Sugerimos usar una imagen cuadrada de 1200 x 1200</label>
                        </div>

                    </div>
                    <label className="label_form">Nombre del Proyecto</label><br />
                    <input className="input_form" type="text" placeholder="Nombre que llevará su proyecto" /><br />
                    <label className="label_form">Descripción del Proyecto</label><br />
                    <textarea className="input_form" placeholder="Menciona la idea general del proyecto"></textarea><br />
                    <label className="label_form">Privacidad del Proyecto</label><br />
                    <select className="select_form">
                        <option>Proyecto Personal</option>
                    </select><br />
                    <div className="flex justify-between mt-4">
                        <label className="label_form">Agregar Colaboradores</label>
                        <button><PlusCircleIcon className="w-6" color="#fecd03" /></button>
                    </div>
                    <div className="mt-12 flex justify-center"><button className="submit" type="submit">CREAR PROYECTO</button></div>
                </form>
            </div>

        )
    }
}


const Startups = () => {
    /*const [DSmenu, setDSmenu] = useState(false);
    const [UXmenu, setUXmenu] = useState(false);
    const [FDmenu, setFDmenu] = useState(false);*/
    //const [workbook,setWorkbook]=useState(false)
    /*const [seleccion,setSeleccion]=useState('');
    const handleCallback=(data)=>{
        setSeleccion(data);
    }*/
    return (
        
        <div>
            <div className="flex bggris">
                <div className="ml-4 mt-4 mb-2">
                    <button className="bg-transparent activo_tab" id="misproyectos" onClick={(e) => {
                        document.getElementById("misproyectos").classList.replace('tab', 'activo_tab');
                        document.getElementById("crearproyecto").classList.replace('activo_tab', 'tab');
                        document.getElementById("formulario").classList.add('hiddenNone');
                        document.getElementById("listaproyectos").classList.replace('hiddenNone', 'visible');
                        document.getElementById("rectCP").classList.add('hiddenNone');
                        document.getElementById("rectLP").classList.replace('hiddenNone', 'visible');
                        document.getElementById('workbook_menu').classList.add('hiddenNone');
                    }}>Mis Proyectos<div className="rectangulo" id="rectLP"></div></button>

                </div>

                

                <div className="ml-4 mt-4 mb-2">
                    <button className="bg-transparent rounded-t-lg tab" id="crearproyecto" onClick={(e) => {
                        document.getElementById("crearproyecto").classList.replace('tab', 'activo_tab');
                        document.getElementById("misproyectos").classList.replace('activo_tab', 'tab');
                        document.getElementById("listaproyectos").classList.add('hiddenNone');
                        document.getElementById("formulario").classList.replace('hiddenNone', 'visible');
                        document.getElementById("rectLP").classList.add('hiddenNone');
                        document.getElementById("rectCP").classList.replace('hiddenNone', 'visible');
                        document.getElementById('workbook_menu').classList.add('hiddenNone');
                    }}>Crear proyectos<div className="rectangulo hiddenNone ml-5" id="rectCP"></div></button>
                </div>
            </div>

            
            <div className="container">
                       
                <div className="flex flex-wrap col-span-8 flex  mt-2 " id="listaproyectos" onClick={()=>{
                    document.getElementById('listaproyectos').classList.add('hiddenNone');
                    document.getElementById('crearproyecto').classList.add('hiddenNone');
                    document.getElementById('workbook_menu').classList.remove('hiddenNone');
                    //setWorkbook(true);
                }}>
  
                    <div className='cursor-pointer bg-white-300 pt-10 mt-2 mr-5 h-2 w-auto'>
                        <Cardproyecto/>
                    </div>

                </div>
                <div className="ml-8 md:ml-4 hiddenNone separador bg-white rounded-lg pt-2" id='workbook_menu'>
                    <button className=' regresar flex mt-8' onClick={()=>{
                        document.getElementById('workbook_menu').classList.add('hiddenNone');
                        document.getElementById('listaproyectos').classList.remove('hiddenNone');
                        document.getElementById('crearproyecto').classList.remove('hiddenNone');
                        //setWorkbook(false);
                    }}><ArrowLeftIcon className='w-3 mr-1' color='#fece01'/>Regresar</button>
                    
                    <div className='flex '>
                        <Workbookmenu title='Sprouders'/>
                    </div>
                </div>
                <div className="col-span-11 hiddenNone separador" id="formulario">
                    <div className="container flex justify-center mt-12">
                        <Formulario/>
                    </div>
                </div>
                {/*
                <div id='miequipo1' className='col-span-3 row-span-2 px-9 pt-0 ml-12'>
                    <div className='flex'>Mi Equipo</div>

                   
                <div className='ayudacard mt-5 text-gray-500'>   

                    <div className="dropdown1 inline-block relative">
                        <button className="py-2 px-8 rounded inline-flex items-center" onClick={() => {
                            document.getElementById('DSR').classList.toggle('hidden');
                            document.getElementById('DSD').classList.toggle('hidden');
                            setDSmenu(!DSmenu);
                        }}>
                            <span className="mr-1">Marketing Digital</span>
                            <ChevronRightIcon id="DSR" className=" fill-current h-4 w-4" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></ChevronRightIcon>
                            <ChevronDownIcon id="DSD" className=" fill-current h-4 w-4 hidden" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></ChevronDownIcon>
                        </button>
                        {DSmenu && (
                            <div id="DSmenu" className=" text-gray-700 pt-1 w-64">
                                <img src={profile} alt="" className="ml-4 inline rounded-full h-10 w-10  items-center justify-center" />
                                <img src={profile} alt="" className="-m-2 inline rounded-full h-10 w-10  items-center justify-center" />
                                <img src={profile} alt="" className="-m-2 inline rounded-full h-10 w-10  items-center justify-center" />
                                <img src={profile} alt="" className="-m-2 inline rounded-full h-10 w-10  items-center justify-center" />
                                <button className="ml-14 inline rounded-full h-6 w-6 items-center justify-center bg-yellow-400">+</button>
                            </div>)}
                    </div><br/>
                    <div className="dropdown1 inline-block relative">
                        <button className="py-2 px-8 rounded inline-flex items-center" onClick={() => {
                            document.getElementById('UDR').classList.toggle('hidden');
                            document.getElementById('UDD').classList.toggle('hidden');
                            setUXmenu(!UXmenu);
                        }}>
                            <span className="mr-1">UX Designers</span>
                            <ChevronRightIcon id="UDR" className=" fill-current h-4 w-4" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></ChevronRightIcon>
                            <ChevronDownIcon id="UDD" className=" fill-current h-4 w-4 hidden" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></ChevronDownIcon>
                        </button>
                        {UXmenu && (<div id="UDmenu" className="text-gray-700 pt-1 w-64">
                            <img src={profile} alt="" className="ml-4 inline rounded-full h-10 w-10  items-center justify-center" />
                            <img src={profile} alt="" className="-m-2 inline rounded-full h-10 w-10  items-center justify-center" />
                            <img src={profile} alt="" className="-m-2 inline rounded-full h-10 w-10  items-center justify-center" />
                            <img src={profile} alt="" className="-m-2 inline rounded-full h-10 w-10  items-center justify-center" />
                            <button className="ml-14 inline rounded-full h-6 w-6 items-center justify-center bg-yellow-400">+</button>
                        </div>)}
                    </div><br/>
                    <div className="dropdown1 inline-block relative mb-8">
                        <button className="py-2 px-8 rounded inline-flex items-center" onClick={() => {
                            document.getElementById('FDR').classList.toggle('hidden');
                            document.getElementById('FDD').classList.toggle('hidden');
                            setFDmenu(!FDmenu);
                        }}>
                            <span className="mr-1">FrontEnd Developer</span>
                            <ChevronRightIcon id="FDR" className=" fill-current h-4 w-4" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></ChevronRightIcon>
                            <ChevronDownIcon id="FDD" className=" fill-current h-4 w-4 hidden" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></ChevronDownIcon>
                        </button>
                        {FDmenu && (<div id="FDmenu" className="text-gray-700 pt-1 w-64">
                            <img src={profile} alt="" className="ml-4 inline rounded-full h-10 w-10  items-center justify-center" />
                            <img src={profile} alt="" className="-m-2 inline rounded-full h-10 w-10  items-center justify-center" />
                            <img src={profile} alt="" className="-m-2 inline rounded-full h-10 w-10  items-center justify-center" />
                            <img src={profile} alt="" className="-m-2 inline rounded-full h-10 w-10  items-center justify-center" />
                            <button className="ml-14 inline rounded-full h-6 w-6 items-center justify-center bg-yellow-400">+</button>
                        </div>)}
                    </div> 
                </div>

                    <div>
                        {workbook && <div>
                            <div className='mt-6 ayuda'>Ayuda</div>
                            <div className='mt-3 ayudacard'> <br/>
                        
                                <span className='sobre'>¿Necesitas ayuda?</span><br/><br/>{seleccion}
                                <p className='mt-2 textoayuda'>Para descubrir tu propósito de vida es necesario comprender perfectamente cuales son tus pasiones en la vida, aquello que tanto amas hacer, al mismo tiempo comprender que de todo eso que amas hacer, eres realmente bueno haciédolo, para de ahí descubir ....</p><br/>

                                <div className='flex justify-end text-gray-300'>Leer artículo completo<ChevronRightIcon className='w-4'/></div><br/>
                            </div>

                            <div className='ayudacard mt-5 text-gray-500'>
                                
                                <iframe className='rounded-md' width="214" height="117" src="https://www.youtube.com/embed/z2ZRDX33kfQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                            </div> 
                        </div>} 
                    </div>
                </div>
                */}
                
            </div>
 
        </div>
    )
}

export default Startups;
