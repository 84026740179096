import React, { useState, useEffect } from 'react';

const CountDown = (props) => {

  const calculateTimeLeft = () => {
    let difference = +new Date(props.endDate) - +new Date();
  
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
  
    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const countDownTranslations = {
    days: "Dias",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos"
  }

  const prependZero = (number) => {
    return number < 10 ? "0" + number : number;
  };

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <div className='flex space-x-2 items-start'>
        <span className='text-xs mt-1.5'>{countDownTranslations[interval]}</span>
        <span className='text-5xl'>{prependZero(timeLeft[interval])}</span>
      </div>
    );
  });

  return (
  <div>
    <div className="rounded-md bg-yellow-200 py-4 px-8 w-full flex items-center space-x-4">
      {timerComponents.length ? <p className="text-lg font-bold">Quedan</p> : ''}
      <div className='flex-1 flex justify-center space-x-6'>
        {timerComponents.length ? timerComponents : <span className='text-5xl'>La convocatoria empezó!</span>}
      </div>
    </div>
  </div>
  );
}

export default CountDown;