import React, { useState, useEffect } from "react";
import imagen2 from "../assets/curso.jpg";
import ItemConvocatoria from "../components/ConvocatoriaC/ItemConvocatoria";

const Convocatorias = () => {
  const [ convocatorias, setConvocatorias ] = useState([]);
  const gqlQuery = `
    {
      application {
        apply {
          sku,
          title,
          description,
          sponsor_img
        }
      }
    }
  `;
  const fetchConvocatorias = async () => {
    const token = window.sessionStorage.getItem("auth-token");
    const response = await fetch('https://startcups-staging.herokuapp.com/graphql', {
      method: 'POST',
      headers: {
        'content-type':'application/json',
        'Authorization': `Bearer ${token}`
      },
      body:JSON.stringify({query: gqlQuery})
    });
    const data = await response.json();
    const convData = data?.data?.application?.apply;
    if(convData){
      const rederedConv = []
      convData.forEach(({sku, title, description, sponsor_img}) => {
        rederedConv.push(
          <ItemConvocatoria key={sku} data={{sku, title, description, sponsor_img}}/>
        )
      });
      setConvocatorias(rederedConv);
    }
    else{
      setConvocatorias([]);
    }
  }

  useEffect( () => {
     fetchConvocatorias();
  });

  return (
    <div className="bggris flex-col h-full">
      <div className="grid grid-cols-7 gap-7 h-full">
        <div className="col-span-4 flex flex-col">
          <div className="flex justify-between max-w-4xl px-4 pt-6 mb-6">
            <div>Convocatorias</div>
            <div className="max-w-lg w-full lg:max-w-xs">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search"
                  name="search"
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Search"
                  type="search"
                />
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="relative h-full">
              <div className="absolute w-full h-full overflow-y-auto">
                <div className="flex flex-col items-center space-y-4 p-6">
                  {convocatorias}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <div className="p-2 mr-2 space-y-4 max-w-lg">
            <div>
              <div className="flex justify-between px-4 pt-6 mb-4">
                <p className="text-md font-bold">Lo más Nuevo</p>
                <p className="text-xs font-bold">Recientes</p>
              </div>
              <div className="bg-yellow-400 rounded-lg w-full h-48"></div>
            </div>
            <div>
              <div className="flex items-center space-x-2 mb-4">
                <p className="text-sm font-bold">Tus Avances</p>
                <div className="flex-1 border border-gray-200"></div>
                <div>
                  <p className="text-xs">Proyectos</p>
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex rounded-lg bg-yellow-400 pb-2 max-w-sm">
                  <div className="w-24 h-24 bg-black rounded-tl-lg relative">
                    <div class="absolute w-full h-full">
                      <img
                        src={imagen2}
                        alt="conv"
                        className="object-cover object-center rounded-tl-lg"
                      />
                    </div>
                  </div>
                  <div className="bg-white flex-1 p-2 rounded-tr-lg">
                    <p className="text-sm font-bold">
                      Cómo levantar capital de ángeles inversionistas
                    </p>
                    <p className="text-sm font-normal text-gray-400">
                      Duración 8 min
                    </p>
                    <p className="text-sm font-bold">Empezar ahora</p>
                  </div>
                </div>
                <div className="p-4 flex rounded-lg bg-white max-w-sm justify-between items-center">
                  <div className="h-6 w-6 rounded-md bg-yellow-400"></div>
                  <p className="text-lg font-bold text-black">Design Thinking</p>
                  <p className="text-sm text-gray-400">Avance 3/5</p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center space-x-2 mb-4">
                <p className="text-sm font-bold">Convocatorias Activas</p>
                <div className="flex-1 border border-gray-200"></div>
                <div>
                  <p className="text-xs">Ver todas</p>
                </div>
              </div>
              <div className="space-y-4">
              <div className="flex rounded-lg max-w-sm">
                  <div className="w-32 h-32 bg-black rounded-tl-lg relative">
                    <div class="absolute w-full h-full rounded-tl-lg bg-yellow-400">
                      
                    </div>
                  </div>
                  <div className="bg-white flex-1 p-2 rounded-tr-lg">
                    <p className="text-xs">Etapa</p>
                    <p className="text-lg font-bold">Finanzas</p>
                    <p className="text-xs">Fecha de revisión</p>
                    <p className="text-sm">08 de Octubre 2021</p>
                    <p className="text-lg">CONTINUAR</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Convocatorias;
