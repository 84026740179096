import React from 'react'
import { BrowserRouter as Router, Routes, Navigate, Route, useLocation, BrowserRouter } from 'react-router-dom'

import { Provider } from 'react-redux';
import {store} from './store/store'
import { AppRouter } from './router/AppRouter';

function App() {
    return (
    <Provider store={store}>
        <BrowserRouter>
            <AppRouter/>
        </BrowserRouter>
    </Provider>
        
    )
}

export default App

