import { Component } from "react";
import { ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'
import "./styles/DesignThinking.css";

class UnitsEconomics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MC: {//Guardar en base datos
        PVU:0.0,
        CVU:0.0,
        UBU:0.0,
        MC:0.0
      },
      CAC:{//Guardar en base datos
        ITP:0.0,
        CNCA:0,
        CAC:0.0
      },
      VTP: {//Guardar en base datos
        VT:0.0,
        CO:0,
        TPC:0.0
      },
      FC: {//Guardar en base datos
       CO:0,
       CCU:0,
       FC:0
      },
      VVC:{//Guardar en base datos
        EADP:0,
        VVC:0.0
      },
      CCR:{//Guardar en base datos
        CPP:0,
        CFP:0,
        CCR:0.0,
      },
      seleccion:'',
      seleccion2:'MU',
      contadorideas:0,
      ideas:[],
      idearepetida:false,
      editando:-1,
      editar:false,
      limitado:true,

    };
    
  }
  
  render() {
    return (
      <div className="ml-8">
        <div className="">
          <div className=" title">Unit Economics</div>
          
          <div className="flex">
            <div
              className="subtitle mr-16 active mt-4 w-18"
              id="MC"
              onClick={() => {
                
                document.getElementById("rectCAC").classList.add("hiddenNone");
                document.getElementById("rectVTP").classList.add("hiddenNone");
                document.getElementById("rectFC").classList.add("hiddenNone");
                document.getElementById("rectVC").classList.add("hiddenNone");
                document.getElementById("rectVVC").classList.add("hiddenNone");
                document.getElementById("rectCCR").classList.add("hiddenNone");
                document.getElementById("rectMC").classList.remove("hiddenNone");
                document.getElementById("CAC").classList.remove("active");
                document.getElementById("VTP").classList.remove("active");
                document.getElementById("FC").classList.remove("active");
                document.getElementById("VC").classList.remove("active");
                document.getElementById("VVC").classList.remove("active");
                document.getElementById("CCR").classList.remove("active");
                document.getElementById("MC").classList.add("active");
                document.getElementById("CACQuestions").classList.add("hiddenNone");
                document.getElementById("VTPQuestions").classList.add("hiddenNone");
                document.getElementById("FCQuestions").classList.add("hiddenNone");
                document.getElementById("VCQuestions").classList.add("hiddenNone");
                document.getElementById("VVCQuestions").classList.add("hiddenNone");
                document.getElementById("CCRQuestions").classList.add("hiddenNone");
                document.getElementById("MCQuestions").classList.remove("hiddenNone");
              }}
            >
              Margen de Contribución
              <div className="rectangle" id="rectMC"></div>
            </div>

            <div
              className="subtitle mr-16 mt-4 w-18"
              id="CAC"
              onClick={() => {
                
                document.getElementById("rectMC").classList.add("hiddenNone");
                document.getElementById("rectVTP").classList.add("hiddenNone");
                document.getElementById("rectFC").classList.add("hiddenNone");
                document.getElementById("rectVC").classList.add("hiddenNone");
                document.getElementById("rectVVC").classList.add("hiddenNone");
                document.getElementById("rectCCR").classList.add("hiddenNone");
                document.getElementById("rectCAC").classList.remove("hiddenNone");
                document.getElementById("MC").classList.remove("active");
                document.getElementById("VTP").classList.remove("active");
                document.getElementById("FC").classList.remove("active");
                document.getElementById("VC").classList.remove("active");
                document.getElementById("VVC").classList.remove("active");
                document.getElementById("CCR").classList.remove("active");
                document.getElementById("CAC").classList.add("active");
                document.getElementById("MCQuestions").classList.add("hiddenNone");
                document.getElementById("VTPQuestions").classList.add("hiddenNone");
                document.getElementById("FCQuestions").classList.add("hiddenNone");
                document.getElementById("VCQuestions").classList.add("hiddenNone");
                document.getElementById("VVCQuestions").classList.add("hiddenNone");
                document.getElementById("CCRQuestions").classList.add("hiddenNone");
                document.getElementById("CACQuestions").classList.remove("hiddenNone");
               
              }}
            >
              CAC
              <div className="rectangle hiddenNone" id="rectCAC"></div>
            </div>
            <div
              className="subtitle mr-16 mt-4 w-18"
              id="VTP"
              onClick={() => {
                
                document.getElementById("rectCAC").classList.add("hiddenNone");
                document.getElementById("rectMC").classList.add("hiddenNone");
                document.getElementById("rectFC").classList.add("hiddenNone");
                document.getElementById("rectVC").classList.add("hiddenNone");
                document.getElementById("rectVVC").classList.add("hiddenNone");
                document.getElementById("rectCCR").classList.add("hiddenNone");
                document.getElementById("rectVTP").classList.remove("hiddenNone");
                document.getElementById("CAC").classList.remove("active");
                document.getElementById("MC").classList.remove("active");
                document.getElementById("FC").classList.remove("active");
                document.getElementById("VC").classList.remove("active");
                document.getElementById("VVC").classList.remove("active");
                document.getElementById("CCR").classList.remove("active");
                document.getElementById("VTP").classList.add("active");
                document.getElementById("CACQuestions").classList.add("hiddenNone");
                document.getElementById("MCQuestions").classList.add("hiddenNone");
                document.getElementById("FCQuestions").classList.add("hiddenNone");
                document.getElementById("VCQuestions").classList.add("hiddenNone");
                document.getElementById("VVCQuestions").classList.add("hiddenNone");
                document.getElementById("CCRQuestions").classList.add("hiddenNone");
                document.getElementById("VTPQuestions").classList.remove("hiddenNone");
              }}
            >
              Ticket
              <div className="rectangle hiddenNone" id="rectVTP"></div>
            </div>
            <div
              className="subtitle mr-16 mt-4 w-18"
              id="FC"
              onClick={() => {
                document.getElementById("rectCAC").classList.add("hiddenNone");
                document.getElementById("rectVTP").classList.add("hiddenNone");
                document.getElementById("rectMC").classList.add("hiddenNone");
                document.getElementById("rectVC").classList.add("hiddenNone");
                document.getElementById("rectVVC").classList.add("hiddenNone");
                document.getElementById("rectCCR").classList.add("hiddenNone");
                document.getElementById("rectFC").classList.remove("hiddenNone");
                document.getElementById("CAC").classList.remove("active");
                document.getElementById("VTP").classList.remove("active");
                document.getElementById("MC").classList.remove("active");
                document.getElementById("VC").classList.remove("active");
                document.getElementById("VVC").classList.remove("active");
                document.getElementById("CCR").classList.remove("active");
                document.getElementById("FC").classList.add("active");
                document.getElementById("CACQuestions").classList.add("hiddenNone");
                document.getElementById("VTPQuestions").classList.add("hiddenNone");
                document.getElementById("MCQuestions").classList.add("hiddenNone");
                document.getElementById("VCQuestions").classList.add("hiddenNone");
                document.getElementById("VVCQuestions").classList.add("hiddenNone");
                document.getElementById("CCRQuestions").classList.add("hiddenNone");
                document.getElementById("FCQuestions").classList.remove("hiddenNone");
              }}
            >
              Frecuencia
              <div className="rectangle hiddenNone" id="rectFC"></div>
            </div>
            <div
              className="subtitle mr-16 mt-4 w-18"
              id="VC"
              onClick={() => {
                document.getElementById("rectCAC").classList.add("hiddenNone");
                document.getElementById("rectVTP").classList.add("hiddenNone");
                document.getElementById("rectFC").classList.add("hiddenNone");
                document.getElementById("rectMC").classList.add("hiddenNone");
                document.getElementById("rectVVC").classList.add("hiddenNone");
                document.getElementById("rectCCR").classList.add("hiddenNone");
                document.getElementById("rectVC").classList.remove("hiddenNone");
                document.getElementById("CAC").classList.remove("active");
                document.getElementById("VTP").classList.remove("active");
                document.getElementById("FC").classList.remove("active");
                document.getElementById("MC").classList.remove("active");
                document.getElementById("VVC").classList.remove("active");
                document.getElementById("CCR").classList.remove("active");
                document.getElementById("VC").classList.add("active");
                document.getElementById("CACQuestions").classList.add("hiddenNone");
                document.getElementById("VTPQuestions").classList.add("hiddenNone");
                document.getElementById("FCQuestions").classList.add("hiddenNone");
                document.getElementById("MCQuestions").classList.add("hiddenNone");
                document.getElementById("VVCQuestions").classList.add("hiddenNone");
                document.getElementById("CCRQuestions").classList.add("hiddenNone");
                document.getElementById("VCQuestions").classList.remove("hiddenNone");
              }}
            >
              Valor del Cliente
              <div className="rectangle hiddenNone" id="rectVC"></div>
            </div>
            <div
              className="subtitle mr-16 mt-4 w-18"
              id="VVC"
              onClick={() => {
                document.getElementById("rectCAC").classList.add("hiddenNone");
                document.getElementById("rectVTP").classList.add("hiddenNone");
                document.getElementById("rectFC").classList.add("hiddenNone");
                document.getElementById("rectVC").classList.add("hiddenNone");
                document.getElementById("rectMC").classList.add("hiddenNone");
                document.getElementById("rectCCR").classList.add("hiddenNone");
                document.getElementById("rectVVC").classList.remove("hiddenNone");
                document.getElementById("CAC").classList.remove("active");
                document.getElementById("VTP").classList.remove("active");
                document.getElementById("FC").classList.remove("active");
                document.getElementById("VC").classList.remove("active");
                document.getElementById("MC").classList.remove("active");
                document.getElementById("CCR").classList.remove("active");
                document.getElementById("VVC").classList.add("active");
                document.getElementById("CACQuestions").classList.add("hiddenNone");
                document.getElementById("VTPQuestions").classList.add("hiddenNone");
                document.getElementById("FCQuestions").classList.add("hiddenNone");
                document.getElementById("VCQuestions").classList.add("hiddenNone");
                document.getElementById("MCQuestions").classList.add("hiddenNone");
                document.getElementById("CCRQuestions").classList.add("hiddenNone");
                document.getElementById("VVCQuestions").classList.remove("hiddenNone");
              }}
            >
              CLTV
              <div className="rectangle hiddenNone" id="rectVVC"></div>
            </div>

            <div
              className="subtitle mr-16 mt-4 w-18"
              id="CCR"
              onClick={() => {
                document.getElementById("rectCAC").classList.add("hiddenNone");
                document.getElementById("rectVTP").classList.add("hiddenNone");
                document.getElementById("rectFC").classList.add("hiddenNone");
                document.getElementById("rectVC").classList.add("hiddenNone");
                document.getElementById("rectMC").classList.add("hiddenNone");
                document.getElementById("rectVVC").classList.add("hiddenNone");
                document.getElementById("rectCCR").classList.remove("hiddenNone");
                document.getElementById("CAC").classList.remove("active");
                document.getElementById("VTP").classList.remove("active");
                document.getElementById("FC").classList.remove("active");
                document.getElementById("VC").classList.remove("active");
                document.getElementById("MC").classList.remove("active");
                document.getElementById("VVC").classList.remove("active");
                document.getElementById("CCR").classList.add("active");
                document.getElementById("CACQuestions").classList.add("hiddenNone");
                document.getElementById("VTPQuestions").classList.add("hiddenNone");
                document.getElementById("FCQuestions").classList.add("hiddenNone");
                document.getElementById("VCQuestions").classList.add("hiddenNone");
                document.getElementById("MCQuestions").classList.add("hiddenNone");
                document.getElementById("VVCQuestions").classList.add("hiddenNone");
                document.getElementById("CCRQuestions").classList.remove("hiddenNone");
              }}
            >
              CCR
              <div className="rectangle hiddenNone" id="rectCCR"></div>
            </div>



          </div>

          <div className='lineamenuDT '></div> 
        </div>


  <div className="mt-8" id="MCQuestions">
    <div className="bg-gray-100 rounded-xl mb-4 -mt-4 pb-4">
      <div className="text-gray-600 pt-4 px-4 text-lg font-bold flex mb-1" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[0].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=0;i<2;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>MARGEN DE CONTRIBUCIÓN (Average Gross Margin)<ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
        <div className="text-xs text-gray-400 p-4 accordion">El margen de contribución es el resultado de restar los costos variables al precio de venta. Otra forma de entender este concepto es como el sobrante de los ingresos en relación a los costes variables representados en porcentaje (Ej. Precio de Venta $100 - Costo de Venta $80 = Utilidad Bruta $20 (20% de Margen de Contribución)).</div>
    </div>
    <div className="grid grid-cols-2 mt-8">
      <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( $ )</label><label>Ventas Totales</label></div>
      <div className="mb-4 -ml-64"><label className="bg-gray-600 text-white p-3">$</label><input className="rounded-r-lg border-gray-300" type="number" defaultValue={this.state.MC.PVU} onChange={(e)=>{
        let mc=this.state.MC
        mc.PVU=e.target.value;
        mc.UBU=Number.parseFloat(mc.PVU-mc.CVU).toFixed(2);
        mc.MC=Math.round(mc.UBU*100/mc.PVU);
        this.setState({MC:mc});
      }}/></div>
      <div><label className="bg-gray-300 text-white mr-4 p-2 px-2.5 rounded-md">( - )</label><label>Costo de Ventas</label></div>
      <div className="mb-4 -ml-64"><label className="bg-gray-600 text-white p-3">$</label><input className="rounded-r-lg border-gray-300" type="number" defaultValue={this.state.MC.CVU} onChange={(e)=>{
        let mc=this.state.MC
        mc.CVU=e.target.value;
        mc.UBU=Number.parseFloat(mc.PVU-mc.CVU).toFixed(2);
        mc.MC=Math.round(mc.UBU*100/mc.PVU);
        this.setState({MC:mc});}} /></div>
      <div><label className="bg-gray-300 text-white mr-4 p-2 px-2 rounded-md">( = )</label><label>Utilidad Bruta</label></div>
      <div className="mb-8 -ml-64">${this.state.MC.UBU}</div>
      <div><label className="bg-gray-300 text-white mr-4 p-2 px-2 rounded-md">( = )</label><label>Margen de Contribución Ponderado</label></div>
      <div className="">{this.state.MC.MC}%</div>
    </div>               
  </div>

      <div className="mt-8 hiddenNone" id="CACQuestions">            
        <div className="bg-gray-100 rounded-xl -mt-4 pb-4">
          <div className="text-gray-600 pt-4 px-4 text-lg font-bold flex mb-1"  onClick={(e)=>{
                         document.getElementsByClassName("accordion")[1].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=2;i<4;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>COSTO DE ADQUISICIÓN DEL CLIENTE (Customer Acquisition Cost) <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
          <div className="text-xs text-gray-400 p-4 accordion">El costo de adquisición del cliente (CAC) es el resultado de la suma de las inversiones realizadas en marketing y ventas dividida por el número de clientes ganados en el mismo período. Es una métrica esencial para medir la salud financiera de una empresa.</div>
        </div>
        <div className="grid grid-cols-2 mt-8">
      <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( $ )</label><label>Inversión Total en Publicidad en 1 Año</label></div>
      <div><label className="bg-gray-600 text-white p-3">$</label><input className="mb-4 rounded-r-lg border-gray-300" type="number" defaultValue={this.state.CAC.ITP} onChange={(e)=>{
        let cac=this.state.CAC
        cac.ITP=e.target.value;
        cac.CAC=Number.parseFloat(cac.ITP/cac.CNCA).toFixed(2);
        this.setState({CAC:cac});
      }}/></div>
      <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( / )</label><label> Cantidad de Nuevos Clientes Adquiridos (Unidades)</label></div>
      <div><label className="bg-gray-600 text-white p-3">U</label><input className="mb-4 rounded-r-lg border-gray-300" type="number" defaultValue={this.state.CAC.CNCA} onChange={(e)=>{
        let cac=this.state.CAC
        cac.CNCA=e.target.value;
        cac.CAC=Number.parseFloat(cac.ITP/cac.CNCA).toFixed(2);
        this.setState({CAC:cac});}} /></div>
      <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( = )</label><label>CAC</label></div>
      <div>${this.state.CAC.CAC}</div>
    </div>
      </div>
  <div className="mt-4 hiddenNone mb-16" id="VTPQuestions">
      <div className="bg-gray-100 rounded-xl mt-4 pb-4">
        <div className="text-gray-600 pt-4 px-4 text-lg flex mb-1" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[2].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=4;i<6;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>VALOR DEL TICKET PROMEDIO (<ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
        <div className="text-xs text-gray-400 p-4 accordion">Para calcularlo, basta tomar la facturación bruta de la empresa en un cierto periodo de tiempo y dividirlo entre la cantidad de ordenes de venta generadas en ese periodo. O también puede ser calculado a partir de la suma total de ventas, dividido por el número de clientes que hicieron compras en este periodo.</div>
      </div>
      <div className="grid grid-cols-2 mt-8">
      <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( $ )</label><label>Ventas Totales</label></div>
      <div><label className="bg-gray-600 text-white p-3">$</label><input className="mb-4 rounded-r-lg border-gray-300" type="number" defaultValue={this.state.VTP.VT} onChange={(e)=>{
        let vtp=this.state.VTP
        vtp.VT=e.target.value;
        vtp.TPC=Number.parseFloat(vtp.VT/vtp.CO).toFixed(2);
        this.setState({VTP:vtp});
      }}/></div>
      <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( / )</label><label>Cantidad de Ordenes</label></div>
      <div><label className="bg-gray-600 text-white p-3">U</label><input className="mb-4 rounded-r-lg border-gray-300" type="number" defaultValue={this.state.VTP.CO} onChange={(e)=>{
        let vtp=this.state.VTP
        vtp.CO=e.target.value;
        vtp.TPC=Number.parseFloat(vtp.VT/vtp.CO).toFixed(2);
        this.setState({VTP:vtp});}} /></div>
      <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( = )</label><label>Ticket promedio de compra</label></div>
      <div>${this.state.VTP.TPC}</div>
    </div>
  </div>

  <div className="mt-8 hiddenNone" id="FCQuestions">
        <div className="bg-gray-100 rounded-xl mb-4 -mt-4 pb-4">
          <div className="text-gray-600 pt-4 px-4 text-lg flex mb-1" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[3].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=6;i<8;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>FRECUENCIA DE COMPRA <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
          <div className="text-xs text-gray-400 p-4 accordion">Para obtener la frecuencia de compra sólo es necesario dividir el monto de ingresos totales ó cantidad de ordenes entre el número de clientes únicos que tiene tu empresa.</div>
        </div>
        <div className="grid grid-cols-2 mt-8">
      <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( U )</label><label>Cantidad de Ordenes</label></div>
      <div><label className="bg-gray-600 text-white p-3">U</label><input className="mb-4 rounded-r-lg border-gray-300" type="number" defaultValue={this.state.FC.CO} onChange={(e)=>{
        let fc=this.state.FC
        fc.CO=e.target.value;
        fc.FC=Number.parseFloat(fc.CO/fc.CCU).toFixed(2);
        this.setState({FC:fc});
      }}/></div>
      <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( / )</label><label>Cantidad de clientes únicos</label></div>
      <div><label className="bg-gray-600 text-white p-3">U</label><input className="mb-4 rounded-r-lg border-gray-300" type="number" defaultValue={this.state.FC.CCU} onChange={(e)=>{
        let fc=this.state.FC
        fc.CCU=e.target.value;
        fc.FC=Number.parseFloat(fc.CO/fc.CCU).toFixed(2);
        this.setState({FC:fc});}} /></div>
      <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( = )</label><label>Frecuencia de compra</label></div>
      <div>{this.state.FC.FC} veces</div>
    </div>
  </div>
  <div className="mt-8 hiddenNone" id="VCQuestions">
        <div className="bg-gray-100 rounded-xl mb-4 -mt-4 pb-4">
          <div className="text-gray-600 pt-4 px-4 text-lg flex mb-1" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[4].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=8;i<10;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>VALOR DEL CLIENTE (Customer Value)<ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
          <div className="text-xs text-gray-400 p-4 accordion">Para obtener el "Varlo del Cliente", es necesario multiplicar el ticket promedio de la compra por el margen de contribución y el resultado multiplicarlo por la frecuencia de compra.</div>
        </div>
        <div className="grid grid-cols-2 mt-8">
          <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( $ )</label><label>Ticket promedio de compra</label></div>
          <div className="mb-8"><label className="bg-gray-600 text-white p-3">$</label><label className="ml-2">${this.state.VTP.TPC}</label></div>
          <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( X )</label><label>Margen de Contribución</label></div>
          <div className="mb-8"><label className="bg-gray-600 text-white p-3">%</label><label className="ml-2">{this.state.MC.MC}%</label></div>
          <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( X )</label><label>Frecuencia de compra</label></div>
          <div className="mb-8"><label className="bg-gray-600 text-white p-3">U</label><label className="ml-2">{this.state.FC.FC} veces</label></div>
          <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( = )</label><label>Valor de cliente</label></div>
          <div className="mb-8"><label className="bg-gray-600 text-white p-3">$</label><label className="ml-2">${this.state.VTP.TPC * (this.state.MC.MC/100) * this.state.FC.FC}</label></div>
        </div>
  </div>
    <div className="mt-8 hiddenNone" id="VVCQuestions">
        <div className="bg-gray-100 rounded-xl mb-4 -mt-4 pb-4">
          <div className="text-gray-600 pt-4 px-4 text-lg flex mb-1" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[5].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=10;i<12;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>VALOR TOTAL DE VIDA DEL CLIENTE (Customer Life Time Value) <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
          <div className="text-xs text-gray-400 p-4 accordion">El "Valor de vida del cliente (CLTV)" se obtiene finalmente multiplicando el valor del cliente por la cantidad estimada de años que durará un cliente en nuestra empresa.</div>
        </div>
        <div className="grid grid-cols-2 mt-8">
          <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( U )</label><label>Estimación de años que durará un cliente promedio</label></div>
          <div><label className="bg-gray-600 text-white p-3">U</label><input className="mb-4 rounded-r-lg border-gray-300" type="number" defaultValue={this.state.VVC.EADP} onChange={(e)=>{
            let vvc=this.state.VVC;
            vvc.EADP=e.target.value;
            vvc.VVC=Number.parseFloat(vvc.EADP * (this.state.VTP.TPC * (this.state.MC.MC/100) * this.state.FC.FC)).toFixed(2);
            this.setState({VVC:vvc});
          }} /></div>
          <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( X )</label><label>Valor del cliente</label></div>
          <div className="mb-8"><label>${this.state.VTP.TPC * (this.state.MC.MC/100) * this.state.FC.FC}</label></div>
          <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( = )</label><label>Valor de vida del cliente</label></div>
          <div><label>${this.state.VVC.VVC}</label></div>
        </div>
    </div>

    <div className="mt-8 hiddenNone" id="CCRQuestions">
        <div className="bg-gray-100 rounded-xl mb-4 -mt-4 pb-4">
          <div className="text-gray-600 pt-4 px-4 text-lg flex mb-1" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[6].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=12;i<14;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>TASA DE CANCELACIÓN O ABANDONO DE CLIENTES (Customer Churn Rate)<ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
          <div className="text-xs text-gray-400 p-4 accordion">El "Customer Churn Rate" hace referencia al momento en que se rompe la relación con el cliente, producto de la migración o cancelación de la suscripción de nuestros clientes. Es muy visible en las relaciones de suscripción de aplicaciones, herramientas de software o la suscripción de contenidos. Esta métrica se calcula como un índice de variación entre los números de clientes o usuarios registrados al principio y final del lapso de observación. El CCR = El número de clientes al principio del mes menos el número de clientes al final del mes, divido por el número de clientes al final del mes y multiplicado por 100.</div>
        </div>
        <div className="grid grid-cols-2 mt-8">
          <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( U )</label><label>Cantidad de clientes al principio del periodo</label></div>
          <div><label className="bg-gray-600 text-white p-3">U</label><input className="mb-4 rounded-r-lg border-gray-300" type="number" defaultValue={this.state.CCR.CPP} onChange={(e)=>{
            let ccr=this.state.CCR;
            ccr.CPP=e.target.value;
            this.setState({CCR:ccr});
          }} /></div>

          <div><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( - )</label><label>Cantidad de clientes al final del periodo</label></div>
          <div className="mb-4"><label className="bg-gray-600 text-white p-3">U</label><input className="mb-4 rounded-r-lg border-gray-300" type="number" defaultValue={this.state.CCR.CFP} onChange={(e)=>{
            let ccr=this.state.CCR;
            ccr.CFP=e.target.value;
            ccr.CCR=parseFloat(((ccr.CPP - ccr.CFP) / ccr.CFP*100).toFixed(2));
            this.setState({CCR:ccr});
          }} /></div>

          <div className="italic text-gray-400 bg-gray-200 rounded-lg p-4"><label className="">( {this.state.CCR.CPP} Clientes al principio – {this.state.CCR.CFP} Clientes al final ) / ( {this.state.CCR.CFP} Clientes al final * 100 )</label></div>
          <div>
          </div>

          <div className="mt-8"><label className="bg-gray-300 text-white mr-4 p-2 rounded-md">( = )</label><label>(CCR) Tasa de Cancelación o Abandono de Clientes</label></div>
          <div className="mt-8 mb-10"><label>{isNaN(this.state.CCR.CCR)?0:(this.state.CCR.CCR)}%</label></div>
        </div>
    </div>



      </div>
    );
  }
}
export default UnitsEconomics;

