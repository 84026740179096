import React from 'react';

import {Navigate, Outlet} from 'react-router-dom'

const useAuth=()=>{
  const user=window.sessionStorage.getItem('auth-token')
  if(user){
    return true
  } else {
    return false
  }
}

export const PrivateRoute=() =>{

  const auth=useAuth()

  return auth ? <Outlet/> : <Navigate to="/login"/>
}