import { Component } from "react";
import './styles/PitchDeck.css'
import {ChevronRightIcon, ChevronLeftIcon,ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'
import imagen from '../../../assets/img-startcups-inc.png';
import profile from '../../../assets/profile.png';
import iconstartcups from '../../../assets/icon-app.png';
import iconinstagram from '../../../assets/Instagram_logo.png';
import iconfacebook from '../../../assets/Facebook_icon.png';
import iconlinkedin from '../../../assets/linkedin_icon.png';
import icontwitter from '../../../assets/Twitter_logo.png';
import iconbehance from '../../../assets/behance_icon.png';
class PitchDeck extends Component{
    constructor(props){
        super(props);
        this.state={
            PI:{//Guardar en base de datos
                seleccion:'1',
                diapositiva:'',
                texto:'',
                carousel:0,
                presentacion:[
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                    {diapositiva:'',texto:''},
                ]
            },
            TI:{//Guardar en base de datos
                DE:0,
                EL:0,
                integrantes:[],
                EP:'https://www.youtube.com/embed/8jP9dU6gAvo',
                Años:[]
            },
        }
    }
    previsualizar(e){
        let PI=this.state.PI;
        PI.diapositiva=URL.createObjectURL(e.target.files[0]);
        PI.presentacion[parseInt(this.state.PI.seleccion)-1].diapositiva=URL.createObjectURL(e.target.files[0]);
        //document.getElementById(this.state.PI.seleccion).style.background=URL.createObjectURL(e.target.files[0]);
        this.setState({PI:PI});
    }
    descripcion(e){
        let PI=this.state.PI;
        PI.presentacion[parseInt(this.state.PI.seleccion)-1].texto=e.target.value;
        
        this.setState({PI:PI});
    }
    cambio(e){
        let PI=this.state.PI;
        PI.seleccion=e.target.id;
        try{
            PI.diapositiva=this.state.PI.presentacion[parseInt(PI.seleccion)-1].diapositiva;
        }catch(e){
            PI.diapositiva='';
        }
        try{
            PI.texto=this.state.PI.presentacion[parseInt(PI.seleccion)-1].texto;
            document.getElementById('PItextarea').value=PI.presentacion[parseInt(this.state.PI.seleccion)-1].texto;
        }catch(e){

        }
        
        
        this.setState({PI:PI});
    }
    render(){
        return(
            <div className="ml-8">
                <div className="">

                    <div className="col-span-2 title">

                        Pitch Deck
                    </div>

                    <div className="flex">

                    <div className="subtitle mr-10 active mt-4 w-26" id="PI" onClick={()=>{
                        document.getElementById('rectTI').classList.add('hiddenNone');
                        document.getElementById('TI').classList.remove('active');
                        document.getElementById('PI').classList.add('active');
                        document.getElementById('rectPI').classList.remove('hiddenNone');
                        document.getElementById('TIQuestions').classList.add('hiddenNone');
                        document.getElementById('PIQuestions').classList.remove('hiddenNone');
                    }}>
                        Presentación para Inversionistas
                        <div className="rectangle" id="rectPI"></div>
                    </div>

                    <div className="subtitle ml-10 mt-4 w-26"  id="TI" onClick={()=>{
                        document.getElementById('rectPI').classList.add('hiddenNone');
                        document.getElementById('PI').classList.remove('active');
                        document.getElementById('TI').classList.add('active');
                        document.getElementById('rectTI').classList.remove('hiddenNone');
                        document.getElementById('TIQuestions').classList.remove('hiddenNone');
                        document.getElementById('PIQuestions').classList.add('hiddenNone');
                    }}>
                        Teaser de Inversión
                        <div className="rectangle hiddenNone" id="rectTI"></div>
                    </div>
                    
                    </div>
                   
                    <div className='lineamenuPV w-full'></div> 

                </div>
                <div className="mt-8"  id="PIQuestions">

                    <div className="bg-gray-100 rounded-xl">
                        <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[0].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=0;i<2;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>PROPÓSITO DE VIDA <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                        <div className="text-xs text-gray-400 p-4 mb-4 accordion">Un propósito de vida es la razón o razones por las que te levantas en la mañana, aquellas motivaciones intrínsecas que dan un sentido de dirección y significado a la propia existencia, y que con dicho propósito será con el que te enfocarás a crear tu nueva empresa, la cual se convertirá en tu parque de diversiones y disfrutarás cada momento trabajando en y por ella.</div>
                    </div>
                    <div className="w-80">{this.state.PI.presentacion[this.state.PI.carousel].texto}</div>
                    <div className="flex justify-center">
                        
                        <div className="w-60 h-40 flex relative">
                        <button className=""onClick={(e)=>{
                                var PI=this.state.PI;
                                if(PI.carousel===0){
                                    PI.carousel=11;
                                }else{
                                    PI.carousel--;
                                }
                                this.setState({PI:PI});
                                }}> <ChevronLeftIcon className="w-8"/> </button>
                            <img src={this.state.PI.presentacion[this.state.PI.carousel].diapositiva} alt={'Diapositiva ' +(this.state.PI.carousel+1)} />
                            
                            <div className="absolute top-0 right-0">{this.state.PI.carousel+1}</div>
                            <button className="" onClick={(e)=>{
                                var PI=this.state.PI;
                                if(PI.carousel===11){
                                    PI.carousel=0;
                                }else{
                                    PI.carousel++;
                                }
                                this.setState({PI:PI});
                            }}> <ChevronRightIcon className="w-8"/> </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div>
                            <div>Sube una captura de pantalla de tu presentación {this.state.PI.seleccion}</div>
                            <div className="w-20 h-20"><img src={this.state.PI.diapositiva} alt="" /></div>
                            <input type="file" accept="image/png, image/gif, image/jpeg,image/jpg" style={{ display: 'none' }} id="filediapositiva" onChange={this.previsualizar.bind(this)}/>
                            <div  onClick={(e)=>{
                                e.preventDefault();
                                document.getElementById('filediapositiva').click();
                            }}><label className="p-2 rounded-full bg-yellow-300">Subir imagen</label></div>
                            <div className="mt-8"><label>Escribe un texto para tu diapositiva:</label></div>
                            <div><textarea cols="40" defaultValue={this.state.PI.texto} id='PItextarea' onChange={this.descripcion.bind(this)}></textarea></div>
                        </div>
                        <div className="grid grid-cols-2 text-sm">
                            <div id="1" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Bienvenida y logotipo <span className="p-2 rounded-full bg-yellow-300">1</span> <img className="w-16" src={this.state.PI.presentacion[0].diapositiva} alt=""/></div>
                            <div id="2" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Presentación de equipo <span className="p-2 rounded-full bg-yellow-300">2</span><img className="w-16" src={this.state.PI.presentacion[1].diapositiva} alt=""/></div>
                            <div id="3" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Descripción del problema <span className=" p-2 rounded-full bg-yellow-300">3</span><img className="w-16" src={this.state.PI.presentacion[2].diapositiva} alt=""/></div>
                            <div id="4" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Justificación con datos estadísticos <span className="p-2 rounded-full bg-yellow-300">4</span><img className="w-16" src={this.state.PI.presentacion[3].diapositiva} alt=""/></div>
                            <div id="5" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Solución Innovadora <span className="p-2 rounded-full bg-yellow-300">5</span><img className="w-16" src={this.state.PI.presentacion[4].diapositiva} alt=""/></div>
                            <div id="6" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Descripción básica de funcionamiento del producto <span className="p-2 rounded-full bg-yellow-300">6</span><img className="w-16" src={this.state.PI.presentacion[5].diapositiva} alt=""/></div>
                            <div id="7" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Estrategia de monetización <span className="p-2 rounded-full bg-yellow-300">7</span><img className="w-16" src={this.state.PI.presentacion[6].diapositiva} alt=""/></div>
                            <div id="8" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Tamaño del mercado y competidores <span className="p-2 rounded-full bg-yellow-300">8</span><img className="w-16" src={this.state.PI.presentacion[7].diapositiva} alt=""/></div>
                            <div id="9" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Proyecciones financieras <span className="p-2 rounded-full bg-yellow-300">9</span><img className="w-16" src={this.state.PI.presentacion[8].diapositiva} alt=""/></div>
                            <div id="10" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Unit economics <span className="p-2 rounded-full bg-yellow-300">10</span><img className="w-16" src={this.state.PI.presentacion[9].diapositiva} alt=""/></div>
                            <div id="11" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Valuación de oferta y equity <span className="p-2 rounded-full bg-yellow-300">11</span><img className="w-16" src={this.state.PI.presentacion[10].diapositiva} alt=""/></div>
                            <div id="12" onClick={this.cambio.bind(this)} className="h-24 cursor-pointer">Agradecimiento y datos de contacto <span className="p-2 rounded-full bg-yellow-300">12</span><img className="w-16" src={this.state.PI.presentacion[11].diapositiva} alt=""/></div>
                        </div>
                    </div>                    
                </div>
                <div className="mt-8 hiddenNone"  id="TIQuestions">

                <div className="bg-gray-100 rounded-xl">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[1].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=2;i<4;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>EMPRESA CON PROPÓSITO <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 mb-4 accordion">El propósito de una empresa va más allá de alcanzar un objetivo económico concreto, sino que debe dotar de sentido a lo que hace una empresa, de algún modo justifica su existencia y define un modo de comportarse en su entorno. El propósito debe tener un impacto efectivo en la sociedad y no solo maximizar el valor de la empresa para el accionista. De este modo, el propósito inspira la estrategia de la empresa, sus planes de negocio y sus objetivos a largo plazo.</div>
                </div>
                <div className="grid grid-cols-2">
                    <div>
                        <div className="flex">
                                <img src={imagen} alt="logo" className="w-36" />
                                <div className="ml-4">
                                    <span className="text-sm">Empresa</span>
                                    <div>Sprouders</div>
                                    <span className="text-sm">Fecha de creación </span>
                                </div>
                        </div>
                        <div className="grid">
                            <div>Descripción breve de la empresa.</div>
                            <textarea cols="3" placeholder="Descripción" className="w-full" onChange={(e)=>{
                                let TI=this.state.TI;
                                TI.DE=e.target.value.length;
                                this.setState({TI:TI});
                            }}></textarea>
                            <div className="justify-self-end contador">{this.state.TI.DE}/1500 caracteres</div>
                        </div>
                        <div>
                            <div className="flex">
                                <img src={profile} alt="profile" className="rounded-full w-28" />
                                <div className="ml-4">
                                    <div>Tu nombre completo</div>
                                    <input type="text" placeholder="Nombre" />
                                    <input type="text" placeholder="Ocupación" />
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div>Descripción breve de la empresa.</div>
                            <textarea cols="3" placeholder="Experiencia Laboral" className="w-full" onChange={(e)=>{
                                let TI=this.state.TI;
                                TI.EL=e.target.value.length;
                                this.setState({TI:TI});
                            }}></textarea>
                            <div className="justify-self-end contador">{this.state.TI.EL}/1500 caracteres</div>
                        </div>
                        <div>
                            <div>Agrega tu equipo de trabajo <span onClick={(e)=>{
                                let TI=this.state.TI;
                                TI.integrantes.push({'nombre':'','puesto':'','posicion':TI.integrantes.length});
                                this.setState({TI:TI})
                            }}>+</span></div>
                            {this.state.TI.integrantes.map(int=><div key={int.posicion} className="flex">
                                <input className="w-20" type="text" defaultValue={int.nombre} placeholder="nombre"/>
                                <input className="w-20" type="text" defaultValue={int.puesto} placeholder="puesto"/>
                                <span onClick={(e)=>{
                                    let TI=this.state.TI;
                                    TI.integrantes.splice(int.posicion,1);
                                    this.setState({TI:TI})
                                }}>-</span>
                            </div>)}
                        </div>
                        
                    </div>
                    <div>
                        <div>
                            <div>Elevator pitch</div>
                            <input type="text" placeholder="Elevator pitch" id="inputEP"/><span onClick={(e)=>{
                                let value=document.getElementById("inputEP").value;
                                value = value.replace('watch?v=','embed/');
                                let TI=this.state.TI
                                TI.EP=value;
                                this.setState({TI:TI});
                            }}>Visualizar</span>
                            <iframe width="360" height="215" src={this.state.TI.EP} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div>
                            <div>Agrega el enlace de tus redes sociales</div>
                            <div className="flex">
                                <img src={iconstartcups} alt="iconstarcups" className="w-8" />
                                <input type="text" />
                            </div>
                            <div className="flex">
                                <img src={iconinstagram} alt="iconstarcups" className="w-8" />
                                <input type="text" />
                            </div>
                            <div className="flex">
                                <img src={iconfacebook} alt="iconstarcups" className="w-8" />
                                <input type="text" />
                            </div>
                            <div className="flex">
                                <img src={iconlinkedin} alt="iconstarcups" className="w-8" />
                                <input type="text" />
                            </div>
                            <div className="flex">
                                <img src={icontwitter} alt="iconstarcups" className="w-8" />
                                <input type="text" />
                            </div>
                            <div className="flex">
                                <img src={iconbehance} alt="iconstarcups" className="w-8" />
                                <input type="text" />
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="mt-8">
                            <div className="grid grid-cols-2">
                                <div className="flex">
                                    <label>Necesidad Capital</label>
                                    <input type="text" defaultValue="$0.00" className="h-4" />
                                </div>
                                <div className="flex">
                                    <label>Participación accionaria</label>
                                    <input type="text" defaultValue="0%" className="h-4" />
                                </div>
                            </div>
                </div>
                <div>
                    <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {this.state.TI.Años.map(año=>
                                            <th key={año.numero}>
                                                Año {año.numero}
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>(+) Ventas</td>
                                        {this.state.TI.Años.map(año=>
                                            <td className="interactive" key={año.numero} id={año.numero} >
                                                $ {año.totalventas}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td>(-) Costo de Ventas</td>
                                        {this.state.TI.Años.map(año=>
                                            <td className="interactive" key={año.numero} id={año.numero}>
                                                $ {año.totalcostos}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td>(=) Utilidad Bruta</td>
                                        {this.state.TI.Años.map(año=>
                                            <td key={año.numero} >
                                                $ {año.totalventas-año.totalcostos}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td>(-) Gastos Operativos</td>
                                        {this.state.TI.Años.map(año=>
                                            <td className="interactive" key={año.numero} id={año.numero}>
                                                $ {año.totalgastos}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td>(=) Utilidad O Pérdida<br/> de la operación</td>
                                        {this.state.TI.Años.map(año=>
                                            <td key={año.numero}>
                                                $ {año.totalventas-año.totalcostos-año.totalgastos}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td>(-) Impuestos,intereses<br/>depreciaciones y<br/> amortizaciones</td>
                                        {this.state.TI.Años.map(año=>
                                            <td className="interactive" key={año.numero} id={año.numero} >
                                                $ {año.totalimpuestos}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td>(=) Utilidad O Pérdida<br/> Neta</td>
                                        {this.state.TI.Años.map(año=>
                                            <td key={año.numero}>
                                                $ {año.totalventas-año.totalcostos-año.totalgastos-año.totalimpuestos}
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                    </table>
                </div>
                <div>
                    <h2>Units Economics</h2>
                    <div className="grid grid-cols-4">
                        <div><label>Margen de Contribución</label></div>
                        <div><label>0%</label></div>
                        <div><label>CAC</label></div>
                        <div><label>$0.00</label></div>
                        <div><label>Valor de Ticket Promedio</label></div>
                        <div><label>$0.00</label></div>
                        <div><label>Frecuencia de Compra</label></div>
                        <div><label>0.00</label></div>
                        <div><label>Valor del Cliente</label></div>
                        <div><label>$0.00</label></div>
                        <div><label>Valor de vida del cliente</label></div>
                        <div><label>$0.00</label></div>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="grid grid-cols-2">
                        <div>
                            <label>Descripción de la Propuesta de Valor</label>
                            <div className="bg-yellow-200 w-24">Idea DPV</div>
                        </div>
                        <div>
                            <label>Productos y servicios</label>
                            <div className="bg-yellow-200 w-24">Idea PS</div>
                        </div>
                        <div>
                            <label>Modelo de ingresos (monetización)</label>
                            <div className="bg-yellow-200 w-24">Idea MI</div>
                        </div>
                        <div>
                            <label>Mercado y competencia</label>
                            <div className="bg-yellow-200 w-24">Idea MC</div>
                        </div>
                        <div>
                            <label>Descripción del problema a resolver</label>
                            <div className="bg-yellow-200 w-24">Idea DPR</div>
                        </div>
                        <div>
                            <label>Detalles y uso del fondeo</label>
                            <div className="bg-yellow-200 w-24">Idea DF</div>
                        </div>
                    </div>
                </div>
                </div>
                
            </div>
        );
    }
}
export default PitchDeck;