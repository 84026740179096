import React from "react";
import "./styles/Academia.css";
import {
  AcademicCapIcon,
  ArchiveIcon,
  CubeIcon,
  RssIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";

import Academycursocard from "../components/AcademiaC/academycursocard";
import Academylongcard from "../components/AcademiaC/academylongcard";
const Academia = () => {
  return (
    <div className="mt-4 ml-14">
      <div className="grid grid-cols-7">
        <div className="col-span-4">
          <div>
            <button
              id="academias"
              className=" w-18 h-12 pl-2 pr-2 buttonactive"
              onClick={() => {
                var options = document.getElementsByClassName("buttonactive");
                for (var i = 0; i < options.length; i++) {
                  options[i].classList.replace("buttonactive", "buttontop");
                }
                document
                  .getElementById("academias")
                  .classList.replace("buttontop", "buttonactive");
              }}
            >
              <AcademicCapIcon className="ml-3 mt-1 w-5" />
              Academias
            </button>
            <button
              id="cursos"
              className=" w-12 h-12 buttontop"
              onClick={() => {
                var options = document.getElementsByClassName("buttonactive");
                for (var i = 0; i < options.length; i++) {
                  options[i].classList.replace("buttonactive", "buttontop");
                }
                document
                  .getElementById("cursos")
                  .classList.replace("buttontop", "buttonactive");
              }}
            >
              <ArchiveIcon className="ml-3 mt-1 w-5" />
              Cursos
            </button>
            <button
              id="programas"
              className=" w-14 h-12 buttontop"
              onClick={() => {
                var options = document.getElementsByClassName("buttonactive");
                for (var i = 0; i < options.length; i++) {
                  options[i].classList.replace("buttonactive", "buttontop");
                }
                document
                  .getElementById("programas")
                  .classList.replace("buttontop", "buttonactive");
              }}
            >
              <CubeIcon className="ml-3 mt-1 w-5" />
              Programas
            </button>
            <button
              id="coffetalks"
              className=" w-18 h-12  buttontop"
              onClick={() => {
                var options = document.getElementsByClassName("buttonactive");
                for (var i = 0; i < options.length; i++) {
                  options[i].classList.replace("buttonactive", "buttontop");
                }
                document
                  .getElementById("coffetalks")
                  .classList.replace("buttontop", "buttonactive");
              }}
            >
              <RssIcon className="ml-3 mt-1 w-5" />
              Coffe Talks
            </button>
          </div>
          <div className="seccion text-xl mb-4">
            Academias para todo tipo de Emprendedor
            <span className="vertodas float-right mr-6">
              Ver todas <ChevronRightIcon className="w-4 inline flecha" />
            </span>
          </div>
          <div className="flex space-x-4">
            <div>
              <Academycursocard />
            </div>
            <div>
              <Academycursocard />
            </div>
            <div>
              <Academycursocard />
            </div>
          </div>
          <div className="seccion mt-5 mb-4">
            Lo mejor del día
            <span className="ordenar float-right mr-6">
              <span className="opacity-50">Ordenar por</span>
              <select
                name="academia"
                id="academia"
                className="select-ordenar float-right border-none opacity-100"
              >
                <option value="popular">Lo mas popular</option>
              </select>
            </span>
          </div>
          <div className="mr-6 space-y-4">
            <Academylongcard />
            <Academylongcard />
            <Academylongcard />
          </div>
        </div>
        <div className="col-span-3 mt-8 mr-7">
          <div className="titlenews">
            Mis Academias
            {/* <select name="noticias" id="noticias" className="float-right">
                                <option value="Recientes">Recientes</option>
                            </select> */}
          </div>
          <div className="space-x-4 px-2">
            <div className="rounded-lg p-4 flex">
              <div className="border-1 border-gray-200 rounded-lg w-12 h-12"></div>
              <div className="relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 absolute top-0 right-0"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                <p className="font-bold text-2xl ">El buen Diseñador</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Academia;
