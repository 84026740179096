import React from 'react'
import { Routes,Route,useLocation,Navigate } from 'react-router-dom';

import Login from '../container/Login'
import Home from '../container/Home'
import Comunidad from '../container/Comunidad'
import Academia from '../container/Academia'
import Startups from '../container/Proyectos'
import Inversiones from '../container/Inversiones'
import Eventos from '../container/Eventos'
import Convocatorias from '../container/Convocatorias'
import ConvocatoriaDetalle from '../container/ConvocatoriaDetalle'
import Marketplace from '../container/Marketplace'
import { PrivateRoute } from './PrivateRoute';

export const AppRouter = () => {
   
  return (
      <div>
          <Routes>
              <Route path="/login" element={<Login />}></Route>

              <Route path="" element={<PrivateRoute />}>
                  <Route element={<Home />}>
                      <Route path="/" element={<Comunidad />}></Route>

                      <Route path="/academia" element={<Academia />}></Route>
                      <Route path="/startups" element={<Startups />}></Route>
                      <Route path="/inversiones" element={<Inversiones />}></Route>
                      <Route path="/eventos" element={<Eventos />}></Route>
                      <Route path="/convocatorias" element={<Convocatorias />}></Route>
                      <Route path="convocatorias/:sku" element={<ConvocatoriaDetalle />}></Route>

                      <Route path="/marketplace" element={<Marketplace />}></Route>
                  </Route>
              </Route>
          </Routes>
      </div>
  )
}
