import { Component } from "react";
import "./styles/DesignThinking.css";
import { CheckCircleIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import ImagenValor from "../../../assets/valor.png"
import ImagenEmpatia from "../../../assets/pensamientocerebro1.png"
import ImagenProductos from "../../../assets/productos.png"
import ImagenCreadores from "../../../assets/creadores.png"
import ImagenAliviadores from "../../../assets/aliviadores.png"
import ImagenAlegrias from "../../../assets/alegrias.png"
import ImagenFrustraciones from "../../../assets/frustraciones.png"
import ImagenTrabajos from "../../../assets/trabajos.png"
import ImagenOido from "../../../assets/oido.png"
import ImagenPiensa from "../../../assets/cerebro.png"
import ImagenObserva from "../../../assets/ojo.png"
import ImagenDice from "../../../assets/boca.png"
import ImagenMiedos from "../../../assets/carafrustracion.png"
import ImagenMotivaciones from "../../../assets/caraalegria.png"
import ImagenInternos from "../../../assets/internos.png"
import ImagenExternos from "../../../assets/externos.png"
import ImagenConexion from "../../../assets/conexion.png"

class DesignThinking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MU: {
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
      },
      MUrespuestas:{//Guardar en base de datos
        p1:'',
        p2:'',
        p3:'',
        p4:''
      },
      ME:{//Guardar en base de datos //array de strings
        '¿Qué escucha el usuario?':[],
        '¿Qué piensa y siente el usuario?':[],
        '¿Qué observa el usuario?':[],
        '¿Qué dice y hace?':[],
        '¿Qué miedos o frustraciones tiene el usuario?':[],
        '¿Qué motivaciones tiene el usuario?':[]
      },
      MA: { //Guardar en base de datos //array de strings
        'Interesados Externos':[],
        'Conexión con Interesados':[],
        'Interesados Internos':[],
      },
      PV: { //Guardar en Base de datos //array de strings
        'Productos y servicios':[],
        'Creadores de alegrías':[],
        'Alegrías del cliente':[],
        'Trabajos del cliente':[],
        'Aliviadores de frustraciones':[],
        'Frustraciones del cliente':[]
      },
      seleccion:'',
      seleccion2:'MU',
      contadorideas:0,
      ideas:[],
      idearepetida:false,
      editando:-1,
      editar:false,
      limitado:true
    };
  }
  agregaridea(e){
    if (e.code === 'Enter' && e.target.value!=="") {
            if(this.state[this.state.seleccion2][this.state.seleccion].findIndex(ideas=>ideas===e.target.value)){
                if(!this.state.editar){
                    let arr=this.state[this.state.seleccion2];
                    arr[this.state.seleccion].push(e.target.value);
                    this.setState({contadorideas:arr[this.state.seleccion].length})
                    this.setState({ideas:arr[this.state.seleccion]});
                    this.setState({[this.state.seleccion2]:arr});
                    document.getElementById('inputidea').value='';
                    this.setState({idearepetida:false});
                }else{
                    let arr=this.state[this.state.seleccion2];
                    arr[this.state.seleccion].splice(this.state.editando,1,e.target.value);
                    this.setState({contadorideas:arr[this.state.seleccion].length})
                    this.setState({ideas:arr[this.state.seleccion]})
                    this.setState({[this.state.seleccion2]:arr});
                    document.getElementById('inputidea').value='';
                    this.setState({idearepetida:false});
                    this.setState({editar:false});
                    this.setState({editando:-1});
                }
            }else{
                this.setState({idearepetida:true});
            }  
    }
  }
  checar(e){
    this.setState({seleccion:e.target.id});
    let arr=this.state[this.state.seleccion2];
    try{
        let num=arr[e.target.id].length;
        this.setState({contadorideas:num});
    }catch(e){
        console.log(e);
    }
    
    this.setState({ideas:arr[e.target.id]});
  }
  render() {
    return (
      <div className="ml-8">
        <div className="">
          <div className=" title">Design Thinking</div>
          
          <div className="flex">
            <div
              className="subtitle mr-16 active mt-4 w-18"
              id="MU"
              onClick={() => {
                document.getElementById("rectME").classList.add("hiddenNone");
                this.setState({seleccion:null});
                document.getElementById("rectMA").classList.add("hiddenNone");
                document.getElementById("rectPV").classList.add("hiddenNone");
                document.getElementById("ME").classList.remove("active");
                document.getElementById("MA").classList.remove("active");
                document.getElementById("PV").classList.remove("active");
                document.getElementById("MU").classList.add("active");
                document.getElementById("rectMU").classList.remove("hiddenNone");
                document.getElementById("MAQuestions").classList.add("hiddenNone");
                document.getElementById("MEQuestions").classList.add("hiddenNone");
                document.getElementById("PVQuestions").classList.add("hiddenNone");
                document.getElementById("MUQuestions").classList.remove("hiddenNone");
              }}
            >
              Mapa de Usuario
              <div className="rectangle" id="rectMU"></div>
            </div>

            <div
              className="subtitle mr-16 mt-4 w-18"
              id="ME"
              onClick={() => {
                document.getElementById("rectMU").classList.add("hiddenNone");
                this.setState({seleccion:null});
                document.getElementById("rectMA").classList.add("hiddenNone");
                document.getElementById("rectPV").classList.add("hiddenNone");
                document.getElementById("MU").classList.remove("active");
                document.getElementById("MA").classList.remove("active");
                document.getElementById("PV").classList.remove("active");
                document.getElementById("ME").classList.add("active");
                document.getElementById("rectME").classList.remove("hiddenNone");
                document.getElementById("MUQuestions").classList.add("hiddenNone");
                document.getElementById("MAQuestions").classList.add("hiddenNone");
                document.getElementById("PVQuestions").classList.add("hiddenNone");
                document.getElementById("MEQuestions").classList.remove("hiddenNone");
                this.setState({seleccion2:'ME'})
              }}
            >
              Mapa de Empatía
              <div className="rectangle hiddenNone" id="rectME"></div>
            </div>
            <div
              className="subtitle mr-16 mt-4 w-18"
              id="MA"
              onClick={() => {
                document.getElementById("rectME").classList.add("hiddenNone");
                this.setState({seleccion:null});
                document.getElementById("rectMU").classList.add("hiddenNone");
                document.getElementById("rectPV").classList.add("hiddenNone");
                document.getElementById("ME").classList.remove("active");
                document.getElementById("MU").classList.remove("active");
                document.getElementById("PV").classList.remove("active");
                document.getElementById("MA").classList.add("active");
                document.getElementById("rectMA").classList.remove("hiddenNone");
                document.getElementById("MUQuestions").classList.add("hiddenNone");
                document.getElementById("MEQuestions").classList.add("hiddenNone");
                document.getElementById("PVQuestions").classList.add("hiddenNone");
                document.getElementById("MAQuestions").classList.remove("hiddenNone");
                this.setState({seleccion2:'MA'})
              }}
            >
              Mapa de Actores
              <div className="rectangle hiddenNone" id="rectMA"></div>
            </div>
            <div
              className="subtitle mt-4 w-18"
              id="PV"
              onClick={() => {
                document.getElementById("rectME").classList.add("hiddenNone");
                this.setState({seleccion:null});
                document.getElementById("rectMA").classList.add("hiddenNone");
                document.getElementById("rectMU").classList.add("hiddenNone");
                document.getElementById("ME").classList.remove("active");
                document.getElementById("MA").classList.remove("active");
                document.getElementById("MU").classList.remove("active");
                document.getElementById("PV").classList.add("active");
                document.getElementById("rectPV").classList.remove("hiddenNone");
                document.getElementById("MUQuestions").classList.add("hiddenNone");
                document.getElementById("MEQuestions").classList.add("hiddenNone");
                document.getElementById("MAQuestions").classList.add("hiddenNone");
                document.getElementById("PVQuestions").classList.remove("hiddenNone");
                this.setState({seleccion2:'PV'})
              }}
            >
              Propuesta de Valor
              <div className="rectangle hiddenNone" id="rectPV"></div>
            </div>
          </div>

          <div className='lineamenuDT'></div> 
        </div>


  <div className="mt-4" id="MUQuestions">
          <div className="bg-gray-100 rounded-xl pb-2">
            <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e) => {
              document.getElementsByClassName("accordion")[0].classList.toggle('colapse');
              var prueba = document.querySelectorAll('.accordionarrow');
              for (let i = 0; i < 2; i++) {
                prueba[i].classList.toggle('hiddenNone');
              }
            }}> MAPA DE USUARIO<ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
            <div className="flex  accordion">
              <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0" allowFullScreen></iframe></div>
              <div className="text-sm text-gray-400 mt-6">El mapa de usuario es una comprensión visual del cliente objetivo, el cual te ayudará a tí y a tu equipo a comprender el éxito de tu producto o servicio desde su perspectiva.</div>
            </div>
          </div>        
          <div className="mt-4">
            <label>1. Perfil Demográfico</label>
            <br />
            <label className="flex">( Edad, sexo, estado civíl, profesión, nivel de ingresos, lugar de residencia, ocupación, nivel de estudios. )</label>
            <br />
            <div className="flex mt-4 text-sm">
              <input id="inputID"
                type="text"
                placeholder="Describe detalladamente el perfil demográfico de tus usuarios / clientes."
                className="flex-grow p-5"
                defaultValue={this.state.MUrespuestas.p1}
                onChange={(e) => {
                  if (e.target.value.length !== 0) {
                    document.getElementById("MUp1").classList.add("complete");
                  } else {
                    document
                      .getElementById("MUp1")
                      .classList.remove("complete");
                  }
                  var p1 = this.state.MU;
                  p1.p1 = e.target.value.length;
                  var mu=this.state.MUrespuestas;
                  mu.p1=e.target.value;
                  this.setState({MUrespuestas:mu});
                  this.setState({ MU: p1 });
                }}
              />
              <div>
                <CheckCircleIcon className="w-5 flex-none iconDT" id="MUp1" />
              </div>
            </div>
            <div className="contador1 mt-2 ml-5">
              Caracteres {this.state.MU.p1}/700
            </div>
          </div>



          <div className="mt-4">
            <label>2. Perfil Psicográfico</label>
            <br />
            <label className="flex">( Personalidad, estilo de vida, intereses, aficiones, status social, actividades diarias, hábitos alimenticios. )</label>
            <br />
            <div className="flex mt-4 text-sm">
              <input id="inputID"
                type="text"
                placeholder="Describe detalladamente el perfil psicográfico de tus usuarios / clientes."
                className="flex-grow p-5"
                defaultValue={this.state.MUrespuestas.p2}
                onChange={(e) => {
                  if (e.target.value.length !== 0) {
                    document.getElementById("MUp2").classList.add("complete");
                  } else {
                    document
                      .getElementById("MUp2")
                      .classList.remove("complete");
                  }
                  var p2 = this.state.MU;
                  p2.p2 = e.target.value.length;
                  var mu=this.state.MUrespuestas;
                  mu.p2=e.target.value;
                  this.setState({MUrespuestas:mu});
                  this.setState({ MU: p2 });
                }}
              />
              <div>
                <CheckCircleIcon className="w-5 flex-none iconDT" id="MUp2" />
              </div>
            </div>
            <div className="contador1 mt-2 ml-5">
              Caracteres {this.state.MU.p2}/700
            </div>
          </div>
          <div className="mt-4">
            <label>
              3. Perfil Tecnológico
            </label>
            <br />
            <label className="flex">
              ( ¿Qué usa?, smartphone, desktop, horas de uso al día, nivel de habilidad. )
            </label>
            <br />
            <div className="flex mt-4 text-sm">
              <input id="inputID"
                type="text"
                placeholder="Describe detalladamente el nivel de adopción tecnológica que tienen tus usuarios."
                className="flex-grow p-5"
                defaultValue={this.state.MUrespuestas.p3}
                onChange={(e) => {
                  if (e.target.value.length !== 0) {
                    document.getElementById("MUp3").classList.add("complete");
                  } else {
                    document
                      .getElementById("MUp3")
                      .classList.remove("complete");
                  }
                  var p3 = this.state.MU;
                  p3.p3 = e.target.value.length;
                  var mu=this.state.MUrespuestas;
                  mu.p3=e.target.value;
                  this.setState({MUrespuestas:mu});
                  this.setState({ MU: p3 });
                }}
              />
              <div>
                <CheckCircleIcon className="w-5 flex-none iconDT" id="MUp3" />
              </div>
            </div>
            <div className="contador1 mt-2 ml-5">
              Caracteres {this.state.MU.p3}/700
            </div>
          </div>
          <div className="mt-4">
            <label>
              4. Motivaciones del usuario
            </label>
            <br />
            <label className="flex"> 
              ( ¿Qué lo inspira?, ¿Qué lo motiva? )
            </label>
            <br />
            <div className="flex mt-4 text-sm">
              <input id="inputID"
                type="text"
                placeholder="Describe cuales son las motivaciones y aspiraciones de tus usuarios."
                className="flex-grow p-5"
                defaultValue={this.state.MUrespuestas.p4}
                onChange={(e) => {
                  if (e.target.value.length !== 0) {
                    document.getElementById("MUp4").classList.add("complete");
                  } else {
                    document
                      .getElementById("MUp4")
                      .classList.remove("complete");
                  }
                  var p4 = this.state.MU;
                  p4.p4 = e.target.value.length;
                  var mu=this.state.MUrespuestas;
                  mu.p4=e.target.value;
                  this.setState({MUrespuestas:mu});
                  this.setState({ MU: p4 });
                }}
              />
              <div>
                <CheckCircleIcon className="w-5 flex-none iconDT" id="MUp4" />
              </div>
            </div>
            <div className="contador1 mt-2 ml-5 pb-5">
              Caracteres {this.state.MU.p4}/700
            </div>
          </div>
        </div>
  <div className="mt-8 hiddenNone" id="MEQuestions">
          <div className="bg-gray-100 rounded-xl pb-2">
            <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e) => {
              document.getElementsByClassName("accordion")[1].classList.toggle('colapse');
              var prueba = document.querySelectorAll('.accordionarrow');
              for (let i = 2; i < 4; i++) {
                prueba[i].classList.toggle('hiddenNone');
              }
            }}>MAPA DE EMPATÍA<ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
            <div className="flex  accordion">
              <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0" allowFullScreen></iframe></div>
              <div className="text-sm text-gray-400 mt-6">El mapa de empatía es un formato que busca describir el cliente ideal de una empresa por medio de análisis de 6 aspectos, relacionados a los sentimientos del ser humano. Puede ser realizado a partir de preguntas que ayudan a  entender a conocer el cliente y como relacionarse con él.</div>
            </div>
          </div>

        <div className="flex justify-end text-sm mt-4"><button onClick={()=>{this.setState({limitado:true})}}> <div className="text-gray-800 mr-2 mb-1">Ver limitado</div></button> <button onClick={()=>{this.setState({limitado:false})}}><div className="ml-3 mr-3 mb-1 text-gray-800">Ver todo</div></button></div>
          <div className="grid grid-cols-3">

            <div className='flex justify-center row-span-3 radioLCB p-1' id="MEescucha">
              <input type="radio" name="MEGroup" id="MEr1" />
              <label className="radioLCC px-4 py-2" htmlFor='MEr1' id="¿Qué escucha el usuario?" onClick={this.checar.bind(this)}>

                <div className="flex justify-center">
                <img src={ImagenOido} className="w-8 h-8 mb-2" alt="img-user" id="¿Qué escucha el usuario?" /> </div>

                ¿Qué escucha<br/> el usuario?
               
              <br/><br/><div className="text-gray-300" id="¿Qué escucha el usuario?">{this.state.ME['¿Qué escucha el usuario?'].length} ideas </div><br/>
              <ul>
                {this.state.limitado && this.state.ME['¿Qué escucha el usuario?'].slice(0,5).map(idea=><li id="¿Qué escucha el usuario?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                {!this.state.limitado && this.state.ME['¿Qué escucha el usuario?'].map(idea=><li id="¿Qué escucha el usuario?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
              </ul></label>
            </div>


            <div className='flex justify-center radioLCB p-1' id="MEpiensa">
              <input type="radio" name="MEGroup" id="MEr2" />
              <label className="radioLCC px-4 py-2" htmlFor='MEr2' id="¿Qué piensa y siente el usuario?" onClick={this.checar.bind(this)}>
                
              <div className="flex justify-center">
                <img src={ImagenPiensa} className="w-8 h-8 mb-2" alt="img-user" id="¿Qué piensa y siente el usuario?"/> </div>   
                
                ¿Qué piensa y siente<br/> el usuario?

              <br/><br/><div className="text-gray-300" id="¿Qué piensa y siente el usuario?">{this.state.ME['¿Qué piensa y siente el usuario?'].length} ideas </div><br/>
              <ul>
                {this.state.limitado && this.state.ME['¿Qué piensa y siente el usuario?'].slice(0,5).map(idea=><li id="¿Qué piensa y siente el usuario?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                {!this.state.limitado && this.state.ME['¿Qué piensa y siente el usuario?'].map(idea=><li id="¿Qué piensa y siente el usuario?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
              </ul></label>
            </div>


            <div className='flex justify-center row-span-3 radioLCB p-1' id="MEobserva">
              <input type="radio" name="MEGroup" id="MEr3" />
              <label className="radioLCC px-4  py-2" htmlFor='MEr3' id="¿Qué observa el usuario?" onClick={this.checar.bind(this)}>
                
              <div className="flex justify-center">
                <img src={ImagenObserva} className="w-8 h-8 mb-2" alt="img-user" id="¿Qué observa el usuario?"/> </div> 
                
                ¿Qué observa<br/> el usuario?

              <br/><br/><div className="text-gray-300" id="¿Qué observa el usuario?">{this.state.ME['¿Qué observa el usuario?'].length} ideas </div><br/>
              <ul>
                {this.state.limitado && this.state.ME['¿Qué observa el usuario?'].slice(0,5).map(idea=><li id="¿Qué observa el usuario?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                {!this.state.limitado && this.state.ME['¿Qué observa el usuario?'].map(idea=><li id="¿Qué observa el usuario?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
              </ul></label>
            </div>
            <div className="grid justify-items-center py-1">
           
                <img src={ImagenEmpatia} className="w-12 h-12" alt="img-user" />
            
            </div>
            <div className='flex justify-center radioLCB p-1' id="MEdice">
              <input type="radio" name="MEGroup" id="MEr4" />
              <label className="radioLCC px-4  py-2" htmlFor='MEr4' id="¿Qué dice y hace?" onClick={this.checar.bind(this)}>
                
              <div className="flex justify-center">
                <img src={ImagenDice} className="w-8 h-8 mb-2" alt="img-user" id="¿Qué dice y hace?"/> </div> 
              
                ¿Qué dice y hace<br/> el usuario?

              <br/><br/><div className="text-gray-300" id="¿Qué dice y hace?">{this.state.ME['¿Qué dice y hace?'].length} ideas </div><br/>
              <ul>
                {this.state.limitado && this.state.ME['¿Qué dice y hace?'].slice(0,5).map(idea=><li id="¿Qué dice y hace?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                {!this.state.limitado && this.state.ME['¿Qué dice y hace?'].map(idea=><li id="¿Qué dice y hace?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
              </ul></label>
            </div> 
          </div>


          <div className="grid grid-cols-2">
            <div className='flex justify-center radioLCB p-1' id="MEmiedos">
              <input type="radio" name="MEGroup" id="MEr5" />
              <label className="radioLCC px-4  py-2" htmlFor='MEr5' id="¿Qué miedos o frustraciones tiene el usuario?" onClick={this.checar.bind(this)}>
                
              <div className="flex justify-center">
                <img src={ImagenMiedos} className="w-8 h-8 mb-2" alt="img-user" id="¿Qué miedos o frustraciones tiene el usuario?"/> </div> 

                ¿Qué miedos o frustraciones<br/> tiene el usuario?

              <br/><br/><div className="text-gray-300" id="¿Qué miedos o frustraciones tiene el usuario?">{this.state.ME['¿Qué miedos o frustraciones tiene el usuario?'].length} ideas </div><br/>
              <ul>
                {this.state.limitado && this.state.ME['¿Qué miedos o frustraciones tiene el usuario?'].slice(0,5).map(idea=><li id="¿Qué miedos o frustraciones tiene el usuario?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                {!this.state.limitado && this.state.ME['¿Qué miedos o frustraciones tiene el usuario?'].map(idea=><li id="¿Qué miedos o frustraciones tiene el usuario?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
              </ul></label>
            </div>


            <div className='flex justify-center radioLCB p-1' id="MEmotivaciones">
              <input type="radio" name="MEGroup" id="MEr6" />
              <label className="radioLCC px-4  py-2" htmlFor='MEr6' id="¿Qué motivaciones tiene el usuario?" onClick={this.checar.bind(this)}>
                
              <div className="flex justify-center">
                <img src={ImagenMotivaciones} className="w-8 h-8 mb-2" alt="img-user" id="¿Qué motivaciones tiene el usuario?"/> </div> 
                
                ¿Qué motivaciones o alegrías<br/> tiene le usuario?

              <br/><br/><div className="text-gray-300" id="¿Qué motivaciones tiene el usuario?">{this.state.ME['¿Qué motivaciones tiene el usuario?'].length} ideas </div><br/>
              <ul>
                {this.state.limitado && this.state.ME['¿Qué motivaciones tiene el usuario?'].slice(0,5).map(idea=><li id="¿Qué motivaciones tiene el usuario?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                {!this.state.limitado && this.state.ME['¿Qué motivaciones tiene el usuario?'].map(idea=><li id="¿Qué motivaciones tiene el usuario?" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
              </ul></label>
            </div>  
          </div>
  </div>
  <div className="mt-4 hiddenNone mb-16" id="MAQuestions">
          <div className="bg-gray-100 rounded-xl pb-2">
            <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e) => {
              document.getElementsByClassName("accordion")[2].classList.toggle('colapse');
              var prueba = document.querySelectorAll('.accordionarrow');
              for (let i = 4; i < 6; i++) {
                prueba[i].classList.toggle('hiddenNone');
              }
            }}>MAPA DE ACTORES<ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
            <div className="flex  accordion">
              <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0" allowFullScreen></iframe></div>
              <div className="text-sm text-gray-400 mt-6">Con la herramienta mapa de actores, el objetivo es mapear “quién es quién” en nuestro sector, identificando los posibles grupos de interés y de poder, para poder analizar cómo pueden influir en nuestro proyecto, cómo nos relacionamos entre sí, o determinar el grado de influencia entre todos.
                Pero lo verdaderamente interesante es poder establecer una conexión o relación estratégica con cada uno de ellos.</div>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-12">
          <div className='justify-center' id="MAexternos">
              <input type="radio" name="MAGroup" id="MAr1" />
              <label className="MAlabels MAradioLCC mt-10 pt-8 px-32 py-24 pb-72" htmlFor='MAr1' id="Interesados Externos" onClick={this.checar.bind(this)}>Interesados externos
              </label>
              <div className='justify-center' id="MAconexion">
                <input type="radio" name="MAGroup" id="MAr2" />
                <label className="MAlabels MAradioLCC px-12 py-2 pb-48 ml-16" htmlFor='MAr2' id="Conexión con Interesados" onClick={this.checar.bind(this)}>Conexión con interesados
                </label>
                <div className='justify-center' id="MAconexion">
                  <input type="radio" name="MAGroup" id="MAr3" />
                  <label className="MAlabels MAradioLCC px-1 -py-2 pb-28 ml-32" htmlFor='MAr3' id="Interesados Internos" onClick={this.checar.bind(this)}>Interesados internos

                  </label><br/><br/><br/><br/><br/><br/>
                </div>
              </div>
          </div>
          {this.state.seleccion==="Interesados Externos" && 
          <div className="flex justify-center">
            <div>
             <img src={ImagenExternos} className="w-16 h-16 ml-20" alt="img-user" id="Interesados Externos"/><br/>
              <div className="text-gray-800 text-md px-4">{this.state.seleccion} <br/> <div className="text-gray-600 text-xs mt-4 px-0">Son todas las personas u organizaciones que interactúan indirectamente con nuestra empresa o idea de negocio como permisos de gobierno, medios de comunicación, comunidades específicas, organizaciones religiosas, ong´s, etc. </div></div></div></div>}

          {this.state.seleccion==="Conexión con Interesados" && 
          <div className="flex justify-center">
            <div>
             <img src={ImagenConexion} className="w-16 h-16 ml-20" alt="img-user" id="Conexion con Interesados"/><br/>
              <div className="text-gray-800 text-md px-4">{this.state.seleccion} <br/> <div className="text-gray-600 text-xs mt-4 px-0">Son todas las personas, organizaciones o elementos que son necesarios para realización de nuestro proyecto o empresa como clientes, proveedores, distribuidores, financiamiento, puntos de venta, etc.  </div></div></div></div>}
          
          {this.state.seleccion==="Interesados Internos" && 
          <div className="flex justify-center">
            <div>
              <img src={ImagenInternos} className="w-16 h-16 ml-20" alt="img-user" id="Interesados Internos"/><br/>
               <div className="text-gray-800 text-md px-4">{this.state.seleccion} <br/> <div className="text-gray-600 text-xs mt-4 px-0">Son todas las personas u organizaciones que interactúan directamente con el proyecto, empresa o idea de negocio, como empleados, jefes, coordinadores, inversionistas, etc. </div></div></div></div>}
          </div>
        </div>

        <div className="mt-8 hiddenNone" id="PVQuestions">
        <div className="bg-gray-100 rounded-xl pb-2">
            <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e) => {
              document.getElementsByClassName("accordion")[3].classList.toggle('colapse');
              var prueba = document.querySelectorAll('.accordionarrow');
              for(let i=6;i<8;i++){
                prueba[i].classList.toggle('hiddenNone');
             }
            }}>PROPUESTA DE VALOR<ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
            <div className="flex  accordion">
              <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0" allowFullScreen></iframe></div>
              <div className="text-sm text-gray-400 mt-6">El mapa de propuesta de valor, es el conjunto de razones con las cuales convences al cliente de que se fije en lo que ofreces y haga lo que le pides. Consiste en cómo ajustas tu oferta para aumentar la demanda; creando y modificando aspectos como producto o servicio y su impacto en el cliente, beneficio que reporta y su diferenciación con respecto a otros productos similares.</div>
            </div>
          </div>
        

        <div className="flex justify-end text-sm mt-4"><button onClick={()=>{this.setState({limitado:true})}}> <div className="text-gray-800 mr-2 mb-1">Ver limitado</div></button> <button onClick={()=>{this.setState({limitado:false})}}><div className="ml-3 mr-3 mb-1 text-gray-800">Ver todo</div></button></div>

            <div className="flex">
            <div className="grid grid-cols-2">
              <div className='flex justify-center row-span-2 radioLCB p-1' id="PVproducto">
                <input type="radio" name="PVGroup" id="PVr1"/>
                
                <label className="radioLCC px-4 py-8" htmlFor='PVr1' id="Productos y servicios" onClick={this.checar.bind(this)}>    
                
                <div className="flex justify-center">
                  <img src={ImagenProductos} className="w-6 h-6 mb-2" alt="img-user" id="Productos y servicios"/>
                </div>
                
                Productos y <br/>Servicios<br/><br/> 

                <div className="text-md text-gray-300" id="Productos y servicios">{this.state.PV['Productos y servicios'].length} ideas </div><br/>
                  <ul>
                  {this.state.limitado && this.state.PV['Productos y servicios'].slice(0,5).map(idea=><li id="Productos y servicios" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                    {!this.state.limitado && this.state.PV['Productos y servicios'].map(idea=><li id="Productos y servicios" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                  </ul></label>
              </div>


              <div className='flex justify-center radioLCB p-1 pr-5' id="PVcreadores">
                <input type="radio" name="PVGroup" id="PVr2" />
                <label className="radioLCC px-4 py-8" htmlFor='PVr2' id="Creadores de alegrías" onClick={this.checar.bind(this)}>
                  
                <div className="flex justify-center">
                  <img src={ImagenCreadores} className="w-6 h-6 mb-2" alt="img-user" id="Creadores de alegrías"/>
                </div>
                  
                  Creadores de <br/> Alegrías<br/><br/><div className="text-md text-gray-300" id="Creadores de alegrías">{this.state.PV['Creadores de alegrías'].length} ideas </div><br/>
                  <ul>
                  {this.state.limitado && this.state.PV['Creadores de alegrías'].slice(0,5).map(idea=><li id="Creadores de alegrías" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                    {!this.state.limitado && this.state.PV['Creadores de alegrías'].map(idea=><li id="Creadores de alegrías" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                  </ul></label>
              </div>

              <div className='flex justify-center radioLCB p-1 pr-5' id="PValiviadores">
                <input type="radio" name="PVGroup" id="PVr5" />
                <label className="radioLCC px-4 py-8" htmlFor='PVr5' id="Aliviadores de frustraciones" onClick={this.checar.bind(this)}>
                  
                <div className="flex justify-center">
                  <img src={ImagenAliviadores} className="w-6 h-6 mb-2" alt="img-user" id="Aliviadores de frustraciones"/>
                </div>
                  
                  Aliviadores de <br/>Frustraciones<br/><br/><div className="text-md text-gray-300 grow-0" id="Aliviadores de frustraciones">{this.state.PV['Aliviadores de frustraciones'].length} ideas </div><br/>
                  <ul>
                  {this.state.limitado && this.state.PV['Aliviadores de frustraciones'].slice(0,5).map(idea=><li id="Aliviadores de frustraciones" className="text-xs text-gray-500 grow-0" key={idea}>{idea}</li>)}
                    {!this.state.limitado && this.state.PV['Aliviadores de frustraciones'].map(idea=><li id="Aliviadores de frustraciones" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                  </ul></label>
              </div>
                </div>
              
              <div className="grid content-center">
               <img src={ImagenValor} className="w-24 h-24" alt="img-user" />
            </div>

            <div className="grid grid-cols-2">
              <div className='flex justify-center radioLCB p-1 pl-5' id="PValegrias">
                <input type="radio" name="PVGroup" id="PVr3" />
                <label className="radioLCC px-4  py-8" htmlFor='PVr3' id="Alegrías del cliente" onClick={this.checar.bind(this)}>
                  
                <div className="flex justify-center">
                  <img src={ImagenAlegrias} className="w-6 h-6 mb-2" alt="img-user" id="Alegrías del cliente"/>
                </div>
                  
                  Alegrías del <br/> Cliente<br/><br/><div className="text-md text-gray-300" id="Alegrías del cliente">{this.state.PV['Alegrías del cliente'].length} ideas </div><br/>
                  <ul>
                  {this.state.limitado && this.state.PV['Alegrías del cliente'].slice(0,5).map(idea=><li id="Alegrías del cliente" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                    {!this.state.limitado && this.state.PV['Alegrías del cliente'].map(idea=><li id="Alegrías del cliente" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                  </ul></label>
              </div>


              <div className='flex justify-center row-span-2 radioLCB p-1' id="PVtrabajos">
                <input type="radio" name="PVGroup" id="PVr4" />
                <label className="radioLCC px-4  py-8" htmlFor='PVr4' id="Trabajos del cliente" onClick={this.checar.bind(this)}>
                  
                <div className="flex justify-center">
                  <img src={ImagenTrabajos} className="w-6 h-6 mb-2" alt="img-user" id="Trabajos del cliente"/>
                </div>
                              
                  Trabajos del <br/>Cliente<br/><br/><div className="text-md text-gray-300" id="Trabajos del cliente">{this.state.PV['Trabajos del cliente'].length} ideas </div><br/>
                  <ul>
                  {this.state.limitado && this.state.PV['Trabajos del cliente'].slice(0,5).map(idea=><li id="Trabajos del cliente" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                    {!this.state.limitado && this.state.PV['Trabajos del cliente'].map(idea=><li id="Trabajos del cliente" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                  </ul></label>
              </div>
             
              <div className='flex justify-center radioLCB p-1 pl-5' id="PVfrustraciones">
                <input type="radio" name="PVGroup" id="PVr6" />
                <label className="radioLCC px-4  py-8" htmlFor='PVr6' id="Frustraciones del cliente" onClick={this.checar.bind(this)}>
                  
                <div className="flex justify-center">
                  <img src={ImagenFrustraciones} className="w-6 h-6 mb-2" alt="img-user" id="Frustraciones del cliente"/>
                </div> 
                                    
                  Frustraciones del<br/> Cliente<br/><br/><div className="text-md text-gray-300" id="Frustraciones del cliente">{this.state.PV['Frustraciones del cliente'].length} ideas </div><br/>
                  <ul>
                  {this.state.limitado && this.state.PV['Frustraciones del cliente'].slice(0,5).map(idea=><li id="Frustraciones del cliente" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                    {!this.state.limitado && this.state.PV['Frustraciones del cliente'].map(idea=><li id="Frustraciones del cliente" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                  </ul></label>
              </div>
            </div>
            </div>
            <div className='lineabajaDT w-full'></div>
        </div>

        {this.state.seleccion && 
                <div>
                    <div className="text-gray-800 text-lg">{this.state.seleccion}</div><div className="text-gray-400 text-sm">/ {this.state.contadorideas} ideas</div>
                    <div className="flex mt-1 ml-2">
                    <input placeholder="Escribe aquí una idea" id="inputidea" className="w-full p-3 pl-2 border-2 rounded-lg mb-4 text-sm" onKeyUp={this.agregaridea.bind(this)}/>
                        {this.state.idearepetida && <div className="text-red-500 ml-2"> * Idea repetida</div>}
                    </div>
                    <div>
                        <ul>
                            {this.state.ideas.map(idea=><div className="flex" key={idea}><button className="bg-gray-200 rounded-full p-1 mt-1 mr-2 text-xs" onClick={()=>{
                                let arr=this.state.ideas;
                                const index=arr.findIndex(ideas=>ideas===idea);
                                arr.splice(index,1);
                                this.setState({ideas:arr});
                                let num=arr.length;
                                this.setState({contadorideas:num});
                                document.getElementById('inputidea').value='';
                            }}>x</button>
                            <li className="text-sm mt-2" onClick={()=>{
                                let arr=this.state.ideas;
                                const index=arr.findIndex(ideas=>ideas===idea);
                                document.getElementById('inputidea').value=arr[index];
                                this.setState({editar:true});
                                this.setState({editando:index});
                            }} >{idea}</li></div>)}
                        </ul>
                        
                    </div>
                    <div className="py-10"></div>                    
                </div>}
      </div>
    );
  }
}
export default DesignThinking;
