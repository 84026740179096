import React, { useState, useEffect } from "react";
import imagen from "../assets/convocatorias.jpeg";
import CountDown from '../components/ConvocatoriaC/CountDown';
import EtapasConvocatoria from '../components/ConvocatoriaC/EtapasConvocatoria';
import BasesConvocatoria from '../components/ConvocatoriaC/BasesConvocatoria';
import { useParams } from "react-router-dom";


const ConvocatoriaDetalle = () => {
  const [ convocatoria, setConvocatoria ] = useState({});
  let params = useParams();

  const gqlQuery = (sku) => {
    return `
    {
      applicationBySku (sku: "${sku}"){
        title,
        subtitle,
        description
        sponsor_img,
        background_img
        start_date,
      }
    }
  `
  };

  const fetchConvocatoria = async () => {
    const token = window.sessionStorage.getItem("auth-token");
    const sku = params.sku;
    const response = await fetch('https://startcups-staging.herokuapp.com/graphql', {
      method: 'POST',
      headers: {
        'content-type':'application/json',
        'Authorization': `Bearer ${token}`
      },
      body:JSON.stringify({query: gqlQuery(sku)})
    });
    const data = await response.json();
    const convData = data?.data?.applicationBySku;
    if(convData){
      setConvocatoria(convData);
    }
    else{
      setConvocatoria({});
    }

    return convData;
  }

  useEffect( () => {
    fetchConvocatoria();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="relative">
      <div className="bg-gray-800 pb-16 h-64">
        <div className="absolute left-10 top-6 text-white text-sm cursor-pointer space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          <span>Regresar</span>
        </div>
      </div>
      <div class="-mt-16">
        <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
          <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
            <div className="flex space-x-8 rounded-lg shadow-lg p-2 bg-white">
              <div>
                <div className="relative w-48 aspect-w-3 aspect-h-4">
                  <img
                    src={convocatoria?.sponsor_img}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src=imagen;
                    }}
                    alt="convocatorias"
                    className="absolute object-cover object-center w-full h-full rounded-lg"
                  />
                </div>
              </div>
              <div className="">
                <p className="text-xl font-bold">
                  {convocatoria?.title}
                </p>
                <p className="text-sm font-bold">{convocatoria?.subtitle}</p>
                <p className="text-sm font-normal">
                {convocatoria?.description}
                </p>
                <br />
                <p className="text-sm font-normal">
                  Se colocará un texto más detallado sobre los requisitos de la convocatoria
                </p>
              </div>
            </div>
            <div className="mt-6 mb-10">
              <CountDown endDate="12/25/2022"/>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-8">
              <EtapasConvocatoria/>
              <BasesConvocatoria/>
            </div>
            <div className="flex justify-center">
              <button className="text-xl font-bold flex items-center space-x-2 px-4 py-2 bg-gradient-to-r from-yellow-400 to-yellow-200 rounded-full">
                <span>Aplica ahora</span>
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10c5.52,0,10-4.48,10-10C22,6.48,17.52,2,12,2z M7,8c0-0.55,0.45-1,1-1h5 c0.55,0,1,0.45,1,1v0c0,0.55-0.45,1-1,1H8C7.45,9,7,8.55,7,8L7,8z M7,11c0-0.55,0.45-1,1-1h5c0.55,0,1,0.45,1,1v0c0,0.55-0.45,1-1,1 H8C7.45,12,7,11.55,7,11L7,11z M10,14c0,0.55-0.45,1-1,1H8c-0.55,0-1-0.45-1-1v0c0-0.55,0.45-1,1-1h1C9.55,13,10,13.45,10,14L10,14z M18.29,14.12l-3.54,3.54c-0.39,0.39-1.02,0.39-1.41,0l-1.41-1.41c-0.39-0.39-0.39-1.02,0-1.41c0.39-0.39,1.02-0.39,1.41,0 l0.71,0.71l2.83-2.83c0.39-0.39,1.02-0.39,1.41,0C18.68,13.1,18.68,13.73,18.29,14.12z"/></svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConvocatoriaDetalle;
