import { Component } from "react";
import './styles/BusinessModelCanvas.css';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'
import ImagenBMCactividades from "../../../assets/BMCactividades.png"
import ImagenBMCaliados from "../../../assets/BMCaliados.png"
import ImagenBMCcanales from "../../../assets/BMCcanales.png"
import ImagenBMCcostos from "../../../assets/BMCcostos.png"
import ImagenBMCingresos from "../../../assets/BMCingresos.png"
import ImagenBMCrecursos from "../../../assets/BMCrecursos.png"
import ImagenBMCrelacion from "../../../assets/BMCrelacion.png"
import ImagenBMCsegmento from "../../../assets/BMCsegmento.png"
import ImagenBMCvalor from "../../../assets/BMCvalor.png"


class BusinessModelCanvas extends Component{
    constructor(props){
        super(props);
        this.state={
            LV:{//Guardar en base de datos //array de strings
                'Aliados clave':[],
                'Actividades clave':[],
                'Propuesta de valor':[],
                'Relación con clientes':[],
                'Segmento de mercado/clientes':[],
                'Recursos clave':[],
                'Canales de comunicación y venta':[],
                'Estructura de costos':[],
                'Fuentes de ingresos':[],
            },
            seleccion:'',
            contadorideas:0,
            ideas:[],
            idearepetida:false,
            editando:-1,
            editar:false,
            limitado:true
        }
    }
    agregaridea(e){
        if (e.code === 'Enter' && e.target.value!=="") {
            
            if(this.state.LV[this.state.seleccion].findIndex(ideas=>ideas===e.target.value)){
                if(!this.state.editar){
                        let arr=this.state.LV;
                        arr[this.state.seleccion].push(e.target.value);
                        this.setState({contadorideas:arr[this.state.seleccion].length})
                        this.setState({ideas:arr[this.state.seleccion]})
                        this.setState({LV:arr});
                        document.getElementById('inputidea').value='';
                        this.setState({idearepetida:false});
                    }else{
                        let arr=this.state.LV;
                        arr[this.state.seleccion].splice(this.state.editando,1,e.target.value);
                        this.setState({contadorideas:arr[this.state.seleccion].length})
                        this.setState({ideas:arr[this.state.seleccion]})
                        this.setState({LV:arr});
                        document.getElementById('inputidea').value='';
                        this.setState({idearepetida:false});
                        this.setState({editar:false});
                        this.setState({editando:-1});
                    }
                }else{
                    this.setState({idearepetida:true});
                }           
        }
    }
    checar(e){
        this.setState({seleccion:e.target.id});
        let arr=this.state.LV;
        try{
            let num=arr[e.target.id].length;
            this.setState({contadorideas:num});
        }catch(e){
            console.log(e);
        }
        
        this.setState({ideas:arr[e.target.id]});
    }
    render(){
        return(
            <div className="ml-8">
                <div className="">
                    <div className="title">
                        Business Model Canvas
                    </div>
                    <div className="flex">
                    <div className="subtitle active mt-4" id="LV">
                        Lienzo de Valor
                        <div className="rectangle" id="rectLV"></div>
                    </div>
                    </div>
                </div>

                <div className='lineamenuBMC w-full'></div> 
                
              
                <div className="mt-4"  id="LVQuestions">
                <div className="bg-gray-100 rounded-xl pb-2">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[0].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=0;i<2;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}> LIENZO DE VALOR <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="flex  accordion">
                     <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0"  allowFullScreen></iframe></div>
                     <div className="text-sm text-gray-400 mt-6">El Business Model Canvas o Modelo Canvas es un modelo muy visual con el que podremos ordenar nuestras ideas a la hora de definir cuál será nuestro modelo de negocio. El Business Model Canvas, método ideado por Alex Osterwalder, es una herramienta que permite obtener una visión de todos los elementos de la actividad empresarial en un único lienzo canvas. Una metodología para definir nuevos modelos de negocio o ayudar a nuevas empresas a integrarse en modelos de negocio de éxito ya establecidos por otras compañías o crear negocios novedosos.</div>
                     </div>
                </div>
                <div className="flex justify-end text-sm mt-4"><button onClick={()=>{this.setState({limitado:true})}}><div className="text-gray-800 mr-2 mb-1">Ver limitado</div></button> <button onClick={()=>{this.setState({limitado:false})}}><div className="ml-3 mr-3 mb-1 text-gray-800">Ver todo</div></button></div>
                    
                    <div className="grid grid-cols-5">
                        <div className='flex justify-center row-span-2 radioLCB p-1' id="LValiados">
                            <input type="radio" name="LVGroup" id="LVr1" />
                            <label className="radioLCC px-4  py-4" htmlFor='LVr1' id="Aliados clave" onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenBMCaliados} className="w-8 h-8 mb-2" alt="img-user" id="Aliados clave" /> </div>
                                
                                Aliados <br/>Clave
                                
                                <br/><br/><div className="text-gray-300" id="Aliados clave"><br/><span className="IdeasNumero">{this.state.LV['Aliados clave'].length} ideas </span></div><br/>
                            <ul>
                                {this.state.limitado && this.state.LV['Aliados clave'].slice(0,5).map(idea=><li id="Aliados clave" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LV['Aliados clave'].map(idea=><li id="Aliados clave" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul></label>
                        </div>
                        <div className='flex justify-center radioLCB p-1' id="LVactividades">
                            <input type="radio" name="LVGroup" id="LVr2" />
                            <label className="radioLCC px-4  py-4" htmlFor='LVr2' id="Actividades clave" onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenBMCactividades} className="w-8 h-8 mb-2" alt="img-user" id="Actividades clave" /> </div>
                                
                                Actividades <br/>Clave
                                
                                <br/><br/><div className="text-gray-300" id="Aliados clave">{this.state.LV['Actividades clave'].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LV['Actividades clave'].slice(0,5).map(idea=><li id="Aliados clave" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LV['Actividades clave'].map(idea=><li id="Aliados clave" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                        <div className='flex justify-center row-span-2 radioLCB p-1' id="LVpropuesta">
                            <input type="radio" name="LVGroup" id="LVr3" />
                            <label className="radioLCC px-4  py-4" htmlFor='LVr3' id='Propuesta de valor' onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenBMCvalor} className="w-8 h-8 mb-2" alt="img-user" id='Propuesta de valor' /> </div>
                                
                                Propuesta<br/> de Valor 
                                
                                <br/><br/><div className="text-gray-300" id='Propuesta de valor'>{this.state.LV["Propuesta de valor"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LV["Propuesta de valor"].slice(0,5).map(idea=><li id='Propuesta de valor' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LV["Propuesta de valor"].map(idea=><li id='Propuesta de valor' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                        <div className='flex justify-center radioLCB p-1' id="LVrelacion">
                            <input type="radio" name="LVGroup" id="LVr4" />
                            <label className="radioLCC px-4  py-4" htmlFor='LVr4' id='Relación con clientes' onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenBMCrelacion} className="w-8 h-8 mb-2" alt="img-user" id='Relación con clientes' /> </div>
                                
                                Relación<br/>con Clientes 
                                
                                <br/><br/><div className="text-gray-300" id='Relación con clientes'>{this.state.LV['Relación con clientes'].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LV['Relación con clientes'].slice(0,5).map(idea=><li id='Relación con clientes' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LV['Relación con clientes'].map(idea=><li id='Relación con clientes' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                        <div className='flex justify-center row-span-2 radioLCB p-1' id="LVsegmento">
                            <input type="radio" name="LVGroup" id="LVr5" />
                            <label className="radioLCC px-4  py-4" htmlFor='LVr5' id='Segmento de mercado/clientes' onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenBMCsegmento} className="w-8 h-8 mb-2" alt="img-user" id='Segmento de mercado/clientes' /> </div>
                                
                                Segmento de<br/>  mercado / clientes
                                
                                <br/><br/><div className="text-gray-300" id='Segmento de mercado/clientes'> {this.state.LV["Segmento de mercado/clientes"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LV["Segmento de mercado/clientes"].slice(0,5).map(idea=><li id='Segmento de mercado/clientes' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LV["Segmento de mercado/clientes"].map(idea=><li id='Segmento de mercado/clientes' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                        <div className='flex justify-center radioLCB p-1' id="LVrecursos">
                            <input type="radio" name="LVGroup" id="LVr6" />
                            <label className="radioLCC px-4  py-4" htmlFor='LVr6' id='Recursos clave' onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenBMCrecursos} className="w-8 h-8 mb-2" alt="img-user" id='Recursos clave' /> </div>
                                
                                Recursos <br/>Clave 
                                
                                <br/><br/><div className="text-gray-300" id='Recursos clave'>{this.state.LV['Recursos clave'].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LV['Recursos clave'].slice(0,5).map(idea=><li id='Recursos clave' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LV['Recursos clave'].map(idea=><li id='Recursos clave' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                        <div className='flex justify-center radioLCB p-1' id="LVcanales">
                            <input type="radio" name="LVGroup" id="LVr7" />
                            <label className="radioLCC px-4  py-4" htmlFor='LVr7' id='Canales de comunicación y venta' onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenBMCcanales} className="w-8 h-8 mb-2" alt="img-user" id='Canales de comunicación y venta' /> </div>
                                
                                Canales de <br/>Comunicación<br/>y Venta 
                                
                                <br/><br/><div className="text-gray-300" id='Canales de comunicación y venta'>{this.state.LV['Canales de comunicación y venta'].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LV['Canales de comunicación y venta'].slice(0,5).map(idea=><li id='Canales de comunicación y venta' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LV['Canales de comunicación y venta'].map(idea=><li id='Canales de comunicación y venta' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                    </div>
                <div className="grid grid-cols-2">
                    <div className='flex justify-center radioLCB p-1' id="LVestructuras">
                        <input type="radio" name="LVGroup" id="LVr9" />
                        <label className="radioLCC px-4  py-4" htmlFor='LVr9' id='Estructura de costos' onClick={this.checar.bind(this)}>
                            
                        <div className="flex justify-center">
                            <img src={ImagenBMCcostos} className="w-8 h-8 mb-2" alt="img-user" id='Estructura de costos' /> </div>
                            
                            Estructura de costos
                            
                            <br/><br/><div className="text-gray-300" id='Estructura de costos'>{this.state.LV["Estructura de costos"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LV["Estructura de costos"].slice(0,5).map(idea=><li id='Estructura de costos' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LV["Estructura de costos"].map(idea=><li id='Estructura de costos' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                        </label>
                    </div>
                    <div className='flex justify-center radioLCB p-1' id="LVfuentes">
                        <input type="radio" name="LVGroup" id="LVr10" />
                        <label className="radioLCC px-4  py-4" htmlFor='LVr10' id='Fuentes de ingresos' onClick={this.checar.bind(this)}>
                            
                        <div className="flex justify-center">
                            <img src={ImagenBMCingresos} className="w-8 h-8 mb-2" alt="img-user" id='Fuentes de ingresos' /> </div>

                            Fuentes de Ingresos
                            
                            <br/><br/><div className="text-gray-300" id='Fuentes de ingresos'>{this.state.LV["Fuentes de ingresos"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LV["Fuentes de ingresos"].slice(0,5).map(idea=><li id='Fuentes de ingresos' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LV["Fuentes de ingresos"].map(idea=><li id='Fuentes de ingresos' className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                        </label>
                    </div>
                </div>
                </div>
                <div className='lineabajaBMC w-full'></div>
                {this.state.seleccion && 
                <div>
                    <div className='text-gray-800 text-lg'>{this.state.seleccion} </div><div className="text-gray-400 text-sm"> / {this.state.contadorideas} ideas</div>
                    <div className="flex mt-1 ml-2">
                    <input placeholder="Escribe una idea" className='w-full p-3 pl-2 border-2 rounded-lg mb-4 text-sm' id="inputidea" onKeyUp={this.agregaridea.bind(this)}/>
                        {this.state.idearepetida && <div className="text-red-500 ml-2">*Idea Repetida</div>}
                    </div>
                    <div>
                        <ul>
                            {this.state.ideas.map(idea=><div className="flex" key={idea}><button className="bg-gray-200 rounded-full p-1 mt-1 mr-2 text-xs"onClick={()=>{
                                let arr=this.state.ideas;
                                const index=arr.findIndex(ideas=>ideas===idea);
                                arr.splice(index,1);
                                this.setState({ideas:arr});
                                let num=arr.length;
                                this.setState({contadorideas:num});
                                document.getElementById('inputidea').value='';
                            }}>x</button>
                            <li className="text-sm mt-2" onClick={()=>{
                                let arr=this.state.ideas;
                                const index=arr.findIndex(ideas=>ideas===idea); 
                                document.getElementById('inputidea').value=arr[index];
                                this.setState({editar:true});
                                this.setState({editando:index});
                            }} >{idea}</li></div>)}
                        </ul>
                        
                    </div>
                    <div className="py-10"></div> 
                </div>}
            </div>
        );
    }
}
export default BusinessModelCanvas;
