import React from "react";

const EtapasConvocatoria = () => {
  return (
    <div>
      <div className="bg-gray-200 rounded-lg py-2 px-6">
        <div className="mb-8">
          <p className="text-lg font-bold">Bases de Conovocatorias</p>
        </div>
        <div className="space-y-6">
          <div className="flex space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
            <div className="">
              <p className="text-xs font-bold">Primer requisito de la convocatoria</p>
              <p className="text-xs font-normal">Algun texto que sirva como apoyo</p>
            </div>
          </div>
          <div className="flex space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
            <div className="">
              <p className="text-xs font-bold">Segundos requisitos de la convocatoria</p>
              <p className="text-xs font-normal">Algun texto que sirva como apoyo</p>
            </div>
          </div>
          <div className="flex space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
            <div className="">
              <p className="text-xs font-bold">Más requisitos de la convocatoria</p>
              <p className="text-xs font-normal">Algun texto que sirva como apoyo</p>
            </div>
          </div>
          <div className="flex space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
            <div className="">
              <p className="text-xs font-bold">Más requisitos de la convocatoria</p>
              <p className="text-xs font-normal">Algun texto que sirva como apoyo</p>
            </div>
          </div>
        </div>
        <div className="mt-10 mb-4">
          <button className="rounded-full shadow-md bg-white flex space-x-4 items-center px-4 py-2">
            <span className="text-sm font-semibold">Descargar</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );

}

export default EtapasConvocatoria;