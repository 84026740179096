import { Component } from "react";
import { ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'
import './styles/Contabilidad.css'

class Contabilidad extends Component{
    constructor(props){
        super(props);
        this.state={
           checkboxes:{//Guardar en base de datos
                'ASRL':false,
                'ASEPM':false,
                'OFE':false,
                'CTF':false,
                'DAI':false,
                'DMI':false,
                'CCP':false,
                'LRC':false,
           }
        }
    }
    checar(e){
        var check=this.state.checkboxes;
        check[e.target.id]=!(check[e.target.id]);
        this.setState({checkboxes:check});
    }
    render(){
        return(
            <div className="ml-8">
                <div className="">

                    <div className="col-span-2 title">
                        Contabilidad
                    </div>

                    <div className="flex">

                    <div className="subtitle mr-10 active mt-4 w-26" id="C">
                        Contabilidad
                        <div className="rectangle" id="rectC"></div>
                    </div>
                    
                    </div>
                    <div className='lineamenuPV w-full'></div>
                </div>
                <div className="mt-8"  id="CQuestions">

                <div className="bg-gray-100 rounded-xl">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[0].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=0;i<2;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>PROPÓSITO DE VIDA <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 mb-4 accordion">Un propósito de vida es la razón o razones por las que te levantas en la mañana, aquellas motivaciones intrínsecas que dan un sentido de dirección y significado a la propia existencia, y que con dicho propósito será con el que te enfocarás a crear tu nueva empresa, la cual se convertirá en tu parque de diversiones y disfrutarás cada momento trabajando en y por ella.</div>
                </div>
                <div className="grid grid-cols-2">
                    <div className="mt-4">
                        <input type="checkbox" id="ASRL" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["ASRL"]}/> <label htmlFor="ASRL">Alta de SAT del representante legal</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="ASEPM" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["ASEPM"]}/> <label htmlFor="ASEPM">Alta del SAT de la empresa o persona moral</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="OFE" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["OFE"]}/> <label htmlFor="OFE">Obtención de firmas electrónicas</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="CTF" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["CTF"]}/> <label htmlFor="CTF">Contratación de timbres fiscales</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="DAI" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["DAI"]}/> <label htmlFor="DAI">Declaraciones anuales de ISR</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="DMI" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["DMI"]}/> <label htmlFor="DMI">Declaraciones mensuales de IVA</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="CCP" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["CCP"]}/> <label htmlFor="CCP">Contratación de contador público</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="LRC" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["LRC"]}/> <label htmlFor="LRC">Llevar registros contables</label><br/>
                    </div>
                </div>                    
                </div>
                <div className="py-10"></div>
            </div>
        );
    }
}
export default Contabilidad;