import React from 'react';
import './styles/academycard.css';
import ProgressBar from './progressbar';
import { BeakerIcon } from '@heroicons/react/solid';

const Academycard = (props) => {
        
    return (
      <div className="grid grid-cols-5">
          <div className="ml-2 mb-2">
            <BeakerIcon className="w-6 figura" />
          </div>
          <div className="titulo col-span-3">
            Design Thinking
          </div>
          <div className="avance">
            avance 3/5
          </div>
          <div className="col-span-5">
            <ProgressBar  bgcolor="#fecd03" completed="70" />
          </div>
          
      </div>
    );
  };
  
  export default Academycard;