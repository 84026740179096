import React from 'react';
import imagen from '../../assets/curso.jpg';
import './styles/cursocard.css';
import ProgressBar from '../AcademiaC/progressbar';
import { ClockIcon } from '@heroicons/react/outline';
const Cursocard = (props) => {
        
    return (
      <div className="grid grid-cols-3 h-auto rounded-lg ">
          <div className="m-0">
            <img src={imagen} alt="img" className="h-24 rounded-l-lg" />
          </div>
          <div className="col-span-2">
            <div className="titulo mt-1">
                Cómo levantar capital de ángeles inversionistas.
            </div>
            <div className="duracion mt-2">
                Duración
                <ClockIcon className="w-4 inline ml-4"/> 8 min
            </div>
            <div className="empezar mt-2">
                Empezar ahora
            </div>
          </div>
          <div className="col-span-3 ">
            <ProgressBar bgcolor="#fecd03" completed="10" />
          </div>
      </div>
    );
  };
  
  export default Cursocard;