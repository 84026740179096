import { React, Component } from 'react'
import './styles/Login.css'
import logo from '../assets/Login/logo-startcups.svg'
import login1 from '../assets/Login/btn-login-startcups.png'
import login2 from '../assets/Login/btn-apple-login.svg'
import login3 from '../assets/Login/btn-google-login.svg'
import login4 from '../assets/Login/btn-facebook-login.svg'
import { Navigate } from 'react-router-dom'
import { RefreshIcon } from '@heroicons/react/solid'

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            loading: false,
            login: false,
            error: false,
        }
    }
    login() {
        this.setState({ loading: true })
        this.setState({ error: false })
        const requestOptions = {
            method: 'POST',

            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password,
            }),
        }
        fetch('https://startcups-staging.herokuapp.com/auth/login', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.token) {
                    window.sessionStorage.setItem('auth-token', data.token)
                    this.setState({ login: true })
                    document.location.href="/";
                } else if (data.error) {
                    this.setState({ error: true })
                    this.setState({ loading: false })
                }
            })
    }
    render() {
        return (
            <div>
                <div className="login-header">
                    <div className="login-header-div">
                        <div className="login-header-logo">
                            <div className="logo-startcups">
                                <img src={logo} alt="logo" />
                            </div>
                        </div>
                        <div id="menu-movil" className="menu-mobile">
                            <i className="fas fa-bars"></i>
                        </div>
                        <div id="menu-movil-div" className="login-header-element-div">
                            <div id="menu-movil-close" className="menu-movil-close">
                                <i className="fas fa-times"></i>
                            </div>
                            <div className="login-header-element">Somos</div>
                            <div className="login-header-element">Incubator</div>
                            <div className="login-header-element">Marketplace</div>
                            <div className="login-header-element">Academy</div>
                            <div className="login-header-element">Comunidad</div>
                        </div>
                    </div>
                </div>
                <div className="login-section">
                    <div className="login-1">
                        <div className="login-titulo-upper">
                            Incubadora y Aceleradora de Empresas
                        </div>
                        <div className="login-titulo">
                            <strong>I N N O V A C I Ó N</strong>
                            <br />Y EMPRENDIMIENTO CORPORATIVO
                        </div>
                        <div className="login-form-group">
                            <label>Email</label>
                            <input
                                className="login-input"
                                type="email"
                                onChange={(e) => this.setState({ email: e.target.value })}
                            />
                        </div>
                        <div className="login-form-group mt-4">
                            <label>Password</label>
                            <input
                                className="login-input "
                                type="text"
                                onChange={(e) => {
                                    this.setState({ password: e.target.value })
                                }}
                            />
                            <i className="fas fa-eye"></i>
                        </div>
                        {this.state.loading && (
                            <div className="text-green-500 flex">
                                <RefreshIcon className="w-4 animate-spin" />
                                Conectando...
                            </div>
                        )}
                        {this.state.error && (
                            <div className="login-description-alert">Error de credenciales*</div>
                        )}
                        <div className="login-btn">
                            <div onClick={this.login.bind(this)}>INICIAR SESIÓN</div>
                        </div>
                        {this.state.login===true && <Navigate to="/" />}      
                        <div className="login-password">
                            ¿Olvidaste tu <span>contraseña</span>?
                        </div>
                        <div className="login-social-text">Puedes iniciar sesión con</div>
                        <div className="login-social-div">
                            <div className="login-icon">
                                <img src={login1} alt="loginstartcups" />
                            </div>
                            <div className="login-icon">
                                <img src={login2} alt="loginapple" />
                            </div>
                            <div className="login-icon">
                                <img src={login3} alt="logingoogle" />
                            </div>
                            <div className="login-icon">
                                <img src={login4} alt="loginfacebook" />
                            </div>
                        </div>
                    </div>
                    <div className="login-2 desktop">
                        <div className="login-img">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/rLpXuMDQztQ"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className="login-copyright-div">
                    <div className="login-registrate-div">
                        <div className="login-registrate">
                            Si aún no tienes una cuenta, crea una <span>Regístrate ahora</span>
                        </div>
                    </div>
                    <div className="login-copyright">
                        <div>Términos y Condiciones</div>
                        <div>Avisos de Privacidad</div>
                        <div className="copyright">
                            Startcups ® | Todos los derechos reservados 2020.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Login
