import { Component } from "react";
import './styles/LeanStartup.css';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'
import ImagenLCcanales from "../../../assets/LCcanales.png"
import ImagenLCcostos from "../../../assets/LCcostos.png"
import ImagenLCingresos from "../../../assets/LCingresos.png"
import ImagenLCmetricas from "../../../assets/LCmetricas.png"
import ImagenLCproblema from "../../../assets/LCproblema.png"
import ImagenLCsegmento from "../../../assets/LCsegmento.png"
import ImagenLCsolucion from "../../../assets/LCsolucion.png"
import ImagenLCvalor from "../../../assets/LCvalor.png"
import ImagenLCventaja from "../../../assets/LCventaja.png"
import ImagenMVPcronograma from "../../../assets/MVPcronograma.png"
import ImagenMVPfuncionalidades from "../../../assets/MVPfuncionalidades.png"
import ImagenMVPmetricas from "../../../assets/MVPmetricas.png"
import ImagenMVPresultados from "../../../assets/MVPresultados.png"
import ImagenMVPsegmento from "../../../assets/MVPsegmento.png"
import ImagenMVPviaje from "../../../assets/MVPviaje.png"
import ImagenMVPvision from "../../../assets/MVPvision.png"

class LeanStartup extends Component{
    constructor(props){
        super(props);
        this.state={
            LC:{//Guardar en base de datos //array de strings
                'Problema':[],
                'Solución':[],
                'Propuesta de valor':[],
                'Ventaja competitiva':[],
                'Segmento de mercado/clientes':[],
                'Métricas clave':[],
                'Canales de comunicación':[],
                'Estructura de costos':[],
                'Fuentes de ingresos':[],
            },
            CM:{//Guardar en base de datos //array de strings
                'Segmentos de personas':[],
                'Propuesta o visión del mvp':[],
                'Resultados esperados':[],
                'Viajes de usuario':[],
                'Funcionalidades':[],
                'Métricas para la validación de hipótesis de negocio':[],
                'Costo y cronograma':[]
            },
            seleccion:'',
            seleccion2:'LC',
            contadorideas:0,
            ideas:[],
            idearepetida:false,
            editando:-1,
            editar:false,
            limitado:true
        }
    }
    agregaridea(e){
        if (e.code === 'Enter' && e.target.value!=="") {
            if(this.state.seleccion2==='LC'){
                if(this.state.LC[this.state.seleccion].findIndex(ideas=>ideas===e.target.value)){
                    if(!this.state.editar){
                        let arr=this.state.LC;
                        arr[this.state.seleccion].push(e.target.value);
                        this.setState({contadorideas:arr[this.state.seleccion].length})
                        this.setState({ideas:arr[this.state.seleccion]})
                        this.setState({LC:arr});
                        document.getElementById('inputidea').value='';
                        this.setState({idearepetida:false});
                    }else{
                        let arr=this.state.LC;
                        arr[this.state.seleccion].splice(this.state.editando,1,e.target.value);
                        this.setState({contadorideas:arr[this.state.seleccion].length})
                        this.setState({ideas:arr[this.state.seleccion]})
                        this.setState({LC:arr});
                        document.getElementById('inputidea').value='';
                        this.setState({idearepetida:false});
                        this.setState({editar:false});
                        this.setState({editando:-1});
                    }
                    
                }else{
                    this.setState({idearepetida:true});
                }
            }else{
                if(this.state.CM[this.state.seleccion].findIndex(ideas=>ideas===e.target.value)){
                    if(!this.state.editar){
                        let arr=this.state.CM;
                        arr[this.state.seleccion].push(e.target.value);
                        this.setState({contadorideas:arr[this.state.seleccion].length})
                        this.setState({ideas:arr[this.state.seleccion]})
                        this.setState({CM:arr});
                        document.getElementById('inputidea').value='';
                        this.setState({idearepetida:false});
                    }else{
                        let arr=this.state.CM;
                        arr[this.state.seleccion].splice(this.state.editando,1,e.target.value);
                        this.setState({contadorideas:arr[this.state.seleccion].length})
                        this.setState({ideas:arr[this.state.seleccion]})
                        this.setState({CM:arr});
                        document.getElementById('inputidea').value='';
                        this.setState({idearepetida:false});
                        this.setState({editar:false});
                        this.setState({editando:-1});
                    }
                    
                }else{
                    this.setState({idearepetida:true});
                }
            }
            
            
        }
    }
    checar(e){
        this.setState({seleccion:e.target.id});
        let arr=this.state.LC;
        try{
            let num=arr[e.target.id].length;
            this.setState({contadorideas:num});
        }catch(e){
            console.log(e);
        }
        
        this.setState({ideas:arr[e.target.id]});
    }
    checar2(e){
        this.setState({seleccion:e.target.id});
        let arr=this.state.CM;
        try{
            let num=arr[e.target.id].length;
            this.setState({contadorideas:num});
        }catch(e){
            console.log(e);
        }
        this.setState({ideas:arr[e.target.id]});
    }
    render(){
        return(
            <div className="ml-8">
                <div className="">
                    <div className="title">
                        Lean Startup
                    </div>
                    <div className="flex">  
                    <div className="subtitle mr-10 active mt-4 w-26" id="LC" onClick={()=>{
                        document.getElementById('rectCM').classList.add('hiddenNone');
                        document.getElementById('CM').classList.remove('active');
                        document.getElementById('LC').classList.add('active');
                        document.getElementById('rectLC').classList.remove('hiddenNone');
                        document.getElementById('CMQuestions').classList.add('hiddenNone');
                        document.getElementById('LCQuestions').classList.remove('hiddenNone');
                        this.setState({seleccion2:'LC'});
                        this.setState({seleccion:''});
                    }}>
                        Lean Canvas
                        <div className="rectangle" id="rectLC"></div>
                    </div>
                    <div className="subtitle mr-10 mt-4 w-26" id="CM" onClick={()=>{
                        document.getElementById('rectLC').classList.add('hiddenNone');
                        document.getElementById('LC').classList.remove('active');
                        document.getElementById('CM').classList.add('active');
                        document.getElementById('rectCM').classList.remove('hiddenNone');
                        document.getElementById('CMQuestions').classList.remove('hiddenNone');
                        document.getElementById('LCQuestions').classList.add('hiddenNone');
                        this.setState({seleccion2:'CM'});
                        this.setState({seleccion:''});
                    }}>
                        Canvas MVP
                        <div className="rectangle hiddenNone" id="rectCM"></div>
                    </div>
                    </div>
                </div>

                <div className='lineamenuLS w-full'></div>     

                <div className="mt-4"  id="LCQuestions">
                <div className="bg-gray-100 rounded-xl pb-2">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[0].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=0;i<2;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>LEAN CANVAS<ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="flex  accordion">
                     <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0"  allowFullScreen></iframe></div>
                     <div className="text-sm text-gray-400 mt-6">El Lean Canvas es una herramienta de visualización de modelos de negocio que aúna elementos del Business Model Canvas, por un lado, y del método Lean Startup, por el otro. En este mapa no trabajaremos sobre la empresa, sino sobre el producto o servicio a desarrollar.</div>
                     </div>
                </div>
                    <div className="flex justify-end text-sm mt-4"><button onClick={()=>{this.setState({limitado:true})}}><div className="text-gray-800 mr-2 mb-1">Ver limitado</div></button> <button onClick={()=>{this.setState({limitado:false})}}><div className="ml-3 mr-3 mb-1 text-gray-800">Ver todo</div></button></div>
                    <div className="grid grid-cols-5">
                        
                        <div className='flex justify-center row-span-2 radioLCanvas p-1' id="LCproblema">
                            <input type="radio" name="LCGroup" id="LCr1" />
                            <label className="radioLCC px-4  py-4" htmlFor='LCr1' id="Problema" onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenLCproblema} className="w-8 h-8 mb-2" alt="img-user" id="Problema" /> </div>

                                2. Problema
                                
                                <br/><br/><div className="text-gray-300" id="Problema">{this.state.LC.Problema.length} ideas </div><br/>

                            <ul>
                                {this.state.limitado && this.state.LC['Problema'].slice(0,5).map(idea=><li id="Problema" className="text-xs text-gray-500 mt-1" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LC['Problema'].map(idea=><li id="Problema" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul></label>
                        </div>


                        <div className='flex justify-center radioLCanvas p-1' id="LCsolucion">
                            <input type="radio" name="LCGroup" id="LCr2" />
                            <label className="radioLCC px-4  py-4" htmlFor='LCr2' id="Solución" onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenLCsolucion} className="w-8 h-8 mb-2" alt="img-user" id="Solución" /> </div>

                                4. Solución
                                
                                <br/><br/><div className="text-gray-300" id="Solución">{this.state.LC.Solución.length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LC['Solución'].slice(0,5).map(idea=><li id="Solución" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LC['Solución'].map(idea=><li id="Solución" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                        
                        <div className='flex justify-center row-span-2 radioLCanvas p-1' id="LCpropuesta">
                            <input type="radio" name="LCGroup" id="LCr3" />
                            <label className="radioLCC px-4  py-4" htmlFor='LCr3' id='Propuesta de valor' onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenLCvalor} className="w-8 h-8 mb-2" alt="img-user" id="Propuesta de valor" /> </div>
                                
                                3. Propuesta<br/> de Valor 
                                
                                <br/><br/><div className="text-gray-300" id="Propuesta de valor">{this.state.LC["Propuesta de valor"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LC["Propuesta de valor"].slice(0,5).map(idea=><li id="Propuesta de valor" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LC["Propuesta de valor"].map(idea=><li id="Propuesta de valor" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>

                        <div className='flex justify-center radioLCanvas p-1' id="LCventaja">
                            <input type="radio" name="LCGroup" id="LCr4" />
                            <label className="radioLCC px-4  py-4" htmlFor='LCr4' id='Ventaja competitiva' onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenLCventaja} className="w-8 h-8 mb-2" alt="img-user" id="Ventaja competitiva" /> </div>
                                
                                5. Ventaja<br/>  Competitiva 
                                
                                <br/><br/><div className="text-gray-300" id="Ventaja competitiva">{this.state.LC["Ventaja competitiva"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LC["Ventaja competitiva"].slice(0,5).map(idea=><li id="Ventaja competitiva" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LC["Ventaja competitiva"].map(idea=><li id="Ventaja competitiva" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                        <div className='flex justify-center row-span-2 radioLCB p-1' id="LCsegmento">
                            <input type="radio" name="LCGroup" id="LCr5" />
                            <label className="radioLCC px-4  py-4" htmlFor='LCr5' id='Segmento de mercado/clientes' onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenLCsegmento} className="w-8 h-8 mb-2" alt="img-user" id="Segmento de mercado/clientes" /> </div>
                                
                                1. Segmento de<br/>  mercado/<br/> clientes
                                
                                <br/><br/><div className="text-gray-300" id="Segmento de mercado/clientes">{this.state.LC["Segmento de mercado/clientes"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LC["Segmento de mercado/clientes"].slice(0,5).map(idea=><li id="Segmento de mercado/clientes" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LC["Segmento de mercado/clientes"].map(idea=><li id="Segmento de mercado/clientes" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                        <div className='flex justify-center radioLCB p-1' id="LCmetricas">
                            <input type="radio" name="LCGroup" id="LCr6" />
                            <label className="radioLCC px-4  py-4" htmlFor='LCr6' id='Métricas clave' onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenLCmetricas} className="w-8 h-8 mb-2" alt="img-user" id="Métricas clave" /> </div>
                                
                                7. Métricas Clave 
                                
                                <br/><br/><div className="text-gray-300" id="Métricas clave">{this.state.LC["Métricas clave"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LC["Métricas clave"].slice(0,5).map(idea=><li id="Métricas clave" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LC["Métricas clave"].map(idea=><li id="Métricas clave" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                        <div className='flex justify-center radioLCB p-1' id="LCcanales">
                            <input type="radio" name="LCGroup" id="LCr7" />
                            <label className="radioLCC px-4  py-4" htmlFor='LCr7' id='Canales de comunicación' onClick={this.checar.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenLCcanales} className="w-8 h-8 mb-2" alt="img-user" id="Canales de comunicación" /> </div>
                                
                                6. Canales de <br/>Comunicación 
                                
                                <br/><br/><div className="text-gray-300" id="Canales de comunicación">{this.state.LC["Canales de comunicación"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LC["Canales de comunicación"].slice(0,5).map(idea=><li id="Canales de comunicación" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LC["Canales de comunicación"].map(idea=><li id="Canales de comunicación" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                            </label>
                        </div>
                    </div>

                <div className="grid grid-cols-2">
                    <div className='flex justify-center radioLCB p-1' id="LCestructuras">
                        <input type="radio" name="LCGroup" id="LCr9" />
                        <label className="radioLCC px-4  py-4" htmlFor='LCr9' id='Estructura de costos' onClick={this.checar.bind(this)}>
                            
                        <div className="flex justify-center">
                            <img src={ImagenLCcostos} className="w-8 h-8 mb-2" alt="img-user" id="Estructura de costos" /> </div>
                            
                            9. Estructura de costos
                            
                            <br/><br/><div className="text-gray-300" id="Estructura de costos">{this.state.LC["Estructura de costos"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LC["Estructura de costos"].slice(0,5).map(idea=><li id="Estructura de costos" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LC["Estructura de costos"].map(idea=><li id="Estructura de costos" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                        </label>
                    </div>
                    <div className='flex justify-center radioLCB p-1' id="LCfuentes">
                        <input type="radio" name="LCGroup" id="LCr10" />
                        <label className="radioLCC px-4  py-4" htmlFor='LCr10' id='Fuentes de ingresos' onClick={this.checar.bind(this)}>
                            
                        <div className="flex justify-center">
                            <img src={ImagenLCingresos} className="w-8 h-8 mb-2" alt="img-user" id="Fuentes de ingresos" /> </div>

                            8. Fuentes de Ingresos
                            
                            <br/><br/><div className="text-gray-300" id="Fuentes de ingresos">{this.state.LC["Fuentes de ingresos"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.LC["Fuentes de ingresos"].slice(0,5).map(idea=><li id="Fuentes de ingresos" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.LC["Fuentes de ingresos"].map(idea=><li id="Fuentes de ingresos" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul>
                        </label>
                    </div>                
                    
                </div>
                </div>
                
                <div className="mt-8 hiddenNone"  id="CMQuestions">
                <div className="bg-gray-100 rounded-xl pb-2">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[1].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=2;i<prueba.length;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}> CANVAS MVP <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="flex  accordion">
                     <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0"  allowFullScreen></iframe></div>
                     <div className="text-sm text-gray-400 mt-6">Canvas MVP es una herramienta para validar ideas de productos. Es un marco visual que ayuda a los emprendedores para alinear y ajustar la estrategia del MVP (Minimum Viable Product). El mapa tiene elementos que describen la visión del Producto Mínimo Viable (MVP), las hipótesis de su negocio con sus respectivas métricas, las personas y sus journeys, las funcionalidades, el costo y el calendario para su creación.</div>
                     </div>
                </div>
                    <div className="flex justify-end text-sm mt-4"><button onClick={()=>{this.setState({limitado:true})}}><div className="text-gray-800 mr-2 mb-1">Ver limitado</div></button> <button onClick={()=>{this.setState({limitado:false})}}><div className="ml-3 mr-3 mb-1 text-gray-800">Ver todo</div></button></div>  
                    <div className="grid grid-cols-3">

                        <div className='flex justify-center row-span-2 radioLCB p-1' id="CMsegmentos">
                            <input type="radio" name="CMGroup" id="CMr1" />
                            <label className="radioLCC px-4  py-4" htmlFor='CMr1' id="Segmentos de personas" onClick={this.checar2.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenMVPsegmento} className="w-8 h-8 mb-2" alt="img-user" id="Segmentos de personas" /> </div>
                                
                                1. Segmentos de personas
                                
                                <br/><br/><div className="text-gray-300" id="Segmentos de personas">{this.state.CM["Segmentos de personas"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.CM['Segmentos de personas'].slice(0,5).map(idea=><li id="Segmentos de personas" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.CM['Segmentos de personas'].map(idea=><li id="Segmentos de personas" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul></label>
                        </div>

                        <div className='flex justify-center radioLCB p-1' id="CMpropuesta">
                            <input type="radio" name="CMGroup" id="CMr2" />
                            <label className="radioLCC px-4  py-4" htmlFor='CMr2' id="Propuesta o visión del mvp" onClick={this.checar2.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenMVPvision} className="w-8 h-8 mb-2" alt="img-user" id="Propuesta o visión del mvp" /> </div>
                                
                                2. Propuesta o Visión del MVP
                                
                                <br/><br/><div className="text-gray-300" id="Propuesta o visión del mvp">{this.state.CM["Propuesta o visión del mvp"].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.CM['Propuesta o visión del mvp'].slice(0,5).map(idea=><li id="Propuesta o visión del mvp" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.CM['Propuesta o visión del mvp'].map(idea=><li id="Propuesta o visión del mvp" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul></label>
                        </div>

                        <div className='flex justify-center row-span-2 radioLCB p-1' id="CMresultados">
                            <input type="radio" name="CMGroup" id="CMr3" />
                            <label className="radioLCC px-4  py-4" htmlFor='CMr3' id="Resultados esperados" onClick={this.checar2.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenMVPresultados} className="w-8 h-8 mb-2" alt="img-user" id="Resultados esperados" /> </div>
                                
                                3. Resultados Esperados
                                
                                <br/><br/><div className="text-gray-300" id="Resultados esperados">{this.state.CM['Resultados esperados'].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.CM['Resultados esperados'].slice(0,5).map(idea=><li id="Resultados esperados" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.CM['Resultados esperados'].map(idea=><li id="Resultados esperados" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul></label>
                        </div>
                        
                        <div className='flex justify-center row-span-2 radioLCB p-1' id="CMfuncionalidades">
                            <input type="radio" name="CMGroup" id="CMr4" />
                            <label className="radioLCC px-4  py-4" htmlFor='CMr4' id="Funcionalidades" onClick={this.checar2.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenMVPfuncionalidades} className="w-8 h-8 mb-2" alt="img-user" id="Funcionalidades" /> </div>
                                
                                4. Funcionalidades
                                
                                <br/><br/><div className="text-gray-300" id="Funcionalidades">{this.state.CM['Funcionalidades'].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.CM['Funcionalidades'].slice(0,5).map(idea=><li id="Funcionalidades" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.CM['Funcionalidades'].map(idea=><li id="Funcionalidades" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul></label>
                        </div>

                        <div className='flex justify-center row-span-2 radioLCB p-1' id="CMviajes">
                            <input type="radio" name="CMGroup" id="CMr5" />
                            <label className="radioLCC px-4  py-4" htmlFor='CMr5' id="Viajes de usuario" onClick={this.checar2.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenMVPviaje} className="w-8 h-8 mb-2" alt="img-user" id="Viajes de usuario" /> </div>
                                
                                5. Viajes de usuario
                                
                                <br/><br/><div className="text-gray-300" id="Viajes de usuario">{this.state.CM['Viajes de usuario'].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.CM['Viajes de usuario'].slice(0,5).map(idea=><li id="Viajes de usuario" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.CM['Viajes de usuario'].map(idea=><li id="Viajes de usuario" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul></label>
                        </div>

                        <div className='flex justify-center row-span-2 radioLCB p-1' id="CMmetricas">
                            <input type="radio" name="CMGroup" id="CMr6" />
                            <label className="radioLCC px-4  py-4" htmlFor='CMr6' id="Métricas para la validación de hipótesis de negocio" onClick={this.checar2.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenMVPmetricas} className="w-8 h-8 mb-2" alt="img-user" id="Métricas para la validación de hipótesis de negocio" /> </div>
                                
                                7. Métricas para la validación <br/> de hipótesis de negocio
                                
                                <br/><br/><div className="text-gray-300" id="Métricas para la validación de hipótesis de negocio">{this.state.CM['Métricas para la validación de hipótesis de negocio'].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.CM['Métricas para la validación de hipótesis de negocio'].slice(0,5).map(idea=><li id="Métricas para la validación de hipótesis de negocio" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.CM['Métricas para la validación de hipótesis de negocio'].map(idea=><li id="Métricas para la validación de hipótesis de negocio" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul></label>
                        </div>

                        <div className='flex justify-center radioLCB p-1' id="CMcosto">
                            <input type="radio" name="CMGroup" id="CMr7" />
                            <label className="radioLCC px-4  py-4" htmlFor='CMr7' id="Costo y cronograma" onClick={this.checar2.bind(this)}>
                                
                            <div className="flex justify-center">
                            <img src={ImagenMVPcronograma} className="w-8 h-8 mb-2" alt="img-user" id="Costo y cronograma" /> </div>
                                
                                6. Costo y cronograma
                                
                                <br/><br/><div className="text-gray-300" id="Costo y cronograma">{this.state.CM['Costo y cronograma'].length} ideas </div><br/>
                            <ul>
                                {this.state.limitado && this.state.CM['Costo y cronograma'].slice(0,5).map(idea=><li id="Costo y cronograma" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                                {!this.state.limitado && this.state.CM['Costo y cronograma'].map(idea=><li id="Costo y cronograma" className="text-xs text-gray-500" key={idea}>{idea}</li>)}
                            </ul></label>
                        </div>
                    </div>
                </div>
                <div className='lineabajaLS w-full'></div>
                {this.state.seleccion && 
                <div>
                    <div className='text-gray-800 text-lg'>{this.state.seleccion} </div><div className="text-gray-400 text-sm"> /  {this.state.contadorideas} ideas</div>
                    <div className="flex mt-1 ml-2">
                    <input placeholder="Escribe aquí una idea" id="inputidea" className='w-full p-3 pl-2 border-2 rounded-lg mb-4 text-sm' onKeyUp={this.agregaridea.bind(this)}/>
                        {this.state.idearepetida && <div className="text-red-500 ml-2">*Idea Repetida</div>}
                    </div>
                    <div>
                        <ul>
                            {this.state.ideas.map(idea=><div className="flex" key={idea}><button className="bg-gray-200 rounded-full p-1 mt-1 mr-2 text-xs"onClick={()=>{
                                let arr=this.state.ideas;
                                const index=arr.findIndex(ideas=>ideas===idea);
                                arr.splice(index,1);
                                this.setState({ideas:arr});
                                let num=arr.length;
                                this.setState({contadorideas:num});
                                document.getElementById('inputidea').value='';
                            }}>x</button>
                            <li className="text-sm mt-2" onClick={()=>{
                                let arr=this.state.ideas;
                                const index=arr.findIndex(ideas=>ideas===idea);
                                document.getElementById('inputidea').value=arr[index];
                                this.setState({editar:true});
                                this.setState({editando:index});
                            }} >{idea}</li></div>)}
                        </ul>
                        
                    </div>
                    <div className="py-10"></div> 
                </div>}
            </div>
        );
    }
}
export default LeanStartup;
