import { Component } from "react";
import './styles/Propositodevidastyle.css'
import {CheckCircleIcon, ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'

class Propositodevida extends Component{
    constructor(props){
        super(props);
        this.state={
            PV:{
                p1:0,
                p2:0,
                p3:0,
                p4:0
            },
            EP:{
                p1:0,
                p2:0,
                p3:0,
                p4:0
            },
            PVrespuestas:{//Guardar a base de datos
                p1:'',
                p2:'',
                p3:'',
                p4:''
            },
            EPrespuestas:{//Guardar a base de datos
                p1:'',
                p2:'',
                p3:'',
                p4:''
            }
        }
    }
    render(){
        return(
            <div className="ml-8">
                <div className="">

                    <div className="col-span-2 title">

                        Propósito Emprendedor
                    </div>

                    <div className="flex">

                    <div className="subtitle mr-10 active mt-4 w-26" id="PV" onClick={()=>{
                        document.getElementById('rectEP').classList.add('hiddenNone');
                        document.getElementById('EP').classList.remove('active');
                        document.getElementById('PV').classList.add('active');
                        document.getElementById('rectPV').classList.remove('hiddenNone');
                        document.getElementById('EPQuestions').classList.add('hiddenNone');
                        document.getElementById('PVQuestions').classList.remove('hiddenNone');
                    }}>
                        Propósito de vida
                        <div className="rectangle" id="rectPV"></div>
                    </div>

                    <div className="subtitle ml-10 mt-4 w-26"  id="EP" onClick={()=>{
                        document.getElementById('rectPV').classList.add('hiddenNone');
                        document.getElementById('PV').classList.remove('active');
                        document.getElementById('EP').classList.add('active');
                        document.getElementById('rectEP').classList.remove('hiddenNone');
                        document.getElementById('EPQuestions').classList.remove('hiddenNone');
                        document.getElementById('PVQuestions').classList.add('hiddenNone');
                    }}>
                        Empresa con propósito
                        <div className="rectangle hiddenNone" id="rectEP"></div>
                    </div>
                    
                    </div>
                   
                    <div className='lineamenuPV '></div> 

                </div>
                <div className="mt-4"  id="PVQuestions">
                <div className="bg-gray-100 rounded-xl pb-2">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[0].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=0;i<2;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}> PROPÓSITO DE VIDA <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="flex  accordion">
                     <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0"  allowFullScreen></iframe></div>
                     <div className="text-sm text-gray-400 mt-6">Un propósito de vida es la razón o razones por las que te levantas en la mañana, aquellas motivaciones intrínsecas que dan un sentido de dirección y significado a la propia existencia, y que con dicho propósito será con el que te enfocarás a crear tu nueva empresa, la cual se convertirá en tu parque de diversiones y disfrutarás cada momento trabajando en y por ella.</div>
                     </div>
                </div>

                    <div className="mt-4">
                        <label>1. ¿Cuáles son mis pasiones? <div className="tooltip">? <span className="tooltiptext">hola alex fbhdjsfihadsjkhfkjakfadskfldsahfklj sjfdklsajfkljsdakljfkldasjfd</span></div></label><br/>
                        <div className="flex mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.PVrespuestas.p1} placeholder="Describe que es lo que más te apasiona hacer en la vida" className="flex-grow p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('PVp1').classList.add('complete');
                                }else{
                                    document.getElementById('PVp1').classList.remove('complete');
                                }
                                var p1=this.state.PV
                                p1.p1=e.target.value.length;
                                var PVr=this.state.PVrespuestas
                                PVr.p1=e.target.value;
                                this.setState({PVrespuestas:PVr})
                                this.setState({PV:p1})
                                }}/>
                            <div><CheckCircleIcon className="w-5 flex-none iconPV1" id="PVp1" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.PV.p1}/700
                        </div>
                    </div>
                    
                    <div className="mt-4">
                        <label>2. ¿Cuáles son mis talentos?</label><br/>
                        <div className="flex mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.PVrespuestas.p2} placeholder="Describe que cosas te consideras bueno haciendo" className="flex-grow p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('PVp2').classList.add('complete');
                                }else{
                                    document.getElementById('PVp2').classList.remove('complete');
                                }
                                var p2=this.state.PV
                                p2.p2=e.target.value.length;
                                var PVr=this.state.PVrespuestas
                                PVr.p2=e.target.value;
                                this.setState({PVrespuestas:PVr})
                                this.setState({PV:p2})
                                }}/>
                            <div><CheckCircleIcon className="w-5 flex-none iconPV1" id="PVp2" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.PV.p2}/700
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>3. ¿Considero que me podrían pagar por hacer eso que me apasiona?</label><br/>
                        <div className="flex mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.PVrespuestas.p3} placeholder="Describe como te pagarían por hacer eso que te apasiona" className="flex-grow p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('PVp3').classList.add('complete');
                                }else{
                                    document.getElementById('PVp3').classList.remove('complete');
                                }
                                var p3=this.state.PV
                                p3.p3=e.target.value.length;
                                var PVr=this.state.PVrespuestas
                                PVr.p3=e.target.value;
                                this.setState({PVrespuestas:PVr})
                                this.setState({PV:p3})
                                }}/>
                            <div><CheckCircleIcon className="w-5 flex-none iconPV1" id="PVp3" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.PV.p3}/700
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>4. ¿El mundo realmente ocupa este trabajo que yo amo hacer?</label><br/>
                        <div className="flex mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.PVrespuestas.p4} placeholder="Describe si puedes resolver un problema importante" className="flex-grow p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('PVp4').classList.add('complete');
                                }else{
                                    document.getElementById('PVp4').classList.remove('complete');
                                }
                                var p4=this.state.PV
                                p4.p4=e.target.value.length;
                                var PVr=this.state.PVrespuestas
                                PVr.p4=e.target.value;
                                this.setState({PVrespuestas:PVr})
                                this.setState({PV:p4})
                                }}/>
                            <div><CheckCircleIcon className="w-5 flex-none iconPV1" id="PVp4" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.PV.p4}/700
                        </div>
                    </div>
                            
                    
                </div>
                <div className="mt-8 hiddenNone"  id="EPQuestions">
                
                <div className="bg-gray-100 rounded-xl pb-2">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[1].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=2;i<prueba.length;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}> EMPRESA CON PROPÓSITO <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="flex  accordion">
                     <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0"  allowFullScreen></iframe></div>
                     <div className="text-sm text-gray-400 mt-6">El propósito de una empresa va más allá de alcanzar un objetivo económico concreto, sino que debe dotar de sentido a lo que hace una empresa, de algún modo justifica su existencia y define un modo de comportarse en su entorno. El propósito debe tener un impacto efectivo en la sociedad y no solo maximizar el valor de la empresa para el accionista. De este modo, el propósito inspira la estrategia de la empresa, sus planes de negocio y sus objetivos a largo plazo.</div>
                     </div>
                </div>
                

                    <div className="mt-4">
                        <label>1. ¿Qué problema he detectado en el mercado y que yo lo pueda resolver?</label><br/>
                        <div className="flex mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.EPrespuestas.p1} placeholder="Describe el problema que piensas resolver" className="flex-grow p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('EPp1').classList.add('complete');
                                }else{
                                    document.getElementById('EPp1').classList.remove('complete');
                                }
                                var p1=this.state.EP
                                p1.p1=e.target.value.length;
                                var EPr=this.state.EPrespuestas
                                EPr.p1=e.target.value;
                                this.setState({EPrespuestas:EPr})
                                this.setState({EP:p1})
                                }}/>
                            <div><CheckCircleIcon className="w-5 flex-none iconPV1" id="EPp1" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.EP.p1}/700
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>2. Describe el nicho de mercado o a las personas que tienen este problema <br/>(Sexo, edad, nivel socioeconómico, lugar geográfico, estilo de vida, etc.)</label><br/>
                        <div className="flex mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.EPrespuestas.p2} placeholder="Describe correctamente a tus posibles clientes" className="flex-grow p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('EPp2').classList.add('complete');
                                }else{
                                    document.getElementById('EPp2').classList.remove('complete');
                                }
                                var p2=this.state.EP
                                p2.p2=e.target.value.length;
                                var EPr=this.state.EPrespuestas
                                EPr.p2=e.target.value;
                                this.setState({EPrespuestas:EPr})
                                this.setState({EP:p2})
                                }}/>
                            <div><CheckCircleIcon className="w-5 flex-none iconPV1" id="EPp2" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.EP.p2}/700
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>3. ¿Alguna otra empresa resuelve este problema en la actualidad?,<br/> ¿Crees que tu puedes hacerlo mejor?</label><br/>
                        <div className="flex mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.EPrespuestas.p3} placeholder="Describe a tu posible competencia directa e indirecta" className="flex-grow p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('EPp3').classList.add('complete');
                                }else{
                                    document.getElementById('EPp3').classList.remove('complete');
                                }
                                var p3=this.state.EP
                                p3.p3=e.target.value.length;
                                var EPr=this.state.EPrespuestas
                                EPr.p1=e.target.value;
                                this.setState({EPrespuestas:EPr})
                                this.setState({EP:p3})
                                }}/>
                            <div><CheckCircleIcon className="w-5 flex-none iconPV1" id="EPp3" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.EP.p3}/700
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>4. ¿Crees que esta idea de negocio puede usar la tecnología como base de la empresa?, ¿En verdad te apasiona esta idea de negocio para llevarla a cabo?</label><br/>
                        <div className="flex mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.EPrespuestas.p4} placeholder="Explica si esta idea de negocio puede desarrollarse con tecnología" className="flex-grow p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('EPp4').classList.add('complete');
                                }else{
                                    document.getElementById('EPp4').classList.remove('complete');
                                }
                                var p4=this.state.EP
                                p4.p4=e.target.value.length;
                                var EPr=this.state.EPrespuestas
                                EPr.p4=e.target.value;
                                this.setState({EPrespuestas:EPr})
                                this.setState({EP:p4})
                                }}/>
                            <div><CheckCircleIcon className="w-5 flex-none iconPV1" id="EPp4" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.EP.p4}/700
                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
export default Propositodevida;
