import { Component } from "react";
import { ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'
import './styles/Legal.css'

class Legal extends Component{
    constructor(props){
        super(props);
        this.state={
            checkboxes:{//Guardar en base de datos
                'AC':false,
                'FCA':false,
                'LRA':false,
                'LAA':false,
                'AA':false,
                'TA':false,
                'AV':false,
                'SO':false,
                'RM':false,
                'RDA':false,
                'CC':false,
                'LUAT':false,
                'TDW':false,
                'AISC':false,
                'MDP':false,
                'TCS':false,
                'OP':false,
                'EL':false,
                'CIT':false,
                'RIT':false,
                'CL':false,
                'RPS':false,
                'PCP':false,
                'BAE':false,
                'CoCl':false,
                'CV':false,
                'CE':false,
                'CCD':false,
                'PLN':false,
                'CNI':false,
            }
        }
    }
    checar(e){
        var check=this.state.checkboxes;
        check[e.target.id]=!(check[e.target.id]);
        this.setState({checkboxes:check});
    }
    render(){
        return(
            <div className="ml-8">
                <div className="">

                    <div className="col-span-2 title">

                        Legal
                    </div>

                    <div className="flex">

                    <div className="subtitle mr-10 active mt-4 w-26" id="SC" onClick={()=>{
                        document.getElementById('rectPI').classList.add('hiddenNone');
                        document.getElementById('rectAP').classList.add('hiddenNone');
                        document.getElementById('rectLCH').classList.add('hiddenNone');
                        document.getElementById('rectC').classList.add('hiddenNone');
                        document.getElementById('rectA').classList.add('hiddenNone');
                        document.getElementById('PI').classList.remove('active');
                        document.getElementById('AP').classList.remove('active');
                        document.getElementById('LCH').classList.remove('active');
                        document.getElementById('C').classList.remove('active');
                        document.getElementById('A').classList.remove('active');
                        document.getElementById('SC').classList.add('active');
                        document.getElementById('rectSC').classList.remove('hiddenNone');
                        document.getElementById('PinQuestions').classList.add('hiddenNone');
                        document.getElementById('APQuestions').classList.add('hiddenNone');
                        document.getElementById('LCHQuestions').classList.add('hiddenNone');
                        document.getElementById('CQuestions').classList.add('hiddenNone');
                        document.getElementById('AQuestions').classList.add('hiddenNone');
                        document.getElementById('SCQuestions').classList.remove('hiddenNone');
                    }}>
                        Societario Corporativo
                        <div className="rectangle" id="rectSC"></div>
                    </div>

                    <div className="subtitle ml-10 mt-4 w-26"  id="PI" onClick={()=>{
                        document.getElementById('rectAP').classList.add('hiddenNone');
                        document.getElementById('rectSC').classList.add('hiddenNone');
                        document.getElementById('rectLCH').classList.add('hiddenNone');
                        document.getElementById('rectC').classList.add('hiddenNone');
                        document.getElementById('rectA').classList.add('hiddenNone');
                        document.getElementById('SC').classList.remove('active');
                        document.getElementById('AP').classList.remove('active');
                        document.getElementById('LCH').classList.remove('active');
                        document.getElementById('C').classList.remove('active');
                        document.getElementById('A').classList.remove('active');
                        document.getElementById('PI').classList.add('active');
                        document.getElementById('rectPI').classList.remove('hiddenNone');
                        document.getElementById('SCQuestions').classList.add('hiddenNone');
                        document.getElementById('APQuestions').classList.add('hiddenNone');
                        document.getElementById('LCHQuestions').classList.add('hiddenNone');
                        document.getElementById('CQuestions').classList.add('hiddenNone');
                        document.getElementById('AQuestions').classList.add('hiddenNone');
                        document.getElementById('PinQuestions').classList.remove('hiddenNone');
                    }}>
                        Propiedad Intelectual
                        <div className="rectangle hiddenNone" id="rectPI"></div>
                    </div>
                    <div className="subtitle ml-10 mt-4 w-26"  id="AP" onClick={()=>{
                        document.getElementById('rectPI').classList.add('hiddenNone');
                        document.getElementById('rectSC').classList.add('hiddenNone');
                        document.getElementById('rectLCH').classList.add('hiddenNone');
                        document.getElementById('rectC').classList.add('hiddenNone');
                        document.getElementById('rectA').classList.add('hiddenNone');
                        document.getElementById('SC').classList.remove('active');
                        document.getElementById('PI').classList.remove('active');
                        document.getElementById('LCH').classList.remove('active');
                        document.getElementById('C').classList.remove('active');
                        document.getElementById('A').classList.remove('active');
                        document.getElementById('AP').classList.add('active');
                        document.getElementById('rectAP').classList.remove('hiddenNone');
                        document.getElementById('SCQuestions').classList.add('hiddenNone');
                        document.getElementById('PinQuestions').classList.add('hiddenNone');
                        document.getElementById('LCHQuestions').classList.add('hiddenNone');
                        document.getElementById('CQuestions').classList.add('hiddenNone');
                        document.getElementById('AQuestions').classList.add('hiddenNone');
                        document.getElementById('APQuestions').classList.remove('hiddenNone');
                    }}>
                        Aviso de Privacidad
                        <div className="rectangle hiddenNone" id="rectAP"></div>
                    </div>
                    <div className="subtitle ml-10 mt-4 w-26"  id="LCH" onClick={()=>{
                        document.getElementById('rectAP').classList.add('hiddenNone');
                        document.getElementById('rectSC').classList.add('hiddenNone');
                        document.getElementById('rectPI').classList.add('hiddenNone');
                        document.getElementById('rectC').classList.add('hiddenNone');
                        document.getElementById('rectA').classList.add('hiddenNone');
                        document.getElementById('SC').classList.remove('active');
                        document.getElementById('AP').classList.remove('active');
                        document.getElementById('PI').classList.remove('active');
                        document.getElementById('C').classList.remove('active');
                        document.getElementById('A').classList.remove('active');
                        document.getElementById('LCH').classList.add('active');
                        document.getElementById('rectLCH').classList.remove('hiddenNone');
                        document.getElementById('SCQuestions').classList.add('hiddenNone');
                        document.getElementById('APQuestions').classList.add('hiddenNone');
                        document.getElementById('PinQuestions').classList.add('hiddenNone');
                        document.getElementById('CQuestions').classList.add('hiddenNone');
                        document.getElementById('AQuestions').classList.add('hiddenNone');
                        document.getElementById('LCHQuestions').classList.remove('hiddenNone');
                    }}>
                        Laboral Capital Humano
                        <div className="rectangle hiddenNone" id="rectLCH"></div>
                    </div>
                    <div className="subtitle ml-10 mt-4 w-26"  id="C" onClick={()=>{
                        document.getElementById('rectAP').classList.add('hiddenNone');
                        document.getElementById('rectSC').classList.add('hiddenNone');
                        document.getElementById('rectLCH').classList.add('hiddenNone');
                        document.getElementById('rectPI').classList.add('hiddenNone');
                        document.getElementById('rectA').classList.add('hiddenNone');
                        document.getElementById('SC').classList.remove('active');
                        document.getElementById('AP').classList.remove('active');
                        document.getElementById('LCH').classList.remove('active');
                        document.getElementById('PI').classList.remove('active');
                        document.getElementById('A').classList.remove('active');
                        document.getElementById('C').classList.add('active');
                        document.getElementById('rectC').classList.remove('hiddenNone');
                        document.getElementById('SCQuestions').classList.add('hiddenNone');
                        document.getElementById('APQuestions').classList.add('hiddenNone');
                        document.getElementById('LCHQuestions').classList.add('hiddenNone');
                        document.getElementById('PinQuestions').classList.add('hiddenNone');
                        document.getElementById('AQuestions').classList.add('hiddenNone');
                        document.getElementById('CQuestions').classList.remove('hiddenNone');
                    }}>
                        Contratos
                        <div className="rectangle hiddenNone" id="rectC"></div>
                    </div>
                    <div className="subtitle ml-10 mt-4 w-26"  id="A" onClick={()=>{
                        document.getElementById('rectAP').classList.add('hiddenNone');
                        document.getElementById('rectSC').classList.add('hiddenNone');
                        document.getElementById('rectLCH').classList.add('hiddenNone');
                        document.getElementById('rectC').classList.add('hiddenNone');
                        document.getElementById('rectPI').classList.add('hiddenNone');
                        document.getElementById('SC').classList.remove('active');
                        document.getElementById('AP').classList.remove('active');
                        document.getElementById('LCH').classList.remove('active');
                        document.getElementById('C').classList.remove('active');
                        document.getElementById('PI').classList.remove('active');
                        document.getElementById('A').classList.add('active');
                        document.getElementById('rectA').classList.remove('hiddenNone');
                        document.getElementById('SCQuestions').classList.add('hiddenNone');
                        document.getElementById('APQuestions').classList.add('hiddenNone');
                        document.getElementById('LCHQuestions').classList.add('hiddenNone');
                        document.getElementById('CQuestions').classList.add('hiddenNone');
                        document.getElementById('PinQuestions').classList.add('hiddenNone');
                        document.getElementById('AQuestions').classList.remove('hiddenNone');
                    }}>
                        Administrativo
                        <div className="rectangle hiddenNone" id="rectA"></div>
                    </div>
                    
                    </div>
                   
                    <div className='lineamenuPV w-full'></div> 

                </div>
                <div className="mt-8"  id="SCQuestions">

                <div className="bg-gray-100 rounded-xl mb-4 -mt-4 pb-4">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex"  onClick={(e)=>{
                         document.getElementsByClassName("accordion")[0].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=0;i<2;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>OBLIGACIONES LEGALES DE LAS STARTUPS <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 accordion">Las startups deben estar al pendiente del funcionamiento de su gobierno corporativo y de las obligaciones legales derivadas del mismo y no cuando un tercero lo solicite, porque son obligaciones legales que determina la Ley de Mercado de Valores y la Ley General de Sociedades Mercantiles, las cuales señalan que todas las sociedades deben cumplir con cierta documentación para brindar seguridad jurídica tanto a sus accionistas como a sus inversionistas.</div>
                </div>
                <div className="grid grid-cols-2">
                    <div className="mt-4">
                        <input type="checkbox" id="AC" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["AC"]}/> <label htmlFor="AC">Acta Constitutiva</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="FCA" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["FCA"]}/> <label htmlFor="FCA">Formación del Consejo de Administración</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="LRA" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["LRA"]}/> <label htmlFor="LRA">Libro de Registro de Accionistas</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="LAA" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["LAA"]}/> <label htmlFor="LAA">Libro de Actas de Asambleas</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="AA" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["AA"]}/> <label htmlFor="AA">Acuerdos de Accionistas (convenios)</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="TA" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["TA"]}/> <label htmlFor="TA">Títulos Accionarios</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="AV" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["AV"]}/> <label htmlFor="AV">Acuerdos de Vesting</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="SO" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["SO"]}/> <label htmlFor="SO">Stock Options</label><br/>
                    </div>
                </div>                    
                </div>
                <div className="mt-8 hiddenNone"  id="PinQuestions">

                <div className="bg-gray-100 rounded-xl">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex"  onClick={(e)=>{
                         document.getElementsByClassName("accordion")[1].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=2;i<4;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>EMPRESA CON PROPÓSITO <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 mb-4 accordion">El propósito de una empresa va más allá de alcanzar un objetivo económico concreto, sino que debe dotar de sentido a lo que hace una empresa, de algún modo justifica su existencia y define un modo de comportarse en su entorno. El propósito debe tener un impacto efectivo en la sociedad y no solo maximizar el valor de la empresa para el accionista. De este modo, el propósito inspira la estrategia de la empresa, sus planes de negocio y sus objetivos a largo plazo.</div>
                </div>
                <div className="grid grid-cols-2">
                    <div className="mt-4">
                        <input type="checkbox" id="RM" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["RM"]}/> <label htmlFor="RM">Registro de Marca</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="RDA" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["RDA"]}/> <label htmlFor="RDA">Registro de Derechos de Autor</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="CC" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["CC"]}/> <label htmlFor="CC">Convenio de Confidencialidad</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="LUAT" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["LUAT"]}/> <label htmlFor="LUAT">Licencias de Uso de Marca a Terceros</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="TDW" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["TDW"]}/> <label htmlFor="AA">Titularidad de Dominios WEB</label><br/>
                    </div>
                </div> 
                   
                    
                </div>
                <div className="mt-8 hiddenNone"  id="APQuestions">

                <div className="bg-gray-100 rounded-xl">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex"  onClick={(e)=>{
                         document.getElementsByClassName("accordion")[2].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=4;i<6;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>EMPRESA CON PROPÓSITO <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 mb-4 accordion">El propósito de una empresa va más allá de alcanzar un objetivo económico concreto, sino que debe dotar de sentido a lo que hace una empresa, de algún modo justifica su existencia y define un modo de comportarse en su entorno. El propósito debe tener un impacto efectivo en la sociedad y no solo maximizar el valor de la empresa para el accionista. De este modo, el propósito inspira la estrategia de la empresa, sus planes de negocio y sus objetivos a largo plazo.</div>
                </div>
                <div className="grid grid-cols-2">
                    <div className="mt-4">
                        <input type="checkbox" id="AISC" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["AISC"]}/> <label htmlFor="AISC">Aviso Integral Simplificado y Corto</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="MDP" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["AISC"]}/> <label htmlFor="MDP">Manejo de Datos Personales (derechos arco)</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="TCS" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["AISC"]}/> <label htmlFor="TCS">Términos y Condiciones de los Servicios</label><br/>
                    </div>
                </div>                        
                    
                </div>
                <div className="mt-8 hiddenNone"  id="LCHQuestions">

                <div className="bg-gray-100 rounded-xl">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[3].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=6;i<8;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>EMPRESA CON PROPÓSITO <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 mb-4 accordion">El propósito de una empresa va más allá de alcanzar un objetivo económico concreto, sino que debe dotar de sentido a lo que hace una empresa, de algún modo justifica su existencia y define un modo de comportarse en su entorno. El propósito debe tener un impacto efectivo en la sociedad y no solo maximizar el valor de la empresa para el accionista. De este modo, el propósito inspira la estrategia de la empresa, sus planes de negocio y sus objetivos a largo plazo.</div>
                </div>
                <div className="grid grid-cols-2">
                    <div className="mt-4">
                        <input type="checkbox" id="OP" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["OP"]}/> <label htmlFor="OP">Organigrama de Puestos</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="EL" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["EL"]}/> <label htmlFor="EL">Expedientes Laborales</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="CIT" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["CIT"]}/> <label htmlFor="CIT">Contratos Individuales de Trabajo</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="RIT" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["RIT"]}/> <label htmlFor="RIT">Reglamento Interior de Trabajo</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="CL" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["CL"]}/> <label htmlFor="CL">Controles Laborales</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="RPS" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["RPS"]}/> <label htmlFor="RPS">Registro Patronal de la Sociedad (IMMS)</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="PCP" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["PCP"]}/> <label htmlFor="PCP">Plan de Capacitación del Personal</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="BAE" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["BAE"]}/> <label htmlFor="BAE">Benficios Adicionales a Empleados</label><br/>
                    </div>
                </div> 
                </div>
                <div className="mt-8 hiddenNone"  id="CQuestions">

                <div className="bg-gray-100 rounded-xl">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[4].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=8;i<10;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>EMPRESA CON PROPÓSITO <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 mb-4 accordion">El propósito de una empresa va más allá de alcanzar un objetivo económico concreto, sino que debe dotar de sentido a lo que hace una empresa, de algún modo justifica su existencia y define un modo de comportarse en su entorno. El propósito debe tener un impacto efectivo en la sociedad y no solo maximizar el valor de la empresa para el accionista. De este modo, el propósito inspira la estrategia de la empresa, sus planes de negocio y sus objetivos a largo plazo.</div>
                </div>
                <div className="grid grid-cols-2">
                    <div className="mt-4">
                        <input type="checkbox" id="CoCl" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["CoCl"]}/> <label htmlFor="CoCl">Contratos de Clientes</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="CV" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["CV"]}/> <label htmlFor="CV">Contratos con proveedores</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="CE" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["CE"]}/> <label htmlFor="CE">Contratos con Empleados</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="CCD" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["CCD"]}/> <label htmlFor="CCD">Contratos de créditos o deudas</label><br/>
                    </div>
                </div> 
                </div>
                <div className="mt-8 hiddenNone"  id="AQuestions">

                <div className="bg-gray-100 rounded-xl">
                     <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[5].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=10;i<12;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>EMPRESA CON PROPÓSITO <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 mb-4 accordion">El propósito de una empresa va más allá de alcanzar un objetivo económico concreto, sino que debe dotar de sentido a lo que hace una empresa, de algún modo justifica su existencia y define un modo de comportarse en su entorno. El propósito debe tener un impacto efectivo en la sociedad y no solo maximizar el valor de la empresa para el accionista. De este modo, el propósito inspira la estrategia de la empresa, sus planes de negocio y sus objetivos a largo plazo.</div>
                </div>
                <div className="grid grid-cols-2">
                    <div className="mt-4">
                        <input type="checkbox" id="PLN" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["PLN"]}/> <label htmlFor="PLN">Permisos y licencias para la operación del negocio</label><br/>
                    </div>
                    <div className="mt-4">
                        <input type="checkbox" id="CNI" onChange={this.checar.bind(this)} defaultValue={this.state.checkboxes["CNI"]}/> <label htmlFor="CNI">Ceritificaciones NOM, ISO, etc.</label><br/>
                    </div>
                </div> 
                </div>
                <div className="py-10"></div>
            </div>
        );
    }
}
export default Legal;