import React from 'react';
import './styles/academylongcard.css';
import { PlayIcon, StarIcon } from '@heroicons/react/solid';
const Academylongcard = (props) => {
        
    return (
      <div className="flex space-x-4 items-center max-w-lg">
          <div className="">
              <img className='object-cover object-center h-32 w-32 rounded-md' src="https://images.unsplash.com/photo-1537511446984-935f663eb1f4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt="img"/>
          </div>
          <div className="flex-1">
              <div className="titulo">
                  The customer journey map
              </div>
              <div className="autor mt-2">
                  Por: Alex Campos
              </div>
              <div className="valoracion flex mt-2">
                  <StarIcon className="w-4 estrella"/><span>4.2</span>
              </div>
              <button className="tema ml-9 mt-2 bg-gray-200 font-bold hover:bg-gray-300 p-2 rounded-lg">
                  E-commerce
              </button>
          </div>
          <div>
            <PlayIcon className="play w-12" />
          </div>
      </div>
    );
  };
  
  export default Academylongcard;