import React from 'react';
import "./styles/Comunidad.css";
import Postimg from '../components/ComunidadC/postimg';
import { GlobeAltIcon,VideoCameraIcon,CubeIcon,RssIcon,PlusIcon } from '@heroicons/react/solid';

import Cursocard from '../components/ComunidadC/cursocard';
import Academycard from '../components/AcademiaC/academycard';
import Eventocard from '../components/ComunidadC/eventoscard';
const Comunidad=()=>{
    return(
        <div className="mt-4 ml-14">
            <div className="grid grid-cols-7">
                <div className="col-span-4">
                    <div>
                        <button id="inicio" className=" w-12 h-12 buttonactive" onClick={()=>{
                            var options=document.getElementsByClassName("buttonactive");
                            for(var i=0;i<options.length;i++){
                                options[i].classList.replace('buttonactive','buttontop');
                            }
                            document.getElementById('inicio').classList.replace('buttontop','buttonactive');
                            }}>
                                <GlobeAltIcon className="ml-3 mt-1 w-5"/>Inicio</button>
                        <button id="live" className=" w-12 h-12 buttontop" onClick={()=>{
                            var options=document.getElementsByClassName("buttonactive");
                            for(var i=0;i<options.length;i++){
                                options[i].classList.replace('buttonactive','buttontop');
                            }
                            document.getElementById('live').classList.replace('buttontop','buttonactive');
                            }}>
                                <VideoCameraIcon className="ml-3 mt-1 w-5"/>Live</button>
                        <button id="pitch" className=" w-12 h-12 buttontop" onClick={()=>{
                            var options=document.getElementsByClassName("buttonactive");
                            for(var i=0;i<options.length;i++){
                                options[i].classList.replace('buttonactive','buttontop');
                            }
                            document.getElementById('pitch').classList.replace('buttontop','buttonactive');
                            }}>
                                <CubeIcon className="ml-3 mt-1 w-5"/>Pitch</button>
                        <button id="noticias" className=" w-12 h-12 buttontop" onClick={()=>{
                            var options=document.getElementsByClassName("buttonactive");
                            for(var i=0;i<options.length;i++){
                                options[i].classList.replace('buttonactive','buttontop');
                            }
                            document.getElementById('noticias').classList.replace('buttontop','buttonactive');
                            }}>
                                <RssIcon className="ml-3 mt-1 w-5"/>Noticias</button>
                        <button className=" w-12 h-12 ml-2 -mt-2 buttontop1" ><PlusIcon className="ml-3 w-5"/></button>
                    </div>
                    <div>
                            <Postimg />
                            
                    </div>
                </div>
                <div className="col-span-3 mt-8 mr-7">
                    <div className="titlenews">
                        Lo más Nuevo
                        
                            <select name="noticias" id="noticias" className="float-right">
                                <option value="Recientes">Recientes</option>
                            </select>
                        
                    </div>
                    <div className="clear-right">
                        <Cursocard />
                        <div className="mt-4">
                            <Academycard />
                        </div>
                        <div className="mt-4">
                            <Eventocard />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Comunidad;