import React from "react";
import { CheckIcon } from '@heroicons/react/solid';

const steps = [
  { name: 'Lanzamiento de convocatoria', description: '12 de Nov 2021', href: '#', status: 'complete' },
  {
    name: 'Cierre de convocatoria',
    description: '12 de Nov 2021',
    href: '#',
    status: 'current',
  },
  { name: 'Presentación de proyectos seleccionados', description: '12 de Nov 2021', href: '#', status: 'upcoming' },
  { name: 'Registro del proyecto', description: '12 de Nov 2021', href: '#', status: 'upcoming' },
  { name: 'Arranque del programa', description: '12 de Nov 2021', href: '#', status: 'upcoming' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const EtapasConvocatoria = () => {
  return (
    <div>
      <div className="py-2 px-6">
        <div className="mb-8">
          <p className="text-lg font-bold">Etapas de la Convocatoria</p>
        </div>
        <nav aria-label="Progress">
          <ul className="overflow-hidden">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                {step.status === 'complete' ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-yellow-300" aria-hidden="true" />
                    ) : null}
                    <a href={step.href} className="relative flex items-start group">
                      <span className="h-9 flex items-center">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-yellow-300 rounded-full group-hover:bg-yellow-400">
                          <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                        </span>
                      </span>
                      <span className="ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase">{step.name}</span>
                        <span className="text-sm text-gray-500">{step.description}</span>
                      </span>
                    </a>
                  </>
                ) : step.status === 'current' ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                    ) : null}
                    <a href={step.href} className="relative flex items-start group" aria-current="step">
                      <span className="h-9 flex items-center" aria-hidden="true">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-yellow-300 rounded-full">
                          <span className="h-2.5 w-2.5 bg-yellow-300 rounded-full" />
                        </span>
                      </span>
                      <span className="ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase text-yellow-300">{step.name}</span>
                        <span className="text-sm text-gray-500">{step.description}</span>
                      </span>
                    </a>
                  </>
                ) : (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                    ) : null}
                    <a href={step.href} className="relative flex items-start group">
                      <span className="h-9 flex items-center" aria-hidden="true">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                          <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                        </span>
                      </span>
                      <span className="ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">{step.name}</span>
                        <span className="text-sm text-gray-500">{step.description}</span>
                      </span>
                    </a>
                  </>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default EtapasConvocatoria;
