import React from 'react';
import imagen from '../../assets/convocatorias.jpeg';
import './styles/eventoscard.css'

const Eventocard = (props) => {
        
    return (
      <div className="grid grid-cols-3">    
            <div>
                <img src={imagen} alt="img"  className="w-24"/>   
            </div>
            <div className="col-span-2 mt-4">
                <div className="titulo">
                    Incubación de Empresas UAA 2021
                </div>
                <div className="info mt-3">
                    Cierre de la convocatoria
                </div>
                <div >
                    <button className="aplicar mt-5">Aplicar</button>
                </div>
            </div>        
      </div>
    );
  };
  
  export default Eventocard;