import { Component } from "react";
import './styles/DireccionEstrategica.css'
import {CheckCircleIcon, ChevronDownIcon, ChevronUpIcon,ChevronRightIcon} from '@heroicons/react/solid';
import Chart from 'react-apexcharts'

class DireccionEstrategica extends Component{
    constructor(props){
        super(props);
        this.state={
            CU:{
                p1:0,
                p2:0,
                p3:0,
                p4:0
            },
            CUrespuestas:{//Guardar en base de datos
                p1:'',
                p2:'',
                p3:''
            },
            CO:{
                p1:0,
                p2:0,
            },
            COrespuestas:{//guardar en base de datos
                p1:'',
                p2:''
            },
            TodosGantt:[],//Guardar en base de datos //array de objetos
            Grupos:[],//Guardar en base de datos //array de strings
            Responsables:[],//Guardar en base de datos //array de strings
            Gantt:[],
            Tareas:[],
            indexAct:0,
            indexTarea:0,
            seleccionGrupo:'',
            boolGrupo:false,
            Tarea:{responsable:'',presupuesto:0,FI:'',FF:'',completado:0},
            boolTarea:false,
            Series:[],
            options:{}
        }
    }
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    agregarG(e){
        if(e.target.value!==''){
            let grupos=this.state.Grupos;
            grupos.push(e.target.value);
            document.getElementById('grupo').value="";
            document.getElementById('grupo').classList.add('hiddenNone');
            this.setState({Grupos:grupos});
        }
    }
    eliminarG(e){
        let todosGantt=this.state.TodosGantt;
        let grupos=this.state.Grupos;
        let grupos2=[];
        let todosGantt2=[];
        for(let i=0;i<grupos.length;i++){
            if(grupos[i]!==this.state.seleccionGrupo){
                grupos2.push(grupos[i]);
            }
        }
        for(let i=0;i<todosGantt.length;i++){
            if(todosGantt[i].nombreGrupo!==this.state.seleccionGrupo){
                todosGantt2.push(todosGantt[i]);
            }
        }
        this.setState({Grupos:grupos2});
        this.setState({TodosGantt:todosGantt2});
        document.getElementById('selectgrupos').value='default';
        this.setState({seleccionGrupo:''})
        this.setState({boolGrupo:false});
    }
    filtrargrupo(e){
        if(e.target.value==='CN'){
            this.setState({Gantt:[]},()=>{
                document.getElementById('grupo').classList.remove('hiddenNone');
                document.getElementById('grupo').focus();
                document.getElementById('grupo').select();
                this.setState({boolGrupo:false});
            });
        }else{
            let gantt=[];
            for(let i=0;i<this.state.TodosGantt.length;i++){
                if(this.state.TodosGantt[i].nombreGrupo===e.target.value){
                    gantt.push(this.state.TodosGantt[i]);
                }
            }
            this.setState({Gantt:gantt});
            this.setState({seleccionGrupo:e.target.value},()=>this.setState({boolGrupo:true}));
            
        }
        this.setState({Tareas:[]});
        this.setState({boolTarea:false})
    }
    crearactividad(){
        let gantt=this.state.Gantt;
        let todosGantt=this.state.TodosGantt;
        let hoy=this.formatDate(new Date());
        let manana=new Date(hoy);
        manana.setDate(manana.getDate()+1);
        manana=this.formatDate(manana);
        let tareas=[{'nombre':'','FI':hoy,'FF':manana,'responsable':'default','presupuesto':0,'completado':0}]
        gantt.push({'nombre':'','nombreGrupo':this.state.seleccionGrupo,'tareas':tareas,'FI':hoy,'FF':manana,'completado':0,'presupuesto':0});
        todosGantt.push({'nombre':'','nombreGrupo':this.state.seleccionGrupo,'tareas':tareas,'FI':hoy,'FF':manana,'completado':0,'presupuesto':0})
        this.setState({Gantt:gantt});
        this.setState({boolTarea:false});
        this.setState({Tareas:[]});
        this.setState({TodosGantt:todosGantt});
    }
    creartarea(){
        let gantt=this.state.Gantt;
        let todosGantt=this.state.TodosGantt
        let tareas=this.state.Tareas
        let hoy=this.formatDate(new Date());
        let manana=new Date(hoy);
        manana.setDate(manana.getDate()+1);
        manana=this.formatDate(manana);
        tareas.push({'nombre':'','FI':hoy,'FF':manana,'responsable':'default','presupuesto':0,'completado':0});
        try{
            gantt[this.state.indexAct]['tareas']=tareas;
            todosGantt[this.state.indexAct]['tareas']=tareas;
        }catch(e){}
       
        this.setState({Gantt:gantt});
        this.setState({TodosGantt:todosGantt});
        this.setState({boolTarea:false});
        this.setState({Tareas:tareas});
    }
    mostrartareas(e){
        this.setState({indexAct:parseInt(e.target.id)},()=>{
            try{
                let tareas=this.state.Gantt[parseInt(e.target.id)]['tareas'];
                this.setState({Tareas:tareas});
                this.setState({boolTarea:false})
            }catch(e){}
        });
    }
    mostrartarea(e){
        this.setState({boolTarea:false},()=>{
            this.setState({indexTarea:parseInt(e.target.id)},()=>{
                let tarea=Object.assign({},this.state.Gantt[this.state.indexAct].tareas[parseInt(e.target.id)]); 
                this.setState({Tarea:tarea},()=>this.setState({boolTarea:true}));
            });
        })
        
    }
    editarnombreA(e){
        let gantt=this.state.Gantt;
        let todosGantt=this.state.TodosGantt;
        gantt[parseInt(e.target.id)].nombre=e.target.value;
        todosGantt[parseInt(e.target.id)].nombre=e.target.value;
        this.setState({Gantt:gantt});
        this.setState({TodosGantt:todosGantt})
    }
    eliminarA(e){
        let gantt=this.state.Gantt;
        let todosGantt=this.state.TodosGantt
        this.setState({Gantt:[]},()=>{
            gantt.splice(parseInt(e.target.id),1);
            todosGantt.splice(parseInt(e.target.id),1);
            this.setState({Gantt:gantt});
            this.setState({TodosGantt:todosGantt});
        });        
    }
    editarnombreT(e){
        let gantt=this.state.Gantt;
        let tareas=this.state.Tareas;
        let todosGantt=this.state.TodosGantt;
        try{
            tareas[parseInt(e.target.id)].nombre=e.target.value;
            gantt[this.state.indexAct].tareas[parseInt(e.target.id)].nombre=e.target.value;
            todosGantt[this.state.indexAct].tareas[parseInt(e.target.id)].nombre=e.target.value;
        }catch(e){}
        this.setState({Gantt:gantt});
        this.setState({Tareas:tareas});
        this.setState({TodosGantt:todosGantt});
    }
    eliminarT(e){
        let gantt=this.state.Gantt;
        let tareas=this.state.Tareas;
        let todosGantt=this.state.TodosGantt
        this.setState({Tareas:[]},()=>{
            try{
                tareas.splice(parseInt(e.target.id),1);
                gantt[this.state.indexAct].tareas.slice(parseInt(e.target.id),1);
                todosGantt[this.state.indexAct].tareas.slice(parseInt(e.target.id),1);
            }catch(e){}
            this.setState({Gantt:gantt});
            this.setState({Tareas:tareas});
            this.setState({TodosGantt:todosGantt})
        });
        
    }
    editarnombreR(e){
        if(e.target.value==='CN'){
            document.getElementById('tareaResponsable').classList.remove('hiddenNone');
            document.getElementById('tareaResponsable').focus();
            document.getElementById('tareaResponsable').select();
        }else{
            let gantt=this.state.Gantt;
            let tareas=this.state.Tareas;
            let todosGantt=this.state.TodosGantt;
            try{
                tareas[this.state.indexTarea].responsable=e.target.value;
                gantt[this.state.indexAct].tareas[this.state.indexTarea].responsable=e.target.value;
                todosGantt[this.state.indexAct].tareas[this.state.indexTarea].responsable=e.target.value;
            }catch(e){}
            this.setState({Gantt:gantt});
            this.setState({Tareas:tareas});
            this.setState({TodosGantt:todosGantt});
        }
        
    }
    agregarR(e){
        if(e.target.value!==''){
            this.setState({boolTarea:false},()=>{
                let responsables=this.state.Responsables;
                responsables.push(e.target.value);
                this.setState({Responsables:responsables},()=>{ 
                    this.setState({boolTarea:true},()=>{ 
                        document.getElementById('tareaResponsable').classList.add('hiddenNone');
                    })
                });
            })
        }
    }
    editarpresupuesto(e){
        let gantt=this.state.Gantt;
        let tareas=this.state.Tareas;
        let todosGantt=this.state.TodosGantt;
        try{
            tareas[this.state.indexTarea].presupuesto=parseFloat(e.target.value);
            gantt[this.state.indexAct].tareas[this.state.indexTarea].presupuesto=parseFloat(e.target.value);
            todosGantt[this.state.indexAct].tareas[this.state.indexTarea].presupuesto=parseFloat(e.target.value);
            let presupuesto=0
            for(let i=0;i<gantt[this.state.indexAct].tareas.length;i++){
                presupuesto+=gantt[this.state.indexAct].tareas[i].presupuesto
            }
            gantt[this.state.indexAct].presupuesto=presupuesto;
            presupuesto=0;
            for(let i=0;i<todosGantt[this.state.indexAct].tareas.length;i++){
                presupuesto+=todosGantt[this.state.indexAct].tareas[i].presupuesto
            }
            todosGantt[this.state.indexAct].presupuesto=presupuesto;
        }catch(e){}
        this.setState({Gantt:gantt});
        this.setState({Tareas:tareas});
        this.setState({TodosGantt:todosGantt});
    }
    editarprogreso(e){
        let gantt=this.state.Gantt;
        let todosGantt=this.state.TodosGantt;
        let tareas=this.state.Tareas;
        let tarea=this.state.Tarea
        try{
            if(e.target.value!=='PA'){
                tareas[this.state.indexTarea].completado=parseInt(e.target.value);
                gantt[this.state.indexAct].tareas[this.state.indexTarea].completado=parseInt(e.target.value);
                todosGantt[this.state.indexAct].tareas[this.state.indexTarea].completado=parseInt(e.target.value);
                tarea.completado=parseInt(e.target.value);
            }else{
                tareas[this.state.indexTarea].completado=e.target.value;
                gantt[this.state.indexAct].tareas[this.state.indexTarea].completado=e.target.value;
                todosGantt[this.state.indexAct].tareas[this.state.indexTarea].completado=e.target.value;
                tarea.completado=e.target.value;
            }
            
            let progresoAct=0;
            for(let i=0;i<gantt[this.state.indexAct].tareas.length;i++){
                if(gantt[this.state.indexAct].tareas[i].completado!=='PA'){
                    progresoAct+=gantt[this.state.indexAct].tareas[i].completado;
                }
            }
            progresoAct=parseFloat((progresoAct/gantt[this.state.indexAct].tareas.length).toFixed(2))
            gantt[this.state.indexAct].completado=progresoAct;
            todosGantt[this.state.indexAct].completado=progresoAct;
        }catch(e){}
        this.setState({Gantt:gantt});
        this.setState({TodosGantt:todosGantt});
        this.setState({Tareas:tareas});
        this.setState({Tarea:tarea});
    }
    editarfechaI(e){
        let gantt=this.state.Gantt;
        let todosGantt=this.state.TodosGantt;
        let tareas=this.state.Tareas;
        try{
            gantt[this.state.indexAct].tareas[this.state.indexTarea].FI=e.target.value;
            todosGantt[this.state.indexAct].tareas[this.state.indexTarea].FI=e.target.value;
            tareas[this.state.indexTarea].FI=e.target.value;
        }catch(e){}
        
        this.setState({Gantt:gantt},()=>{
            this.setState({Tareas:tareas});
            this.setState({TodosGantt:todosGantt});
            this.actualizarfechasAct();
        });
    }
    editarfechaF(e){
        let gantt=this.state.Gantt;
        let todosGantt=this.state.TodosGantt;
        let tareas=this.state.Tareas;
        try{
            gantt[this.state.indexAct].tareas[this.state.indexTarea].FF=e.target.value;
            todosGantt[this.state.indexAct].tareas[this.state.indexTarea].FF=e.target.value;
            tareas[this.state.indexTarea].FF=e.target.value;
        }catch(e){}
        this.setState({Gantt:gantt},()=>{
            this.setState({Tareas:tareas});
            this.setState({TodosGantt:todosGantt});
            this.actualizarfechasAct();
        });
    }
    actualizarfechasAct(){
        let gantt=this.state.Gantt;
        let todosGantt=this.state.TodosGantt;
        for(let i=0;i<gantt.length;i++){
            gantt[i].FI=gantt[i].tareas[0].FI;
            gantt[i].FF=gantt[i].tareas[gantt[i].tareas.length-1].FF;
        }
        for(let i=0;i<todosGantt.length;i++){
            todosGantt[i].FI=todosGantt[i].tareas[0].FI;
            todosGantt[i].FF=todosGantt[i].tareas[todosGantt[i].tareas.length-1].FF;
        }
        this.setState({Gantt:gantt});
        this.setState({TodosGantt:todosGantt});
    }
    visualizar(){
        let gantt=this.state.Gantt;
        let series=[];
        let datos=[]
        let options= {
            chart: {
              height: 450,
              type: 'rangeBar'
            },
            
            dataLabels: {
                enabled: true,
                formatter: function(val,obj) {
                    if(obj.w.config.series[obj.seriesIndex].data[obj.dataPointIndex].z['presupuesto']===0){
                        return obj.w.config.series[obj.seriesIndex].data[obj.dataPointIndex].z['desc'];
                    }else{
                        return [obj.w.config.series[obj.seriesIndex].data[obj.dataPointIndex].z['desc'],'presupuesto: $'+obj.w.config.series[obj.seriesIndex].data[obj.dataPointIndex].z['presupuesto']];
                    }
                    
                }
            },
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: '100%',
              }
            },
            colors: [function({ value, seriesIndex, w }) {
                if(w.config.series[seriesIndex].data[0].w==='A'){
                    return '#6E6E6E'
                }else{
                    return '#A4A4A4'
                }
              }],
            xaxis: {
              type: 'datetime'
            },
            stroke: {
              width: 1
            },
            fill: {
                type: 'solid',
                opacity: 0.6
                
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left'
            }
        }
        for(let i=0;i<gantt.length;i++){
            for(let j=0;j<gantt[i].tareas.length;j++){
                let band=false
                for(let k=0;k<datos.length;k++){
                    if(datos[k].nombre===gantt[i].tareas[j].responsable){
                        let tarea={
                            nombreAct:gantt[i].nombre,
                            nombre:gantt[i].tareas[j].nombre,
                            responsable:gantt[i].tareas[j].responsable,
                            FI:gantt[i].tareas[j].FI,
                            FF:gantt[i].tareas[j].FF,
                            presupuesto:gantt[i].tareas[j].presupuesto,
                            completado:gantt[i].tareas[j].completado
                        }
                        datos[k].tareas.push(tarea);
                        band=true;
                        break;
                    }
                }
                if(!band){
                    let tarea={
                        nombreAct:gantt[i].nombre,
                        nombre:gantt[i].tareas[j].nombre,
                        responsable:gantt[i].tareas[j].responsable,
                        FI:gantt[i].tareas[j].FI,
                        FF:gantt[i].tareas[j].FF,
                        presupuesto:gantt[i].tareas[j].presupuesto,
                        completado:gantt[i].tareas[j].completado
                    }
                    datos.push({nombre:gantt[i].tareas[j].responsable,tareas:[tarea]})
                }
            }
        }
        for(let i=0;i<gantt.length;i++){
            let desc=gantt[i].nombre+' avance: '+gantt[i].completado+'%';
            let color='#BDBDBD';
                if(gantt[i].completado>=0&&gantt[i].completado<25){
                    color='#FF0000'
                }else if(gantt[i].completado>=25&&gantt[i].completado<50){
                    color='#FF8000'
                }else if(gantt[i].completado>=50&&gantt[i].completado<75){
                    color='#FFFF00'
                }else if(gantt[i].completado>=75&&gantt[i].completado<100){
                    color='#C8FE2E'
                }else if(gantt[i].completado>=100){
                    color='#40FF00'
                }
                let finueva=new Date(gantt[i].FI);
                finueva.setDate(finueva.getDate()+1);
                let ffnueva=new Date(gantt[i].FF);
                ffnueva.setDate(ffnueva.getDate());
            series.push({'name':gantt[i].nombre,'data':[{'x':gantt[i].nombre,w:'A',y:[new Date(gantt[i].FI).getTime(),new Date(gantt[i].FF).getTime()],z:{name:'value',desc:desc,presupuesto:gantt[i].presupuesto},goals:[{name:'Break',value:ffnueva.getTime(),strokeColor:color,strokeWidth: 20}]}]})
        }
        for(let i=0;i<datos.length;i++){
            let data=[];
            for(let j=0;j<datos[i].tareas.length;j++){
                let color='#BDBDBD';
                if(datos[i].tareas[j].completado===0){
                    color='#FF0000'
                }else if(datos[i].tareas[j].completado===25){
                    color='#FF8000'
                }else if(datos[i].tareas[j].completado===50){
                    color='#FFFF00'
                }else if(datos[i].tareas[j].completado===75){
                    color='#C8FE2E'
                }else if(datos[i].tareas[j].completado===100){
                    color='#40FF00'
                }
                let desc=datos[i].tareas[j].nombre+' avance: '+datos[i].tareas[j].completado+'%';
                let finueva=new Date(datos[i].tareas[j].FI);
                finueva.setDate(finueva.getDate()+1);
                let ffnueva=new Date(datos[i].tareas[j].FF);
                ffnueva.setDate(ffnueva.getDate());
               data.push({'x':datos[i].tareas[j].nombreAct,w:'T',y:[new Date(datos[i].tareas[j].FI).getTime(),new Date(datos[i].tareas[j].FF).getTime(),],z:{name:'value',desc:desc,presupuesto:datos[i].tareas[j].presupuesto},goals:[{name:'Break',value:ffnueva.getTime(),strokeColor:color,strokeWidth: 20}]})
            }
            series.push({'name':datos[i].nombre,'data':data})
            
        }
        this.setState({options:options},()=>this.setState({Series:series}))
        
    }
    render(){
        return(
            <div className="ml-8">
                <div className="">

                    <div className="col-span-2 title">

                        Dirección Estratégica de Negocios
                    </div>

                    <div className="flex">

                    <div className="subtitle mr-10 active mt-4 w-26" id="CU" onClick={()=>{
                        document.getElementById('rectCO').classList.add('hiddenNone');
                        document.getElementById('rectDG').classList.add('hiddenNone');
                        document.getElementById('CO').classList.remove('active');
                        document.getElementById('DG').classList.remove('active');
                        document.getElementById('CU').classList.add('active');
                        document.getElementById('rectCU').classList.remove('hiddenNone');
                        document.getElementById('COQuestions').classList.add('hiddenNone');
                        document.getElementById('DGQuestions').classList.add('hiddenNone');
                        document.getElementById('CUQuestions').classList.remove('hiddenNone');
                    }}>
                        Cultura Corporativa
                        <div className="rectangle" id="rectCU"></div>
                    </div>

                    <div className="subtitle ml-10 mt-4 w-26"  id="CO" onClick={()=>{
                        document.getElementById('rectCU').classList.add('hiddenNone');
                        document.getElementById('rectDG').classList.add('hiddenNone');
                        document.getElementById('CU').classList.remove('active');
                        document.getElementById('DG').classList.remove('active');
                        document.getElementById('CO').classList.add('active');
                        document.getElementById('rectCO').classList.remove('hiddenNone');
                        document.getElementById('COQuestions').classList.remove('hiddenNone');
                        document.getElementById('CUQuestions').classList.add('hiddenNone');
                        document.getElementById('DGQuestions').classList.add('hiddenNone');
                    }}>
                        Control Operativo
                        <div className="rectangle hiddenNone" id="rectCO"></div>
                    </div>
                    <div className="subtitle ml-10 mt-4 w-26"  id="DG" onClick={()=>{
                        document.getElementById('rectCU').classList.add('hiddenNone');
                        document.getElementById('rectCO').classList.add('hiddenNone');
                        document.getElementById('CU').classList.remove('active');
                        document.getElementById('CO').classList.remove('active');
                        document.getElementById('DG').classList.add('active');
                        document.getElementById('rectDG').classList.remove('hiddenNone');
                        document.getElementById('DGQuestions').classList.remove('hiddenNone');
                        document.getElementById('CUQuestions').classList.add('hiddenNone');
                        document.getElementById('COQuestions').classList.add('hiddenNone');
                    }}>
                        Diagrama de Gantt
                        <div className="rectangle hiddenNone" id="rectDG"></div>
                    </div>
                    
                    </div>
                   
                   <div className='line'>

                   </div>
                </div>
                <div className="mt-4"  id="CUQuestions">

                <div className="bg-gray-100 rounded-xl mt-4 mb-4 pb-4">
                    <div className="text-gray-600 pt-4 px-4 text-lg flex mb-1" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[0].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=0;i<2;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                     }}>CULTURA CORPORATIVA <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 accordion">La cultura corporativa es la personalidad de una organización, esta se refleja a través de hábitos, comportamientos y creencias establecidas en el tiempo por medio de normas, valores, aptitudes, y expectativas compartidas por parte de todos los miembros de la organización.</div>
                </div> 

                    <div>
                        <label>Misión</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.CUrespuestas.p1} placeholder="La misión es la descripción de lo que una compañía se compromete a hacer por sus clientes en la actualidad." className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('CUp1').classList.add('complete');
                                }else{
                                    document.getElementById('CUp1').classList.remove('complete');
                                }
                                var p1=this.state.CU
                                p1.p1=e.target.value.length;
                                var cu=this.state.CUrespuestas
                                cu.p1=e.target.value;
                                this.setState({CUrespuestas:cu})
                                this.setState({CU:p1})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconDE" id="CUp1" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.CU.p1}/700
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>Visión</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.CUrespuestas.p2} placeholder="La visión describe una expectativa ideal sobre lo que se espera que la organización sea o alcance en un futuro." className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('CUp2').classList.add('complete');
                                }else{
                                    document.getElementById('CUp2').classList.remove('complete');
                                }
                                var p2=this.state.CU
                                p2.p2=e.target.value.length;
                                var cu=this.state.CUrespuestas
                                cu.p2=e.target.value;
                                this.setState({CUrespuestas:cu})
                                this.setState({CU:p2})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconDE" id="CUp2" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.CU.p2}/700
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>Valores</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.CUrespuestas.p3} placeholder="Los valores de empresa son aquellas características que la definen y que sirven como eje para su crecimiento." className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('CUp3').classList.add('complete');
                                }else{
                                    document.getElementById('CUp3').classList.remove('complete');
                                }
                                var p3=this.state.CU
                                p3.p3=e.target.value.length;
                                var cu=this.state.CUrespuestas
                                cu.p3=e.target.value;
                                this.setState({CUrespuestas:cu})
                                this.setState({CU:p3})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconDE" id="CUp3" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.CU.p3}/700
                        </div>
                    </div>
                    
                </div>
                <div className="mt-4 hiddenNone"  id="COQuestions">

                <div className="bg-gray-100 rounded-xl mt-4 mb-4 pb-4">
                    <div className="text-gray-600 pt-4 px-4 text-lg flex mb-1" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[1].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=2;i<prueba.length;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                         }}>CONTROL OPERATIVO <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 accordion">El objeto del control operacional es identificar aquellas operaciones y actividades sobre las que es necesario aplicar medidas de control, para evitar problemas en la operación del negocio. Las medidas de control más usadas son los KPI´s y OKR´s</div>
                </div> 

                    <div>
                        <label>KPI's (Indicadores clave de desempeño)</label><br/>
                        <div className="flex mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.COrespuestas.p1} placeholder="Los KPI´s son los valores que miden el desempeño de los proyectos, actividades y metas de tu negocio, y sirven para saber qué tan cerca estás de tus objetivos empresariales." className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('COp1').classList.add('complete');
                                }else{
                                    document.getElementById('COp1').classList.remove('complete');
                                }
                                var p1=this.state.CO
                                p1.p1=e.target.value.length;
                                var co=this.state.COrespuestas;
                                co.p1=e.target.value;
                                this.setState({COrespuestas:co});
                                this.setState({CO:p1})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconDE" id="COp1" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.CO.p1}/700
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>OKR's (Objetivos y resultados clave)</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.COrespuestas.p2} placeholder="Los OKR´s tratan de que cada equipo de trabajo fije entre tres y cinco metas ambiciosas para alcanzarlas en un marco temporal que habitualmente abarca los tres meses y siempre alineadas con la estrategia de la empresa." className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('COp2').classList.add('complete');
                                }else{
                                    document.getElementById('COp2').classList.remove('complete');
                                }
                                var p2=this.state.CO
                                p2.p2=e.target.value.length;
                                var co=this.state.COrespuestas;
                                co.p2=e.target.value;
                                this.setState({COrespuestas:co});
                                this.setState({CO:p2})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconDE" id="COp2" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.CO.p2}/700
                        </div>
                    </div>
                    
                </div>
                <div className="mt-4 hiddenNone"  id="DGQuestions">

                <div className="bg-gray-100 rounded-xl mt-4 mb-4 pb-4">
                    <div className="text-gray-600 pt-4 px-4 text-lg flex mb-1" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[2].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=4;i<prueba.length;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                         }}>CONTROL OPERATIVO <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 accordion">El objeto del control operacional es identificar aquellas operaciones y actividades sobre las que es necesario aplicar medidas de control, para evitar problemas en la operación del negocio. Las medidas de control más usadas son los KPI´s y OKR´s</div>
                </div> 
                <div>
                    <div>
                        <select id="selectgrupos" defaultValue="default" onChange={this.filtrargrupo.bind(this)}>
                            <option value="default" disabled hidden>Escoge un grupo</option>
                            <option value="CN" >Crear Nuevo</option>
                            {this.state.Grupos.map((grupo,index)=><option key={index} value={grupo}>{grupo}</option>)}
                        </select>
                        {this.state.seleccionGrupo!==''&&<div>
                            <button onClick={this.eliminarG.bind(this)}>Eliminar</button>
                        </div>}<br/>
                        <input id="grupo" className="hiddenNone"  onBlur={this.agregarG.bind(this)} />
                    </div>
                    <div className="flex">
                         {this.state.boolGrupo && <div>
                            <button type="button" onClick={this.visualizar.bind(this)}>visualizar</button>
                            <button type="button" onClick={this.crearactividad.bind(this)}>Crear Actividad</button>
                            {this.state.Gantt.map((act,index)=><div key={index} className="flex">
                                <button id={index} onClick={this.eliminarA.bind(this)}>x</button>
                                <input defaultValue={act.nombre} id={index} onBlur={this.editarnombreA.bind(this)}/>
                                <ChevronRightIcon className="w-8" id={index} onClick={this.mostrartareas.bind(this)}/></div>)}
                         </div>}
                         {this.state.Tareas.length!==0 &&<div>
                            <button type="button" onClick={this.creartarea.bind(this)}>Crear Tarea</button>
                            {this.state.Tareas.map((tarea,index)=><div className="flex" key={index}>
                                <button id={index} onClick={this.eliminarT.bind(this)}>x</button>
                                <input defaultValue={tarea.nombre} id={index} onBlur={this.editarnombreT.bind(this)}/>
                                <ChevronRightIcon className="w-8" id={index} onClick={this.mostrartarea.bind(this)}/>
                            </div>)}
                            </div>}
                            <div>
                                {this.state.boolTarea && <div>
                                    <label>Responsable:</label><br/>
                                    <select defaultValue={this.state.Tarea['responsable']}  onChange={this.editarnombreR.bind(this)}>
                                        <option value="default" disabled hidden>Escoge un responsable</option>
                                        <option value="CN">Crear Nuevo</option>
                                        {this.state.Responsables.map((res,index)=><option key={index} value={res}>
                                            {res}
                                        </option>)}
                                    </select><br/>
                                    <input id="tareaResponsable" className="hiddenNone"  onBlur={this.agregarR.bind(this)} />
                                    <br/><label>Fecha de inicio:</label><br/>
                                    <input type="date" defaultValue={this.state.Tarea.FI} min={this.state.Tarea.FI} onChange={this.editarfechaI.bind(this)}/>
                                    <br/><label>Fecha Final:</label><br/>
                                    <input type="date" defaultValue={this.state.Tarea.FF} min={this.state.Tarea.FI} onChange={this.editarfechaF.bind(this)}/>
                                    <br/><label>Presupuesto:</label><br/>
                                    <input type="number" defaultValue={this.state.Tarea.presupuesto}  onBlur={this.editarpresupuesto.bind(this)}/>
                                    <br/><label>Progreso:</label><br/>
                                    <label><input type="radio" value='PA' checked={this.state.Tarea.completado==='PA'} onChange={this.editarprogreso.bind(this)}/>Pendiente de asignar</label>
                                    <label><input type="radio" value={0} checked={this.state.Tarea.completado===0} onChange={this.editarprogreso.bind(this)}/> 0%</label>
                                    <label><input type="radio" value={25} checked={this.state.Tarea.completado===25} onChange={this.editarprogreso.bind(this)}/> 25%</label>
                                    <label><input type="radio" value={50} checked={this.state.Tarea.completado===50} onChange={this.editarprogreso.bind(this)}/> 50%</label>
                                    <label><input type="radio" value={75} checked={this.state.Tarea.completado===75} onChange={this.editarprogreso.bind(this)}/> 75%</label>
                                    <label><input type="radio" value={100} checked={this.state.Tarea.completado===100} onChange={this.editarprogreso.bind(this)}/> 100%</label>
                                </div>}
                            </div>
                    </div>
                </div>
                {this.state.Series.length!==0 && <Chart options={this.state.options} series={this.state.Series} type="rangeBar"  height={450} />
                    }                    
                </div>
            </div>
        );
    }
}
export default DireccionEstrategica;