import React from "react";
import {Link} from "react-router-dom";
import imagen from "../../assets/convocatorias.jpeg";


const ItemConvocatoria = (props) => {
  const {sku, title, description, sponsor_img } = props.data; 
  return (
    <Link to={`/convocatorias/${sku}`}>
      <div className="flex space-x-4 rounded-lg shadow-lg p-2 pr-4 max-w-lg bg-white">
        <div>
          <div className="relative w-32 aspect-w-3 aspect-h-4">
            <img
              src={sponsor_img}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=imagen;
              }}
              alt="convocatorias"
              className="absolute object-cover object-center w-full h-full rounded-lg"
            />
          </div>
        </div>
        <div className="">
          <p className="text-xl font-bold">
            {title}
          </p>
          <p className="text-sm font-normal">
            {description}
          </p>
          <br />
          <p className="text-sm font-normal">
            Fecha de Termino{" "}
            <span className="font-bold">05 - 07 - 2021</span>
          </p>
          <p className="text-sm font-normal">
            Participantes <span className="font-bold">18</span>
          </p>
          <div className="flex justify-between space-x-4 items-center">
            <p className="text-sm font-bold">Descargar Requisitos</p>
            <button className="text-sm font-bold flex items-center space-x-2 px-4 py-2 bg-gradient-to-r from-yellow-400 to-yellow-200 rounded-full">
                <span>Aplicar</span>
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10c5.52,0,10-4.48,10-10C22,6.48,17.52,2,12,2z M7,8c0-0.55,0.45-1,1-1h5 c0.55,0,1,0.45,1,1v0c0,0.55-0.45,1-1,1H8C7.45,9,7,8.55,7,8L7,8z M7,11c0-0.55,0.45-1,1-1h5c0.55,0,1,0.45,1,1v0c0,0.55-0.45,1-1,1 H8C7.45,12,7,11.55,7,11L7,11z M10,14c0,0.55-0.45,1-1,1H8c-0.55,0-1-0.45-1-1v0c0-0.55,0.45-1,1-1h1C9.55,13,10,13.45,10,14L10,14z M18.29,14.12l-3.54,3.54c-0.39,0.39-1.02,0.39-1.41,0l-1.41-1.41c-0.39-0.39-0.39-1.02,0-1.41c0.39-0.39,1.02-0.39,1.41,0 l0.71,0.71l2.83-2.83c0.39-0.39,1.02-0.39,1.41,0C18.68,13.1,18.68,13.73,18.29,14.12z"/></svg>
            </button>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ItemConvocatoria;