import React from 'react';
import './styles/academycursocard.css'
import imagen from '../../assets/img-startcups-inc.png';
import imagen2 from '../../assets/icon-app.png';
import { PlayIcon,ChevronRightIcon } from '@heroicons/react/solid';
const Academycursocard = (props) => {
        
    return (
      <div className="grid justify-items-center w-40 border border-gray-200 shadow-md rounded-md">
        <span className="playback inline-block"><img src={imagen} alt="img" className=""/><PlayIcon className="play relative float-right w-8 -mt-8 " /></span>
        <div className="flex items-center space-x-2 p-2">
            <img src={imagen2} alt="img2" className="w-6 flex-shrink-0"/>
            <div className="info">
                Tu tecno Emprendimiento
            </div>
            <div className="ver flex-shrink-0">
                <span className="inline">Ver <ChevronRightIcon className="w-2 inline"/></span>
            </div>
        </div>  
      </div>
    );
  };
  
  export default Academycursocard;