import React from 'react';
import './styles/cardproyecto.css';
import imagen from '../../assets/img-sprouders-inc-06.png';
import imagen2 from '../../assets/alejandro-foto.png';
import ProgressBar from '../ProyectosC/progressbar';
import { StarIcon,DotsHorizontalIcon } from '@heroicons/react/solid';
const Cardproyecto= (props) => {
        
    return (
      <div className="grid grid-cols-4 mt-4 shadow-md rounded-lg p-2 h-40 bg-white ml-8 mr-18 w-fit">

          <div className="flex justify-center">
              <img src={imagen} alt="img" className="rounded-2xl object-scale-down h-24 mt-6"/>
          </div>

          <div className="col-span-1">
                <div className="proyecto mt-7">
                  Proyecto:
                </div>
              
                <div className="nombreproyecto mt-0.5">
                  Sprouders
                </div>
              
                <div className="autor mt-3.5">
                  Creado por:
                </div>
              
                <div className="nombreautor mt-1.5 flex">
                <img src={imagen2} alt="img" className="object-scale-down h-5 rounded-md mr-2"/>
                  <div className='text-gray-500 mt-1'>
                   Alejandro Lomas
                  </div>
                </div>
          </div>

          <div className="autor ml-2 mt-20 pt-6 w-fit">
                <div className="flex">
                  <span className="mr-2">Avance</span> <ProgressBar bgcolor="#fecd03" completed="60"/> <span className="ml-2">60%</span>
                </div>
          </div>

          <div className="grid grid-cols-2 w-fit justify-content-end">
              <div className="flex justify-end mt-3">
                
              </div>
              <div className="flex justify-end">
                <StarIcon className="w-5 h-6 mr-2 p-0.5 mt-1.5" color="#DFDEDE"/>
                <DotsHorizontalIcon className="w-5 h-6 shadow-lg rounded-md p-0.5 mt-1.5" color="#fecd03"/>
              </div>

              <div className="flex fecha inline  pt-9">
                Creado 12/OCT/2020
              </div>

          </div>
      </div>
    );
  };
  
  export default Cardproyecto;
