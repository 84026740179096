import React from "react";
import { Outlet, Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/iconos/logo-startcups.svg";
import profile from "../assets/profile.png";
import "./styles/Home.css";
import {
  MenuIcon,
  SearchIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import { MailIcon, BellIcon } from "@heroicons/react/outline";

import { ReactComponent as ComunidadAicon } from "../assets/iconos/icono-comunidad-activo.svg";
import { ReactComponent as Comunidadicon } from "../assets/iconos/icono-comunidad.svg";
import { ReactComponent as Academiaicon } from "../assets/iconos/icono-academia.svg";
import { ReactComponent as AcademiaAicon } from "../assets/iconos/icono-academia-activo.svg";
import { ReactComponent as Proyectosicon } from "../assets/iconos/icono-proyectos.svg";
import { ReactComponent as ProyectosAicon } from "../assets/iconos/icono-proyectos-activo.svg";
import { ReactComponent as Inversionesicon } from "../assets/iconos/icono-inversiones.svg";
import { ReactComponent as InversionesAicon } from "../assets/iconos/icono-inversiones-activo.svg";
import { ReactComponent as Eventosicon } from "../assets/iconos/icono-eventos.svg";
import { ReactComponent as EventosAicon } from "../assets/iconos/icono-eventos-activo.svg";
import { ReactComponent as Convocatoriasicon } from "../assets/iconos/icono-convocatorias.svg";
import { ReactComponent as ConvocatoriasAicon } from "../assets/iconos/icono-convocatorias-activo.svg";
import { ReactComponent as Marketplaceicon } from "../assets/iconos/icono-marketplace.svg";
import { ReactComponent as MarketplaceAicon } from "../assets/iconos/icono-marketplace-activo.svg";

function Home() {
  
  return (
    <div className="relative min-h-screen md:flex">
      <div className="sidemenu1 flex justify-between md:hidden">
        <Link to="/">
          <Logo className="w-50 h-20 ml-4" />
        </Link>
        <button
          className="p-4 focus:outline-none focus:bg-gray-100"
          onClick={(e) => {
            const sidemenu = document.querySelector(".sidemenu");
            sidemenu.classList.toggle("-translate-x-full");
          }}
        >
          <MenuIcon className="w-8 h-8" />
        </button>
      </div>
      <div className="sidemenu bg-gray-600 w-64 md:w-48 space-y-6 absolute inset-y-0 left-0 transform -translate-x-full md:relative overflow-x-hidden md:translate-x-0 transition duration-200 ease-in-out">
        <Link
          to="/"
          className="flex items-center space-x-2 ml-4 mt-4"
          onClick={() => {
            var options = document.getElementsByClassName("navoptionsactive");
            var icons = document.getElementsByClassName("iconH");
            var icons2 = document.getElementsByClassName("off");
            for (var i = 0; i < options.length; i++) {
              options[i].classList.replace("navoptionsactive", "navoptions");
            }
            for (i = 0; i < icons.length; i++) {
              icons[i].classList.replace("navicons", "hidden");
            }
            for (i = 0; i < icons2.length; i++) {
              icons2[i].classList.replace("hidden", "navicons");
            }
            document
              .getElementById("comunidad")
              .classList.replace("navoptions", "navoptionsactive");
            document
              .getElementById("comunidadAicon")
              .classList.replace("hidden", "navicons");
            document
              .getElementById("comunidadicon")
              .classList.replace("navicons", "hidden");
          }}
        >
          <Logo className="m-auto mr-14 w-40 h-20" />
        </Link>
        <nav className="group">
          <Link
            to="/"
            id="comunidad"
            className="navoptionsactive"
            onClick={() => {
              var options = document.getElementsByClassName("navoptionsactive");
              var icons = document.getElementsByClassName("iconH");
              var icons2 = document.getElementsByClassName("off");
              for (var i = 0; i < options.length; i++) {
                options[i].classList.replace("navoptionsactive", "navoptions");
              }
              for (i = 0; i < icons.length; i++) {
                icons[i].classList.replace("navicons", "hidden");
              }
              for (i = 0; i < icons2.length; i++) {
                icons2[i].classList.replace("hidden", "navicons");
              }
              document
                .getElementById("comunidad")
                .classList.replace("navoptions", "navoptionsactive");
              document
                .getElementById("comunidadAicon")
                .classList.replace("hidden", "navicons");
              document
                .getElementById("comunidadicon")
                .classList.replace("navicons", "hidden");
            }}
          >
            <ComunidadAicon id="comunidadAicon" className="navicons iconH" />
            <Comunidadicon id="comunidadicon" className="hidden iconH off" />
            Comunidad
          </Link>
          <Link
            to="/academia"
            id="academia"
            className="navoptions"
            onClick={() => {
              var options = document.getElementsByClassName("navoptionsactive");
              var icons = document.getElementsByClassName("iconH");
              var icons2 = document.getElementsByClassName("off");
              for (var i = 0; i < options.length; i++) {
                options[i].classList.replace("navoptionsactive", "navoptions");
              }
              for (i = 0; i < icons.length; i++) {
                icons[i].classList.replace("navicons", "hidden");
              }
              for (i = 0; i < icons2.length; i++) {
                icons2[i].classList.replace("hidden", "navicons");
              }
              document
                .getElementById("academia")
                .classList.replace("navoptions", "navoptionsactive");
              document
                .getElementById("academiaaicon")
                .classList.replace("hidden", "navicons");
              document
                .getElementById("academiaicon")
                .classList.replace("navicons", "hidden");
            }}
          >
            <AcademiaAicon id="academiaaicon" className="hidden iconH" />{" "}
            <Academiaicon id="academiaicon" className="navicons iconH off" />
            Academia
          </Link>
          <Link
            to="/startups"
            id="startcups"
            className="navoptions"
            onClick={() => {
              var options = document.getElementsByClassName("navoptionsactive");
              var icons = document.getElementsByClassName("iconH");
              var icons2 = document.getElementsByClassName("off");
              for (var i = 0; i < options.length; i++) {
                options[i].classList.replace("navoptionsactive", "navoptions");
              }
              for (i = 0; i < icons.length; i++) {
                icons[i].classList.replace("navicons", "hidden");
              }
              for (i = 0; i < icons2.length; i++) {
                icons2[i].classList.replace("hidden", "navicons");
              }
              document
                .getElementById("startcups")
                .classList.replace("navoptions", "navoptionsactive");
              document
                .getElementById("proyectosAicon")
                .classList.replace("hidden", "navicons");
              document
                .getElementById("proyectosicon")
                .classList.replace("navicons", "hidden");
            }}
          >
            <ProyectosAicon id="proyectosAicon" className="hidden iconH" />
            <Proyectosicon id="proyectosicon" className="navicons iconH off" />
            Mis Proyectos
          </Link>
          <Link
            to="/inversiones"
            id="inversiones"
            className="navoptions"
            onClick={() => {
              var options = document.getElementsByClassName("navoptionsactive");
              var icons = document.getElementsByClassName("iconH");
              var icons2 = document.getElementsByClassName("off");
              for (var i = 0; i < options.length; i++) {
                options[i].classList.replace("navoptionsactive", "navoptions");
              }
              for (i = 0; i < icons.length; i++) {
                icons[i].classList.replace("navicons", "hidden");
              }
              for (i = 0; i < icons2.length; i++) {
                icons2[i].classList.replace("hidden", "navicons");
              }
              document
                .getElementById("inversiones")
                .classList.replace("navoptions", "navoptionsactive");
              document
                .getElementById("inversionesAicon")
                .classList.replace("hidden", "navicons");
              document
                .getElementById("inversionesicon")
                .classList.replace("navicons", "hidden");
            }}
          >
            <InversionesAicon id="inversionesAicon" className="hidden iconH" />{" "}
            <Inversionesicon
              id="inversionesicon"
              className="navicons iconH off"
            />
            Mis Inversiones
          </Link>
          <Link
            to="/eventos"
            id="eventos"
            className="navoptions"
            onClick={() => {
              var options = document.getElementsByClassName("navoptionsactive");
              var icons = document.getElementsByClassName("iconH");
              var icons2 = document.getElementsByClassName("off");
              for (var i = 0; i < options.length; i++) {
                options[i].classList.replace("navoptionsactive", "navoptions");
              }
              for (i = 0; i < icons.length; i++) {
                icons[i].classList.replace("navicons", "hidden");
              }
              for (i = 0; i < icons2.length; i++) {
                icons2[i].classList.replace("hidden", "navicons");
              }
              document
                .getElementById("eventos")
                .classList.replace("navoptions", "navoptionsactive");
              document
                .getElementById("eventosAicon")
                .classList.replace("hidden", "navicons");
              document
                .getElementById("eventosicon")
                .classList.replace("navicons", "hidden");
            }}
          >
            <EventosAicon id="eventosAicon" className="hidden iconH" />
            <Eventosicon id="eventosicon" className="navicons iconH off" />
            Eventos
          </Link>
          <Link
            to="/convocatorias"
            id="convocatorias"
            className="navoptions"
            onClick={() => {
              var options = document.getElementsByClassName("navoptionsactive");
              var icons = document.getElementsByClassName("iconH");
              var icons2 = document.getElementsByClassName("off");
              for (var i = 0; i < options.length; i++) {
                options[i].classList.replace("navoptionsactive", "navoptions");
              }
              for (i = 0; i < icons.length; i++) {
                icons[i].classList.replace("navicons", "hidden");
              }
              for (i = 0; i < icons2.length; i++) {
                icons2[i].classList.replace("hidden", "navicons");
              }
              document
                .getElementById("convocatorias")
                .classList.replace("navoptions", "navoptionsactive");
              document
                .getElementById("convocatoriasAicon")
                .classList.replace("hidden", "navicons");
              document
                .getElementById("convocatoriasicon")
                .classList.replace("navicons", "hidden");
            }}
          >
            <ConvocatoriasAicon
              id="convocatoriasAicon"
              className="hidden iconH"
            />
            <Convocatoriasicon
              id="convocatoriasicon"
              className="navicons iconH off"
            />
            Convocatorias
          </Link>
          <a
            href="https://marketplace.startcups.com/" 
            target="_blank"
            id="marketplace"
            rel="noopener noreferrer"
            className="navoptions"
            onClick={() => {/*
              var options = document.getElementsByClassName("navoptionsactive");
              var icons = document.getElementsByClassName("iconH");
              var icons2 = document.getElementsByClassName("off");
              for (var i = 0; i < options.length; i++) {
                options[i].classList.replace("navoptionsactive", "navoptions");
              }
              for (i = 0; i < icons.length; i++) {
                icons[i].classList.replace("navicons", "hidden");
              }
              for (i = 0; i < icons2.length; i++) {
                icons2[i].classList.replace("hidden", "navicons");
              }
              document
                .getElementById("marketplace")
                .classList.replace("navoptions", "navoptionsactive");
              document
                .getElementById("marketplaceAicon")
                .classList.replace("hidden", "navicons");
              document
                .getElementById("marketplaceicon")
                .classList.replace("navicons", "hidden");
            */}}
          >
            <MarketplaceAicon id="marketplaceAicon" className="hidden iconH" />
            <Marketplaceicon
              id="marketplaceicon"
              className="navicons iconH off"
            />
            Marketplace
          </a>
          
        </nav>
      </div>
      <div className="flex-1 font-bold text-gray-800 flex flex-col">
        <div className="inline flex justify-between border-b-2 border-gray-300">
          <div className="flex mt-4 ml-4 mb-4">
            <SearchIcon className="ml-2 w-8 h-5 text-gray-400" />
            <input
              type="text"
              placeholder="Buscar"
              id="buscador"
              className="outline-none text-base ml-2 w-24 h-6 md:w-60 lg:w-80"
            />
          </div>
          <div className="flex mt-2">
            <MailIcon className="w-8 h-6 text-gray-400 mr-2 mt-2.5" />
            <BellIcon className="w-8 h-6 text-gray-400 mt-2.5" />
            <div className="dropdown mr-24">
              <button className="text-gray-700 font-semibold px-4 rounded inline-flex items-center ">
                <img
                  src={profile}
                  alt=""
                  className="py-0 rounded-full h-8 w-8 mt-1.5 items-center justify-center"
                />
                <ChevronDownIcon className="fill-current h-4 w-4" />
              </button>
              <ul className="dropdown-menu absolute hidden text-gray-700 pt-1">
                <li className="">
                  <Link
                    to=""
                    className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  >
                    opción 1
                  </Link>
                </li>
                <li className="">
                  <Link
                    to=""
                    className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  >
                    opción 2
                  </Link>
                </li>
                <li className="">
                  <Link
                    to=""
                    className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  >
                    opción 3
                  </Link>
                </li>
              </ul>
            </div>
            -
          </div>
        </div>

        <div className="flex-auto">
          <div className="bggris flex-col h-full">
            <div className="relative h-full">
              <div className="absolute w-full h-full overflow-y-auto">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
