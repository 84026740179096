import { compose,applyMiddleware,legacy_createStore as createStore } from 'redux';
import { rootReducer } from '../reducers/rootReducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const saga=createSagaMiddleware()

export const store=createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(saga)
    )
);

saga.run(rootSaga)

