import { Component } from "react";
import './styles/Mercadotecnia.css'
import {CheckCircleIcon,ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'
import ImagenEmocional from "../../../assets/MKTemocional.png"
import ImagenFuncional from "../../../assets/MKTfuncional.png"

class Mercadotecnia extends Component{
    constructor(props){
        super(props);
        this.state={
            B:{
                p1:0,
                p2:0,
                p3:0
            },
            Brespuestas:{//Guardar en base de datos
                p1:'',
                p2:'',
                p3:''
            },
            P:{
                p1:0,
                p2:0,
                p3:0,
                p4:0            
            },
            Prespuestas:{//Guardar en base de datos
                p1:'',
                p2:'',
                p3:'',
                p4:''
            },
            GM:{//Guardar en base de datos
                E1:'',E2:'',E3:'',E4:'',E5:'',E6:'',E7:'',E8:'',E9:'',E10:'',E11:'',E12:'',
                F1:'',F2:'',F3:'',F4:'',F5:'',F6:'',F7:'',F8:'',F9:'',F10:'',F11:'',F12:'',
            },
            propuestas:[],
            marcas:true,
        }
    }
    cambio(e){
        let gm=this.state.GM;
        gm[e.target.id]=e.target.value;
        this.setState({GM:gm});
        console.log(this.state.GM);
        console.log(e);
    }
    generar(e){
        let gm=this.state.GM
        this.setState({propuestas:[]})
        let propuestas=[];
        for(let i=1;i<13;i++){
            if(gm['F'+i]!=="" && gm['E'+i]!==""){
                let ffinal=gm['F'+i].charAt(gm['F'+i].length-3)+gm['F'+i].charAt(gm['F'+i].length-2)+gm['F'+i].charAt(gm['F'+i].length-1);
                let finicio=gm['F'+i].substr(0,3);
                let efinal=gm['E'+i].charAt(gm['E'+i].length-3)+gm['E'+i].charAt(gm['E'+i].length-2)+gm['E'+i].charAt(gm['E'+i].length-1);
                let einicio=gm['E'+i].substr(0,3);
                let emocion=gm["E"+i];
                let funcion=gm["F"+i];
                propuestas.push(emocion+ffinal);
                propuestas.push(finicio+emocion);
                propuestas.push(funcion+efinal);
                propuestas.push(einicio+funcion);
            }
        }
        this.setState({propuestas:propuestas});
    }
    render(){
        return(
            <div className="ml-8">
                <div className="">
                    <div className="title">

                        Mercadotecnia
                    </div>

                    <div className="flex">

                    <div className="subtitle mr-10 active mt-4 w-26" id="CM" onClick={()=>{
                        document.getElementById('rectB').classList.add('hiddenNone');
                        document.getElementById('B').classList.remove('active');
                        document.getElementById('CM').classList.add('active');
                        document.getElementById('rectCM').classList.remove('hiddenNone');
                        document.getElementById('BQuestions').classList.add('hiddenNone');
                        document.getElementById('CMQuestions').classList.remove('hiddenNone');
                        document.getElementById('rectP').classList.add('hiddenNone');
                        document.getElementById('P').classList.remove('active');
                        document.getElementById('PQuestions').classList.add('hiddenNone');
                        this.setState({marcas:true})
                    }}>
                        Naming
                        <div className="rectangle" id="rectCM"></div>
                    </div>

                    <div className="subtitle ml-10 mt-4 w-26"  id="B" onClick={()=>{
                        document.getElementById('rectCM').classList.add('hiddenNone');
                        document.getElementById('CM').classList.remove('active');
                        document.getElementById('B').classList.add('active');
                        document.getElementById('rectB').classList.remove('hiddenNone');
                        document.getElementById('BQuestions').classList.remove('hiddenNone');
                        document.getElementById('CMQuestions').classList.add('hiddenNone');
                        document.getElementById('rectP').classList.add('hiddenNone');
                        document.getElementById('P').classList.remove('active');
                        document.getElementById('PQuestions').classList.add('hiddenNone');
                        this.setState({marcas:false})
                    }}>
                        Branding
                        <div className="rectangle hiddenNone" id="rectB"></div>
                    </div>
                    <div className="subtitle ml-10  mt-4 w-26" id="P" onClick={()=>{
                        document.getElementById('rectB').classList.add('hiddenNone');
                        document.getElementById('B').classList.remove('active');
                        document.getElementById('P').classList.add('active');
                        document.getElementById('rectP').classList.remove('hiddenNone');
                        document.getElementById('CMQuestions').classList.add('hiddenNone');
                        document.getElementById('PQuestions').classList.remove('hiddenNone');
                        document.getElementById('rectCM').classList.add('hiddenNone');
                        document.getElementById('CM').classList.remove('active');
                        document.getElementById('BQuestions').classList.add('hiddenNone');
                        this.setState({marcas:false})
                    }}>
                        Posicionamiento
                        <div className="rectangle hiddenNone" id="rectP"></div>
                    </div>
                    
                    </div>
                   
                </div>

                <div className='lineamenuMKT'></div> 

                <div className="mt-4 text-sm"  id="CMQuestions">
                    <div className="bg-gray-100 rounded-xl pb-2">
                        <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e) => {
                            document.getElementsByClassName("accordion")[0].classList.toggle('colapse');
                            var prueba = document.querySelectorAll('.accordionarrow');
                            for (let i = 0; i < 2; i++) {
                                prueba[i].classList.toggle('hiddenNone');
                            }
                        }}>NAMING<ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                        <div className="flex  accordion">
                            <div className="mr-4"><iframe src="https://player.vimeo.com/video/392354235?h=fe82cbb732" className="rounded-md" width="160" height="141" title="PropositoVida" frameBorder="0" allowFullScreen></iframe></div>
                            <div className="text-sm text-gray-400 mt-6">El naming es el proceso creativo donde un compendio de técnicas sociológicas, psicológicas y de marketing se usan para nombrar algo, <br/>buscando una diferenciación clara y concisa de lo ya existente en el mercado y una cohesión de la marca con los valores y el hacer de una compañía.</div>
                        </div>
                    </div> 
                    <div className="grid grid-cols-2">
                        <div className="text-gray-600 italic font-light text-xs">
                        Agrega palabras emocionales relacionadas a tu empresa <br/>(Ej. Start, fantástico, happy, delicioso, lovely, etc.)
                        </div>

                        <div className="ml-8 text-gray-600 italic font-light text-xs">
                        Agrega palabras funcionales relacionadas a tu empresa <br/>(Ej. Cups, automóvil, wallet, finanzas, pet, shop, etc.)</div>
                    </div><br/>

                    <div className="grid grid-cols-2">
                        <div className="">
                            <div className="flex justify-left">
                            <img src={ImagenEmocional} className="w-10 h-10 mr-3 -mt-3" alt="img-user" id="¿Qué escucha el usuario?" />PALABRAS EMOCIONALES
                            </div>
                        </div>
                        <div className="ml-8">
                            <div className="flex justify-left">
                            <img src={ImagenFuncional} className="w-10 h-10 mr-3 -mt-3" alt="img-user" id="¿Qué escucha el usuario?" />PALABRAS FUNCIONALES
                            </div>
                        </div>
                    </div>

                    <div className="flex mt-4 ">
                        <div className="flex">
                            <div className="mr-2"><input type="text" id="E1" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                            <div className="mr-2"><input type="text" id="E2" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                            <div className=""><input type="text" id="E3" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                        </div>

                        <div className="flex pl-20">
                            <div className=" mr-2"><input type="text" id="F1" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div> 
                            <div className="mr-2"><input type="text" id="F2" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div>
                            <div className="mr-2"><input type="text" id="F3" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div>
                        </div>
                    </div>

                    <div className="flex mt-4">
                        <div className="flex">
                            <div className="mr-2"><input type="text" id="E4" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                            <div className="mr-2"><input type="text" id="E5" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                            <div className=""><input type="text" id="E6" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                        </div>

                        <div className="flex pl-20">
                            <div className="mr-2"><input type="text" id="F4" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div> 
                            <div className="mr-2"><input type="text" id="F5" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div>
                            <div className="mr-2"><input type="text" id="F6" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div>
                        </div>
                    </div>

                    <div className="flex mt-4">
                        <div className="flex">
                            <div className="mr-2"><input type="text" id="E7" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                            <div className="mr-2"><input type="text" id="E8" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                            <div className=""><input type="text" id="E9" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                        </div>

                        <div className="flex pl-20">
                            <div className="mr-2"><input type="text" id="F7" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div> 
                            <div className="mr-2"><input type="text" id="F8" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div>
                            <div className="mr-2"><input type="text" id="F9" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div>
                        </div>
                    </div>

                    <div className="flex mt-4">
                        <div className="flex">
                            <div className="mr-2"><input type="text" id="E10" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                            <div className="mr-2"><input type="text" id="E11" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                            <div className=""><input type="text" id="E12" className="tableinput pl-2 inputID" placeholder="Emoción" onChange={this.cambio.bind(this)}/></div>
                        </div>

                        <div className="flex pl-20">
                            <div className="mr-2"><input type="text" id="F10" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div> 
                            <div className="mr-2"><input type="text" id="F11" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div>
                            <div className="mr-2"><input type="text" id="F12" className="tableinput pl-2 inputID" placeholder="Función" onChange={this.cambio.bind(this)}/></div>
                        </div>
                    </div>      

                    <div className="-ml-10 mt-6 grid justify-center">
                        <button className="w-60 h-10 rounded-lg bg-yellow-400 font-bold" onClick={this.generar.bind(this)}>Generar Marcas</button>
                    </div>
                    <div className="mt-10"></div>
                </div>

                <div className="mt-4 hiddenNone"  id="BQuestions">

                <div className="bg-gray-100 rounded-xl mt-4 mb-4 pb-4">
                    <div className="text-gray-600 pt-4 px-4 mb-1 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[1].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=2;i<4;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                         }}>BRANDING <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 accordion">El branding, también conocido como gestión de marca, está conformado por un conjunto de acciones relacionadas con el posicionamiento, el propósito y los valores de una marca. Su objetivo es crear conexiones conscientes e inconscientes con el público para influir en sus decisiones de compra. En otras palabras, el branding se centra en hacer conocida y deseada una marca, así como en ejercer una imagen positiva en la mente y el corazón de los consumidores.</div>
                </div> 

                    <div>
                        <label>Descriptivo técnico de la marca</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.Brespuestas.p1} placeholder="Ej. Incubator, Universidad Virtual, Café Tostado en Grano, Red Social para Emprendedores, etc." className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('Bp1').classList.add('complete');
                                }else{
                                    document.getElementById('Bp1').classList.remove('complete');
                                }
                                var p1=this.state.B
                                p1.p1=e.target.value.length;
                                var b=this.state.Brespuestas;
                                b.p1=e.target.value;
                                this.setState({Brespuestas:b})
                                this.setState({B:p1})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconMKT" id="Bp1" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.B.p1}/700
                        </div>
                    </div>

                    <div className="mt-4">
                        <label>Slogan</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.Brespuestas.p2}  placeholder="Ej. Innovation starts with a cup of coffee, A la parrilla saber mejor, Just Do It, Conneting People, etc." className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('Bp2').classList.add('complete');
                                }else{
                                    document.getElementById('Bp2').classList.remove('complete');
                                }
                                var p2=this.state.B
                                p2.p2=e.target.value.length;
                                var b=this.state.Brespuestas;
                                b.p2=e.target.value;
                                this.setState({Brespuestas:b})
                                this.setState({B:p2})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconMKT" id="Bp2" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.B.p2}/700
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>Frase publicitaria</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.Brespuestas.p3}  placeholder="Ej. Your next startup starts here!, Conecta con los campeones y descarga nuestra app ya!, etc." className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('Bp3').classList.add('complete');
                                }else{
                                    document.getElementById('Bp3').classList.remove('complete');
                                }
                                var p3=this.state.B
                                p3.p3=e.target.value.length;
                                var b=this.state.Brespuestas;
                                b.p3=e.target.value;
                                this.setState({Brespuestas:b})
                                this.setState({B:p3})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconMKT" id="Bp3" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.B.p3}/700
                        </div>
                    </div>                    
                </div>

                <div className="mt-4 hiddenNone"  id="PQuestions">

                <div className="bg-gray-100 rounded-xl mt-4 mb-4 pb-4">
                    <div className="text-gray-600 pt-4 px-4 text-lg flex mb-1"  onClick={(e)=>{
                         document.getElementsByClassName("accordion")[2].classList.toggle('colapse');                         
                         var prueba=document.querySelectorAll('.accordionarrow');
                         for(let i=4;i<6;i++){
                            prueba[i].classList.toggle('hiddenNone');
                         }
                         }}>POSICIONAMIENTO <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 accordion">El posicionamiento de marca representa el lugar propio que ocupa una marca en la mente de los consumidores, diferenciándola así de sus competidores. Debe definir lo que el cliente puede esperar de ella y lo que representa.</div>
                </div> 

                    <div>
                        <label>Describe a tu competencia directa</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.Prespuestas.p1}  placeholder="Ej. Uber, Cabify, Didi y Yaxi" className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('Pp1').classList.add('complete');
                                }else{
                                    document.getElementById('Pp1').classList.remove('complete');
                                }
                                var p1=this.state.P
                                p1.p1=e.target.value.length;
                                var p=this.state.Prespuestas;
                                p.p1=e.target.value;
                                this.setState({Prespuestas:p})
                                this.setState({P:p1})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconMKT" id="Pp1" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.P.p1}/700
                        </div>
                    </div>

                    <div>
                        <label>Describe a tu competencia indirecta</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.Prespuestas.p2}  placeholder="Ej. Taxi, metro, autobus y combi" className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('Pp2').classList.add('complete');
                                }else{
                                    document.getElementById('Pp2').classList.remove('complete');
                                }
                                var p2=this.state.P
                                p2.p2=e.target.value.length;
                                var p=this.state.Prespuestas;
                                p.p2=e.target.value;
                                this.setState({Prespuestas:p})
                                this.setState({P:p2})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconMKT" id="Pp2" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.P.p2}/700
                        </div>
                    </div>


                    <div className="mt-4">
                        <label>Propuesta única de venta (USP)</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.Prespuestas.p3} placeholder="Ej. Plataforma digital de servicio de transportes privados exclusiva para mujeres." className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('Pp3').classList.add('complete');
                                }else{
                                    document.getElementById('Pp3').classList.remove('complete');
                                }
                                var p3=this.state.P
                                p3.p3=e.target.value.length;
                                var p=this.state.Prespuestas;
                                p.p3=e.target.value;
                                this.setState({Prespuestas:p})
                                this.setState({P:p3})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconMKT" id="Pp3" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.P.p3}/700
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>Ventaja competitiva sostenible (SCA)</label><br/>
                        <div className="flex justify-between mt-4">
                            <input id="inputID" type='text' defaultValue={this.state.Prespuestas.p4}  placeholder="Ej. Con el respaldo de más de 2,000 conductoras capacitadas y comprometidas con la seguridad de las mujeres." className="w-full p-5" onChange={(e)=>{
                                if(e.target.value.length!==0){
                                    document.getElementById('Pp4').classList.add('complete');
                                }else{
                                    document.getElementById('Pp4').classList.remove('complete');
                                }
                                var p4=this.state.P
                                p4.p4=e.target.value.length;
                                var p=this.state.Prespuestas;
                                p.p4=e.target.value;
                                this.setState({Prespuestas:p})
                                this.setState({P:p4})
                                }}/>
                            <div><CheckCircleIcon className="w-5 iconMKT" id="Pp4" /></div>
                        </div>
                        <div className="contador mt-1 m-3">
                            Caracteres {this.state.P.p4}/700
                        </div>
                    </div>                    
                </div>
                {this.state.marcas&&
                    <div>
                        <div className="grid justify-left -mt-6 text-lg">PROPUESTAS DE MARCA</div>
                        <div className="lineabajaMKT "></div>
                        <div className="grid grid-cols-4 gap-4 mt-2">
                        {this.state.propuestas.map(nombre=><div key={nombre} className="rounded-full h-8 marcas grid justify-center">{nombre}</div>)}
                        </div>
                        <div className="py-20"></div>
                    </div>
                    
                }  
            </div>
        );
    }
}
export default Mercadotecnia;
