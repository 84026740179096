import { Component,Fragment } from "react";
import { ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'
import './styles/Finanzas.css';
import Chart from 'react-apexcharts'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

class Finanzas extends Component{
    constructor(props){
        super(props);
        this.state={
            PF:{//Guardar en base datos objeto con array de objetos
               Años:[
                   {
                   numero:1,
                   alias:new Date().getFullYear(),
                   InvInicial:0,
                   InvInicialreal:0,
                   InvInicialmes:0,
                   InvInicialrealmes:0,
                   UPAnoanterior:0,
                   UPAnoanteriorreal:0,
                   UPAnoanteriormes:0,
                   UPAnoanteriorrealmes:0,
                    Inversiones:[],
                    Ventas:[],
                    Gastos:[],
                    Impuestos:[],
                    totalventas:0,
                    totalcostos:0,
                    totalgastos:0,
                    totalimpuestos:0,
                    totalrealventas:0,
                    totalrealcostos:0,
                    totalrealgastos:0,
                    totalrealimpuestos:0,
                    totalmesventas:0,
                    totalmescostos:0,
                    totalmesgastos:0,
                    totalmesimpuestos:0,
                    totalmesrealventas:0,
                    totalmesrealcostos:0,
                    totalmesrealgastos:0,
                    totalmesrealimpuestos:0,
                    },
                ],
            },
            SVE:{//Guardar en base de datos
                Inv:0,
                Ebitda:0.00,
                TD:15,
                VPN:0.00,
                VE:0.00,
                NC:0.00,
                PAEV:0.00,
                CCR:0.00,
                VMVC:0.00,
                VP:0.00,
                PED:0.00,
                PEM:0,
                ROI:0.00,
                ROIE:0.00,
                index:-1,
                limites:[],
                limite:0
            },
            crecimientoAnual:[0],
           anoseleccionado:{},
           seleccion:'',
           totales:[],
           PFselecciongasto:'',
           PFtotalgastos:0.0,
           PFGastos:[],
           ffañoseleccionado:{},
           ffmesseleccionado:1,
           ffsemanas:[],
           ffproductos:[],
           ffindex:0,
           ffseleccion:'',
           canoseleccionado:{},
           cmesseleccionado:1,
           options:{},
           series:[],
           options2:{},
           series2:[],
           todasemana:{Ventas:[],Gastos:[],Impuestos:[]},
           todassemanas:[],
           numDias:0,
           date:[]
        }
    }
    crearaño(){
        let años=this.state.PF.Años;
        let nuevoaño=años[años.length-1].numero+1;
        let alias=años[años.length-1].alias+1;
        let ultimoano=años.length-1
        let ventas=[];
        let gastos=[];
        let impuestos=[];
        let inversiones=[];
        for(let i=0;i<años[ultimoano].Ventas.length;i++){
            let producto={'nombre':años[ultimoano].Ventas[i].nombre,'totalunidades':0,'totalcosto':0,'totalprecio':0,info:[]}
            ventas.push(producto);
            for(let j=1;j<=12;j++){
                ventas[i].info.push({'mes':j,'unidades':0,'precio':0,'costo':0,CR:[],VR:[]})
            }
        }
        for(let i=0;i<años[ultimoano].Gastos.length;i++){
            let gasto={'nombre':años[ultimoano].Gastos[i].nombre,'totalcantidad':0,'tipo':años[ultimoano].Gastos[i].tipo,info:[]}
            gastos.push(gasto);
            for(let j=1;j<=12;j++){
                gastos[i].info.push({'mes':j,'cantidad':0,CR:[]})
            }
        }
        for(let i=0;i<años[ultimoano].Impuestos.length;i++){
            let impuesto={'nombre':años[ultimoano].Impuestos[i].nombre,'totalcantidad':0,'tipo':años[ultimoano].Impuestos[i].tipo,info:[]}
            impuestos.push(impuesto);
            for(let j=1;j<=12;j++){
                impuestos[i].info.push({'mes':j,'cantidad':0,CR:[]})
            }
        }
        let utilidadneta=años[años.length-1].UPAnoanterior+años[años.length-1].InvInicial+años[años.length-1].totalventas-años[años.length-1].totalcostos-Math.abs(años[años.length-1].totalgastos)-años[años.length-1].totalimpuestos;
        let utilidadnetareal=años[años.length-1].UPAnoanteriorreal+años[años.length-1].InvInicialreal+años[años.length-1].totalrealventas-años[años.length-1].totalrealcostos-Math.abs(años[años.length-1].totalrealgastos)-años[años.length-1].totalrealimpuestos;
        años.push({'numero':nuevoaño,'alias':alias,InvInicial:0,InvInicialreal:0,InvInicialmes:0,InvInicialrealmes:0,UPAnoanterior:utilidadneta,UPAnoanteriorreal:utilidadnetareal,UPAnoanteriormes:0,UPAnoanteriorrealmes:0,Inversiones:inversiones,'Ventas':ventas,'Gastos':gastos,'Impuestos':impuestos,
        'totalventas':0,'totalcostos':0,'totalgastos':0,'totalimpuestos':0,'totalrealventas':0,'totalrealcostos':0,'totalrealgastos':0,'totalrealimpuestos':0,
        'totalmesventas':0,'totalmescostos':0,'totalmesgastos':0,'totalmesimpuestos':0,'totalmesrealventas':0,'totalmesrealcostos':0,'totalmesrealgastos':0,'totalmesrealimpuestos':0});
        this.setState({[this.state.PF.Años]:años});
        let crecimiento=this.state.crecimientoAnual;
        crecimiento.push(0);
        this.setState({crecimientoAnual:crecimiento});
        this.setState({seleccion:''})
    }
    cambioalias(e){
        let anos=this.state.PF.Años;
        for(let i=0;i<anos.length;i++){
            if(anos[i].numero.toString()===e.target.id){
                anos[i].alias=e.target.value;
                break;
            }
        }
        this.setState({[this.state.PF.Años]:anos});
        this.setState({[this.state.FF.Años]:anos})
    }
    cambioInv(e){
        let numero=e.target.id;
        let anos=this.state.PF.Años;
        if(!isNaN(e.target.value)){
            for(let i=0;i<anos.length;i++){
                if(anos[i].numero===parseInt(numero)){
                    anos[i].InvInicial=parseFloat(e.target.value);
                }
            }
        }else{
            for(let i=0;i<anos.length;i++){
                if(anos[i].numero===parseInt(numero)){
                    anos[i].InvInicial=0;
                }
            }
        }

        for(let i=1;i<anos.length;i++){
            let upneta=anos[i-1].UPAnoanterior+anos[i-1].InvInicial+anos[i-1].totalventas-anos[i-1].totalcostos-Math.abs(anos[i-1].totalgastos)-anos[i-1].totalimpuestos;
            anos[i].UPAnoanterior=upneta;
            console.log(upneta)
        }
        this.setState({[this.state.PF.Años]:anos});
        
    }
    mostrarinversiones(e){
        this.setState({seleccion:''},()=>{
            let ano;
            for(let i=0;i<this.state.PF.Años.length;i++){
                ano=Object.assign({},this.state.PF.Años[i]);
                if(ano.numero.toString()===e.target.id){
                    break;
                }
            }
            this.setState({anoseleccionado:ano},()=>{
                this.setState({seleccion:'inversiones'});
            });
        });
        
        
    }
    mostrarventas(e){
        this.setState({seleccion:''},()=>{
            let ano={};
            for(let i=0;i<this.state.PF.Años.length;i++){
                ano=Object.assign({},this.state.PF.Años[i]);
                if(ano.numero.toString()===e.target.id){
                    break;
                }else{
                    ano={};
                }
            }
            this.setState({anoseleccionado:ano},()=>{
                this.setState({seleccion:'ventas'});
                this.setState({PFselecciongasto:''});
            });
        });
    }
    mostrarcostos(e){
        this.setState({seleccion:''},()=>{
            let ano;
            for(let i=0;i<this.state.PF.Años.length;i++){
                ano=this.state.PF.Años[i];
                if(ano.numero.toString()===e.target.id){
                    break;
                }
            }
            this.setState({anoseleccionado:ano},()=>{
                this.setState({seleccion:'costos'});
                this.setState({PFselecciongasto:''});
            });
        });
    }
    mostrargastos(e){
        this.setState({seleccion:''},()=>{
            let ano;
            for(let i=0;i<this.state.PF.Años.length;i++){
                ano=this.state.PF.Años[i];
                if(ano.numero.toString()===e.target.id){
                    break;
                }
            }
            this.setState({anoseleccionado:ano});
            this.setState({seleccion:'gastos'});
        });        
    }
    mostrarimpuestos(e){
        this.setState({seleccion:''},()=>{
            let ano;
            for(let i=0;i<this.state.PF.Años.length;i++){
                ano=this.state.PF.Años[i];
                if(ano.numero.toString()===e.target.id){
                    break;
                }
            }
            this.setState({anoseleccionado:ano});
            this.setState({seleccion:'impuestos'});
            this.setState({PFselecciongasto:''});
        });
        
        
    }
    crearinversion(){
        let año=this.state.anoseleccionado;
        let inversion={'nombre':'',totalcantidad:0,info:[]};
        for(let i=1;i<=12;i++){
            inversion.info.push({'mes':i,'cantidad':0,'cantidadreal':0,CR:[]})
        }
        año.Inversiones.push(inversion);
        this.setState({anoseleccionado:año});
        
    }
    cambioinversion(e){
        let año=this.state.anoseleccionado;
        let años=this.state.PF.Años;
        let nombre=e.target.id.split('-');
        if(nombre[1]==='t'){
            for(let i=0;i<año.Inversiones.length;i++){
                if(año.Inversiones[i].nombre===nombre[0]){
                    año.Impuestos[i].tipo=e.target.value;
                    break
                }
            }
        }else if(nombre[1]==='n'){
            for(let i=0;i<año.Inversiones.length;i++){
                if(año.Inversiones[i].nombre===nombre[0]){
                    año.Inversiones[i].nombre=e.target.value;
                    break
                }
            }
        }
        
        for(let i=0;i<años.length;i++){
            if(años[i].numero===año.numero){
                años[i]=año
                break;
            }
        }
        this.setState({anoseleccionado:año});
        this.setState({[this.state.PF.Años]:años});
    }
    actualizarinversion(e){
        let año=this.state.anoseleccionado;
        let años=this.state.PF.Años;
        let nombre=e.target.id.split('-');
        for(let i=0;i<año.Inversiones.length;i++){
            if(año.Inversiones[i].nombre===nombre[0]){
                for(let j=0;j<12;j++){
                    if(año.Inversiones[i].info[j].mes.toString()===nombre[1]){
                        año.Inversiones[i].info[j].cantidad=e.target.value;
                        break;
                    }
                }
                let totalcantidad=0
                for(let j=0;j<12;j++){
                    totalcantidad+=parseFloat(año.Inversiones[i].info[j].cantidad);
                }
                año.Inversiones[i].totalcantidad=parseFloat(totalcantidad.toFixed(2));
                break;
            }
        }
        año.InvInicial=0;
        for(let i=0;i<año.Inversiones.length;i++){
            for(let j=0;j<=11;j++){
                año.InvInicial+=parseFloat(año.Inversiones[i].info[j].cantidad);
            }
        }
        año.InvInicial=parseFloat(año.InvInicial.toFixed(2));
        for(let i=0;i<años.length;i++){
            if(años[i].numero===año.numero){
                años[i]=Object.assign({},año);
                break;
            }
        }
        this.setState({anoseleccionado:año});
        this.setState({[this.state.PF.Años]:años},()=>this.actualizarUPA());
    }
    crearproducto(){
        let año=this.state.anoseleccionado;
        let producto={'nombre':'','totalunidades':0,'totalcosto':0,'totalprecio':0,info:[]};
        for(let i=1;i<=12;i++){
            producto.info.push({'mes':i,'unidades':0,'precio':0,'costo':0,'precioreal':0,'unidadesreal':0,'costoreal':0,VR:[],CR:[]})
        }
        año.Ventas.push(producto);
        this.setState({anoseleccionado:año});
        
    }
    actualizarventas(e){
        let info=e.target.id.split('-');
        let ano=this.state.anoseleccionado;
        for(let i=0;i<ano.Ventas.length;i++){
            if(ano.Ventas[i].nombre===info[0]){
                for(let j=0;j<12;j++){
                    if(ano.Ventas[i].info[j].mes.toString()===info[1]){
                        if(info[2]==='u'){
                            let prueba=0;
                            prueba=parseFloat(e.target.value);
                            ano.Ventas[i].info[j].unidades=prueba;
                            break;
                        }else if(info[2]==='p'){
                            ano.Ventas[i].info[j].precio=e.target.value;
                            break;
                        }
                        else if(info[2]==='c'){
                            ano.Ventas[i].info[j].costo=e.target.value;
                            break;
                        }
                    }
                }
                let unidades=0;
                let totalprecio=0;
                let totalcosto=0;
                for(let j=0; j<12;j++){
                    unidades+=parseInt(ano.Ventas[i].info[j].unidades);
                    totalprecio+=parseFloat((ano.Ventas[i].info[j].unidades*ano.Ventas[i].info[j].precio));
                    totalcosto+=parseFloat((ano.Ventas[i].info[j].unidades*ano.Ventas[i].info[j].costo));
                }
                ano.Ventas[i].totalunidades=unidades;
                ano.Ventas[i].totalprecio=parseFloat(totalprecio.toFixed(2));
                ano.Ventas[i].totalcosto=parseFloat(totalcosto.toFixed(2));
                break;
            }
            
        }
        
        ano.totalventas=0;
        ano.totalcostos=0
        for(let i=0;i<ano.Ventas.length;i++){
            for(let j=0;j<=11;j++){
                ano.totalventas+=(ano.Ventas[i].info[j].unidades*ano.Ventas[i].info[j].precio)
                ano.totalcostos+=(ano.Ventas[i].info[j].unidades*ano.Ventas[i].info[j].costo)
            }
        }
        ano.totalventas=parseFloat(ano.totalventas.toFixed(2));
        ano.totalcostos=parseFloat(ano.totalcostos.toFixed(2));
        /*let PFtotalGastos=0;
        for(let i=0;i<ano.Gastos.length;i++){
            for(let j=0;j<ano.Gastos[i].info.length;j++){
                for(let k=0;k<ano.Gastos[i].info[j].CR.length;k++){
                    PFtotalGastos+=parseFloat(ano.Gastos[i].info[j].CR[k]);
                }
            }
        }
        PFtotalGastos-=ano.totalcostos;
        PFtotalGastos=parseFloat(PFtotalGastos.toFixed(2));
        ano.totalgastos=PFtotalGastos;
        this.setState({PFtotalGastos:PFtotalGastos})*/
        let años=this.state.PF.Años;
        for(let i=0;i<años.length;i++){
            if(años[i].numero===ano.numero){
                
                años[i]=Object.assign({},ano);
                break;
            }
        }
        let crecimiento=this.state.crecimientoAnual;
        for(let i=1;i<años.length;i++){
            crecimiento[i]=((años[i].totalventas/años[i-1].totalventas)*100).toFixed(2);
        }
        this.setState({[this.state.PF.Años]:años},()=>this.actualizarUPA())
        this.setState({anoseleccionado:ano})
        this.setState({crecimientoAnual:crecimiento});
        this.forceUpdate()
    }
    cambioventas(e){
        let año=this.state.anoseleccionado;
        let años=this.state.PF.Años;
        for(let i=0;i<año.Ventas.length;i++){
            if(año.Ventas[i].nombre===e.target.id){
                año.Ventas[i].nombre=e.target.value;
                break
            }
        }
        for(let i=0;i<años.length;i++){
            if(años[i].numero===año.numero){
                años[i]=año
                break;
            }
        }
        this.setState({anoseleccionado:año});
        this.setState({[this.state.PF.Años]:años}); 
    }
    creargasto(){
        let año=this.state.anoseleccionado;
        let seleccgasto=this.state.PFselecciongasto;
        let gasto={'nombre':'','totalcantidad':0,'tipo':seleccgasto,info:[]};
        for(let i=1;i<=12;i++){
            gasto.info.push({'mes':i,'cantidad':0,'cantidadreal':0,CR:[]})
        }
        año.Gastos.push(gasto);
        let PFGastos=this.state.PFGastos;
        PFGastos.push(gasto);
        this.setState({anoseleccionado:año});
        this.setState({PFGastos:PFGastos});
    }
    selecciongasto(e){
        this.setState({PFselecciongasto:e.target.value});
        let ano=this.state.anoseleccionado;
        let PFGastos=[];
        let PFtotalgastos=0;
        if(e.target.value==='Ver todos'){
            PFGastos=ano.Gastos;
            for(let i=0;i<ano.Gastos.length;i++){ 
                for(let j=0;j<ano.Gastos[i].info.length;j++){
                    PFtotalgastos+=parseFloat(ano.Gastos[i].info[j].cantidad);
                }
                if(ano.Gastos[i].tipo==='Inventarios/Costos de Venta'){
                    PFtotalgastos-=ano.totalcostos;
                }
            }
        }else{
            for(let i=0;i<ano.Gastos.length;i++){
                if(ano.Gastos[i].tipo===e.target.value){
                    PFGastos.push(ano.Gastos[i]);
                    for(let j=0;j<ano.Gastos[i].info.length;j++){
                        PFtotalgastos+=parseFloat(ano.Gastos[i].info[j].cantidad);
                    }
                }
            }
            if(e.target.value==='Inventarios/Costos de Venta'){
                PFtotalgastos-=ano.totalcostos;
            }
        }
        this.setState({PFGastos:PFGastos});
        this.setState({PFtotalgastos:PFtotalgastos});
        
    }
    cambiogasto(e){
        let año=this.state.anoseleccionado;
        let años=this.state.PF.Años;
        let nombre=e.target.id.split('-');
        if(nombre[1]==='t'){
            for(let i=0;i<año.Gastos.length;i++){
                if(año.Gastos[i].nombre===nombre[0]){
                    año.Gastos[i].tipo=e.target.value;
                    break
                }
            }
        }else if(nombre[1]==='n'){
            for(let i=0;i<año.Gastos.length;i++){
                if(año.Gastos[i].nombre===nombre[0]){
                    año.Gastos[i].nombre=e.target.value;
                    break
                }
            }
        }
        
        for(let i=0;i<años.length;i++){
            if(años[i].numero===año.numero){
                años[i]=año
                break;
            }
        }
        let PFGastos=[];
        let PFtotalgastos=0;
        for(let i=0;i<año.Gastos.length;i++){
            if(año.Gastos[i].tipo===this.state.PFselecciongasto){
                PFGastos.push(año.Gastos[i]);
                for(let j=0;j<año.Gastos[i].info.length;j++){
                    PFtotalgastos+=parseFloat(año.Gastos[i].info[j].cantidad);
                }
                if(this.state.PFselecciongasto==='Inventarios/Costos de Venta'){
                    PFtotalgastos-=año.totalcostos;
                }
            }
        }
        
        this.setState({PFGastos:PFGastos});
        this.setState({PFtotalgastos:PFtotalgastos});
        this.setState({anoseleccionado:año});
        this.setState({[this.state.PF.Años]:años});
    }
    actualizargastos(e){
        let año=this.state.anoseleccionado;
        let años=this.state.PF.Años;
        let nombre=e.target.id.split('-');
        for(let i=0;i<año.Gastos.length;i++){
            if(año.Gastos[i].nombre===nombre[0]){
                for(let j=0;j<12;j++){
                    if(año.Gastos[i].info[j].mes.toString()===nombre[1]){
                        año.Gastos[i].info[j].cantidad=e.target.value;
                        break;
                    }
                }
                let totalcantidad=0
                for(let j=0;j<12;j++){
                    totalcantidad+=parseFloat(año.Gastos[i].info[j].cantidad);
                }
                año.Gastos[i].totalcantidad=parseFloat(totalcantidad).toFixed(2);
                break;
            }
        }
        año.totalgastos=0;
        let bandINVCV=false;
        for(let i=0;i<año.Gastos.length;i++){
            for(let j=0;j<=11;j++){
                año.totalgastos+=parseFloat(año.Gastos[i].info[j].cantidad);
            }
            if(año.Gastos[i].tipo==='Inventarios/Costos de Venta'){
                bandINVCV=true;
            }
        }
        if(bandINVCV){
            año.totalgastos-=año.totalcostos
        }
        let PFtotalgastos=0;
        for(let i=0;i<año.Gastos.length;i++){
            if(año.Gastos[i].tipo===this.state.PFselecciongasto){
                for(let j=0;j<año.Gastos[i].info.length;j++){
                    PFtotalgastos+=parseFloat(año.Gastos[i].info[j].cantidad);
                }
                if(this.state.PFselecciongasto==='Inventarios/Costos de Venta'){
                    PFtotalgastos-=año.totalcostos;
                }
            }else if(this.state.PFselecciongasto==='Ver todos'){
                PFtotalgastos=año.totalgastos;
                if(bandINVCV){
                    PFtotalgastos-=año.totalcostos;
                }
                break;
            }
        }
        año.totalgastos=parseFloat(año.totalgastos.toFixed(2));
        PFtotalgastos=parseFloat(PFtotalgastos.toFixed(2))
        for(let i=0;i<años.length;i++){
            if(años[i].numero===año.numero){
                años[i]=año
                break;
            }
        }
        this.setState({PFtotalgastos:PFtotalgastos});
        this.setState({anoseleccionado:año});
        this.setState({[this.state.PF.Años]:años},()=>this.actualizarUPA());
        
    }
    crearimpuesto(){
        let año=this.state.anoseleccionado;
        let impuesto={'nombre':'','totalcantidad':0,'tipo':'Impuestos',info:[]};
        for(let i=1;i<=12;i++){
            impuesto.info.push({'mes':i,'cantidad':0,'cantidadreal':0,CR:[]})
        }
        año.Impuestos.push(impuesto);
        this.setState({anoseleccionado:año});
    }
    cambioimpuesto(e){
        let año=this.state.anoseleccionado;
        let años=this.state.PF.Años;
        let nombre=e.target.id.split('-');
        if(nombre[1]==='t'){
            for(let i=0;i<año.Impuestos.length;i++){
                if(año.Impuestos[i].nombre===nombre[0]){
                    año.Impuestos[i].tipo=e.target.value;
                    break
                }
            }
        }else if(nombre[1]==='n'){
            for(let i=0;i<año.Impuestos.length;i++){
                if(año.Impuestos[i].nombre===nombre[0]){
                    año.Impuestos[i].nombre=e.target.value;
                    break
                }
            }
        }
        
        for(let i=0;i<años.length;i++){
            if(años[i].numero===año.numero){
                años[i]=año
                break;
            }
        }
        this.setState({anoseleccionado:año});
        this.setState({[this.state.PF.Años]:años});
    }
    actualizarimpuesto(e){
        let año=this.state.anoseleccionado;
        let años=this.state.PF.Años;
        let nombre=e.target.id.split('-');
        for(let i=0;i<año.Impuestos.length;i++){
            if(año.Impuestos[i].nombre===nombre[0]){
                for(let j=0;j<12;j++){
                    if(año.Impuestos[i].info[j].mes.toString()===nombre[1]){
                        año.Impuestos[i].info[j].cantidad=e.target.value;
                        break;
                    }
                }
                let totalcantidad=0
                for(let j=0;j<12;j++){
                    totalcantidad+=parseFloat(año.Impuestos[i].info[j].cantidad);
                }
                año.Impuestos[i].totalcantidad=parseFloat(totalcantidad.toFixed(2));
                break;
            }
        }
        año.totalimpuestos=0;
        for(let i=0;i<año.Impuestos.length;i++){
            for(let j=0;j<=11;j++){
                año.totalimpuestos+=parseFloat(año.Impuestos[i].info[j].cantidad);
            }
        }
        año.totalimpuestos=parseFloat(año.totalimpuestos.toFixed(2));
        for(let i=0;i<años.length;i++){
            if(años[i].numero===año.numero){
                años[i]=año
                break;
            }
        }
        this.setState({anoseleccionado:año});
        this.setState({[this.state.PF.Años]:años},()=>this.actualizarUPA());
    }
    actualizarUPA(){
        let anos=this.state.PF.Años;
        for(let i=1;i<anos.length;i++){
            let upneta=anos[i-1].UPAnoanterior+anos[i-1].InvInicial+anos[i-1].totalventas-anos[i-1].totalcostos-Math.abs(anos[i-1].totalgastos)-anos[i-1].totalimpuestos;
            anos[i].UPAnoanterior=upneta;
        }
        this.setState({[this.state.PF.Años]:anos});
    }
    //finance fitness funciones
    cambioaño(e){
        let años=this.state.PF.Años;
        for(let i=0;i<años.length;i++){
            let año=años[i];
            if(año.numero===parseInt(e.target.value)){
                if(años.length>1&&año.numero!==1){
                    let UPAnoanteriorreal=años[i-1].InvInicialreal+años[i-1].totalrealventas-años[i-1].totalrealcostos-Math.abs(años[i-1].totalrealgastos)-años[i-1].totalrealimpuestos;
                    año.UPAnoanteriorreal=UPAnoanteriorreal;
                }
                this.setState({ffañoseleccionado:año});
                this.setState({ffmesseleccionado:1});
                break;
            }
        }
        this.setState({ffsemanas:[]},()=>{
            this.setState({ffseleccion:''},()=>{
                document.getElementById('selectmes').value='default';
            });
        })
    }
    cambiomes(e){
        let year=parseInt(this.state.ffañoseleccionado.alias);
        let month=parseInt(e.target.value)-1;
        let weeks = [],
        firstDate = new Date(year, month, 1),
        lastDate = new Date(year, month + 1, 0),
        numDays = lastDate.getDate();
        let start = 1;
        let end = 7 - firstDate.getDay();
        if (start === 'monday') {
            if (firstDate.getDay() === 0) {
                end = 1;
            } else {
            end = 7 - firstDate.getDay() + 1;
            }
        }
        while (start <= numDays) {
            weeks.push({start: start, end: end});
            start = end + 1;
            end = end + 7;
            end = start === 1 && end === 8 ? 1 : end;
            if (end > numDays) {
                end = numDays;
            }
        }
        this.setState({date:[]})
        this.setState({ffmesseleccionado:e.target.value});
        let año=this.state.ffañoseleccionado;
        if(e.target.value==="1"){
            año.UPAnoanteriorrealmes=año.UPAnoanteriorreal;
            año.UPAnoanteriormes=año.UPAnoanterior;
        }else{
            año.UPAnoanteriorrealmes=0;
            año.UPAnoanteriormes=0;
        }
        let totalmesventas=0;
        let totalrealmesventas=0;
        let totalmescostos=0;
        let totalrealmescostos=0;
        for(let i=0;i<año.Ventas.length;i++){
            let producto=año.Ventas[i].info;            
            for(let j=0;j<producto.length;j++){
                if(producto[j].mes===parseInt(e.target.value)){
                    totalmesventas+=producto[j].unidades*producto[j].precio;
                    for(let k=0;k<producto[j].VR.length;k++){
                        totalrealmesventas+=producto[j].VR[k];
                        totalrealmescostos+=parseFloat(parseFloat(producto[j].CR[k]).toFixed(2));
                    }
                    /*if(producto[j].CR.length!==0){
                        for(let k=0;k<producto[j].CR.length;k++){
                            totalrealmescostos+=parseFloat(parseFloat(producto[j].CR[k]).toFixed(2));
                        }
                    }else{
                        let unitweek=0;
                        let semanas=weeks;
                        let numdias=numDays;
                        for(let k=0;k<semanas.length;k++){
                            unitweek=(parseFloat(producto[j].unidades)/numdias*(parseInt(semanas[k].end)-parseInt(semanas[k].start)+1));
                            producto[j].CR.push((parseFloat(unitweek*parseFloat(producto[j].costo))).toFixed(2));
                            totalrealmescostos+=parseFloat(((unitweek*parseFloat(producto[j].costo))).toFixed(2));
                        }
                        año.Ventas[i].info[j].CR=producto[j].CR;
                    }*/
                    producto[j].unidadesreal=totalrealmesventas;
                    totalmescostos+=producto[j].unidades*producto[j].costo;
                    producto[j].costoreal=totalrealmescostos;
                    break;
                }
            }
        }
        año.totalmesventas=parseFloat(totalmesventas.toFixed(2));
        año.totalmescostos=parseFloat(totalmescostos.toFixed(2));
        año.totalmesrealventas=parseFloat(totalrealmesventas.toFixed(2));
        año.totalmesrealcostos=parseFloat((totalrealmescostos).toFixed(2));
        
        let totalmesgastos=0;
        let totalrealmesgastos=0;
        for(let i=0;i<año.Gastos.length;i++){
            let gasto=año.Gastos[i];           
            for(let j=0;j<gasto.info.length;j++){
                if(gasto.info[j].mes===parseInt(e.target.value)){
                    /*if(gasto.tipo==='Inventarios/Costos de Venta'){
                        totalmesgastos+=parseFloat(gasto.info[j].cantidad);
                        let unitweek=0;
                        let numdias=numDays;
                        if(gasto.info[j].CR.length===0){
                            for(let k=0;k<weeks.length;k++){
                                unitweek=(parseFloat(gasto.info[j].cantidad)/numdias*(parseInt(weeks[k].end)-parseInt(weeks[k].start)+1));
                                gasto.info[j].CR.push(parseFloat(parseFloat(unitweek).toFixed(2)));
                                totalrealmesgastos+=parseFloat((unitweek).toFixed(2));
                            }
                        }else{
                            for(let k=0;k<weeks.length;k++){
                                unitweek=(parseFloat(gasto.info[j].cantidad)/numdias*(parseInt(weeks[k].end)-parseInt(weeks[k].start)+1));
                                gasto.info[j].CR[k]=parseFloat((parseFloat(unitweek).toFixed(2)));
                                totalrealmesgastos+=parseFloat((unitweek).toFixed(2));
                            }
                        }
                        año.Gastos[i].info[j].CR=gasto.info[j].CR;
                    }else{*/
                        totalmesgastos+=parseFloat(gasto.info[j].cantidad);
                        for(let k=0;k<gasto.info[j].CR.length;k++){
                            totalrealmesgastos+=parseFloat(gasto.info[j].CR[k]);
                        }
                    //}
                    break;
                }
            }
        }
        año.totalmesgastos=totalmesgastos-totalmescostos;
        año.totalmesgastos=parseFloat(año.totalmesgastos.toFixed(2));
        totalrealmesgastos-=año.totalmesrealcostos;
        año.totalmesrealgastos=parseFloat((totalrealmesgastos).toFixed(2));
        let totalrealgastos=0;
        try{
            for(let i=0;año.Gastos.length;i++){
                let gasto=año.Gastos[i];
                for(let j=0;j<gasto.info.length;j++){
                    for(let k=0;k<gasto.info[j].CR.length;k++){
                        totalrealgastos+=parseFloat(gasto.info[j].CR[k]);
                    }
                }
            }
        }catch(e){
        }
        
        año.totalrealgastos=parseFloat(parseFloat(totalrealgastos-año.totalrealcostos).toFixed(2));
        
        let totalmesimpuestos=0;
        let totalrealmesimpuestos=0;
        for(let i=0;i<año.Impuestos.length;i++){
            let impuesto=año.Impuestos[i].info;            
            for(let j=0;j<impuesto.length;j++){
                if(impuesto[j].mes===parseInt(e.target.value)){
                    totalmesimpuestos+=parseFloat(impuesto[j].cantidad);
                    for(let k=0;k<impuesto[j].CR.length;k++){
                        totalrealmesimpuestos+=parseFloat(impuesto[j].CR[k]);
                    }
                    break;
                }
            }
        }
        año.totalmesimpuestos=parseFloat(totalmesimpuestos.toFixed(2));
        año.totalmesrealimpuestos=parseFloat(totalrealmesimpuestos.toFixed(2));
        let totalmesinversiones=0;
        let totalrealmesinversiones=0;
        for(let i=0;i<año.Inversiones.length;i++){
            let inversion=año.Inversiones[i].info;            
            for(let j=0;j<inversion.length;j++){
                if(inversion[j].mes===parseInt(e.target.value)){
                    totalmesinversiones+=parseFloat(inversion[j].cantidad);
                    for(let k=0;k<inversion[j].CR.length;k++){
                        totalrealmesinversiones+=parseFloat(inversion[j].CR[k]);
                    }
                    break;
                }
            }
        }
        año.InvInicialmes=parseFloat(totalmesinversiones.toFixed(2));
        año.InvInicialrealmes=parseFloat(totalrealmesinversiones.toFixed(2));
        this.setState({ffañoseleccionado:año});
        this.setState({ffsemanas:[]},()=>{
            this.setState({ffseleccion:''},()=>{
                this.semanas();
            });
        })
        
    }
    semanas(){
        let year=parseInt(this.state.ffañoseleccionado.alias);
        let month=parseInt(this.state.ffmesseleccionado-1);
        let weeks = [],
        firstDate = new Date(year, month, 1),
        lastDate = new Date(year, month + 1, 0),
        numDays = lastDate.getDate();
        let start = 1;
        let end = 7 - firstDate.getDay();
        if (start === 'monday') {
            if (firstDate.getDay() === 0) {
                end = 1;
            } else {
            end = 7 - firstDate.getDay() + 1;
            }
        }
        while (start <= numDays) {
            weeks.push({start: start, end: end});
            start = end + 1;
            end = end + 7;
            end = start === 1 && end === 8 ? 1 : end;
            if (end > numDays) {
                end = numDays;
            }
        }
        this.setState({ffsemanas:weeks},()=>{
            this.setState({numDias:numDays});
            this.setState({ffindex:0});
        });
        
    }
    todosemana(e){
        this.setState({ffseleccion:'mostrar'})
        if(e.target.value==='todas'){
            this.todasemanas(e);
            this.setState({ffseleccion:'mostrartodo'});
            this.setState({date:[]})
        }else{
            let semanas=this.state.ffsemanas;
        let año=this.state.ffañoseleccionado;
        let todasemana={UPAnoanterior:0,UPAnoanteriorreal:0,Inversiones:[],Ventas:[],Gastos:[],Impuestos:[],InvInicialsem:0,InvInicialsemreal:0,totalsemventas:0,totalsemrealventas:0,totalsemcostos:0,totalsemrealcostos:0,totalsemgastos:0,totalsemrealgastos:0,totalsemimpuestos:0,totalsemrealimpuestos:0};
        let productos=[];
        let rangos=[{
            startDate:new Date(parseInt(año.alias),parseInt(this.state.ffmesseleccionado)-1,parseInt(semanas[parseInt(e.target.value)].start)),
            endDate:new Date(parseInt(año.alias),parseInt(this.state.ffmesseleccionado)-1,parseInt(semanas[parseInt(e.target.value)].end)),
            key:'selection'
            }]
        this.setState({date:rangos})
        let prod={'nombre':'','totalunidades':0,'totalcosto':0,'totalprecio':0,info:[{'unidades':0}]};
        this.setState({ffindex:parseInt(e.target.value)});
        let aux=0;
        let aux2=0;
        let aux3=0;
        let aux4=0;
        if(this.state.ffmesseleccionado==="1" && parseInt(e.target.value)===0){
            todasemana.UPAnoanterior=año.UPAnoanterior;
            todasemana.UPAnoanteriorreal=año.UPAnoanteriorreal;
        }
        for(let i=0;i<año.Ventas.length;i++){
            prod=Object.assign({},año.Ventas[i]);
            //console.log(año.Ventas[i].info)
            for(let j=0;j<año.Ventas[i].info.length;j++){
                if(año.Ventas[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Ventas[i].info[j].VR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Ventas[i].info[j].VR.push(0);
                        }
                    }
                    if(año.Ventas[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Ventas[i].info[j].CR.push(0);
                        }
                    }
                    prod.info=Object.assign({},año.Ventas[i].info[j]);
                    
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let numdias=this.state.numDias;
                    try{
                        //unitweek=Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.value)].end)-parseInt(semanas[parseInt(e.target.value)].start))+1));
                        unitweek=(año.Ventas[i].info[j].unidades/numdias*((parseInt(semanas[parseInt(e.target.value)].end)-parseInt(semanas[parseInt(e.target.value)].start))+1)).toFixed(2);
                        prod.info.unidades=unitweek;
                        aux+=parseFloat((unitweek*prod.info.precio).toFixed(2));
                        aux2+=parseFloat((unitweek*prod.info.costo).toFixed(2));
                        aux3+=parseFloat((prod.info.VR[parseInt(e.target.value)]).toFixed(2));
                        aux4+=parseFloat(parseFloat(prod.info.CR[parseInt(e.target.value)]).toFixed(2));
                        
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
            productos.push(prod);
        }
        todasemana.totalsemventas=parseFloat((aux).toFixed(2));
        todasemana.totalsemcostos=parseFloat((aux2).toFixed(2));
        todasemana.totalsemrealventas=parseFloat((aux3).toFixed(2));
        todasemana.totalsemrealcostos=parseFloat((aux4).toFixed(2));
        
        todasemana.Ventas=productos;
        aux=0;
        aux2=0;
        let gastos=[];
        let gasto={'nombre':'','totalcantidad':0,'tipo':'',info:[]};
        this.setState({ffindex:parseInt(e.target.value)});
        for(let i=0;i<año.Gastos.length;i++){
            gasto=Object.assign({},año.Gastos[i]);
            for(let j=0;j<año.Gastos[i].info.length;j++){
                if(año.Gastos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Gastos[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Gastos[i].info[j].CR.push(0);
                        }
                    }
                    gasto.info=Object.assign({},año.Gastos[i].info[j]);
                    
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let numdias=this.state.numDias;
                    try{
                        unitweek=(año.Gastos[i].info[j].cantidad/numdias*((parseInt(semanas[parseInt(e.target.value)].end)-parseInt(semanas[parseInt(e.target.value)].start))+1));
                        gasto.info.cantidad=parseFloat(unitweek.toFixed(2));
                        aux+=unitweek;
                        aux2+=gasto.info.CR[parseInt(e.target.value)];
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
            gastos.push(gasto);
            
        }
        todasemana.totalsemgastos=parseFloat((aux-todasemana.totalsemcostos).toFixed(2));
        todasemana.totalsemrealgastos=parseFloat((aux2-todasemana.totalsemrealcostos).toFixed(2));
        todasemana.Gastos=gastos;
        aux=0;
        aux2=0;
        let impuestos=[];
        let impuesto={'nombre':'','totalcantidad':0,'tipo':'',info:[]};
        this.setState({ffindex:parseInt(e.target.value)});
        for(let i=0;i<año.Impuestos.length;i++){
            impuesto=Object.assign({},año.Impuestos[i]);
            for(let j=0;j<año.Impuestos[i].info.length;j++){
                if(año.Impuestos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Impuestos[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Impuestos[i].info[j].CR.push(0);
                        }
                    }
                    impuesto.info=Object.assign({},año.Impuestos[i].info[j]);
                    
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let numdias=this.state.numDias;
                    try{
                        unitweek=(año.Impuestos[i].info[j].cantidad/numdias*((parseInt(semanas[parseInt(e.target.value)].end)-parseInt(semanas[parseInt(e.target.value)].start))+1));
                        impuesto.info.cantidad=parseFloat(unitweek.toFixed(2));
                        aux+=unitweek;
                        aux2+=impuesto.info.CR[parseInt(e.target.value)];
                        
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
           impuestos.push(impuesto);
        }
        todasemana.totalsemimpuestos=parseFloat(aux.toFixed(2));
        todasemana.totalsemrealimpuestos=parseFloat(aux2.toFixed(2));
        todasemana.Impuestos=impuestos;
        aux=0;
        aux2=0;
        let inversiones=[];
        let inversion={'nombre':'','totalcantidad':0,'tipo':'',info:[]};
        this.setState({ffindex:parseInt(e.target.value)});
        for(let i=0;i<año.Inversiones.length;i++){
            inversion=Object.assign({},año.Inversiones[i]);
            for(let j=0;j<año.Inversiones[i].info.length;j++){
                if(año.Inversiones[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Inversiones[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Inversiones[i].info[j].CR.push(0);
                        }
                    }
                    inversion.info=Object.assign({},año.Inversiones[i].info[j]);
                    
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let numdias=this.state.numDias;
                    try{
                        unitweek=(año.Inversiones[i].info[j].cantidad/numdias*((parseInt(semanas[parseInt(e.target.value)].end)-parseInt(semanas[parseInt(e.target.value)].start))+1));
                        inversion.info.cantidad=parseFloat(unitweek.toFixed(2));
                        aux+=unitweek;
                        aux2+=inversion.info.CR[parseInt(e.target.value)];
                        
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
           inversiones.push(inversion);
        }
        todasemana.InvInicialsem=parseFloat(aux.toFixed(2));
        todasemana.InvInicialsemreal=parseFloat(aux2.toFixed(2));
        todasemana.Inversiones=inversiones;
        this.setState({todasemana:todasemana});
        this.setState({ffseleccion:'mostrar'});
        }
    }
    todasemanas(e){
        let semanas=this.state.ffsemanas;
        let todassemanas=[];
        for(let z=0;z<semanas.length;z++){
            let año=this.state.ffañoseleccionado;
        let todasemana={UPAnoanterior:0,UPAnoanteriorreal:0,Inversiones:[],Ventas:[],Gastos:[],Impuestos:[],InvInicialsem:0,InvInicialsemreal:0,totalsemventas:0,totalsemrealventas:0,totalsemcostos:0,totalsemrealcostos:0,totalsemgastos:0,totalsemrealgastos:0,totalsemimpuestos:0,totalsemrealimpuestos:0};
        let productos=[];
        let prod={'nombre':'','totalunidades':0,'totalcosto':0,'totalprecio':0,info:[{'unidades':0}]};
        let aux=0;
        let aux2=0;
        let aux3=0;
        let aux4=0;
        if(this.state.ffmesseleccionado==="1"&&z===0){
            todasemana.UPAnoanterior=año.UPAnoanterior;
            todasemana.UPAnoanteriorreal=año.UPAnoanteriorreal;
        }
        for(let i=0;i<año.Ventas.length;i++){
            prod=Object.assign({},año.Ventas[i]);
            //console.log(año.Ventas[i].info)
            for(let j=0;j<año.Ventas[i].info.length;j++){
                if(año.Ventas[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Ventas[i].info[j].VR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Ventas[i].info[j].VR.push(0);
                            año.Ventas[i].info[j].CR.push(0);
                        }
                    }
                    prod.info=Object.assign({},año.Ventas[i].info[j]);
                    
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let numdias=this.state.numDias
                    try{
                        //unitweek=Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[z].end)-parseInt(semanas[z].start))+1));
                        unitweek=(año.Ventas[i].info[j].unidades/numdias*((parseInt(semanas[z].end)-parseInt(semanas[z].start))+1)).toFixed(2);
                        prod.info.unidades=unitweek;
                        aux+=parseFloat((unitweek*prod.info.precio).toFixed(2));
                        aux2+=parseFloat((unitweek*prod.info.costo).toFixed(2));
                        aux3+=parseFloat(parseFloat(prod.info.VR[z]).toFixed(2));
                        aux4+=parseFloat((prod.info.CR[z]).toFixed(2));
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
            productos.push(prod);
        }
        todasemana.totalsemventas=parseFloat((aux).toFixed(2));
        todasemana.totalsemcostos=parseFloat((aux2).toFixed(2));
        todasemana.totalsemrealventas=parseFloat(aux3.toFixed(2));
        todasemana.totalsemrealcostos=parseFloat(aux4.toFixed(2));
        todasemana.Ventas=productos;
        aux=0;
        aux2=0;
        let gastos=[];
        let gasto={'nombre':'','totalcantidad':0,'tipo':'',info:[]};
        for(let i=0;i<año.Gastos.length;i++){
            gasto=Object.assign({},año.Gastos[i]);
            for(let j=0;j<año.Gastos[i].info.length;j++){
                if(año.Gastos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Gastos[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Gastos[i].info[j].CR.push(0);
                        }
                    }
                    gasto.info=Object.assign({},año.Gastos[i].info[j]);
                    
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let final=semanas.length
                    let numdias=parseInt(semanas[final-1].end);
                    try{
                        //unitweek=Math.round(año.Gastos[i].info[j].cantidad/((parseInt(semanas[z].end)-parseInt(semanas[z].start))+1));
                        unitweek=(año.Gastos[i].info[j].cantidad/numdias*((parseInt(semanas[z].end)-parseInt(semanas[z].start))+1)).toFixed(2);
                        gasto.info.cantidad=parseFloat(unitweek);
                        aux+=parseFloat(unitweek);
                        aux2+=parseFloat(gasto.info.CR[z]);
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
            gastos.push(gasto);
            
        }
        todasemana.totalsemgastos=parseFloat((aux-todasemana.totalsemcostos).toFixed(2));
        todasemana.totalsemrealgastos=parseFloat((aux2-todasemana.totalsemcostos).toFixed(2));
        todasemana.Gastos=gastos;
        aux=0;
        aux2=0;
        let inversiones=[];
        let inversion={'nombre':'','totalcantidad':0,'tipo':'',info:[]};
        for(let i=0;i<año.Inversiones.length;i++){
            inversion=Object.assign({},año.Inversiones[i]);
            for(let j=0;j<año.Inversiones[i].info.length;j++){
                if(año.Inversiones[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Inversiones[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Inversiones[i].info[j].CR.push(0);
                        }
                    }
                    inversion.info=Object.assign({},año.Inversiones[i].info[j]);
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let final=semanas.length
                    let numdias=parseInt(semanas[final-1].end);
                    try{
                        //unitweek=Math.round(año.Impuestos[i].info[j].cantidad/((parseInt(semanas[z].end)-parseInt(semanas[z].start))+1));
                        unitweek=(año.Inversiones[i].info[j].cantidad/numdias*((parseInt(semanas[z].end)-parseInt(semanas[z].start))+1));
                        inversion.info.cantidad=parseFloat(unitweek.toFixed(2));
                        
                        aux+=parseFloat(unitweek);
                        aux2+=parseFloat(inversion.info.CR[z]);
                        
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
           inversiones.push(inversion);
        }
        todasemana.InvInicialsem=parseFloat(aux.toFixed(2));
        todasemana.InvInicialsemreal=parseFloat(aux2.toFixed(2));
        todasemana.Inversiones=inversiones;
        
        aux=0;
        aux2=0;
        let impuestos=[];
        let impuesto={'nombre':'','totalcantidad':0,'tipo':'',info:[]};
        for(let i=0;i<año.Impuestos.length;i++){
            impuesto=Object.assign({},año.Impuestos[i]);
            for(let j=0;j<año.Impuestos[i].info.length;j++){
                if(año.Impuestos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Impuestos[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Impuestos[i].info[j].CR.push(0);
                        }
                    }
                    impuesto.info=Object.assign({},año.Impuestos[i].info[j]);
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let final=semanas.length
                    let numdias=parseInt(semanas[final-1].end);
                    try{
                        //unitweek=Math.round(año.Impuestos[i].info[j].cantidad/((parseInt(semanas[z].end)-parseInt(semanas[z].start))+1));
                        unitweek=(año.Impuestos[i].info[j].cantidad/numdias*((parseInt(semanas[z].end)-parseInt(semanas[z].start))+1));
                        impuesto.info.cantidad=parseFloat(unitweek.toFixed(2));
                        
                        aux+=parseFloat(unitweek);
                        aux2+=parseFloat(impuesto.info.CR[z]);
                        
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
           impuestos.push(impuesto);
        }
        todasemana.totalsemimpuestos=parseFloat(aux.toFixed(2));
        todasemana.totalsemrealimpuestos=parseFloat(aux2.toFixed(2));
        todasemana.Impuestos=impuestos;
        todassemanas.push(todasemana);
        }
        this.setState({todassemanas:todassemanas})
    }
    //inversiones
    seleccseminversiones(e){
        this.setState({ffseleccion:'inversiones'});
        let semanas=this.state.ffsemanas;
        let año=this.state.ffañoseleccionado;
        let inversiones=[];
        this.setState({ffproductos:inversiones});
        let inversion={'nombre':'','totalcantidad':0,'tipo':'',info:[]};
        for(let i=0;i<año.Inversiones.length;i++){
            inversion=Object.assign({},año.Inversiones[i]);
            for(let j=0;j<año.Inversiones[i].info.length;j++){
                if(año.Inversiones[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Inversiones[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Inversiones[i].info[j].CR.push(0);
                        }
                    }
                    inversion.info=Object.assign({},año.Inversiones[i].info[j]);
                    
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let numdias=this.state.numDias;
                    try{
                        //unitweek=Math.round(año.Impuestos[i].info[j].cantidad/((parseInt(semanas[this.state.ffindex].end)-parseInt(semanas[this.state.ffindex].start))+1));
                        unitweek=(año.Inversiones[i].info[j].cantidad/numdias*((parseInt(semanas[parseInt(this.state.ffindex)].end)-parseInt(semanas[parseInt(this.state.ffindex)].start))+1)).toFixed(2);
                        inversion.info.cantidad=unitweek;
                        
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
           inversiones.push(inversion);
            
        }
        this.setState({ffproductos:inversiones});
    }
    cambiosinversionessemanas(e){
        let ano=this.state.ffañoseleccionado;
        let anos=this.state.PF.Años;
        for(let i=0;i<ano.Inversiones.length;i++){
            if(ano.Inversiones[i].nombre===e.target.id){
                for(let j=0;j<ano.Inversiones[i].info.length;j++){
                    if(ano.Inversiones[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                        ano.Inversiones[i].info[j].CR[this.state.ffindex]=parseFloat(e.target.value);
                        break;
                    }
                }
                break;
            }
        }
        ano.InvInicialrealmes=0;
        for(let i=0;i<ano.Inversiones.length;i++){
            for(let j=0;j<ano.Inversiones[i].info.length;j++){
                if(ano.Inversiones[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    let aux=0;
                    for(let k=0;k<ano.Inversiones[i].info[j].CR.length;k++){
                        aux=parseFloat(aux)+parseFloat(ano.Inversiones[i].info[j].CR[k]);
                    }
                    ano.Inversiones[i].info[j].cantidadreal=aux;
                    ano.InvInicialrealmes=parseFloat(ano.InvInicialrealmes)+parseFloat(aux);
                    break;
                }
            }
        }
        ano.InvInicialrealmes=parseFloat(ano.InvInicialrealmes.toFixed(2));
        ano.InvInicialreal=0;
        for(let i=0;i<ano.Inversiones.length;i++){
            for(let j=0;j<ano.Inversiones[i].info.length;j++){
                
                    let aux=0;
                    for(let k=0;k<ano.Inversiones[i].info[j].CR.length;k++){
                        aux=parseFloat(aux)+parseFloat(ano.Inversiones[i].info[j].CR[k]);
                    }
                    ano.InvInicialreal=parseFloat(ano.InvInicialreal)+parseFloat(aux);
                  
            }
        }
        ano.InvInicialreal=parseFloat(ano.InvInicialreal.toFixed(2))
        for(let i=0;i<anos.length;i++){
            if(anos[i].numero===ano.numero){
                anos[i]=Object.assign({},ano);
                break;
            }
        }
        this.setState({ffañoseleccionado:ano});
        this.setState({[this.state.PF.Años]:anos});
        
    }
    //ventas
    ventassemanas(e){
        this.setState({ffseleccion:'ventas'});
    }
    cambioventassemanas(e){
        let ano=this.state.ffañoseleccionado;
        let anos=this.state.PF.Años;
        for(let i=0;i<ano.Ventas.length;i++){
            if(ano.Ventas[i].nombre===e.target.id){
                for(let j=0;j<ano.Ventas[i].info.length;j++){
                    if(ano.Ventas[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                        ano.Ventas[i].info[j].VR[this.state.ffindex]=parseFloat(e.target.value);
                        break;
                    }
                }
                break;
            }
        }
        ano.totalmesrealventas=0;
        for(let i=0;i<ano.Ventas.length;i++){
            for(let j=0;j<ano.Ventas[i].info.length;j++){
                if(ano.Ventas[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    let aux=0;
                    for(let k=0;k<ano.Ventas[i].info[j].VR.length;k++){
                        aux+=ano.Ventas[i].info[j].VR[k];
                    }
                    ano.Ventas[i].info[j].unidadesreal=aux;
                    ano.totalmesrealventas+=aux;
                    
                    break;
                }
            }
        }
        ano.totalmesrealventas=(ano.totalmesrealventas).toFixed(2);
        ano.totalrealventas=0;
        //aplicar costo de la venta que se aplico
        for(let i=0;i<ano.Ventas.length;i++){
            for(let j=0;j<ano.Ventas[i].info.length;j++){
                if(ano.Ventas[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(ano.Ventas[i].info[j].CR.length===0){
                        for(let k=0;k<this.state.ffsemanas.length;k++){
                            ano.Ventas[i].info[j].CR.push(0);
                        }
                    }
                    if(ano.Ventas[i].info[j].VR[this.state.ffindex]!==0){
                        let unitweek=0;
                        let semanas=this.state.ffsemanas;
                        let numdias=this.state.numDias;
                        unitweek=(parseFloat(ano.Ventas[i].info[j].unidades)/numdias*(parseInt(semanas[this.state.ffindex].end)-parseInt(semanas[this.state.ffindex].start)+1));
                        ano.Ventas[i].info[j].CR[this.state.ffindex]=parseFloat((unitweek*ano.Ventas[i].info[j].costo).toFixed(2));
                    }else{
                        ano.Ventas[i].info[j].CR[this.state.ffindex]=0;
                    }
                    
                    break;
                }
            }
        }
        //suma de costos
        ano.totalmesrealcostos=0;
        for(let i=0;i<ano.Ventas.length;i++){
            for(let j=0;j<ano.Ventas[i].info.length;j++){
                if(ano.Ventas[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    let aux=0;
                    for(let k=0;k<ano.Ventas[i].info[j].CR.length;k++){
                        aux=parseFloat(aux)+parseFloat(ano.Ventas[i].info[j].CR[k]);
                    }
                    ano.Ventas[i].info[j].costoreal=aux;
                    ano.totalmesrealcostos=parseFloat(ano.totalmesrealcostos)+parseFloat(aux);
                    ano.totalmesrealcostos=parseFloat((ano.totalmesrealcostos).toFixed(2));
                    break;
                }
            }
        }
        ano.totalrealcostos=0;
        for(let i=0;i<ano.Ventas.length;i++){
            for(let j=0;j<ano.Ventas[i].info.length;j++){
                    let aux=0;
                    for(let k=0;k<ano.Ventas[i].info[j].CR.length;k++){
                        aux=parseFloat(aux)+parseFloat(ano.Ventas[i].info[j].CR[k]);
                    }
                    ano.totalrealcostos=parseFloat(ano.totalrealcostos)+(parseFloat(aux));
                    ano.totalrealcostos=parseFloat((ano.totalrealcostos).toFixed(2));
            }
        }
        ano.totalmesrealgastos=0;
        for(let i=0;i<ano.Gastos.length;i++){
            for(let j=0;j<ano.Gastos[i].info.length;j++){
                if(ano.Gastos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    for(let k=0;k<ano.Gastos[i].info[j].CR.length;k++){
                        ano.totalmesrealgastos+=ano.Gastos[i].info[j].CR[k];
                    }
                    break;
                }
            }
        }
        ano.totalrealgastos=0;
        for(let i=0;i<ano.Gastos.length;i++){
            for(let j=0;j<ano.Gastos[i].info.length;j++){
                for(let k=0;k<ano.Gastos[i].info[j].CR.length;k++){
                    ano.totalrealgastos+=ano.Gastos[i].info[j].CR[k];
                }
            }
        }
        ano.totalmesrealgastos-=ano.totalmesrealcostos;
        ano.totalrealgastos-=ano.totalrealcostos;
        ano.totalmesrealgastos=parseFloat((ano.totalmesrealgastos).toFixed(2));
        ano.totalrealgastos=parseFloat((ano.totalrealgastos).toFixed(2));
        //suma de ventas
        for(let i=0;i<ano.Ventas.length;i++){
            for(let j=0;j<ano.Ventas[i].info.length;j++){
                    let aux=0;
                    for(let k=0;k<ano.Ventas[i].info[j].VR.length;k++){
                        aux+=ano.Ventas[i].info[j].VR[k];
                    }
                    ano.totalrealventas+=aux;
            }
        }
        ano.totalrealventas=(ano.totalrealventas).toFixed(2);
        for(let i=0;i<anos.length;i++){
            if(anos[i].numero===ano.numero){
                anos[i]=Object.assign({},ano);
                break;
            }
        }
        this.setState({[this.state.PF.Años]:anos});
        this.setState({ffañoseleccionado:ano});
        
        
    }
    seleccsemventas(e){
        this.setState({ffseleccion:'ventas'});
        let semanas=this.state.ffsemanas;
        let año=this.state.ffañoseleccionado;
        let productos=[];
        this.setState({ffproductos:productos});
        let prod={'nombre':'','totalunidades':0,'totalcosto':0,'totalprecio':0,info:[{'unidades':0}]};
        for(let i=0;i<año.Ventas.length;i++){
            prod=Object.assign({},año.Ventas[i]);
            //console.log(año.Ventas[i].info)
            for(let j=0;j<año.Ventas[i].info.length;j++){
                if(año.Ventas[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Ventas[i].info[j].VR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Ventas[i].info[j].VR.push(0);
                        }
                    }
                    prod.info=Object.assign({},año.Ventas[i].info[j]);
                    
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let numdias=this.state.numDias;
                    try{
                        //unitweek=Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[this.state.ffindex].end)-parseInt(semanas[this.state.ffindex].start))+1));
                        unitweek=(año.Ventas[i].info[j].unidades/numdias*((parseInt(semanas[parseInt(this.state.ffindex)].end)-parseInt(semanas[parseInt(this.state.ffindex)].start))+1)).toFixed(2);
                        prod.info.unidades=unitweek;
                        
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
            productos.push(prod);
            
        }
        this.setState({ffproductos:productos});
    }
    //ff costos
    cambiocostossemanas(e){
        let ano=this.state.ffañoseleccionado;
        let anos=this.state.PF.Años;
        for(let i=0;i<ano.Ventas.length;i++){
            if(ano.Ventas[i].nombre===e.target.id){
                for(let j=0;j<ano.Ventas[i].info.length;j++){
                    if(ano.Ventas[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                        if(ano.Ventas[i].info[j].VR[this.state.ffindex]!==0){
                            ano.Ventas[i].info[j].CR[this.state.ffindex]=parseFloat(e.target.value);
                        }else{
                            ano.Ventas[i].info[j].CR[this.state.ffindex]=0;
                        }
                        
                        break;
                    }
                }
                break;
            }
        }
        ano.totalmesrealcostos=0;
        for(let i=0;i<ano.Ventas.length;i++){
            for(let j=0;j<ano.Ventas[i].info.length;j++){
                if(ano.Ventas[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    let aux=0;
                    for(let k=0;k<ano.Ventas[i].info[j].CR.length;k++){
                        aux=parseFloat(aux)+parseFloat(ano.Ventas[i].info[j].CR[k]);
                    }
                    ano.Ventas[i].info[j].costoreal=aux;
                    ano.totalmesrealcostos=parseFloat(ano.totalmesrealcostos)+parseFloat(aux);
                    ano.totalmesrealcostos=parseFloat((ano.totalmesrealcostos).toFixed(2));
                    break;
                }
            }
        }
        ano.totalrealcostos=0;
        for(let i=0;i<ano.Ventas.length;i++){
            for(let j=0;j<ano.Ventas[i].info.length;j++){
                    let aux=0;
                    for(let k=0;k<ano.Ventas[i].info[j].CR.length;k++){
                        aux=parseFloat(aux)+parseFloat(ano.Ventas[i].info[j].CR[k]);
                    }
                    ano.totalrealcostos=parseFloat(ano.totalrealcostos)+(parseFloat(aux));
                    ano.totalrealcostos=parseFloat((ano.totalrealcostos).toFixed(2));
            }
        }
        ano.totalmesrealgastos=0;
        for(let i=0;i<ano.Gastos.length;i++){
            for(let j=0;j<ano.Gastos[i].info.length;j++){
                if(ano.Gastos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    for(let k=0;k<ano.Gastos[i].info[j].CR.length;k++){
                        ano.totalmesrealgastos+=parseFloat(ano.Gastos[i].info[j].CR[k]);
                    }
                    break;
                }
            }
        }
        ano.totalmesrealgastos-=ano.totalmesrealcostos;
        ano.totalmesrealgastos=parseFloat(ano.totalmesrealgastos.toFixed(2));
        ano.totalrealgastos=0;
        for(let i=0;i<ano.Gastos.length;i++){
            for(let j=0;j<ano.Gastos[i].info.length;j++){
                for(let k=0;k<ano.Gastos[i].info[j].CR.length;k++){
                    ano.totalrealgastos+=parseFloat(ano.Gastos[i].info[j].CR[k]);
                }
            }
        }
        ano.totalrealgastos-=ano.totalrealcostos;
        ano.totalrealgastos=parseFloat(ano.totalrealgastos.toFixed(2));
        for(let i=0;i<anos.length;i++){
            if(anos[i].numero===ano.numero){
                anos[i]=Object.assign({},ano);
                break;
            }
        }
        this.setState({ffañoseleccionado:ano});
        this.setState({[this.state.PF.Años]:anos});
        
    }
    seleccsemcostos(e){
        this.setState({ffseleccion:'costos'});
        let semanas=this.state.ffsemanas;
        let año=this.state.ffañoseleccionado;
        let productos=[];
        this.setState({ffproductos:productos});
        let prod={'nombre':'','totalunidades':0,'totalcosto':0,'totalprecio':0,info:[{'unidades':0}]};
        for(let i=0;i<año.Ventas.length;i++){
            prod=Object.assign({},año.Ventas[i]);
            //console.log(año.Ventas[i].info)
            for(let j=0;j<año.Ventas[i].info.length;j++){
                if(año.Ventas[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Ventas[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Ventas[i].info[j].CR.push(0);
                        }
                    }
                    prod.info=Object.assign({},año.Ventas[i].info[j]);
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let numdias=this.state.numDias;
                    try{
                        //unitweek=Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[this.state.ffindex].end)-parseInt(semanas[this.state.ffindex].start))+1));
                        unitweek=(año.Ventas[i].info[j].unidades/numdias*((parseInt(semanas[parseInt(this.state.ffindex)].end)-parseInt(semanas[parseInt(this.state.ffindex)].start))+1)).toFixed(2);
                        prod.info.unidades=unitweek;
                        
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
            productos.push(prod);
            
        }
        this.setState({ffproductos:productos});
    }
    //ff gastos
    cambiosgastossemanas(e){
        let ano=this.state.ffañoseleccionado;
        let anos=this.state.PF.Años;
        for(let i=0;i<ano.Gastos.length;i++){
            if(ano.Gastos[i].nombre===e.target.id){
                for(let j=0;j<ano.Gastos[i].info.length;j++){
                    if(ano.Gastos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                        ano.Gastos[i].info[j].CR[this.state.ffindex]=parseFloat(e.target.value);
                        break;
                    }
                }
                break;
            }
        }
        ano.totalmesrealgastos=0;
        for(let i=0;i<ano.Gastos.length;i++){
            for(let j=0;j<ano.Gastos[i].info.length;j++){
                if(ano.Gastos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    let aux=0;
                    for(let k=0;k<ano.Gastos[i].info[j].CR.length;k++){
                        aux=parseFloat(aux)+parseFloat(ano.Gastos[i].info[j].CR[k]);
                    }
                    ano.Gastos[i].info[j].cantidadreal=aux;
                    ano.totalmesrealgastos=parseFloat(ano.totalmesrealgastos)+parseFloat(aux);
                    break;
                }
            }
        }
        ano.totalmesrealgastos=parseFloat(ano.totalmesrealgastos)-parseFloat(ano.totalmesrealcostos);
        ano.totalmesrealgastos=parseFloat(ano.totalmesrealgastos.toFixed(2))
        ano.totalrealgastos=0;
        for(let i=0;i<ano.Gastos.length;i++){
            for(let j=0;j<ano.Gastos[i].info.length;j++){
                
                    let aux=0;
                    for(let k=0;k<ano.Gastos[i].info[j].CR.length;k++){
                        aux=parseFloat(aux)+parseFloat(ano.Gastos[i].info[j].CR[k]);
                    }
                    
                    ano.totalrealgastos=parseFloat(ano.totalrealgastos)+parseFloat(aux);
            }
        }
        ano.totalrealgastos=parseFloat(ano.totalrealgastos)-parseFloat(ano.totalrealcostos);
        ano.totalrealgastos=parseFloat(ano.totalrealgastos.toFixed(2));
        for(let i=0;i<anos.length;i++){
            if(anos[i].numero===ano.numero){
                anos[i]=Object.assign({},ano);
                break;
            }
        }
        this.setState({ffañoseleccionado:ano});
        this.setState({[this.state.PF.Años]:anos});
        
    }
    seleccsemgastos(e){
        this.setState({ffseleccion:'gastos'});
        let semanas=this.state.ffsemanas;
        let año=this.state.ffañoseleccionado;
        let gastos=[];
        this.setState({ffproductos:gastos});
        let gasto={'nombre':'','totalcantidad':0,'tipo':'',info:[]};
        
        for(let i=0;i<año.Gastos.length;i++){
            gasto=Object.assign({},año.Gastos[i]);
            for(let j=0;j<año.Gastos[i].info.length;j++){
                if(año.Gastos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Gastos[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Gastos[i].info[j].CR.push(0);
                        }
                    }
                    gasto.info=Object.assign({},año.Gastos[i].info[j]);
                    
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let numdias=this.state.numDias;
                    try{
                        //unitweek=Math.round(año.Gastos[i].info[j].cantidad/((parseInt(semanas[this.state.ffindex].end)-parseInt(semanas[this.state.ffindex].start))+1));
                        unitweek=(año.Gastos[i].info[j].cantidad/numdias*((parseInt(semanas[parseInt(this.state.ffindex)].end)-parseInt(semanas[parseInt(this.state.ffindex)].start))+1)).toFixed(2);
                        gasto.info.cantidad=unitweek;
                        
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
            gastos.push(gasto);
            
        }
        this.setState({ffproductos:gastos});
    }
    //ff impuestos
    cambiosimpuestossemanas(e){
        let ano=this.state.ffañoseleccionado;
        let anos=this.state.PF.Años;
        for(let i=0;i<ano.Impuestos.length;i++){
            if(ano.Impuestos[i].nombre===e.target.id){
                for(let j=0;j<ano.Impuestos[i].info.length;j++){
                    if(ano.Impuestos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                        ano.Impuestos[i].info[j].CR[this.state.ffindex]=parseFloat(e.target.value);
                        break;
                    }
                }
                break;
            }
        }
        ano.totalmesrealimpuestos=0;
        for(let i=0;i<ano.Impuestos.length;i++){
            for(let j=0;j<ano.Impuestos[i].info.length;j++){
                if(ano.Impuestos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    let aux=0;
                    for(let k=0;k<ano.Impuestos[i].info[j].CR.length;k++){
                        aux=parseFloat(aux)+parseFloat(ano.Impuestos[i].info[j].CR[k]);
                    }
                    ano.Impuestos[i].info[j].cantidadreal=aux;
                    ano.totalmesrealimpuestos=parseFloat(ano.totalmesrealimpuestos)+parseFloat(aux);
                    break;
                }
            }
        }
        ano.totalmesrealimpuestos=parseFloat(ano.totalmesrealimpuestos.toFixed(2));
        ano.totalrealimpuestos=0;
        for(let i=0;i<ano.Impuestos.length;i++){
            for(let j=0;j<ano.Impuestos[i].info.length;j++){
                
                    let aux=0;
                    for(let k=0;k<ano.Impuestos[i].info[j].CR.length;k++){
                        aux=parseFloat(aux)+parseFloat(ano.Impuestos[i].info[j].CR[k]);
                    }
                    ano.totalrealimpuestos=parseFloat(ano.totalrealimpuestos)+parseFloat(aux);
                  
            }
        }
        ano.totalrealimpuestos=parseFloat(ano.totalrealimpuestos.toFixed(2))
        for(let i=0;i<anos.length;i++){
            if(anos[i].numero===ano.numero){
                anos[i]=Object.assign({},ano);
                break;
            }
        }
        this.setState({ffañoseleccionado:ano});
        this.setState({[this.state.PF.Años]:anos});
        
    }
    seleccsemimpuestos(e){
        this.setState({ffseleccion:'impuestos'});
        let semanas=this.state.ffsemanas;
        let año=this.state.ffañoseleccionado;
        let impuestos=[];
        this.setState({ffproductos:impuestos});
        let impuesto={'nombre':'','totalcantidad':0,'tipo':'',info:[]};
        for(let i=0;i<año.Impuestos.length;i++){
            impuesto=Object.assign({},año.Impuestos[i]);
            for(let j=0;j<año.Impuestos[i].info.length;j++){
                if(año.Impuestos[i].info[j].mes===parseInt(this.state.ffmesseleccionado)){
                    if(año.Impuestos[i].info[j].CR.length===0){
                        for(let k=0;k<semanas.length;k++){
                            año.Impuestos[i].info[j].CR.push(0);
                        }
                    }
                    impuesto.info=Object.assign({},año.Impuestos[i].info[j]);
                    
                    //console.log(Math.round(año.Ventas[i].info[j].unidades/((parseInt(semanas[parseInt(e.target.id)].end)-parseInt(semanas[parseInt(e.target.id)].start))+1)))
                    let unitweek=0;
                    let numdias=this.state.numDias;
                    try{
                        //unitweek=Math.round(año.Impuestos[i].info[j].cantidad/((parseInt(semanas[this.state.ffindex].end)-parseInt(semanas[this.state.ffindex].start))+1));
                        unitweek=(año.Impuestos[i].info[j].cantidad/numdias*((parseInt(semanas[parseInt(this.state.ffindex)].end)-parseInt(semanas[parseInt(this.state.ffindex)].start))+1)).toFixed(2);
                        impuesto.info.cantidad=unitweek;
                        
                    }catch(e){
                        //console.log(e);
                    }
                    //console.log(prod);
                       
                    break;                
                }
            }
           impuestos.push(impuesto);
            
        }
        this.setState({ffproductos:impuestos});
    }
    //consultas
    Ccambioaño(e){
        let años=this.state.PF.Años;
        for(let i=0;i<años.length;i++){
            let año=años[i];
            if(año.numero===parseInt(e.target.value)){
                this.setState({canoseleccionado:año});
                this.setState({cmesseleccionado:1});
                break;
            }
        }
    }
    Ccambiomes(e){
        this.setState({cmesseleccionado:e.target.value});
        let año=this.state.canoseleccionado;
        let totalmesventas=0;
        let totalrealmesventas=0;
        let totalmescostos=0;
        let totalrealmescostos=0;
        for(let i=0;i<año.Ventas.length;i++){
            let producto=año.Ventas[i].info;            
            for(let j=0;j<producto.length;j++){
                if(producto[j].mes===parseInt(e.target.value)){
                    totalmesventas+=producto[j].unidades*producto[j].precio;
                    totalmescostos+=producto[j].unidades*producto[j].costo;
                    for(let z=0;z<producto[j].VR.length;z++){
                        totalrealmesventas+=producto[j].VR[z];
                    }
                    for(let z=0;z<producto[j].CR.length;z++){
                        totalrealmescostos+=producto[j].CR[z];
                    }                    
                    break;
                }
            }
        }
        año.totalmesventas=parseFloat(totalmesventas.toFixed(2));
        año.totalmescostos=parseFloat(totalmescostos.toFixed(2));
        año.totalmesrealventas=parseFloat(totalrealmesventas.toFixed(2));
        año.totalmesrealmescostos=parseFloat(totalrealmescostos.toFixed(2));
        let totalmesgastos=0;
        let totalrealmesgastos=0;
        for(let i=0;i<año.Gastos.length;i++){
            let gasto=año.Gastos[i].info;            
            for(let j=0;j<gasto.length;j++){
                if(gasto[j].mes===parseInt(e.target.value)){
                    totalmesgastos+=parseFloat(gasto[j].cantidad);
                    for(let z=0;z<gasto[j].CR.length;z++){
                        totalrealmesgastos+=parseFloat(gasto[j].CR[z]);
                    }
                    
                    break;
                }
            }
        }
        año.totalmesgastos=parseFloat((totalmesgastos-totalmescostos).toFixed(2));
        año.totalmesrealgastos=parseFloat((totalrealmesgastos-totalrealmescostos).toFixed(2));
        let totalmesimpuestos=0;
        let totalrealmesimpuestos=0;
        for(let i=0;i<año.Impuestos.length;i++){
            let impuesto=año.Impuestos[i].info;            
            for(let j=0;j<impuesto.length;j++){
                if(impuesto[j].mes===parseInt(e.target.value)){
                    totalmesimpuestos+=parseFloat(impuesto[j].cantidad);
                    for(let z=0;z<impuesto[j].CR.length;z++){
                        totalrealmesimpuestos+=parseFloat(impuesto[j].CR[z]);
                    }
                    break;
                }
            }
        }
        año.totalmesimpuestos=parseFloat(totalmesimpuestos.toFixed(2));
        año.totalmesrealimpuestos=parseFloat(totalrealmesimpuestos.toFixed(2));
        //console.log(año);
        this.setState({canoseleccionado:año});
    }
    consultar(e){
        let ano=this.state.canoseleccionado;
        let mes=this.state.cmesseleccionado
        let datos=[];
        this.setState({series:[]});
        this.setState({series2:[]})
        if(e.target.value==='1'){
            datos.push(ano.totalmesrealventas-ano.totalmesrealcostos)
            datos.push(ano.totalmesrealgastos);
            datos.push(ano.totalmesrealimpuestos);
            datos.push(ano.totalmesrealventas-ano.totalmesrealcostos-ano.totalmesrealgastos-ano.totalmesrealimpuestos)
            let data={
                labels:  ['Utilidad Bruta','Gastos','Impuestos','Utilidad Neta']
            }
            this.setState({options:data});
            this.setState({series:datos});
        }else if(e.target.value==="2"){
            let data={labels:[]}
            let restogastos=0;
            for(let i=0;i<ano.Gastos.length;i++){
                for(let j=0;j<ano.Gastos[i].info.length;j++){
                    if(ano.Gastos[i].info[j].mes===parseInt(mes)){
                        if(ano.Gastos[i].tipo==="Gasto Administrativo"){
                            datos.push(ano.Gastos[i].info[j].cantidadreal);
                            data.labels.push(ano.Gastos[i].nombre);
                        }else{
                            restogastos+=ano.Gastos[i].info[j].cantidadreal
                        }
                        break;
                    }
                }
            }
            datos.push(restogastos);
            data.labels.push('Resto de Gastos');
            this.setState({options:data});
            this.setState({series:datos});
        }else if(e.target.value==="3"){
            let data = {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                    '#f48024', '#69d2e7'
                ],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff']
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val+"%"
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                xaxis: {
                    categories: [],
                  },
                  yaxis: {
                    labels: {
                      show: false
                    }
                  },
            }
            for(let i=0;i<ano.Gastos.length;i++){
                for(let j=0;j<ano.Gastos[i].info.length;j++){
                    if(ano.Gastos[i].info[j].mes===parseInt(mes)){
                        if(ano.Gastos[i].tipo==="Gasto Administrativo"){
                            datos.push(parseFloat((ano.Gastos[i].info[j].cantidadreal/ano.totalmesrealventas*100).toFixed(2)));
                            data.xaxis.categories.push(ano.Gastos[i].nombre);
                        }
                        break;
                    }
                }
            }
            let series=[{'data':datos}];
            this.setState({options2:data});
            this.setState({series2:series});
        }else if(e.target.value==="4"){
            let data={labels:[]}
            let restogastos=0;
            for(let i=0;i<ano.Gastos.length;i++){
                for(let j=0;j<ano.Gastos[i].info.length;j++){
                    if(ano.Gastos[i].info[j].mes===parseInt(mes)){
                        if(ano.Gastos[i].tipo==="Gasto de Mercadotecnia"){
                            datos.push(ano.Gastos[i].info[j].cantidadreal);
                            data.labels.push(ano.Gastos[i].nombre);
                        }else{
                            restogastos+=ano.Gastos[i].info[j].cantidadreal
                        }
                        break;
                    }
                }
            }
            datos.push(restogastos);
            data.labels.push('Resto de Gastos');
            this.setState({options:data});
            this.setState({series:datos});
        }else if(e.target.value==="5"){
            let data = {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                    '#f48024', '#69d2e7'
                ],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff']
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val+"%"
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                xaxis: {
                    categories: [],
                  },
                  yaxis: {
                    labels: {
                      show: false
                    }
                  },
            }
            for(let i=0;i<ano.Gastos.length;i++){
                for(let j=0;j<ano.Gastos[i].info.length;j++){
                    if(ano.Gastos[i].info[j].mes===parseInt(mes)){
                        if(ano.Gastos[i].tipo==="Gasto de Mercadotecnia"){
                            datos.push(parseFloat((ano.Gastos[i].info[j].cantidadreal/ano.totalmesrealventas*100).toFixed(2)));
                            data.xaxis.categories.push(ano.Gastos[i].nombre);
                        }
                        break;
                    }
                }
            }
            let series=[{'data':datos}];
            this.setState({options2:data});
            this.setState({series2:series});
        }else if(e.target.value==="6"){
            let data={labels:[]}
            let restogastos=0;
            for(let i=0;i<ano.Gastos.length;i++){
                for(let j=0;j<ano.Gastos[i].info.length;j++){
                    if(ano.Gastos[i].info[j].mes===parseInt(mes)){
                        if(ano.Gastos[i].tipo==="Inventarios/Costos de Venta"){
                            datos.push(ano.Gastos[i].info[j].cantidadreal);
                            data.labels.push(ano.Gastos[i].nombre);
                        }else{
                            restogastos+=ano.Gastos[i].info[j].cantidadreal
                        }
                        break;
                    }
                }
            }
            datos.push(restogastos);
            data.labels.push('Resto de Gastos');
            this.setState({options:data});
            this.setState({series:datos});
        }else if(e.target.value==="7"){
            let data = {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                    '#f48024', '#69d2e7'
                ],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff']
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val+"%"
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                xaxis: {
                    categories: [],
                  },
                  yaxis: {
                    labels: {
                      show: false
                    }
                  },
            }
            for(let i=0;i<ano.Gastos.length;i++){
                for(let j=0;j<ano.Gastos[i].info.length;j++){
                    if(ano.Gastos[i].info[j].mes===parseInt(mes)){
                        if(ano.Gastos[i].tipo==="Inventarios/Costos de Venta"){
                            datos.push(parseFloat((ano.Gastos[i].info[j].cantidadreal/ano.totalmesrealventas*100).toFixed(2)));
                            data.xaxis.categories.push(ano.Gastos[i].nombre);
                        }
                        break;
                    }
                }
            }
            let series=[{'data':datos}];
            this.setState({options2:data});
            this.setState({series2:series});
        }else if(e.target.value==="8"){
            let data={labels:[]}
            let restogastos=0;
            for(let i=0;i<ano.Gastos.length;i++){
                for(let j=0;j<ano.Gastos[i].info.length;j++){
                    if(ano.Gastos[i].info[j].mes===parseInt(mes)){
                        if(ano.Gastos[i].tipo==="Otro gasto"){
                            datos.push(ano.Gastos[i].info[j].cantidadreal);
                            data.labels.push(ano.Gastos[i].nombre);
                        }else{
                            restogastos+=ano.Gastos[i].info[j].cantidadreal
                        }
                        break;
                    }
                }
            }
            datos.push(restogastos);
            data.labels.push('Resto de Gastos');
            this.setState({options:data});
            this.setState({series:datos});
        }else if(e.target.value==="9"){
            let data = {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                    '#f48024', '#69d2e7'
                ],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff']
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val+"%"
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                xaxis: {
                    categories: [],
                  },
                  yaxis: {
                    labels: {
                      show: false
                    }
                  },
            }
            for(let i=0;i<ano.Gastos.length;i++){
                for(let j=0;j<ano.Gastos[i].info.length;j++){
                    if(ano.Gastos[i].info[j].mes===parseInt(mes)){
                        if(ano.Gastos[i].tipo==="Otro gasto"){
                            datos.push(parseFloat((ano.Gastos[i].info[j].cantidadreal/ano.totalmesrealventas*100).toFixed(2)));
                            data.xaxis.categories.push(ano.Gastos[i].nombre);
                        }
                        break;
                    }
                }
            }
            let series=[{'data':datos}];
            this.setState({options2:data});
            this.setState({series2:series});
        }else if(e.target.value==="10"){
            let data={labels:[]}
            let restoimpuestos=0;
            for(let i=0;i<ano.Impuestos.length;i++){
                for(let j=0;j<ano.Impuestos[i].info.length;j++){
                    if(ano.Impuestos[i].info[j].mes===parseInt(mes)){
                        if(ano.Impuestos[i].tipo==="Impuestos"){
                            datos.push(ano.Impuestos[i].info[j].cantidadreal);
                            data.labels.push(ano.Impuestos[i].nombre);
                        }else{
                            restoimpuestos+=ano.Impuestos[i].info[j].cantidadreal
                        }
                        break;
                    }
                }
            }
            datos.push(restoimpuestos);
            data.labels.push('Resto de ITDA');
            this.setState({options:data});
            this.setState({series:datos});
        }else if(e.target.value==="11"){
            let data = {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                    '#f48024', '#69d2e7'
                ],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff']
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val+"%"
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                xaxis: {
                    categories: [],
                  },
                  yaxis: {
                    labels: {
                      show: false
                    }
                  },
            }
            for(let i=0;i<ano.Impuestos.length;i++){
                for(let j=0;j<ano.Impuestos[i].info.length;j++){
                    if(ano.Impuestos[i].info[j].mes===parseInt(mes)){
                        if(ano.Impuestos[i].tipo==="Impuestos"){
                            datos.push(parseFloat((ano.Impuestos[i].info[j].cantidadreal/ano.totalmesrealventas*100).toFixed(2)));
                            data.xaxis.categories.push(ano.Impuestos[i].nombre);
                        }
                        break;
                    }
                }
            }
            let series=[{'data':datos}];
            this.setState({options2:data});
            this.setState({series2:series});
        }else if(e.target.value==="12"){
            let data={labels:[]}
            let restoimpuestos=0;
            for(let i=0;i<ano.Impuestos.length;i++){
                for(let j=0;j<ano.Impuestos[i].info.length;j++){
                    if(ano.Impuestos[i].info[j].mes===parseInt(mes)){
                        if(ano.Impuestos[i].tipo==="Intereses"){
                            datos.push(ano.Impuestos[i].info[j].cantidadreal);
                            data.labels.push(ano.Impuestos[i].nombre);
                        }else{
                            restoimpuestos+=ano.Impuestos[i].info[j].cantidadreal
                        }
                        break;
                    }
                }
            }
            datos.push(restoimpuestos);
            data.labels.push('Resto de ITDA');
            this.setState({options:data});
            this.setState({series:datos});
        }else if(e.target.value==="13"){
            let data = {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                    '#f48024', '#69d2e7'
                ],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff']
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val+"%"
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                xaxis: {
                    categories: [],
                  },
                  yaxis: {
                    labels: {
                      show: false
                    }
                  },
            }
            for(let i=0;i<ano.Impuestos.length;i++){
                for(let j=0;j<ano.Impuestos[i].info.length;j++){
                    if(ano.Impuestos[i].info[j].mes===parseInt(mes)){
                        if(ano.Impuestos[i].tipo==="Intereses"){
                            datos.push(parseFloat((ano.Impuestos[i].info[j].cantidadreal/ano.totalmesrealventas*100).toFixed(2)));
                            data.xaxis.categories.push(ano.Impuestos[i].nombre);
                        }
                        break;
                    }
                }
            }
            let series=[{'data':datos}];
            this.setState({options2:data});
            this.setState({series2:series});
        }
    }
    //Simulador de valuación
    limitesimulacion(e){
        this.setState({[this.state.SVE.index]:-1},()=>{
            let anos=this.state.PF.Años;
            let index=parseInt(e.target.value)-1;
            let limites=[];
            for(let i=index;i<anos.length;i++){
                limites.push(anos[i]);
            }
            let sve=this.state.SVE;
            sve.limites=limites;
            sve.index=index;
            this.setState({SVE:sve},()=>{
                document.getElementById('selectSVE').value='default'
            });
        })
        
    }
    calculoebitda(e){
        let anos=this.state.PF.Años;
        let Ebitda=0;
        let limite=parseInt(e.target.value);
        for(let i=this.state.SVE.index;i<limite;i++){
            Ebitda+=anos[i].InvInicial+anos[i].totalventas-anos[i].totalcostos-Math.abs(anos[i].totalgastos)
        }
        let sve=this.state.SVE;
        sve.Ebitda=parseFloat(Ebitda.toFixed(2));
        sve.Inv=anos[this.state.SVE.index].InvInicial;
        sve.limite=limite;
        this.setState({SVE:sve},()=>this.calculovpn(limite));
    }
    calculovpn(limite){
        let anos=this.state.PF.Años;
        let vpn=0;
        let td=this.state.SVE.TD;
        td=td/100;
        let cont=1;
        for(let i=this.state.SVE.index;i<limite;i++){
            let ebitda=0;
            ebitda=anos[i].UPAnoanterior+anos[i].InvInicial+anos[i].totalventas-anos[i].totalcostos-Math.abs(anos[i].totalgastos);
            vpn+=ebitda/Math.pow(1+td,cont)
            cont++;
        }
        vpn=vpn-anos[this.state.SVE.index].InvInicial;
        let sve=this.state.SVE;
        sve.VPN=parseFloat(vpn.toFixed(2));
        this.setState({SVE:sve},()=>this.calculove(limite));
    }
    calculove(limite){
        //let anos=this.state.PF.Años;
        let td=this.state.SVE.TD;
        td=td/100;
        let ve=0;
        let anosaevaludados=this.state.SVE.limite-this.state.SVE.index
        ve=(this.state.SVE.Ebitda/Math.pow(1+td,anosaevaludados))+this.state.SVE.VPN;
        let sve=this.state.SVE;
        if(ve<0){
            sve.VE='La información capturada no es correcta para determinar una valuación';
        }else{
            sve.VE=parseFloat(ve.toFixed(2));
        }
        this.setState({SVE:sve},()=>this.calculonc(limite));
    }
    calculonc(limite){
        let anos=this.state.PF.Años;
        let nc=0;
        let cont=0;
        for(let i=this.state.SVE.index;i<limite;i++){
            
            if(cont>1){
                break;
            }else if(anos[i].totalventas-anos[i].totalcostos-Math.abs(anos[i].totalgastos)>0){
                break;
            }else{
                nc+=anos[i].totalventas-anos[i].totalcostos-Math.abs(anos[i].totalgastos);
            }
            cont++
        }
        let sve=this.state.SVE;
        sve.NC=Math.abs(parseFloat(nc.toFixed(2)));
        if(isNaN(sve.VE)){
            sve.PAEV='La información capturada en la corrida financiera no es suficiente para arrojar un porcentaje de acciones';
        }else{
            sve.PAEV=parseFloat((sve.NC*100/sve.VE).toFixed(2));
        }
        
        
        this.setState({SVE:sve},()=>this.calculoped());
    }
    calculomvc(limite){
        let sve=this.state.SVE;
        let ccr=sve.CCR;
        let td=sve.TD/100;
        let paev=sve.PAEV/100;
        let multiplo=0;
        let incrementoV=0;
        let numanos=sve.limite-sve.index;
        for(let i=0;i<this.state.crecimientoAnual.length;i++){
            incrementoV+=parseFloat(this.state.crecimientoAnual[i]);
        }
        incrementoV=incrementoV/this.state.crecimientoAnual.length;
        if(incrementoV>=350){
            multiplo=8;
        }
        if(incrementoV<350&&incrementoV>=325){
            multiplo=7.5;
        }
        if(incrementoV<325&&incrementoV>=300){
            multiplo=7;
        }
        if(incrementoV<300&&incrementoV>=275){
            multiplo=6.5;
        }
        if(incrementoV<275&&incrementoV>=250){
            multiplo=6;
        }
        if(incrementoV<250&&incrementoV>=225){
            multiplo=5.5;
        }
        if(incrementoV<225&&incrementoV>=200){
            multiplo=5;
        }
        if(incrementoV<200&&incrementoV>=175){
            multiplo=4.5;
        }
        if(incrementoV<175&&incrementoV>=150){
            multiplo=4;
        }
        if(incrementoV<150&&incrementoV>=125){
            multiplo=3.5;
        }
        if(ccr<0.06){
            multiplo+=.5
        }
        if(ccr<.125){
            multiplo+=.5
        }
        if(ccr<.25){
            multiplo+=.5
        }
        if(ccr<.50){
            multiplo+=.5
        }
        if(ccr<.75){
            multiplo+=.5
        }
        if(ccr<1){
            multiplo+=.5
        }
        if(ccr>=1.5){
            multiplo-=.5
        }
        if(ccr>=2){
            multiplo-=.5
        }
        if(ccr>=2.5){
            multiplo-=.5
        }
        if(ccr>=3){
            multiplo-=.5
        }
        if(ccr>=3.5){
            multiplo-=.5
        }
        if(ccr>=4){
            multiplo-=.5
        }
        if(multiplo>=8){
            multiplo=8;
        }
        if(multiplo<=1.5){
            multiplo=1.5
        }
        sve.VMVC=((sve.Ebitda*multiplo)*(1-paev))/Math.pow(1+td,numanos);
        sve.VMVC=parseFloat(sve.VMVC.toFixed(2));
        sve.VP=parseFloat(((sve.VMVC+sve.VE)/2).toFixed(2));
        this.setState({[this.state.SVE]:sve});
    }
    calculoped(){
        let anos=this.state.PF.Años;
        let acumebitda=0;
        let acuminv=0;
        let acumventas=0;
        let acumcostos=0;
        let acumgastos=0;
        let puntoEqmes=0;
        let band=false
        for(let i=0;i<anos.length;i++){
            acuminv+=anos[i].InvInicial;
            for(let mes=0;mes<11;mes++){
                let costo=0;
                for(let j=0;j<anos[i].Ventas.length;j++){
                    acumventas+=(anos[i].Ventas[j].info[mes].unidades*anos[i].Ventas[j].info[mes].precio);
                    acumcostos+=(anos[i].Ventas[j].info[mes].unidades*anos[i].Ventas[j].info[mes].costo);
                    costo+=(anos[i].Ventas[j].info[mes].unidades*anos[i].Ventas[j].info[mes].costo)
                }
                for(let j=0;j<anos[i].Gastos.length;j++){
                    acumgastos+=parseFloat(anos[i].Gastos[j].info[mes].cantidad);
                    if(anos[i].Gastos[j].tipo==="Inventarios/Costos de Venta"){
                        acumgastos-=costo;
                    }                    
                }
                acumebitda=acumventas-acumcostos-Math.abs(acumgastos)-parseFloat(acuminv);
                if(acumebitda>=0){
                    puntoEqmes+=1
                    band=true;
                    break;
                }else{
                    puntoEqmes+=1;
                }
            }
            if(band){
                break;
            }
        }
        let sve=this.state.SVE;
        sve.PED=acumventas;
        sve.PEM=puntoEqmes;
        if(!band){
            puntoEqmes+=1
            sve.PED=''
            sve.PEM='La proyección financiera no cuenta con suficiente información para determinar un punto de equilibrio.'
        }
        this.setState({SVE:sve},()=>this.calculoroi());
    }
    calculoroi(){
        let sve=this.state.SVE;
        sve.ROIE=parseFloat(((sve.Ebitda-sve.Inv)/sve.Inv).toFixed(2));
        //let dif=sve.limite-sve.index;
        sve.ROI=parseFloat((sve.VE/(sve.VE+sve.NC)).toFixed(2));
        this.setState({SVE:sve});
    }
    render(){
        return(
            <div className="ml-8">
                <div className="">

                    <div className="col-span-2 title">

                        Finanzas
                    </div>

                    <div className="flex">

                        <div className="subtitle mr-10 active mt-4 w-26" id="PF" onClick={()=>{
                        document.getElementById('rectSVE').classList.add('hiddenNone');
                        document.getElementById('rectFF').classList.add('hiddenNone');
                        document.getElementById('rectC').classList.add('hiddenNone');
                        document.getElementById('SVE').classList.remove('active');
                        document.getElementById('FF').classList.remove('active');
                        document.getElementById('C').classList.remove('active');
                        document.getElementById('PF').classList.add('active');
                        document.getElementById('rectPF').classList.remove('hiddenNone');
                        document.getElementById('PFQuestions').classList.remove('hiddenNone');
                        document.getElementById('SVEQuestions').classList.add('hiddenNone');
                        document.getElementById('FFQuestions').classList.add('hiddenNone');
                        document.getElementById('CQuestions').classList.add('hiddenNone');
                        }}>
                            Proyección Financiera
                            <div className="rectangle" id="rectPF"></div>
                        </div>
                        <div className="subtitle mr-10 mt-4 w-26" id="SVE" onClick={()=>{
                        document.getElementById('rectPF').classList.add('hiddenNone');
                        document.getElementById('rectFF').classList.add('hiddenNone');
                        document.getElementById('rectC').classList.add('hiddenNone');
                        document.getElementById('FF').classList.remove('active');
                        document.getElementById('PF').classList.remove('active');
                        document.getElementById('C').classList.remove('active');
                        document.getElementById('SVE').classList.add('active');
                        document.getElementById('rectSVE').classList.remove('hiddenNone');
                        document.getElementById('SVEQuestions').classList.remove('hiddenNone');
                        document.getElementById('PFQuestions').classList.add('hiddenNone');
                        document.getElementById('FFQuestions').classList.add('hiddenNone');
                        document.getElementById('CQuestions').classList.add('hiddenNone');
                        this.setState({[this.state.SVE.index]:-1},()=>{
                            document.getElementById('selectSVEindex').value='default'
                        });
                        }}>
                            Simulador de valuación de empresa
                            <div className="rectangle hiddenNone" id="rectSVE"></div>
                        </div>
                        <div className="subtitle ml-10 mt-4 w-26"  id="FF" onClick={()=>{
                        document.getElementById('rectPF').classList.add('hiddenNone');
                        document.getElementById('rectSVE').classList.add('hiddenNone');
                        document.getElementById('rectC').classList.add('hiddenNone');
                        document.getElementById('PF').classList.remove('active');
                        document.getElementById('SVE').classList.remove('active');
                        document.getElementById('C').classList.remove('active');
                        document.getElementById('FF').classList.add('active');
                        document.getElementById('rectFF').classList.remove('hiddenNone');
                        document.getElementById('FFQuestions').classList.remove('hiddenNone');
                        document.getElementById('PFQuestions').classList.add('hiddenNone');
                        document.getElementById('SVEQuestions').classList.add('hiddenNone');
                        document.getElementById('CQuestions').classList.add('hiddenNone');
                        let value=this.state.PF.Años[0]; 
                        this.setState({ffañoseleccionado:value});
                        this.setState({seleccion:''})
                        this.setState({PFselecciongasto:''});
                        }}>
                            Finance Fitness
                        <div className="rectangle hiddenNone" id="rectFF"></div>
                        </div>
                        <div className="subtitle ml-10 mt-4 w-26"  id="C" onClick={()=>{
                        document.getElementById('rectPF').classList.add('hiddenNone');
                        document.getElementById('rectSVE').classList.add('hiddenNone');
                        document.getElementById('rectFF').classList.add('hiddenNone');
                        document.getElementById('PF').classList.remove('active');
                        document.getElementById('SVE').classList.remove('active');
                        document.getElementById('FF').classList.remove('active');
                        document.getElementById('C').classList.add('active');
                        document.getElementById('rectC').classList.remove('hiddenNone');
                        document.getElementById('CQuestions').classList.remove('hiddenNone');
                        document.getElementById('PFQuestions').classList.add('hiddenNone');
                        document.getElementById('SVEQuestions').classList.add('hiddenNone');
                        document.getElementById('FFQuestions').classList.add('hiddenNone');
                        let value=this.state.PF.Años[0]; 
                        this.setState({canoseleccionado:value});
                        }}>
                            Gráficas
                        <div className="rectangle hiddenNone" id="rectC"></div>
                        </div>                    
                    </div>
                   
                   <div className='line'></div>
                </div>
                <div className="mt-8"  id="PFQuestions">

                <div className="bg-gray-100 rounded-xl mt-4 mb-4">
                    <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[0].classList.toggle('colapse');                         
                         var content=document.querySelectorAll('.accordionarrow');
                         for(let i=0;i<2;i++){
                            content[i].classList.toggle('hiddenNone');
                         }
                     }}>PROYECCIÓN FINANCIERA <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 accordion">Una proyección financiera es un análisis que te permite calcular "desde el punto de vista financiero y contable" cuál será el desenvolvimiento de tu empresa o negocio en un futuro determinado. De esta manera, podrás anticipar las eventuales utilidades o pérdidas del proyecto, así como conocer el punto de equilibrio y la necesidad de capital que ocuparas para crecer tu startup.</div>
                </div> 

                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    {this.state.PF.Años.map(año=>
                                        <th key={año.numero}>
                                            <div className="cuadroanio text-md font-bold text-white p-5">AÑO {año.numero}
                                            
                                            </div>
                                            <input type="number" id={año.numero} defaultValue={año.alias} onChange={this.cambioalias.bind(this)} className="w-20"/>
                                        </th>
                                    )}
                                    <th className="interactive p-1 px-2" onClick={this.crearaño.bind(this)}>Crear<br/>siguiente<br/>año</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                         (+) Utilidad ó <br/>Perdida Neta <br/>año anterior
                                        </div>
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td className="" key={año.numero} >
                                            $ {año.UPAnoanterior}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (+) Inversión Inicial
                                        </div>
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td className="interactive" id={año.numero} key={año.numero} onClick={this.mostrarinversiones.bind(this)}>
                                            ${año.InvInicial}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (+) Ventas
                                        </div>
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td className="interactive" key={año.numero} id={año.numero} onClick={this.mostrarventas.bind(this)}>
                                            $ {año.totalventas}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                         Crecimiento Anual
                                        </div>
                                    </td>
                                    {this.state.crecimientoAnual.map((cant,index)=>
                                        <td key={index} >
                                             {cant}%
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-bold bg-yellow-400 text-white p-2">
                                        (-) Costo de Ventas
                                        </div>
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td className="interactive" key={año.numero} id={año.numero} onClick={this.mostrarcostos.bind(this)}>
                                            $ {año.totalcostos}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                        (=) Utilidad Bruta
                                        </div>
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td key={año.numero} >
                                            $ {(año.UPAnoanterior+año.InvInicial+año.totalventas-año.totalcostos).toFixed(2)}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                         Margen de <br/>Contribución
                                        </div>
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td key={año.numero} >
                                             {isNaN(((año.UPAnoanterior+año.InvInicial+año.totalventas-año.totalcostos)*100/año.totalventas).toFixed(2)) ? 0: ((año.totalventas-año.totalcostos)*100/año.totalventas).toFixed(2)}%
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-bold bg-yellow-400 text-white p-2">
                                        (-) Gastos <br/>Operativos
                                        </div>
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td className="interactive" key={año.numero} id={año.numero} onClick={this.mostrargastos.bind(this)}>
                                            $ {año.totalgastos}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                        (=) EBITDA
                                        </div>
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td key={año.numero}>
                                            $ {(año.UPAnoanterior+año.InvInicial+año.totalventas-año.totalcostos-Math.abs(año.totalgastos)).toFixed(2)}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                         % EBITDA
                                        </div>
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td key={año.numero}>
                                             {isNaN(((año.UPAnoanterior+año.InvInicial+año.totalventas-año.totalcostos-Math.abs(año.totalgastos))*100/año.totalventas).toFixed(2)) ? 0: ((año.totalventas-año.totalcostos-año.totalgastos)*100/año.totalventas).toFixed(2)}%
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-bold bg-yellow-400 text-white p-2">
                                        (-) Impuestos,<br/>intereses,<br/>depreciaciones<br/>y amortizaciones
                                         </div>   
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td className="interactive" key={año.numero} id={año.numero} onClick={this.mostrarimpuestos.bind(this)}>
                                            $ {año.totalimpuestos}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                        (=) Utilidad ó<br/>Pérdida Neta
                                        </div> 
                                    </td>
                                    {this.state.PF.Años.map(año=>
                                        <td key={año.numero}>
                                            $ {(año.UPAnoanterior+año.InvInicial+año.totalventas-año.totalcostos-Math.abs(año.totalgastos)-año.totalimpuestos).toFixed(2)}
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                    {this.state.seleccion==="inversiones" && 
                    <div className="tabla mt-8">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Inversiones<br/> Año {this.state.anoseleccionado.numero}</th>
                                    <th>Enero</th><th>Febreo</th><th>Marzo</th><th>Abril</th><th>Mayo</th><th>Junio</th><th>Julio</th><th>Agosto</th><th>Septiembre</th><th>Octubre</th><th>Noviembre</th><th>Diciembre</th><th>Total</th>
                                </tr>
                            </thead>
                            
                                    {this.state.anoseleccionado.Inversiones.map(inversion=><tbody key={inversion.nombre}>
                                        <tr>
                                            <td ><input className="nombre" type="text" id={inversion.nombre+'-n'} defaultValue={inversion.nombre} placeholder="nombre" onBlur={this.cambioinversion.bind(this)} /></td>
                                            {inversion.info.map(info1=><td key={inversion.nombre+info1.mes}><input className="numero" type="text" id={inversion.nombre+"-"+info1.mes} defaultValue={info1.cantidad} onBlur={this.actualizarinversion.bind(this)}/></td>)}
                                            <td>{inversion.totalcantidad}</td>
                                        </tr>
                                    </tbody>)}
                            <tfoot>
                                <tr>
                                    <td className="interactive" onClick={this.crearinversion.bind(this)}>+ Crear Nueva<br/>Inversión</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>} 
                    {this.state.seleccion==="ventas" && 
                    <div className="tabla mt-8">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Concepto</th>
                                    <th>Ventas Año {this.state.anoseleccionado.numero}</th>
                                    <th>Enero</th><th>Febrero</th><th>Marzo</th><th>Abril</th><th>Mayo</th><th>Junio</th><th>Julio</th><th>Agosto</th><th>Septiembre</th><th>Octubre</th><th>Noviembre</th><th>Diciembre</th><th>Total</th>
                                </tr>
                            </thead>
                            
                                    {this.state.anoseleccionado.Ventas.map(producto=><tbody key={producto.nombre+'u'}>
                                        <tr>
                                            <td rowSpan={3}><input className="nombre" placeholder="nombre" type="text" defaultValue={producto.nombre} id={producto.nombre} onBlur={this.cambioventas.bind(this)} /></td>
                                            <td>Unidades</td>
                                            {producto.info.map(info1=><td key={producto.nombre+info1.mes}><input className="numero" type="number" min="1" step="1" defaultValue={info1.unidades} id={producto.nombre+'-'+info1.mes+'-u'} onBlur={this.actualizarventas.bind(this)}/></td>)}
                                            <td>{producto.totalunidades}</td>
                                        </tr>
                                        <tr>
                                            <td>Precio de<br/> venta</td>
                                            {producto.info.map(info1=><td key={producto.nombre+info1.mes}><input className="numero" type="text" defaultValue={info1.precio} id={producto.nombre+'-'+info1.mes+'-p'} onBlur={this.actualizarventas.bind(this)}/></td>)}
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>Total de<br/> ventas</td>
                                            {producto.info.map(info1=><td key={producto.nombre+info1.mes}>{(info1.unidades*info1.precio).toFixed(2)}</td>)}
                                            <td>{producto.totalprecio}</td>
                                        </tr>
                                    </tbody>)}
                            <tfoot>
                                <tr>
                                    <td className="interactive" onClick={this.crearproducto.bind(this)}>+ Crear Nuevo<br/>Producto</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>}
                    {this.state.seleccion==="costos" && 
                    <div className="tabla mt-8">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Concepto</th>
                                    <th>Costos Año {this.state.anoseleccionado.numero}</th>
                                    <th>Enero</th><th>Febrero</th><th>Marzo</th><th>Abril</th><th>Mayo</th><th>Junio</th><th>Julio</th><th>Agosto</th><th>Septiembre</th><th>Octubre</th><th>Noviembre</th><th>Diciembre</th><th>Total</th>
                                </tr>
                            </thead>
                            
                                    {this.state.anoseleccionado.Ventas.map(producto=><tbody key={producto.nombre+'u'}>
                                        <tr>
                                            <td rowSpan={3}><input className="nombre" placeholder="nombre" type="text" defaultValue={producto.nombre} id={producto.nombre} onBlur={this.cambioventas.bind(this)}/></td>
                                            <td>Unidades</td>
                                            {producto.info.map(info1=><td key={producto.nombre+info1.mes}>{info1.unidades}</td>)}
                                            <td>{producto.totalunidades}</td>
                                        </tr>
                                        <tr>
                                            <td>Costo de venta</td>
                                            {producto.info.map(info1=><td key={producto.nombre+info1.mes}><input className="numero" type="text" defaultValue={info1.costo} id={producto.nombre+'-'+info1.mes+'-c'} onBlur={this.actualizarventas.bind(this)}/></td>)}
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>Total de costo</td>
                                            {producto.info.map(info1=><td key={producto.nombre+info1.mes}>{(info1.unidades*info1.costo).toFixed(2)}</td>)}
                                            <td>{producto.totalcosto}</td>
                                        </tr>
                                    </tbody>)}
                            <tfoot>
                                <tr>
                                    <td className="interactive" onClick={this.crearproducto.bind(this)}>+ Crear Nuevo<br/>Producto</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>}
                    {this.state.seleccion==="gastos" && 
                    <div className="tabla mt-8">
                        <select defaultValue='default' onChange={this.selecciongasto.bind(this)}>
                            <option value="default" disabled hidden>Escoge un Gasto</option>
                            <option value="Gasto Nómina">Gasto Nómina</option>
                            <option value="Gasto Administrativo">Gasto Administrativo</option>
                            <option value="Gasto de Mercadotecnia">Gasto de Mercadotecnia</option>
                            <option value="Inventarios/Costos de Venta">Inventarios/Costos de Venta</option>
                            <option value="Otro gasto">Otro gasto</option>
                            <option value="Ver todos">Ver todos</option>
                        </select>
                        {this.state.PFselecciongasto!==''&&
                            <table className="">
                            <thead>
                                <tr>
                                    <th>Concepto</th>
                                    <th>Gastos operativos<br/> Año {this.state.anoseleccionado.numero}</th>
                                    <th>Enero</th><th>Febrero</th><th>Marzo</th><th>Abril</th><th>Mayo</th><th>Junio</th><th>Julio</th><th>Agosto</th><th>Septiembre</th><th>Octubre</th><th>Noviembre</th><th>Diciembre</th><th>Total</th>
                                </tr>
                            </thead>
                                    {this.state.PFGastos.map(gasto=><tbody key={gasto.nombre}>
                                        <tr>
                                            <td><select id={gasto.nombre+'-t'} defaultValue={gasto.tipo} onChange={this.cambiogasto.bind(this)}>
                                                <option value="Gasto Nómina">Gasto Nómina</option>
                                                <option value="Gasto Administrativo">Gasto Administrativo</option>
                                                <option value="Gasto de Mercadotecnia">Gasto de Mercadotecnia</option>
                                                <option value="Inventarios/Costos de Venta">Inventarios/Costos de Venta</option>
                                                <option value="Otro gasto">Otro gasto</option>
                                            </select></td>
                                            <td><input className="nombre" type="text" placeholder="nombre" id={gasto.nombre+'-n'} defaultValue={gasto.nombre} onBlur={this.cambiogasto.bind(this)} /></td>
                                            {gasto.info.map(info1=><td key={gasto.nombre+info1.mes}><input className="numero" type="text" id={gasto.nombre+"-"+info1.mes} defaultValue={info1.cantidad} onBlur={this.actualizargastos.bind(this)}/></td>)}
                                            <td>{gasto.totalcantidad}</td>
                                        </tr>
                                        
                                    </tbody>)}
                            <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {(this.state.PFselecciongasto!=='Ver todos') &&<td colSpan={3} className="cuadroanio text-md font-bold text-white p-3">Total de {this.state.PFselecciongasto}</td>}
                                {this.state.PFselecciongasto==='Ver todos' &&<td colSpan={3} className="cuadroanio text-md font-bold text-white p-3">Total de Gastos operativos</td>}
                                {(this.state.PFselecciongasto==='Ver todos' || this.state.PFselecciongasto==='Inventarios/Costos de Venta') &&<td> {this.state.PFtotalgastos}</td>}
                                {(this.state.PFselecciongasto!=='Ver todos' && this.state.PFselecciongasto!=='Inventarios/Costos de Venta') &&<td> {this.state.PFtotalgastos}</td>}
                               
                            </tr>
                            </tbody>
                            {this.state.PFselecciongasto!=='Ver todos' &&<tfoot>
                                <tr>
                                    <td className="interactive" onClick={this.creargasto.bind(this)}>+ Crear Nuevo<br/>Gasto</td>
                                </tr>
                            </tfoot>}
                        </table>}
                    </div>}
                    {this.state.seleccion==="impuestos" && 
                    <div className="tabla mt-8">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Concepto</th>
                                    <th>ITDA<br/> Año {this.state.anoseleccionado.numero}</th>
                                    <th>Enero</th><th>Febreo</th><th>Marzo</th><th>Abril</th><th>Mayo</th><th>Junio</th><th>Julio</th><th>Agosto</th><th>Septiembre</th><th>Octubre</th><th>Noviembre</th><th>Diciembre</th><th>Total</th>
                                </tr>
                            </thead>
                            
                                    {this.state.anoseleccionado.Impuestos.map(impuesto=><tbody key={impuesto.nombre}>
                                        <tr>
                                            <td><select className="nombre" id={impuesto.nombre+'-t'} defaultValue={impuesto.tipo} onChange={this.cambioimpuesto.bind(this)}>
                                                <option value="Impuestos">Impuestos</option>
                                                <option value="Intereses">Intereses</option>
                                                <option value="Depreciaciones">Depreciaciones</option>
                                                <option value="Amortizaciones">Amortizaciones</option>
                                                <option value="CapEx">CapEx</option>
                                                <option value="Non-cash working capital">Non-cash working capital</option>
                                                <option value="Otro">Otro</option>
                                            </select></td>
                                            <td ><input className="nombre" type="text" id={impuesto.nombre+'-n'} defaultValue={impuesto.nombre} placeholder="nombre" onBlur={this.cambioimpuesto.bind(this)} /></td>
                                            {impuesto.info.map(info1=><td key={impuesto.nombre+info1.mes}><input className="numero" type="text" id={impuesto.nombre+"-"+info1.mes} defaultValue={info1.cantidad} onBlur={this.actualizarimpuesto.bind(this)}/></td>)}
                                            <td>{impuesto.totalcantidad}</td>
                                        </tr>
                                    </tbody>)}
                            <tfoot>
                                <tr>
                                    <td className="interactive" onClick={this.crearimpuesto.bind(this)}>+ Crear Nuevo<br/>ITDA</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>}       
                                 
                </div>
                <div className="mt-8 hiddenNone"  id="SVEQuestions">

                <div className="bg-gray-100 rounded-xl mt-4 mb-4">
                    <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[1].classList.toggle('colapse');                         
                         var content=document.querySelectorAll('.accordionarrow');
                         for(let i=2;i<4;i++){
                            content[i].classList.toggle('hiddenNone');
                         }
                     }}>PROYECCIÓN FINANCIERA <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 accordion">Una proyección financiera es un análisis que te permite calcular "desde el punto de vista financiero y contable" cuál será el desenvolvimiento de tu empresa o negocio en un futuro determinado. De esta manera, podrás anticipar las eventuales utilidades o pérdidas del proyecto, así como conocer el punto de equilibrio y la necesidad de capital que ocuparas para crecer tu startup.</div>
                     <div>
                     <label className="mr-4">Selecciona un año</label>
                        <select id="selectSVEindex" defaultValue="default" onChange={this.limitesimulacion.bind(this)}>
                            <option value="default" disabled hidden>Selecciona</option>
                            {this.state.PF.Años.map(año=>
                                <option key={año.numero} value={año.numero}>
                                    Año {año.alias}
                                </option>
                            )}
                        </select>
                        {this.state.SVE.index!==-1 &&<div>
                            <label className="mr-4">Hasta que año</label>
                        <select id="selectSVE" defaultValue="default" onChange={this.calculoebitda.bind(this)}>
                            <option value="default" disabled hidden>Selecciona</option>
                            {this.state.SVE.limites.map(año=>
                                <option key={año.numero} value={año.numero}>
                                    Año {año.alias}
                                </option>
                            )}
                        </select>
                        </div>}
                        
                     </div>
                     <div className="grid grid-cols-2 mt-8">
                        <div>Inversión inicial del año</div>
                        <div>${this.state.SVE.Inv}</div>
                        <div>Ebitda Acumulado</div>
                        <div>${this.state.SVE.Ebitda}</div>
                        <div>Tasa de descuento (5% a 20%)</div>
                        <div><input type="number" step="0.01" defaultValue={this.state.SVE.TD} onBlur={(e)=>{
                            let sve=this.state.SVE;
                            sve.TD=parseFloat(e.target.value);
                            this.setState({SVE:sve},()=>this.calculovpn(this.state.SVE.limite))
                        }}/></div>
                        <div>Valor presente neto</div>
                        <div>${this.state.SVE.VPN}</div>
                        <div>Valuación de la empresa método flujos descontados</div>
                        <div>${this.state.SVE.VE}</div>
                        <div>Necesidad capital</div>
                        <div>${this.state.SVE.NC}</div>
                        <div>Porcentaje de acciones de la empresa a vender (equity)</div>
                        <div>{this.state.SVE.PAEV}%</div>
                        <div>Churn rate</div>
                        <div><input type="number" step="0.01" defaultValue={this.state.SVE.CCR} onBlur={(e)=>{
                            let sve=this.state.SVE;
                            sve.CCR=parseFloat(e.target.value);
                            this.setState({SVE:sve},()=>this.calculomvc(this.state.SVE.limite))
                        }}/></div>
                        <div>Valuación de la empresa método Venture Capital</div>
                        <div>${this.state.SVE.VMVC}</div>
                        <div>Valuación de la empresa Ponderada entre ambos métodos</div>
                        <div>${this.state.SVE.VP}</div>
                        <div>Punto de equilibrio en dinero</div>
                        <div>${this.state.SVE.PED}</div>
                        <div>Punto de equilibrio en meses</div>
                        <div>{this.state.SVE.PEM} meses</div>
                        <div>Retorno de Inversión Inversionistas</div>
                        <div>{(this.state.SVE.ROI*100).toFixed(2)}% --- {this.state.SVE.ROI} veces</div>
                        <div>Retorno de Inversión Empresa</div>
                        <div>{(this.state.SVE.ROIE*100).toFixed(2)}% --- {this.state.SVE.ROIE} veces</div>
                     </div>
                </div>               
                </div>
                <div className="mt-8 hiddenNone"  id="FFQuestions">

                <div className="bg-gray-100 rounded-xl mt-4 mb-4">
                    <div className="text-gray-600 pt-4 px-4 text-lg flex" onClick={(e)=>{
                         document.getElementsByClassName("accordion")[2].classList.toggle('colapse');                         
                         var content=document.querySelectorAll('.accordionarrow');
                         for(let i=4;i<6;i++){
                            content[i].classList.toggle('hiddenNone');
                         }
                     }}>PROYECCIÓN FINANCIERA <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                     <div className="text-xs text-gray-400 p-4 accordion">Una proyección financiera es un análisis que te permite calcular "desde el punto de vista financiero y contable" cuál será el desenvolvimiento de tu empresa o negocio en un futuro determinado. De esta manera, podrás anticipar las eventuales utilidades o pérdidas del proyecto, así como conocer el punto de equilibrio y la necesidad de capital que ocuparas para crecer tu startup.</div>
                </div>
                <div className="flex">
                    <div>
                        <div className="mt-4">
                            <label className="mr-4">Selecciona un año</label><br/>
                            <select onChange={this.cambioaño.bind(this)}>
                                {this.state.PF.Años.map(año=>
                                    <option key={año.numero} value={año.numero}>
                                        Año {año.alias}
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className="mt-4">
                            <label className="mr-4">Selecciona un mes</label><br/>
                            <select id="selectmes" onChange={this.cambiomes.bind(this)} defaultValue="default">
                                <option value="default" disabled hidden>Escoge un mes</option>
                                <option value={1}>Enero</option>
                                <option value={2}>Febrero</option>
                                <option value={3}>Marzo</option>
                                <option value={4}>Abril</option>
                                <option value={5}>Mayo</option>
                                <option value={6}>Junio</option>
                                <option value={7}>Julio</option>
                                <option value={8}>Agosto</option>
                                <option value={9}>Septiembre</option>
                                <option value={10}>Octubre</option>
                                <option value={11}>Noviembre</option>
                                <option value={12}>Diciembre</option>
                            </select>
                        </div>
                    {(this.state.ffsemanas.length!==0 )&& <div className="mt-4"><label className="mr-4">Selecciona una semana</label><br/><select onChange={this.todosemana.bind(this)} defaultValue="default">
                        <option value="default" disabled hidden>Escoge una semana</option>
                    {this.state.ffsemanas.map((sem,index)=><option key={sem.start} id={index} value={index} >
                                    Semana del {sem.start} al {sem.end}
                                </option>)}
                                <option value={'todas'}>Ver todas</option>
                        </select>
                        </div>}
                        </div>
                        <div>
                        {this.state.date.length!==0 && 
                            <DateRange
                                onChange={(e)=>{}}
                                showDateDisplay={false}
                                moveRangeOnFirstSelection={false}
                                showMonthAndYearPickers={false}
                                ranges={this.state.date}/>
                            }
                        </div>
                        
                </div> 
                    <div>
                        <table >
                        <thead>
                             <tr>
                                 <th></th>
                                 <th colSpan={5} className="cuadroanio text-md font-bold text-white ">Anual</th>
                                 <th colSpan={6} className="cuadroanio text-md font-bold text-white ">Mensual</th>
                             </tr>
                                <tr>
                                    <th></th>
                                    <th>
                                        <div className="cuadroanio text-md font-bold text-white p-3">Total<br/>proyectado</div>
                                    </th>
                                    <th>
                                    <div className="cuadroanio text-md font-bold text-white p-3">Total<br/>Real</div>
                                    </th>
                                    <th colSpan={2}>
                                    <div className="cuadroanio text-md font-bold text-white p-3">Diferencia</div>
                                    </th>
                                    <th>
                                    <div className="cuadroanio text-md font-bold text-white p-3">Acumulado</div>
                                    </th>
                                    <th>
                                        <div className="cuadroanio text-md font-bold text-white p-3">Total<br/>proyectado</div>
                                    </th>
                                    <th>
                                        <div className="cuadroanio text-md font-bold text-white p-3">Total<br/>Real</div>
                                    </th>
                                    <th>
                                    <div className="cuadroanio text-md font-bold text-white p-3">%<br/>con Ventas</div>
                                    </th>
                                    <th colSpan={2}>
                                        <div className="cuadroanio text-md font-bold text-white p-3">Diferencia</div>
                                    </th>
                                    <th>
                                        <div className="cuadroanio text-md font-bold text-white p-3">Acumulado</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                        Utilidad ó <br/>Perdida Neta<br/>Año anterior
                                        </div>
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.UPAnoanterior}
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.UPAnoanteriorreal}
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.UPAnoanteriorreal-this.state.ffañoseleccionado.UPAnoanterior).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.UPAnoanteriorreal/this.state.ffañoseleccionado.UPAnoanterior).toFixed(2)) ? 0: (this.state.ffañoseleccionado.UPAnoanteriorreal/this.state.ffañoseleccionado.UPAnoanterior).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.UPAnoanteriorreal/this.state.ffañoseleccionado.UPAnoanteriorreal).toFixed(2)) ? 0: (this.state.ffañoseleccionado.UPAnoanteriorreal/this.state.ffañoseleccionado.UPAnoanterior).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.UPAnoanteriormes}
                                    </td>
                                    <td className="fftotales" >
                                        ${this.state.ffañoseleccionado.UPAnoanteriorrealmes}
                                    </td>
                                    <td className="fftotales">
                                        -
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.UPAnoanteriorrealmes-this.state.ffañoseleccionado.UPAnoanteriormes).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.UPAnoanteriorrealmes/this.state.ffañoseleccionado.UPAnoanteriormes).toFixed(2)) ? 0: (this.state.ffañoseleccionado.UPAnoanteriorrealmes/this.state.ffañoseleccionado.UPAnoanteriormes).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.UPAnoanteriorrealmes/this.state.ffañoseleccionado.UPAnoanteriormes).toFixed(2)) ? 0: (this.state.ffañoseleccionado.UPAnoanteriorrealmes/this.state.ffañoseleccionado.UPAnoanteriormes).toFixed(2)}%
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                        Inversión Inicial
                                        </div>
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.InvInicial}
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.InvInicialreal}
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.InvInicialreal-this.state.ffañoseleccionado.InvInicial).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.InvInicialreal/this.state.ffañoseleccionado.InvInicial).toFixed(2)) ? 0: (this.state.ffañoseleccionado.InvInicialreal/this.state.ffañoseleccionado.InvInicial).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.InvInicialreal/this.state.ffañoseleccionado.InvInicial).toFixed(2)) ? 0: (this.state.ffañoseleccionado.InvInicialreal/this.state.ffañoseleccionado.InvInicial).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.InvInicialmes}
                                    </td>
                                    <td className="interactive"  onClick={this.seleccseminversiones.bind(this)}>
                                        ${this.state.ffañoseleccionado.InvInicialrealmes}
                                    </td>
                                    <td className="fftotales">
                                        -
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.InvInicialrealmes-this.state.ffañoseleccionado.InvInicialmes).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.InvInicialrealmes/this.state.ffañoseleccionado.InvInicialmes).toFixed(2)) ? 0: (this.state.ffañoseleccionado.InvInicialrealmes/this.state.ffañoseleccionado.InvInicialmes).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.InvInicialrealmes/this.state.ffañoseleccionado.InvInicialmes).toFixed(2)) ? 0: (this.state.ffañoseleccionado.InvInicialrealmes/this.state.ffañoseleccionado.InvInicialmes).toFixed(2)}%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (+) Ventas
                                        </div>
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalventas}
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalrealventas}
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalrealventas).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalrealventas/this.state.ffañoseleccionado.totalventas).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalrealventas/this.state.ffañoseleccionado.totalventas).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalrealventas/this.state.ffañoseleccionado.totalventas).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalrealventas/this.state.ffañoseleccionado.totalventas).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalmesventas}
                                    </td>
                                    <td className="interactive"  onClick={this.seleccsemventas.bind(this)}>
                                        ${this.state.ffañoseleccionado.totalmesrealventas}
                                    </td>
                                    <td className="fftotales">
                                        -
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesventas).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealventas/this.state.ffañoseleccionado.totalmesventas).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealventas/this.state.ffañoseleccionado.totalmesventas).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealventas/this.state.ffañoseleccionado.totalmesventas).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealventas/this.state.ffañoseleccionado.totalmesventas).toFixed(2)}%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-bold bg-yellow-400 text-white p-2">
                                        (-) Costo de Ventas
                                        </div>
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalcostos}
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalrealcostos}
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.totalrealcostos-this.state.ffañoseleccionado.totalcostos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalrealcostos/this.state.ffañoseleccionado.totalcostos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalrealcostos/this.state.ffañoseleccionado.totalcostos).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalrealcostos/this.state.ffañoseleccionado.totalcostos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalrealcostos/this.state.ffañoseleccionado.totalcostos).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalmescostos}
                                    </td>
                                    <td className="interactive" onClick={this.seleccsemcostos.bind(this)}>
                                        ${this.state.ffañoseleccionado.totalmesrealcostos}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealcostos*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealcostos*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.totalmesrealcostos-this.state.ffañoseleccionado.totalmescostos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealcostos/this.state.ffañoseleccionado.totalmescostos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealcostos/this.state.ffañoseleccionado.totalmescostos).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealcostos/this.state.ffañoseleccionado.totalmescostos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealcostos/this.state.ffañoseleccionado.totalmescostos).toFixed(2)}%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                        (=) Utilidad Bruta
                                        </div>
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        ${((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos)-(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos)).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos)/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos)).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos)/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos)).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos)/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos)).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos)/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos)).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos)*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos)*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos)-(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos)).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos)/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos)).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos)/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos)).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos)/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos)).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos)/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos)).toFixed(2)}%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-bold bg-yellow-400 text-white p-2">
                                        (-) Gastos <br/>Operativos
                                        </div>
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalgastos}
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalrealgastos}
                                    </td>
                                    <td className="fftotales">
                                        ${(Math.abs(this.state.ffañoseleccionado.totalrealgastos)-Math.abs(this.state.ffañoseleccionado.totalgastos)).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalrealgastos/this.state.ffañoseleccionado.totalgastos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalrealgastos/this.state.ffañoseleccionado.totalgastos).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalrealgastos/this.state.ffañoseleccionado.totalgastos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalrealgastos/this.state.ffañoseleccionado.totalgastos).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalmesgastos}
                                    </td>
                                    <td className="interactive" onClick={this.seleccsemgastos.bind(this)}>
                                        ${this.state.ffañoseleccionado.totalmesrealgastos}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealgastos*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealgastos*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${(Math.abs(this.state.ffañoseleccionado.totalmesrealgastos)-Math.abs(this.state.ffañoseleccionado.totalmesgastos)).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealgastos/this.state.ffañoseleccionado.totalmesgastos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealgastos/this.state.ffañoseleccionado.totalmesgastos).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealgastos/this.state.ffañoseleccionado.totalmesgastos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealgastos/this.state.ffañoseleccionado.totalmesgastos).toFixed(2)}%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                        (=) EBITDA
                                        </div>
                                    </td>
                                    <td className="fftotales">
                                        ${((this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos)-Math.abs(this.state.ffañoseleccionado.totalgastos)).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        ${((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos)-Math.abs(this.state.ffañoseleccionado.totalrealgastos)).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        ${((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos))-(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos))).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos))/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos))).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos))/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos))).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos))/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos))).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos))/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos))).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${((this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos)-Math.abs(this.state.ffañoseleccionado.totalmesgastos)).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        ${((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos)-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos)).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos))*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos))*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos))-(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos))).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos))/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos))).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos))/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos))).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos))/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos))).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos))/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos))).toFixed(2)}%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-bold bg-yellow-400 text-white p-2">
                                        (-) Impuestos,<br/>intereses,<br/>depreciaciones<br/>y amortizaciones
                                         </div>   
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalimpuestos}
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalrealimpuestos}
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.totalrealimpuestos-this.state.ffañoseleccionado.totalimpuestos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalrealimpuestos/this.state.ffañoseleccionado.totalimpuestos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalrealimpuestos/this.state.ffañoseleccionado.totalimpuestos).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalrealimpuestos/this.state.ffañoseleccionado.totalimpuestos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalrealimpuestos/this.state.ffañoseleccionado.totalimpuestos).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${this.state.ffañoseleccionado.totalmesimpuestos}
                                    </td>
                                    <td className="interactive" onClick={this.seleccsemimpuestos.bind(this)}>
                                        ${this.state.ffañoseleccionado.totalmesrealimpuestos}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealimpuestos*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealimpuestos*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.totalmesrealimpuestos-this.state.ffañoseleccionado.totalmesimpuestos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealimpuestos/this.state.ffañoseleccionado.totalmesimpuestos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealimpuestos/this.state.ffañoseleccionado.totalmesimpuestos).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN((this.state.ffañoseleccionado.totalmesrealimpuestos/this.state.ffañoseleccionado.totalmesimpuestos).toFixed(2)) ? 0: (this.state.ffañoseleccionado.totalmesrealimpuestos/this.state.ffañoseleccionado.totalmesimpuestos).toFixed(2)}%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-md font-normal bg-gray-200 text-gray p-2">
                                        (=) Utilidad ó<br/>Pérdida Neta
                                        </div> 
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos)-this.state.ffañoseleccionado.totalimpuestos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos)-this.state.ffañoseleccionado.totalrealimpuestos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        ${((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos)-this.state.ffañoseleccionado.totalrealimpuestos)-(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos)-this.state.ffañoseleccionado.totalimpuestos)).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos)-this.state.ffañoseleccionado.totalrealimpuestos)/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos)-this.state.ffañoseleccionado.totalimpuestos)).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos)-this.state.ffañoseleccionado.totalrealimpuestos)/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos)-this.state.ffañoseleccionado.totalimpuestos)).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos)-this.state.ffañoseleccionado.totalrealimpuestos)/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos)-this.state.ffañoseleccionado.totalimpuestos)).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorreal+this.state.ffañoseleccionado.InvInicialreal+this.state.ffañoseleccionado.totalrealventas-this.state.ffañoseleccionado.totalrealcostos-Math.abs(this.state.ffañoseleccionado.totalrealgastos)-this.state.ffañoseleccionado.totalrealimpuestos)/(this.state.ffañoseleccionado.UPAnoanterior+this.state.ffañoseleccionado.InvInicial+this.state.ffañoseleccionado.totalventas-this.state.ffañoseleccionado.totalcostos-Math.abs(this.state.ffañoseleccionado.totalgastos)-this.state.ffañoseleccionado.totalimpuestos)).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos)-this.state.ffañoseleccionado.totalmesimpuestos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        ${(this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos)-this.state.ffañoseleccionado.totalmesrealimpuestos).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos)-this.state.ffañoseleccionado.totalmesrealimpuestos)*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos)-this.state.ffañoseleccionado.totalmesrealimpuestos)*100/this.state.ffañoseleccionado.totalmesrealventas).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        ${((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos)-this.state.ffañoseleccionado.totalmesrealimpuestos)-(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos)-this.state.ffañoseleccionado.totalmesimpuestos)).toFixed(2)}
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos)-this.state.ffañoseleccionado.totalmesrealimpuestos)/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos)-this.state.ffañoseleccionado.totalmesimpuestos)).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos)-this.state.ffañoseleccionado.totalmesrealimpuestos)/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos)-this.state.ffañoseleccionado.totalmesimpuestos)).toFixed(2)}%
                                    </td>
                                    <td className="fftotales">
                                        {isNaN(((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos)-this.state.ffañoseleccionado.totalmesrealimpuestos)/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos)-this.state.ffañoseleccionado.totalmesimpuestos)).toFixed(2)) ? 0: ((this.state.ffañoseleccionado.UPAnoanteriorrealmes+this.state.ffañoseleccionado.InvInicialrealmes+this.state.ffañoseleccionado.totalmesrealventas-this.state.ffañoseleccionado.totalmesrealcostos-Math.abs(this.state.ffañoseleccionado.totalmesrealgastos)-this.state.ffañoseleccionado.totalmesrealimpuestos)/(this.state.ffañoseleccionado.UPAnoanteriormes+this.state.ffañoseleccionado.InvInicialmes+this.state.ffañoseleccionado.totalmesventas-this.state.ffañoseleccionado.totalmescostos-Math.abs(this.state.ffañoseleccionado.totalmesgastos)-this.state.ffañoseleccionado.totalmesimpuestos)).toFixed(2)}%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {(this.state.ffseleccion==='mostrar' && this.state.ffsemanas.length!==0) && <div className="flex"><table>
                            <thead>
                                <tr><th></th><th className="cuadroanio text-md font-bold text-white p-3" colSpan={4}> Semana {this.state.ffsemanas[parseInt(this.state.ffindex)].start}-{this.state.ffsemanas[parseInt(this.state.ffindex)].end}</th></tr>
                                <tr><th></th>
                                    <th className="cuadroanio text-md font-bold text-white p-3">Nombre</th>
                                    <th className="cuadroanio text-md font-bold text-white p-3">Total <br/> proyectado</th>
                                    <th className="cuadroanio text-md font-bold text-white p-3">Total <br/> Real</th>
                                    <th className="cuadroanio text-md font-bold text-white p-3" colSpan={2}>Diferencia</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.todasemana.UPAnoanterior!==0 && <tr>
                                <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Utilidad ó<br/> Pérdida del<br/>Año anterior</td>
                                <td className="fftotales">-</td>
                                <td className="fftotales">{this.state.todasemana.UPAnoanterior}</td>
                                <td className="fftotales">{this.state.todasemana.UPAnoanteriorreal}</td>
                                <td className="fftotales">{(this.state.todasemana.UPAnoanteriorreal-this.state.todasemana.UPAnoanterior).toFixed(2)}</td>
                                </tr>}
                            
                            {this.state.todasemana.Inversiones.map(prod=><tr key={prod.nombre}>
                                    <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Inversión</td>
                                    <td className="fftotales">{prod.nombre}</td>
                                    <td className="fftotales">${prod.info.cantidad}</td>
                                    <td className="fftotales">${prod.info.CR[this.state.ffindex]}</td>
                                    <td className="fftotales">${(prod.info.CR[this.state.ffindex]-prod.info.cantidad).toFixed(2)}</td>
                                </tr>)}
                                <tr>
                                    <td className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (+) Inversiones</td>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${this.state.todasemana.InvInicialsem}</td>
                                    <td className="fftotales">${this.state.todasemana.InvInicialsemreal}</td>
                                    <td className="fftotales">${(this.state.todasemana.InvInicialsemreal-this.state.todasemana.InvInicialsem).toFixed(2)}</td>
                                </tr>
                            {this.state.todasemana.Ventas.map(prod=><tr key={prod.nombre}>
                                <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Ventas</td>
                                <td className="fftotales">{prod.nombre}</td>
                                <td className="fftotales">${(prod.info.unidades*prod.info.precio).toFixed(2)}</td>
                                <td className="fftotales">${prod.info.VR[this.state.ffindex]}</td>
                                <td className="fftotales">${((prod.info.unidades*prod.info.precio)-prod.info.VR[this.state.ffindex]).toFixed(2)}</td>
                            </tr>)}
                                <tr>
                                    <td className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (+) Ventas</td>
                                        <td className="fftotales">Total</td>
                                    <td className="fftotales">${this.state.todasemana.totalsemventas}</td>
                                    <td className="fftotales">${this.state.todasemana.totalsemrealventas}</td>
                                    <td className="fftotales">${(this.state.todasemana.totalsemrealventas-this.state.todasemana.totalsemventas).toFixed(2)}</td>
                                </tr>
                                {this.state.todasemana.Ventas.map(prod=><tr key={prod.nombre}>
                                    <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Costo de Ventas</td>
                                    <td className="fftotales">{prod.nombre}</td>
                                    <td className="fftotales">${(prod.info.unidades*prod.info.costo).toFixed(2)}</td>
                                    <td className="fftotales">${prod.info.CR[this.state.ffindex]}</td>
                                    <td className="fftotales">${((prod.info.unidades*prod.info.costo)-prod.info.CR[this.state.ffindex]).toFixed(2)}</td>
                                </tr>)}
                                <tr>
                                    <td className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (-) Costo de Ventas</td>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${this.state.todasemana.totalsemcostos}</td>
                                    <td className="fftotales">${this.state.todasemana.totalsemrealcostos}</td>
                                    <td className="fftotales">${(this.state.todasemana.totalsemrealcostos-this.state.todasemana.totalsemcostos).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (=) Utilidad Bruta</td>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${(this.state.todasemana.UPAnoanterior+this.state.todasemana.InvInicialsem+this.state.todasemana.totalsemventas-this.state.todasemana.totalsemcostos).toFixed(2)}</td>
                                    <td className="fftotales">${(this.state.todasemana.UPAnoanteriorreal+this.state.todasemana.InvInicialsemreal+this.state.todasemana.totalsemrealventas-this.state.todasemana.totalsemrealcostos).toFixed(2)}</td>
                                    <td className="fftotales">${((this.state.todasemana.UPAnoanteriorreal+this.state.todasemana.InvInicialsemreal+this.state.todasemana.totalsemrealventas-this.state.todasemana.totalsemrealcostos)-(this.state.todasemana.UPAnoanterior+this.state.todasemana.InvInicialsem+this.state.todasemana.totalsemventas-this.state.todasemana.totalsemcostos)).toFixed(2)}</td>
                                </tr>
                                {this.state.todasemana.Gastos.map(prod=><tr key={prod.nombre}>
                                    <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Gastos</td>
                                    <td className="fftotales">{prod.nombre}</td>
                                    <td className="fftotales">${prod.info.cantidad}</td>
                                    <td className="fftotales">${prod.info.CR[this.state.ffindex]}</td>
                                    <td className="fftotales">${(prod.info.CR[this.state.ffindex]-prod.info.cantidad).toFixed(2)}</td>
                                </tr>)}
                                <tr>
                                    <td className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (-) Gastos<br/>Operativos</td>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${this.state.todasemana.totalsemgastos}</td>
                                    <td className="fftotales">${this.state.todasemana.totalsemrealgastos}</td>
                                    <td className="fftotales">${(Math.abs(this.state.todasemana.totalsemrealgastos)-Math.abs(this.state.todasemana.totalsemgastos)).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (=) EBITDA</td>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${(this.state.todasemana.UPAnoanterior+this.state.todasemana.InvInicialsem+this.state.todasemana.totalsemventas-this.state.todasemana.totalsemcostos-Math.abs(this.state.todasemana.totalsemgastos)).toFixed(2)}</td>
                                    <td className="fftotales">${(this.state.todasemana.UPAnoanteriorreal+this.state.todasemana.InvInicialsemreal+this.state.todasemana.totalsemrealventas-this.state.todasemana.totalsemrealcostos-Math.abs(this.state.todasemana.totalsemrealgastos)).toFixed(2)}</td>
                                    <td className="fftotales">${((this.state.todasemana.UPAnoanteriorreal+this.state.todasemana.InvInicialsemreal+this.state.todasemana.totalsemrealventas-this.state.todasemana.totalsemrealcostos-Math.abs(this.state.todasemana.totalsemrealgastos))-(this.state.todasemana.UPAnoanterior+this.state.todasemana.InvInicialsem+this.state.todasemana.totalsemventas-this.state.todasemana.totalsemcostos-Math.abs(this.state.todasemana.totalsemgastos))).toFixed(2)}</td>
                                </tr>
                                {this.state.todasemana.Impuestos.map(prod=><tr key={prod.nombre}>
                                    <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">ITDA</td>
                                    <td className="fftotales">{prod.nombre}</td>
                                    <td className="fftotales">${prod.info.cantidad}</td>
                                    <td className="fftotales">${prod.info.CR[this.state.ffindex]}</td>
                                    <td className="fftotales">${(prod.info.CR[this.state.ffindex]-prod.info.cantidad).toFixed(2)}</td>
                                </tr>)}
                                <tr>
                                    <td className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (-) Impuestos,<br/>Intereses, <br/>depreciaciones<br/>y amortizaciones</td>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${this.state.todasemana.totalsemimpuestos}</td>
                                    <td className="fftotales">${this.state.todasemana.totalsemrealimpuestos}</td>
                                    <td className="fftotales">${(this.state.todasemana.totalsemrealimpuestos-this.state.todasemana.totalsemimpuestos).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className="text-md font-bold bg-yellow-400 text-white p-2">
                                         (=) Utilidad ó <br/>Pérdida Neta</td>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${(this.state.todasemana.UPAnoanterior+this.state.todasemana.InvInicialsem+this.state.todasemana.totalsemventas-this.state.todasemana.totalsemcostos-Math.abs(this.state.todasemana.totalsemgastos)-this.state.todasemana.totalsemimpuestos).toFixed(2)}</td>
                                    <td className="fftotales">${(this.state.todasemana.UPAnoanteriorreal+this.state.todasemana.InvInicialsemreal+this.state.todasemana.totalsemrealventas-this.state.todasemana.totalsemrealcostos-Math.abs(this.state.todasemana.totalsemrealgastos)-this.state.todasemana.totalsemrealimpuestos).toFixed(2)}</td>
                                    <td className="fftotales">${((this.state.todasemana.UPAnoanteriorreal+this.state.todasemana.InvInicialsemreal+this.state.todasemana.totalsemrealventas-this.state.todasemana.totalsemrealcostos-Math.abs(this.state.todasemana.totalsemrealgastos)-this.state.todasemana.totalsemrealimpuestos)-(this.state.todasemana.UPAnoanterior+this.state.todasemana.InvInicialsem+this.state.todasemana.totalsemventas-this.state.todasemana.totalsemcostos-Math.abs(this.state.todasemana.totalsemgastos)-this.state.todasemana.totalsemimpuestos)).toFixed(2)}</td>
                                </tr>
                            </tbody>
                    </table>
                    
                    </div>}
                    {this.state.ffseleccion==='mostrartodo' && <div className="overflow-x-scroll todas container" >
                        <table >
                                <thead>
                                    <tr>
                                    <th></th>
                                    {this.state.ffsemanas.map((sem,index)=><th key={index} className="cuadroanio text-md font-bold text-white p-3" colSpan={4}>
                                        Semana {sem.start} - {sem.end}                                  
                                    </th>)}
                                    </tr>
                                    <tr>
                                    <th></th>
                                    {this.state.ffsemanas.map((sem,index)=><Fragment key={index}>
                                        <th className="cuadroanio text-md font-bold text-white p-3 px-16">Nombre</th>
                                        <th className="cuadroanio text-md font-bold text-white p-3">Total <br/>proyectado</th>
                                        <th className="cuadroanio text-md font-bold text-white p-3">Total <br/>real</th>
                                        <th className="cuadroanio text-md font-bold text-white p-3">Diferencia</th>                                
                                    </Fragment>)}
                                    </tr>
                                    </thead>
                            <tbody>
                                {this.state.ffmesseleccionado==="1"&& <tr>
                                <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Utilidad ó<br/> Pérdida del<br/>Año anterior</td>
                                {this.state.todassemanas.map((todasemana,index)=><Fragment key={index}>
                                    <td colSpan={4} className="border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="fftotales content overflow-auto w-48" >-</td>
                                                    <td className="fftotales cantidades pl-2">{todasemana.UPAnoanterior}</td>
                                                    <td className="fftotales cantidades pl-2">{todasemana.UPAnoanteriorreal}</td>
                                                    <td className="fftotales cantidades pl-2">{(todasemana.UPAnoanteriorreal-todasemana.UPAnoanterior).toFixed(2)}</td>
                                                </tr>
                                                
                                                
                                            </tbody>
                                        </table>
                                    </td>
                                </Fragment>)}
                                </tr>}
                            <tr>
                            <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Inversión</td>
                            {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td colSpan={4} className="border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">
                                        <table className="">
                                            <tbody>
                                {todasemana.Inversiones.map(inversion=><tr className="" key={inversion.nombre}>
                                        <td className="fftotales content overflow-auto w-48" >{inversion.nombre}</td>
                                        <td className="fftotales cantidades pl-2" >${inversion.info.cantidad}</td>
                                        <td className="fftotales cantidades pl-6" >${inversion.info.CR[index]}</td>
                                        <td className="fftotales cantidades pl-2" >${(inversion.info.CR[index]-inversion.info.cantidad).toFixed(2)}</td> 
                                </tr>)}
                                </tbody>
                                </table>
                                </td>                        
                            </Fragment>)}
                            </tr>
                            <tr>
                                <td className="text-md font-bold bg-yellow-400 text-white p-2 labeltable">
                                         (+) Inversiones</td>
                                {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${todasemana.InvInicialsem}</td>
                                    <td className="fftotales">${todasemana.InvInicialsemreal}</td>
                                    <td className="fftotales border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">${(todasemana.InvInicialsemreal-todasemana.InvInicialsem).toFixed(2)}</td>
                                </Fragment>)}
                            </tr>
                            <tr>
                            <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Ventas</td>
                            {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td colSpan={4} className="border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800 ">
                                        <table>
                                            <tbody>
                                {todasemana.Ventas.map(prod=><tr className="" key={prod.nombre}>
                                        <td className="fftotales content overflow-auto w-48" >{prod.nombre}</td>
                                        <td className="fftotales cantidades pl-2" >${(prod.info.unidades*prod.info.precio).toFixed(2)}</td>
                                        <td className="fftotales cantidades pl-6" >${prod.info.VR[index]}</td>
                                        <td className="fftotales cantidades pl-2" >${(prod.info.VR[index]-(prod.info.unidades*prod.info.precio)).toFixed(2)}</td> 
                                </tr>)}
                                </tbody>
                                </table>
                                </td>                        
                            </Fragment>)}
                            </tr> 
                            <tr>
                                <td className="text-md font-bold bg-yellow-400 text-white p-2 labeltable">
                                    (+) Ventas</td>
                                    {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${todasemana.totalsemventas}</td>
                                    <td className="fftotales">${todasemana.totalsemrealventas}</td>
                                    <td className="fftotales border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800 ">${(todasemana.totalsemrealventas-todasemana.totalsemventas).toFixed(2)}</td>
                                </Fragment>)}
                            </tr>
                            <tr>
                            <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Costos</td>
                            {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td colSpan={4} className="border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">
                                        <table className="">
                                            <tbody>
                                {todasemana.Ventas.map(prod=><tr className="" key={prod.nombre}>
                                        <td className="fftotales content overflow-auto w-48" >{prod.nombre}</td>
                                        <td className="fftotales cantidades pl-2" >${(prod.info.unidades*prod.info.costo).toFixed(2)}</td>
                                        <td className="fftotales cantidades pl-6" >${prod.info.CR[index]}</td>
                                        <td className="fftotales cantidades pl-2" >${(prod.info.CR[index]-(prod.info.unidades*prod.info.costo)).toFixed(2)}</td> 
                                </tr>)}
                                </tbody>
                                </table>
                                </td>                        
                            </Fragment>)}
                            </tr>
                            <tr>
                                <td className="text-md font-bold bg-yellow-400 text-white p-2 labeltable">
                                    (-) Costo de Ventas</td>
                                    {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${todasemana.totalsemcostos}</td>
                                    <td className="fftotales">${todasemana.totalsemrealcostos}</td>
                                    <td className="fftotales border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">${(todasemana.totalsemrealcostos-todasemana.totalsemcostos).toFixed(2)}</td>
                                </Fragment>)}
                            </tr>
                            <tr>
                                <td className="text-md font-bold bg-yellow-400 text-white p-2 labeltable">
                                    (=) Utilidad Bruta</td>
                                    {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${(todasemana.UPAnoanterior+todasemana.InvInicialsem+todasemana.totalsemventas-todasemana.totalsemcostos).toFixed(2)}</td>
                                    <td className="fftotales">${(todasemana.UPAnoanteriorreal+todasemana.InvInicialsemreal+todasemana.totalsemrealventas-todasemana.totalsemrealcostos).toFixed(2)}</td>
                                    <td className="fftotales border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">${((todasemana.UPAnoanteriorreal+todasemana.InvInicialsemreal+todasemana.totalsemrealventas-todasemana.totalsemrealcostos)-(todasemana.UPAnoanterior+todasemana.InvInicialsem+todasemana.totalsemventas-todasemana.totalsemcostos)).toFixed(2)}</td>
                                </Fragment>)}
                            </tr>
                            <tr>
                            <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Gastos</td>
                            {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td colSpan={4} className="border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">
                                        <table className="">
                                        <tbody>
                                {todasemana.Gastos.map(gasto=><tr className="" key={gasto.nombre}>
                                        <td className="fftotales content overflow-auto w-48" >{gasto.nombre}</td>
                                        <td className="fftotales cantidades pl-2" >${gasto.info.cantidad}</td>
                                        <td className="fftotales cantidades pl-6" >${gasto.info.CR[index]}</td>
                                        <td className="fftotales cantidades pl-2" >${(gasto.info.CR[index]-gasto.info.cantidad).toFixed(2)}</td> 
                                </tr>)}
                                </tbody>
                                </table>
                                </td>                        
                            </Fragment>)}
                            </tr>
                            <tr>
                                <td className="text-md font-bold bg-yellow-400 text-white p-2 labeltable">
                                    (-) Gastos<br/>Operativos</td>
                                    {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${todasemana.totalsemgastos}</td>
                                    <td className="fftotales">${todasemana.totalsemrealgastos}</td>
                                    <td className="fftotales border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">${(Math.abs(todasemana.totalsemrealgastos)-Math.abs(todasemana.totalsemgastos)).toFixed(2)}</td>
                                </Fragment>)}
                            </tr>
                            <tr>
                                <td className="text-md font-bold bg-yellow-400 text-white p-2 labeltable">
                                         (=) EBITDA</td>
                                {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                <td className="fftotales">Total</td>
                                    <td className="fftotales">${(todasemana.UPAnoanterior+todasemana.InvInicialsem+todasemana.totalsemventas-todasemana.totalsemcostos-Math.abs(todasemana.totalsemgastos)).toFixed(2)}</td>
                                    <td className="fftotales">${(todasemana.UPAnoanteriorreal+todasemana.InvInicialsemreal+todasemana.totalsemrealventas-todasemana.totalsemrealcostos-Math.abs(todasemana.totalsemrealgastos)).toFixed(2)}</td>
                                    <td className="fftotales border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">${((todasemana.UPAnoanteriorreal+todasemana.InvInicialsemreal+todasemana.totalsemrealventas-todasemana.totalsemrealcostos-Math.abs(todasemana.totalsemrealgastos))-(todasemana.UPAnoanterior+todasemana.InvInicialsem+todasemana.totalsemventas-todasemana.totalsemcostos-Math.abs(todasemana.totalsemgastos))).toFixed(2)}</td>
                                </Fragment>)}
                            </tr>
                            <tr>
                            <td className="text-md font-normal bg-gray-200 text-gray p-2 labeltable">Impuestos</td>
                            {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td colSpan={4} className="border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">
                                        <table className="">
                                            <tbody>
                                {todasemana.Impuestos.map(impuesto=><tr className="" key={impuesto.nombre}>
                                        <td className="fftotales content overflow-auto w-48" >{impuesto.nombre}</td>
                                        <td className="fftotales cantidades pl-2" >${impuesto.info.cantidad}</td>
                                        <td className="fftotales cantidades pl-6" >${impuesto.info.CR[index]}</td>
                                        <td className="fftotales cantidades pl-2" >${(impuesto.info.CR[index]-impuesto.info.cantidad).toFixed(2)}</td> 
                                </tr>)}
                                </tbody>
                                </table>
                                </td>                        
                            </Fragment>)}
                            </tr>
                            <tr>
                                <td className="text-md font-bold bg-yellow-400 text-white p-2 labeltable">
                                         (-) Impuestos,<br/>Intereses, <br/>depreciaciones<br/>y amortizaciones</td>
                                {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td className="fftotales">Total</td>
                                    <td className="fftotales">${todasemana.totalsemimpuestos}</td>
                                    <td className="fftotales">${todasemana.totalsemrealimpuestos}</td>
                                    <td className="fftotales border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">${(todasemana.totalsemrealimpuestos-todasemana.totalsemimpuestos).toFixed(2)}</td>
                                </Fragment>)}
                            </tr>
                            <tr>
                                <td className="text-md font-bold bg-yellow-400 text-white p-2 labeltable">
                                         (=) Utilidad ó <br/>Pérdida Neta</td>
                                {this.state.todassemanas.map((todasemana,index)=> <Fragment key={index}>
                                    <td className="fftotales lateralI">Total</td>
                                    <td className="fftotales">${(todasemana.UPAnoanterior+todasemana.InvInicialsem+todasemana.totalsemventas-todasemana.totalsemcostos-Math.abs(todasemana.totalsemgastos)-todasemana.totalsemimpuestos).toFixed(2)}</td>
                                    <td className="fftotales">${(todasemana.UPAnoanteriorreal+todasemana.InvInicialsemreal+todasemana.totalsemrealventas-todasemana.totalsemrealcostos-Math.abs(todasemana.totalsemrealgastos)-todasemana.totalsemrealimpuestos).toFixed(2)}</td>
                                    <td className="fftotales border-r-1 border-l-0 border-t-0 border-b-0 border-gray-800">${((todasemana.UPAnoanteriorreal+todasemana.InvInicialsemreal+todasemana.totalsemrealventas-todasemana.totalsemrealcostos-Math.abs(todasemana.totalsemrealgastos)-todasemana.totalsemrealimpuestos)-(todasemana.UPAnoanterior+todasemana.InvInicialsem+todasemana.totalsemventas-todasemana.totalsemcostos-Math.abs(todasemana.totalsemgastos)-todasemana.totalsemimpuestos)).toFixed(2)}</td>
                                </Fragment>)}
                            </tr>
                            </tbody>
                    </table></div>}
                    {(this.state.ffsemanas.length!==0 &&(this.state.ffseleccion==='ventas' || this.state.ffseleccion==='costos')  ) && <table>
                        <caption className="cuadroanio text-md font-bold text-white ">{(this.state.ffseleccion).toUpperCase()}</caption>
                        <thead>
                            {/*<tr>
                                <th className="cuadroanio text-md font-bold text-white ">Producto\Semanas</th>
                                {this.state.ffsemanas.map((sem,index)=><th key={sem.start} id={index} onClick={this.seleccsemventas.bind(this)} className="cuadroanio text-md font-bold text-white w-24 ">
                                    {sem.start}-{sem.end}
                                </th>)}
                            </tr>*/}
                            <tr>
                                <td className="cuadroanio text-md font-bold text-white ">Productos</td>
                                <td className="cuadroanio text-md font-bold text-white">Proyectado</td>
                                <td className="cuadroanio text-md font-bold text-white">Real</td>
                                <td className="cuadroanio text-md font-bold text-white" colSpan={2}>Diferencia</td>
                            </tr>
                        </thead>
                        
                            
                            {(this.state.ffproductos.length!==0 && this.state.ffseleccion==='ventas') &&<tbody>
                                {this.state.ffproductos.map(prod=><tr key={prod.nombre}>
                                    <td>{prod.nombre}</td>
                                    <td>${(prod.info.unidades*prod.info.precio).toFixed(2)}</td>
                                    <td><input type="number" step="0.01" id={prod.nombre} className="w-28" defaultValue={`${prod.info.VR[this.state.ffindex]}`} onBlur={this.cambioventassemanas.bind(this)}/></td>
                                    <td>${(prod.info.VR[this.state.ffindex]-(prod.info.unidades*prod.info.precio)).toFixed(2)}</td>
                                    <td>{(prod.info.VR[this.state.ffindex]/(prod.info.unidades*prod.info.precio)).toFixed(2)}%</td>
                                </tr>)}
                                </tbody>}
                            {(this.state.ffproductos.length!==0 && this.state.ffseleccion==='costos') &&<tbody>
                                {this.state.ffproductos.map(prod=><tr key={prod.nombre}>
                                    <td>{prod.nombre}</td>
                                    <td>${(prod.info.unidades*prod.info.costo).toFixed(2)}</td>
                                    <td><input type="number" step="0.01" id={prod.nombre} className="w-28" defaultValue={`${prod.info.CR[this.state.ffindex]}`} onBlur={this.cambiocostossemanas.bind(this)}/></td>
                                    <td>${(prod.info.CR[this.state.ffindex]-(prod.info.unidades*prod.info.costo)).toFixed(2)}</td>
                                    <td>{(prod.info.CR[this.state.ffindex]/(prod.info.unidades*prod.info.costo)).toFixed(2)}%</td>
                                </tr>)}
                                </tbody>}
                       
                        </table>}       
                        {(this.state.ffsemanas.length!==0 &&(this.state.ffseleccion==='gastos' || this.state.ffseleccion==='impuestos' || this.state.ffseleccion==='inversiones')  ) && <table>
                        <caption className="cuadroanio text-md font-bold text-white ">{(this.state.ffseleccion).toUpperCase()}</caption>
                        <thead>
                            {/*<tr>
                                <th className="cuadroanio text-md font-bold text-white ">Producto\Semanas</th>
                                {this.state.ffsemanas.map((sem,index)=><th key={sem.start} id={index} onClick={this.seleccsemventas.bind(this)} className="cuadroanio text-md font-bold text-white w-24 ">
                                    {sem.start}-{sem.end}
                                </th>)}
                            </tr>*/}
                            <tr>
                                <td className="cuadroanio text-md font-bold text-white ">Tipo</td>
                                <td className="cuadroanio text-md font-bold text-white ">Nombre</td>
                                <td className="cuadroanio text-md font-bold text-white">Proyectado</td>
                                <td className="cuadroanio text-md font-bold text-white">Real</td>
                                <td className="cuadroanio text-md font-bold text-white" colSpan={2}>Diferencia</td>
                            </tr>
                        </thead>
                        {(this.state.ffproductos.length!==0 && this.state.ffseleccion==='inversiones') &&<tbody>
                                {this.state.ffproductos.map(prod=><tr key={prod.nombre}>
                                    <td>{prod.nombre}</td>
                                    <td>${prod.info.cantidad}</td>
                                    <td><input type="number" step="0.01" id={prod.nombre} className="w-28" defaultValue={`${prod.info.CR[this.state.ffindex]}`} onBlur={this.cambiosinversionessemanas.bind(this)}/></td>
                                    <td>${(prod.info.CR[this.state.ffindex]-(prod.info.cantidad)).toFixed(2)}</td>
                                    <td>{(prod.info.CR[this.state.ffindex]/(prod.info.cantidad)).toFixed(2)}%</td>
                                </tr>)}
                                </tbody>}
                            {(this.state.ffproductos.length!==0 && this.state.ffseleccion==='gastos') &&<tbody>
                                {this.state.ffproductos.map(prod=><tr key={prod.nombre}>
                                    <td>{prod.tipo}</td>
                                    <td>{prod.nombre}</td>
                                    <td>${prod.info.cantidad}</td>
                                    <td><input type="number" step="0.01" id={prod.nombre} className="w-28" defaultValue={`${prod.info.CR[this.state.ffindex]}`} onBlur={this.cambiosgastossemanas.bind(this)}/></td>
                                    <td>${(prod.info.CR[this.state.ffindex]-(prod.info.cantidad)).toFixed(2)}</td>
                                    <td>{(prod.info.CR[this.state.ffindex]/(prod.info.cantidad)).toFixed(2)}%</td>
                                </tr>)}
                                </tbody>}
                            {(this.state.ffproductos.length!==0 && this.state.ffseleccion==='impuestos') &&<tbody>
                                {this.state.ffproductos.map(prod=><tr key={prod.nombre}>
                                    <td>{prod.tipo}</td>
                                    <td>{prod.nombre}</td>
                                    <td>${prod.info.cantidad}</td>
                                    <td><input type="number" step="0.01" id={prod.nombre} className="w-28" defaultValue={`${prod.info.CR[this.state.ffindex]}`} onBlur={this.cambiosimpuestossemanas.bind(this)}/></td>
                                    <td>${(prod.info.CR[this.state.ffindex]-(prod.info.cantidad)).toFixed(2)}</td>
                                    <td>{(prod.info.CR[this.state.ffindex]/(prod.info.cantidad)).toFixed(2)}%</td>
                                </tr>)}
                                </tbody>}
                       
                        </table>}               
                </div>
                <div className="mt-8 hiddenNone" id="CQuestions">
                    <div className="bg-gray-100 rounded-xl mt-4 mb-4">
                        <div className="text-gray-600 pt-4 px-4 text-lg flex"  onClick={(e)=>{
                         document.getElementsByClassName("accordion")[3].classList.toggle('colapse');                         
                         var content=document.querySelectorAll('.accordionarrow');
                         for(let i=6;i<8;i++){
                            content[i].classList.toggle('hiddenNone');
                         }
                     }}>PROYECCIÓN FINANCIERA <ChevronDownIcon className="w-6 hiddenNone accordionarrow" /><ChevronUpIcon className="w-6 accordionarrow" /></div>
                        <div className="text-xs text-gray-400 p-4 accordion">Una proyección financiera es un análisis que te permite calcular "desde el punto de vista financiero y contable" cuál será el desenvolvimiento de tu empresa o negocio en un futuro determinado. De esta manera, podrás anticipar las eventuales utilidades o pérdidas del proyecto, así como conocer el punto de equilibrio y la necesidad de capital que ocuparas para crecer tu startup.</div>
                    </div>
                    <div>
                        <label className="mr-4">Selecciona un año</label>
                        <select onChange={this.Ccambioaño.bind(this)}>
                            {this.state.PF.Años.map(año=>
                                <option key={año.numero} value={año.numero}>
                                    Año {año.alias}
                                </option>
                            )}
                        </select>
                        <label className="mx-4">Selecciona un mes</label>
                        <select onChange={this.Ccambiomes.bind(this)} defaultValue="default">
                            <option value="default" disabled hidden>Escoge un mes</option>
                            <option value={1}>Enero</option>
                            <option value={2}>Febrero</option>
                            <option value={3}>Marzo</option>
                            <option value={4}>Abril</option>
                            <option value={5}>Mayo</option>
                            <option value={6}>Junio</option>
                            <option value={7}>Julio</option>
                            <option value={8}>Agosto</option>
                            <option value={9}>Septiembre</option>
                            <option value={10}>Octubre</option>
                            <option value={11}>Noviembre</option>
                            <option value={12}>Diciembre</option>
                        </select>
                        <br/>
                        
                    </div>
                    <div className="mt-4">
                        <label className="mx-4">Selecciona consulta</label>
                        <select onChange={this.consultar.bind(this)} defaultValue="default">
                            <option value="default" disabled hidden>Escoge una consulta</option>
                            <option value={1}>Totales entre % Ventas</option>
                            <option value={2}>Gastos Administrativos a Total de Gastos</option>
                            <option value={3}>Gastos Administrativos a Total de Ventas</option>
                            <option value={4}>Gastos Mercadotecnia a Total de Gastos</option>
                            <option value={5}>Gastos Mercadotecnia a Total de Ventas</option>
                            <option value={6}>Inventario/Costos de Venta a Total de Gastos</option>
                            <option value={7}>Inventario/Costos de Venta a Total de Ventas</option>
                            <option value={8}>Otros Gastos a Total de Gastos</option>
                            <option value={9}>Otros Gastos a Total de Ventas</option>
                            <option value={10}>Impuestos a Total de ITDA</option>
                            <option value={11}>Impuestos a Total de Ventas</option>
                            <option value={12}>Intereses a Total de ITDA</option>
                            <option value={13}>Intereses a Total de Ventas</option>
                        </select>  
                    </div>
                        
                    {this.state.series.length!==0 && <Chart options={this.state.options} series={this.state.series} type="pie" width={500} height={320} />
                    }
                    {this.state.series2.length!==0 && <Chart options={this.state.options2} series={this.state.series2} type="bar" width={500} height={380} />
                    }
                </div>
                
                
            </div>
        );
    }
}
export default Finanzas;